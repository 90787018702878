import {Dispatch} from "redux";
import {
    AUDIT_LIST_HISTORIC_STORE,
    AuditListHistoricDispatchTypes
} from "./AuditListHistoricActionTypes";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyAuditListHistoricStore = () => {
    return async (dispatch: Dispatch<AuditListHistoricDispatchTypes>) => {
        dispatch({
            type: AUDIT_LIST_HISTORIC_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const getHistoricAuditListForAudit = (auditId: number) => {
    return async (dispatch: Dispatch<AuditListHistoricDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                AUDIT_LIST_HISTORIC_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getHistoricDrugAuditList(auditId),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_LIST_HISTORIC_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
