import React from "react";
import PrintableTableHead from "../../../../../Print/Components/PrintableTableHead";
import PrintableTableRow from "../../../../../Print/Components/PrintableTableRow";
import PrintableTH from "../../../../../Print/Components/PrintableTH";
import PrintableH6 from "../../../../../Print/Components/PrintableH6";

const AdministrationReportHistoryHeader = () => {
    return (
        <PrintableTableHead>
            <PrintableTableRow rowType={"headerRow"}>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`50px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"History:"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Change By"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`150`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Administration Date Change"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Qty Change"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Location"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Administered By Change"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Witnessed By Change"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Quality Assured Change"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Dosage Change"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Wastage Change"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Route of Administration Change"} />
                </PrintableTH>
            </PrintableTableRow>
        </PrintableTableHead>
    );
};

export default AdministrationReportHistoryHeader;
