import React from "react";
import {DrugExpiryItem, DrugExpiryType} from "../Helpers/medicineManagementStatsHelpers";
import {formatUnixToDDMMYYYY} from "../../../../../utils/momentUtils";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../Table/NoItemsMessage";
import FormHeaderWithAction from "../../../../Form/FormHeaderWithAction";
import {IconType} from "../../../../Icon/Icon";
import TableItemAction from "../../../../Table/TableItemAction";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../Navigation/routeNames";

const DrugExpiryTable = ({expiries, noItemsMessage, expiryType}: Props) => {
    const history = useHistory();
    const viewDrugInLocation = (item: TableRow) => {
        if (item.action.drugPackId > 0) {
            //Take user to stock management table with search fields populated.
            history.push({
                pathname: `${routeNames.drugPackWithContentsEdit.path}/${item.action.drugPackId}`
            });
            return;
        }

        //Take user to stock management table with search fields populated.
        history.push({
            pathname: `${routeNames.stockManagement.path}`,
            search: `?DRG=${item.drugName}&BAT=${item.batchNumber}`
        });
    };

    function getHeaderName() {
        switch (expiryType) {
            case DrugExpiryType.Expired:
                return "Expired drugs needing action";
            case DrugExpiryType.Week:
                return "Drugs expiring within the next 7 days";
            case DrugExpiryType.Month:
                return "Drugs expiring within the next 30 days";
        }
    }

    function getHeaderIconClassName() {
        switch (expiryType) {
            case DrugExpiryType.Expired:
            case DrugExpiryType.Week:
                return "red-expiry";
            case DrugExpiryType.Month:
                return "yellow-expiry";
        }
    }

    function getHeaderTooltipText() {
        switch (expiryType) {
            case DrugExpiryType.Expired:
                return "These are the list of drugs that have expired and require urgent action.";
            case DrugExpiryType.Week:
                return "These are the list of drugs expiring in the next 7 days";
            case DrugExpiryType.Month:
                return "These are the list of drugs expiring in the next 30 days";
        }
    }

    return (
        <React.Fragment>
            <FormHeaderWithAction
                headerName={getHeaderName()}
                showIcon={true}
                icon={IconType.Circle}
                iconClassName={getHeaderIconClassName()}
                tooltipText={getHeaderTooltipText()}
            />
            <PulseTable
                items={toTableRow(expiries)}
                headers={{
                    drugName: "Drug Name",
                    batchNumber: "Batch Number",
                    drugLocation: "Drug Location",
                    expiryDate: "Expiry Date",
                    action: "Actions"
                }}
                customRenderers={{
                    action: (item: TableRow) => (
                        <React.Fragment>
                            <TableItemAction
                                tooltipText={
                                    item.action.drugPackId > 0
                                        ? "View Drug Pack"
                                        : "View Drug Batch"
                                }
                                onClick={() => viewDrugInLocation(item)}
                                icon={IconType.Eye}
                            />
                        </React.Fragment>
                    )
                }}
                noItemsSection={<NoItemsMessage message={noItemsMessage} />}
            />
        </React.Fragment>
    );
};

export default DrugExpiryTable;

interface Props {
    expiries: DrugExpiryItem[];
    noItemsMessage: string;
    expiryType: DrugExpiryType;
}

interface TableRow {
    drugName: string;
    batchNumber: string;
    drugLocation: string;
    expiryDate: string;
    action: DrugExpiryItem;
}

function toTableRow(props: DrugExpiryItem[]): TableRow[] {
    return props.map((item) => {
        return {
            drugName: item.drugName,
            batchNumber: item.batchNumber,
            drugLocation: item.drugPackName,
            expiryDate: formatUnixToDDMMYYYY(item.expiry),
            action: item
        };
    });
}
