import React from "react";
import {DrugExpiryRollout} from "../../../../../../store/expiryReports/actions/ExpiryReportActionTypes";
import PrintableTable from "../../../../../Print/Components/PrintableTable";
import ExpiryReportHeader from "./ExpiryReportHeader";
import PrintableTableBody from "../../../../../Print/Components/PrintableTableBody";
import {toArray} from "../../../../../../utils/sortingUtils";
import ExpiryReportRow from "./ExpiryReportRow";
import ExpiryReportKey from "../Components/ExpiryReportKey";

const PrintExpiryReport = (props: DrugExpiryRollout[]) => {
    return (
        <React.Fragment>
            <ExpiryReportKey />
            <PrintableTable width={"800px"} marginTop={"15px"}>
                <ExpiryReportHeader />
                <PrintableTableBody>
                    {toArray(props).map((item, index) => {
                        return <ExpiryReportRow report={item} index={index} key={index} />;
                    })}
                </PrintableTableBody>
            </PrintableTable>
        </React.Fragment>
    );
};

export default PrintExpiryReport;
