import {CalendarEntryDropdownProps} from "../CalendarEntryDropdown";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {useEffect, useState} from "react";
import moment from "moment";
import {CalendarEntry, EventCalendarEntry, FrontlineCalendarEntry, Venue} from "../../../api/grs";
import {getCalendarEntriesList} from "../../../store/calendarEntryList/actions/CalendarEntryListActions";
import {fetchAllVenues} from "../../../store/venueList/actions/VenueListActions";
import {
    NominalActionLocationDetails,
    DDProps,
    DropdownOption,
    getSelectedDropdownOption,
    sortCalendarEntriesToDropdownProps
} from "../Helpers/dropdownUtils";
import {SingleValue} from "react-select";

export function useCalendarEntryDropdown(props: CalendarEntryDropdownProps) {
    const dispatch = useDispatch();
    const calendarEntryListStore = useSelector((state: RootStore) => state.calendarEntryList);
    const [options, setOptions] = useState<DropdownOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<DropdownOption>();
    const [calendarEntries, setCalendarEntries] = useState<CalendarEntry[]>([]);
    const [venues, setVenues] = useState<Venue[]>([]);

    useEffect(() => {
        if (props.startDate === 0) return;

        const actualStart = moment.unix(props.startDate).startOf("day").unix();
        const actualEnd = moment.unix(props.startDate).endOf("day").unix();

        (async function getEntriesForDay() {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const entries: EventCalendarEntry[] | FrontlineCalendarEntry[] = await dispatch(
                getCalendarEntriesList({startDate: actualStart, endDate: actualEnd})
            );

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const venueList: Venue[] = await dispatch(fetchAllVenues());

            if (!venueList) return;

            if (!entries) return;

            //Filter any entries that start on the previous day but straddle the actual start date.
            const filteredEntries = entries.filter(
                (el: CalendarEntry) => el.startDate >= actualStart
            );

            processDataIncoming(filteredEntries, venueList);
        })();
    }, [props.startDate]);

    function processDataIncoming(entryList: CalendarEntry[], venueList: Venue[]) {
        setCalendarEntries(entryList);
        setVenues(venueList);
        const generated = sortCalendarEntriesToDropdownProps(entryList, venueList);
        setOptions(generated);
    }

    useEffect(() => {
        const found = getSelectedDropdownOption(props.entryId, options);

        setSelectedOption(found);
    }, [props.entryId, options]);

    function onOptionChanged(newOption: SingleValue<DDProps>) {
        if (!newOption) {
            setSelectedOption(undefined);
            props.changeOption(undefined);
            return;
        }
        const found = getSelectedDropdownOption(newOption.value, options);
        setSelectedOption(found);

        const changeOptions = getAdministeredLocationDetails(+newOption.value, venues);
        props.changeOption(changeOptions);
    }

    function getAdministeredLocationDetails(entryId: number | undefined, venueList: Venue[]) {
        const entry = calendarEntries.find((el) => el.id === entryId);
        if (!entry) return;

        const venue = venueList.find((el: Venue) => el.id === entry.venueId);
        if (!venue) return;

        const actionLocationDetails: NominalActionLocationDetails = {
            venue,
            entry
        };

        return actionLocationDetails;
    }

    return {
        calendarEntryListStore,
        selectedOption,
        options,
        onOptionChanged
    };
}
