import React from "react";
import Icon, {IconType} from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";

const TableItemAction = ({tooltipText, onClick, icon, wrapperClassName}: Props) => {
    return (
        <React.Fragment>
            <span className={`${wrapperClassName ?? ""}`}>
                <Tooltip
                    tooltipText={tooltipText}
                    theme={"dark"}
                    size={"lg"}
                    place={"left"}
                    rootElement="span"
                >
                    <Icon
                        rootElement="Span"
                        className="cursor-pointer"
                        icon={icon}
                        size={"Large"}
                        onClick={onClick}
                    />
                </Tooltip>
            </span>
        </React.Fragment>
    );
};

export default TableItemAction;

interface Props {
    tooltipText: string;
    onClick: () => void;
    icon: IconType;
    wrapperClassName?: string;
}
