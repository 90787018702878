import React from "react";
import {DrugPackDrugWithBatch, DrugPackWithContents} from "../../../../api/mm";
import FormRowWithAction from "../../../Form/FormRowWithAction";
import Icon, {IconType} from "../../../Icon/Icon";
import {Modal, useModal} from "pulse-modal";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import MoveStockAction from "./Actions/MoveStockAction";
import {sortDrugBatchesInDrugPackAlphabetically} from "../Helpers/drugbatchListHelpers";
import DisposeDrugAction from "./Actions/DisposeDrugAction";
import AdministerDrugAction from "./Actions/AdministerDrugAction";
import DrugStore from "../../../DrugStore/DrugStore";
import Tooltip from "../../../Tooltip/Tooltip";
import {
    getDrugExpiry,
    getExpiryIconClassName
} from "../../StockManagementList/Components/StockManagementTable";

const DrugPackWithContentsDrugTable = (props: DrugPackWithContents) => {
    const {isShown, toggle} = useModal();
    return (
        <React.Fragment>
            <FormRowWithAction
                icon={IconType.Add}
                showIcon={true}
                tooltipText={"Add Drugs"}
                onClick={toggle}
                rowName={"Drugs"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <PulseTable
                    items={toTableRow(props)}
                    headers={{
                        drugName: "Drug Name",
                        drugType: "Drug Type",
                        batchNumber: "Batch Number",
                        quantity: "Quantity",
                        expiry: "Expiry Date",
                        actions: "Actions"
                    }}
                    customRenderers={{
                        expiry: (item: TableRow) => {
                            const {batchInfo} = item.actions;
                            if (!batchInfo) return;
                            const expiring = getDrugExpiry(batchInfo.expiryDate, batchInfo.name);
                            return (
                                <React.Fragment>
                                    <p className="mb-0">
                                        <span className="mr-2">
                                            {formatUnixToDDMMYYYY(batchInfo?.expiryDate || 0)}
                                        </span>
                                        {expiring && (
                                            <React.Fragment>
                                                <Tooltip
                                                    tooltipText={expiring.tooltipMessage}
                                                    size={"md"}
                                                    place={"right"}
                                                    theme={"dark"}
                                                >
                                                    <Icon
                                                        rootElement={"Span"}
                                                        icon={IconType.DrugExpiry}
                                                        size={"Medium"}
                                                        className={`cursor-pointer ${getExpiryIconClassName(
                                                            expiring
                                                        )}`}
                                                    />
                                                </Tooltip>
                                            </React.Fragment>
                                        )}
                                    </p>
                                </React.Fragment>
                            );
                        },
                        actions: (item: TableRow) => (
                            <React.Fragment>
                                {item.actions.batchInfo && (
                                    <div className="row">
                                        <AdministerDrugAction
                                            batchInfo={{
                                                ...item.actions.batchInfo,
                                                movements: [],
                                                administrations: [],
                                                disposals: []
                                            }}
                                            maxQty={item.actions.qty}
                                            nominalMovementId={""}
                                        />
                                        <DisposeDrugAction
                                            batchInfo={{
                                                ...item.actions.batchInfo,
                                                movements: [],
                                                administrations: [],
                                                disposals: []
                                            }}
                                            maxQty={item.actions.qty}
                                            nominalMovementId={""}
                                        />
                                        <MoveStockAction
                                            moveToStore={true}
                                            maxQty={item.actions.qty}
                                            drugBatch={item.actions.batchInfo}
                                            locationId={props.locationId}
                                        />
                                    </div>
                                )}
                            </React.Fragment>
                        )
                    }}
                    noItemsSection={
                        <NoItemsMessage
                            message={
                                "There are no drugs in the drug bag! Click the plus button above to add drugs"
                            }
                        />
                    }
                />
                <Modal
                    isShown={isShown}
                    onClose={toggle}
                    title={"Add Drug to Drug Pack"}
                    modalSize={"xl"}
                    bodyChildren={<DrugStore />}
                />
            </FormRowWithAction>
        </React.Fragment>
    );
};

export default DrugPackWithContentsDrugTable;

interface TableRow {
    drugName?: string;
    drugType?: string;
    batchNumber?: string;
    quantity: number;
    expiry?: string;
    actions: DrugPackDrugWithBatch;
}

function toTableRow(props: DrugPackWithContents): TableRow[] {
    if (!props.drugs) return [];
    const sortedDrugs = sortDrugBatchesInDrugPackAlphabetically(props.drugs);

    return sortedDrugs.map((drug) => {
        return {
            drugName: drug.batchInfo?.name,
            drugType: drug.batchInfo?.type,
            batchNumber: drug.batchInfo?.batchNumber,
            quantity: drug.qty,
            expiry: formatUnixToDDMMYYYY(drug.batchInfo?.expiryDate || 0),
            actions: drug
        };
    });
}
