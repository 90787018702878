import React, {useEffect, useState} from "react";
import {pagedRequestConfig, RequestFilterProps} from "../../../Filters/helpers/filterHelpers";
import {DateRange, DrugAuditPagedRequest, DrugAuditType} from "../../../../api/mm";
import {useQuery} from "../../../Hooks/useQuery";
import {useHistory} from "react-router-dom";
import moment, {Moment} from "moment";
import {StaffAccessLevel} from "../../../../api/staff";
import {useUserData} from "../../../Hooks/useUserData";
import {capitalizeFirstLetter} from "../../../../utils/textUtils";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import LocationDropdown from "../../../Dropdown/LocationDropdown";
import AuthAmI from "../../../AuthAmI/AuthAmI";
import StaffDropdown from "../../../Dropdown/StaffDropdown";
import PulseEnumDropdown from "../../../Dropdown/PulseEnumDropdown";

const AuditListFilters = (props: RequestFilterProps<DrugAuditPagedRequest>) => {
    const query = useQuery();
    const history = useHistory();
    const user = useUserData();

    //Filters for creation date range and expiry date range.
    const [createdDateStart, setCreatedDateStart] = useState<Moment>();

    const [modifiedDateStart, setModifiedDateStart] = useState<Moment>();

    const [auditType, setAuditType] = useState<DrugAuditTypeForFilters>();
    const [managerId, setManagerId] = useState<string>();
    const [locationId, setLocationId] = useState<number>(-1);

    const [pageNumber, setPageNumber] = useState<number>(0);

    useEffect(() => {
        const cds = query.get("CDS");
        const mds = query.get("MDS");
        const staffId = query.get("managerId");
        const locId = query.get("locationId");
        const type = query.get("type");
        const pageNum = query.get("pageNum");

        setPageNumber(pageNum ? +pageNum : 0);

        //Time
        setCreatedDateStart(cds ? moment.unix(+cds) : moment().startOf("month"));
        setModifiedDateStart(mds ? moment.unix(+mds) : undefined);
        setAuditType(
            type
                ? getAuditTypeForFiltersFromString(capitalizeFirstLetter(type))
                : DrugAuditTypeForFilters.None
        );
        setManagerId(staffId ? staffId : undefined);
        setLocationId(locId ? +locId : -1);
    }, []);

    useEffect(() => {
        if (user.username.length === 0) return;
        if (!createdDateStart) return;
        if (!auditType) return;
        const staffId =
            user.accessLevel === StaffAccessLevel.DutyManager ? user.username : managerId;
        const createdDateEnd = createdDateStart.clone().endOf("month").unix();
        const modifiedDateEnd = modifiedDateStart
            ? modifiedDateStart.clone().endOf("month").unix()
            : undefined;

        const request = buildAuditPagedRequest(
            createdDateStart.clone().startOf("month").unix(),
            createdDateEnd,
            auditType,
            modifiedDateStart?.unix(),
            modifiedDateEnd,
            locationId,
            staffId,
            pageNumber
        );

        props.onRequestChanged(request);
        buildUrlHistory(request);
    }, [createdDateStart, modifiedDateStart, locationId, managerId, auditType, pageNumber, user]);

    const buildAuditPagedRequest = (
        incomingCreatedDateStart: number,
        incomingCreatedDateEnd: number,
        incomingType: DrugAuditTypeForFilters,
        incomingModifiedDateStart?: number,
        incomingModifiedDateEnd?: number,
        incomingLocationId?: number,
        incomingManagerId?: string,
        incomingPageNumber?: number
    ): DrugAuditPagedRequest => {
        const createdDate: DateRange = {
            startDate: incomingCreatedDateStart,
            endDate: incomingCreatedDateEnd
        };
        const modifiedDate = getDateRange(incomingModifiedDateStart, incomingModifiedDateEnd);

        return {
            pageNum: incomingPageNumber ? incomingPageNumber : 0,
            numPerPage: pagedRequestConfig.resultsPerPage,
            locationId: incomingLocationId === -1 ? undefined : incomingLocationId,
            createdDate,
            modifiedDate,
            reportingManagerId: incomingManagerId,
            type: getAuditTypeForRequest(incomingType)
        };
    };

    const buildUrlHistory = (request: DrugAuditPagedRequest) => {
        const queryStrings: string[] = ["?"];

        if (request.createdDate) {
            queryStrings.push(`&CDS=${request.createdDate.startDate}`);
        }
        if (request.modifiedDate) {
            queryStrings.push(`&MDS=${request.modifiedDate.startDate}`);
        }
        if (request.locationId && locationId > 0) {
            queryStrings.push(`&locationId=${request.locationId}`);
        }

        if (request.reportingManagerId) {
            queryStrings.push(`&managerId=${request.reportingManagerId}`);
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (request.pageNum > -1) {
            queryStrings.push(`&pageNum=${request.pageNum}`);
        }
        const type = request.type ? request.type : DrugAuditTypeForFilters.None;

        queryStrings.push(`&type=${type}`);

        history.push({search: queryStrings.join("")});
    };

    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Created Date</h6>
                    <DatePicker
                        selected={createdDateStart?.toDate()}
                        onChange={(date) => {
                            setCreatedDateStart(moment(date));
                        }}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                        portalId="root-portal"
                        customInput={<DatePickerInputButton />}
                    />
                </div>
                <div className="filter-item">
                    <h6>Modified Date</h6>
                    <DatePicker
                        selected={modifiedDateStart?.toDate()}
                        onChange={(date) => {
                            setModifiedDateStart(moment(date));
                        }}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                        portalId="root-portal"
                        customInput={<DatePickerInputButton />}
                    />
                </div>

                <div className="filter-item">
                    <h6>Drug Audit Type</h6>
                    {auditType && (
                        <PulseEnumDropdown
                            enumOptions={DrugAuditTypeForFilters}
                            onChange={(item) => {
                                if (!item) return;
                                setAuditType(
                                    getAuditTypeForFiltersFromString(item.value.toString())
                                );
                            }}
                            value={auditType}
                            disabled={false}
                            searchable={false}
                            clearable={false}
                            appendToBody
                        />
                    )}
                </div>

                <div className="filter-item">
                    <h6>Location</h6>
                    <LocationDropdown
                        clearable={true}
                        onChange={(item) => {
                            setLocationId(item ? item.id : -1);
                        }}
                        locationId={locationId}
                        disabled={false}
                        appendToBody
                    />
                </div>
                <AuthAmI accessLevels={[StaffAccessLevel.SystemAdministrator]}>
                    <div className="filter-item">
                        <h6>Reporting Manager</h6>
                        <StaffDropdown
                            searchable={true}
                            changeOption={(item) => {
                                setManagerId(item?.staffId);
                            }}
                            clearable={true}
                            username={managerId || ""}
                            appendToBody
                        />
                    </div>
                </AuthAmI>
            </div>
        </React.Fragment>
    );
};

export default AuditListFilters;

export function getAuditTypeForFiltersFromString(value: string): DrugAuditTypeForFilters {
    return DrugAuditTypeForFilters[value as keyof typeof DrugAuditTypeForFilters];
}

export function getAuditTypeForRequest(type: DrugAuditTypeForFilters): DrugAuditType | undefined {
    switch (type) {
        case DrugAuditTypeForFilters.ControlledDrugAudit:
            return DrugAuditType.ControlledDrugAudit;
        case DrugAuditTypeForFilters.None:
            return undefined;
        case DrugAuditTypeForFilters.WeeklyDrugPackAudit:
            return DrugAuditType.WeeklyDrugPackAudit;
    }
}

export function getDateRange(start?: number | null, end?: number | null): DateRange | undefined {
    if (!start || !end) {
        return undefined;
    }

    return {
        startDate: start,
        endDate: end
    };
}

// eslint-disable-next-line no-shadow
enum DrugAuditTypeForFilters {
    WeeklyDrugPackAudit = "WeeklyDrugPackAudit",
    ControlledDrugAudit = "ControlledDrugAudit",
    None = "None"
}
