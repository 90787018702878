import React from "react";
import Select from "react-select";
import {Loading} from "store-fetch-wrappers";
import {StaffDropdownProps} from "./Helpers/dropdownUtils";
import {useStaffDropdown} from "./Hooks/useStaffDropdown";

/** Dropdown that will show all users assigned in an entry or all users in the database. */
const StaffDropdown = (props: StaffDropdownProps) => {
    const {selectedOption, options, handleOptionChange, staffListStore} = useStaffDropdown(props);
    return (
        <React.Fragment>
            {staffListStore.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={options}
                    onChange={handleOptionChange}
                    isSearchable={props.searchable}
                    value={selectedOption}
                    noOptionsMessage={() => "No Staff Members Found"}
                    placeholder="Select Staff Member"
                    isClearable={props.clearable}
                    menuPortalTarget={props.appendToBody ? document.body : undefined}
                />
            )}
        </React.Fragment>
    );
};

export default StaffDropdown;
