import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../Navigation/routeNames";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import {
    fetchAllDrugPackCategories,
    nullifyDrugPackCategoryListStore
} from "../../../../store/drugPackCategoryList/actions/DrugPackCategoryListActions";

export function useDrugPackCategoryList() {
    const dispatch = useDispatch();
    const drugPackCategoryListStore = useSelector((state: RootStore) => state.drugPackCategoryList);
    const drugPackCategoryStore = useSelector((state: RootStore) => state.drugPackCategory);
    const {searchQueries} = usePageUrl();
    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: `${routeNames.medicineManagement.path}${searchQueries}`
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            },
            {
                name: routeNames.auditLocationList.name,
                path: routeNames.auditLocationList.path
            }
        ];

        dispatch(setNavigationItems(nav));

        dispatch(fetchAllDrugPackCategories());

        return function () {
            dispatch(nullifyDrugPackCategoryListStore());
        };
    }, []);
    return {
        drugPackCategoryListStore,
        drugPackCategoryStore
    };
}
