import React from "react";
import {useDropdown} from "./Hooks/useDropdown";
import {DrugPackCategory} from "../../api/mm";
import {BaseDropdownProps} from "./Helpers/dropdownUtils";
import {Loading} from "store-fetch-wrappers";
import Select from "react-select";

function DrugPackCategoryDropdown({
    drugPackCategoryId,
    onChange,
    ...rest
}: PulseDrugPackCategoryDropdown) {
    const {options, selectedOption, handleDropdownChange} = useDropdown<DrugPackCategory>({
        storeName: "drugPackCategoryList",
        onChange,
        id: drugPackCategoryId,
        storeKeyLabel: "name",
        storeKeyValue: "id"
    });

    return (
        <React.Suspense fallback={<Loading type={"three-ring"} showLoadingText={false} />}>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={options}
                onChange={handleDropdownChange}
                isSearchable={rest.searchable}
                value={selectedOption}
                noOptionsMessage={() => rest.noItemsMessage}
                placeholder={rest.placeholder}
                isDisabled={rest.disabled}
                isClearable={rest.clearable}
                menuPortalTarget={document.body}
            />
        </React.Suspense>
    );
}

export default DrugPackCategoryDropdown;

export interface PulseDrugPackCategoryDropdown extends BaseDropdownProps<DrugPackCategory> {
    drugPackCategoryId?: number;
}
