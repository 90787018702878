import {CALENDAR_ENTRY_LIST_STORE} from "../actions/CalendarEntryListActionTypes";
import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../api/grs";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<EventCalendarEntry[] | FrontlineCalendarEntry[]>([]);

const calendarEntryListReducer = (
    state: StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> = defaultState,
    action: ServiceActionTypes<EventCalendarEntry[] | FrontlineCalendarEntry[]>
): StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> =>
    createReducer(state, action, CALENDAR_ENTRY_LIST_STORE, () => showErrorToast(action.error));

export default calendarEntryListReducer;
