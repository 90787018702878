import React from "react";
import {useAdministrationStage1} from "../Hooks/useAdministrationStage1";
import {DrugBatchActionFormProps} from "../../Helpers/drugActionFormHelpers";
import AdministrationForm from "./AdministrationForm";

function AdministrationStage1(props: DrugBatchActionFormProps) {
    const {request, actionStage1} = useAdministrationStage1(props);
    return (
        <AdministrationForm
            onClose={props.onClose}
            initialAdministration={request.administration}
            drugBatch={props.drugBatch}
            onSave={async (administration) => {
                await actionStage1({
                    drugBatchId: props.drugBatch.id,
                    administration
                });
            }}
            maxQtyAvailable={props.maxQtyAvailable}
        />
    );
}

export default AdministrationStage1;
