import {DRUG_PACK_CATEGORY_STORE} from "../actions/DrugPackCategoryActionTypes";
import {DrugPackCategory} from "../../../api/mm";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugPackCategory>(null);

const drugPackCategoryReducer = (
    state: StoreServiceData<DrugPackCategory> = defaultState,
    action: ServiceActionTypes<DrugPackCategory>
): StoreServiceData<DrugPackCategory> =>
    createReducer(state, action, DRUG_PACK_CATEGORY_STORE, () => showErrorToast(action.error));

export default drugPackCategoryReducer;
