import React from "react";
import {DrugBatch} from "../../../../api/mm";
import FormHeader from "../../../Form/FormHeader";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {getRemainingStock} from "../../StockManagementList/Helpers/stockManagementListHelpers";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";

const DrugDetails = (props: DrugBatch) => {
    return (
        <React.Fragment>
            <FormHeader headerName={"Drug Batch Audit Details"} />
            <PulseTable
                items={toTableRow(props)}
                headers={{
                    drugBatchName: "Drug Batch Name",
                    drugType: "Drug Type",
                    batchNumber: "Batch Number",
                    quantity: "Quantity",
                    remainingStock: "Remaining Stock",
                    creationDate: "Creation Date",
                    expiryDate: "Expiry Date"
                }}
                noItemsSection={<NoItemsMessage message={"A drug must be selected"} />}
            />
        </React.Fragment>
    );
};

export default DrugDetails;

interface TableRow {
    drugBatchName: string;
    drugType: string;
    batchNumber: string;
    quantity: number;
    remainingStock: number;
    creationDate: string;
    expiryDate: string;
}

function toTableRow(batch: DrugBatch): TableRow[] {
    return [
        {
            drugBatchName: batch.name,
            drugType: getUiFriendlyText(batch.type),
            batchNumber: batch.batchNumber,
            quantity: batch.qty,
            remainingStock: getRemainingStock(batch),
            creationDate: formatUnixToDDMMYYYY(batch.creationDate),
            expiryDate: formatUnixToDDMMYYYY(batch.expiryDate)
        }
    ];
}
