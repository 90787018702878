export const routeNames = {
    drugPackCategoryList: {
        path: "/drug-pack-category/list",
        name: "Drug Pack Categories"
    },
    drugPackCategoryAdd: {
        path: "/drug-pack-category/add",
        name: "Add Drug Pack Category"
    },
    drugPackCategoryEdit: {
        path: "/drug-pack-category/edit",
        name: "Edit Drug Pack Category"
    },
    medicineManagement: {
        path: "/overview",
        name: "Medicine Management"
    },
    drugPackWithContentsAdd: {
        path: "/drug-pack/add",
        name: "Add Drug Pack"
    },
    drugPackWithContentsEdit: {
        path: "/drug-pack/edit",
        name: "Edit Drug Pack"
    },
    stockManagementAdd: {
        path: "/stock-management/add",
        name: "Add Stock"
    },
    stockManagementEdit: {
        path: "/stock-management/edit",
        name: "Edit Stock"
    },
    viewActiveStock: {
        path: "/stock-management/view-active-stock",
        name: "View Active Stock"
    },
    stockManagement: {
        path: "/stock-management/all",
        name: "Stock Management"
    },
    generalReports: {
        path: "/reports",
        name: "Reports"
    },
    disposalReports: {
        path: "/reports/disposal",
        name: "Disposal Report"
    },
    administrationReports: {
        path: "/reports/administration",
        name: "Administration Report"
    },
    expiryReports: {
        path: "/reports/expiry",
        name: "Expiry Report"
    },
    statusReports: {
        path: "/reports/status",
        name: "Status Report"
    },
    stockManagementAudit: {
        path: "/stock-management/audit",
        name: "Audit Stock"
    },
    mmAuditsList: {
        path: "/audits/list",
        name: "Audits"
    },
    auditLocationList: {
        path: "/location/list",
        name: "Locations"
    },
    auditLocationAdd: {
        path: "/location/add",
        name: "Add Location"
    },
    auditLocationEdit: {
        path: "/location/edit",
        name: "Edit Location"
    },
    newAudit: {
        path: "/audits/new",
        name: "New Audit"
    },
    editAudit: {
        path: "/audits/edit",
        name: "Edit Audit"
    },
    viewAudit: {
        path: "/audits/view",
        name: "View Audit"
    },
    historicAuditList: {
        path: "/audits/historic/list",
        name: "Historic Audit List"
    },
    historicAuditView: {
        path: "/audits/historic/view",
        name: "Historic Audit"
    },
    //Auth pages
    login: {
        path: "/login",
        name: "",
        icon: ""
    },
    loggedin: {
        path: "/loggedin",
        name: "",
        icon: ""
    },
    logout: {
        path: "/logout",
        name: "",
        icon: ""
    },
    inActive: {
        path: "/inactive",
        name: "",
        icon: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: "",
        icon: ""
    },
    notFound: {
        path: "/not-found",
        name: "",
        icon: ""
    }
};
