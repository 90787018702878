import {DrugPackCategoryInfo} from "./auditHelpers";

export interface WeeklyAudit {
    drugPackAudit: DrugPackAudit;
}

interface DrugPackAudit {
    drugsPacksWithCategories: DrugPackCategoryInfo[];
}

export function generateNewWeeklyAudit(
    categoriesWithDrugPack: DrugPackCategoryInfo[],
    locationId: number
): WeeklyAudit {
    return {
        drugPackAudit: {
            drugsPacksWithCategories: getDrugPacksForLocation(locationId, categoriesWithDrugPack)
        }
    };
}

function getDrugPacksForLocation(
    locationId: number,
    drugPackCategoryInfo: DrugPackCategoryInfo[]
): DrugPackCategoryInfo[] {
    const filteredCategories: DrugPackCategoryInfo[] = [];

    for (const cat of drugPackCategoryInfo) {
        const filteredDrugPacks = cat.drugPackInfoList.filter(
            (item) => item.locationId === locationId
        );
        if (filteredDrugPacks.length === 0) continue;

        filteredCategories.push({
            ...cat,
            drugPackInfoList: filteredDrugPacks
        });
    }

    return filteredCategories;
}
