import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useState} from "react";
import {DrugAuditPagedRequest} from "../../../../api/mm";
import {RootStore} from "../../../../store/Store";
import {routeNames} from "../../../Navigation/routeNames";
import {fetchAuditListPaged} from "../../../../store/auditListPaged/actions/AuditListPagedActions";
import {deleteAuditFromService} from "../../../../store/audit/actions/AuditActions";

export function useAuditListTable() {
    const history = useHistory();
    const dispatch = useDispatch();
    const [pagedRequest, setPagedRequest] = useState<DrugAuditPagedRequest>();
    const auditStore = useSelector((state: RootStore) => state.audit);
    const editAudit = (id: number) => {
        const path = `${routeNames.editAudit.path}/${id}`;
        history.push({
            pathname: path,
            search: location.search
        });
    };

    const viewHistoricAudits = (id: number) => {
        const path = `${routeNames.historicAuditList.path}/${id}`;
        history.push({
            pathname: path,
            search: location.search
        });
    };

    const viewAudit = (id: number) => {
        const path = `${routeNames.viewAudit.path}/${id}`;
        history.push({
            pathname: path,
            search: location.search
        });
    };

    const onRequestChanged = (request: DrugAuditPagedRequest) => {
        if (JSON.stringify(request) === JSON.stringify(pagedRequest)) return;
        setPagedRequest(request);
        fetchData(request);
    };

    const fetchData = (request: DrugAuditPagedRequest) => {
        dispatch(fetchAuditListPaged(request));
    };

    const newAudit = () => {
        const path = `${routeNames.newAudit.path}`;
        history.push({
            pathname: path,
            search: location.search
        });
    };

    async function deleteAudit(id: number): Promise<boolean> {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const deleted: boolean = await dispatch(deleteAuditFromService(id));
        if (!deleted) return false;
        if (!pagedRequest) return false;

        fetchData(pagedRequest);
        return true;
    }

    return {
        onRequestChanged,
        newAudit,
        viewHistoricAudits,
        editAudit,
        viewAudit,
        auditStore,
        pagedRequest,
        fetchData,
        deleteAudit
    };
}
