import React from "react";
import {ComponentChildrenProps} from "../../../utils/componentUtils";
import {isEven} from "../../../utils/mathUtils";

const PrintableTableRow = ({rowType, children, index}: PrintableTableRowProps) => {
    const getBodyRowBackground = () => {
        if (!index) {
            return "#eeeeee";
        }

        return isEven(index) ? "#eeeeee" : "#ffffff";
    };
    const getStyles = () => {
        switch (rowType) {
            case "headerRow":
                return {
                    background: "#1a2a35",
                    borderBottom: "1px solid #1a2a35",
                    width: "100%"
                };
            case "bodyRow":
                return {
                    background: getBodyRowBackground(),
                    borderBottom: "1px solid transparent",
                    width: "100%"
                };
        }
    };
    return <tr style={getStyles()}>{children}</tr>;
};

export default PrintableTableRow;

interface PrintableTableRowProps extends ComponentChildrenProps {
    rowType: RowType;
    index?: number;
}

type RowType = "headerRow" | "bodyRow";
