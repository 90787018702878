import {DrugBatch, DrugType} from "../../../api/mm";
import {Dispatch} from "redux";
import {DRUG_BATCH_STORE, DrugBatchDispatchTypes} from "./DrugBatchActionTypes";
import moment from "moment";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithData,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyDrugBatchStore = () => {
    return async (dispatch: Dispatch<DrugBatchDispatchTypes>) => {
        dispatch({
            type: DRUG_BATCH_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const createNewDrugBatch = () => {
    return async (dispatch: Dispatch<DrugBatchDispatchTypes>) => {
        const now = moment().startOf("day").unix();

        const drugBatch: DrugBatch = {
            id: 0,
            batchNumber: "",
            name: "",
            qty: 0,
            type: DrugType.Normal,
            creationDate: now,
            expiryDate: now,
            lowStockQty: 0,
            movements: [],
            administrations: [],
            disposals: []
        };

        dispatch({
            type: DRUG_BATCH_STORE.SUCCESS,
            error: null,
            loading: false,
            data: drugBatch
        });
    };
};

export const setDrugBatch = (drugBatch: DrugBatch) => {
    return async (dispatch: Dispatch<DrugBatchDispatchTypes>) => {
        dispatch({
            type: DRUG_BATCH_STORE.SUCCESS,
            error: null,
            loading: false,
            data: drugBatch
        });
    };
};

export const fetchDrugBatchById = (id: number) => {
    return async (dispatch: Dispatch<DrugBatchDispatchTypes>) => {
        try {
            const batch = await getDataFromServiceWithData(
                DRUG_BATCH_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getDrugBatch(id),
                statusCodeCallback
            );

            dispatch({
                type: DRUG_BATCH_STORE.SUCCESS,
                loading: false,
                error: null,
                data: batch
            });
        } catch (e: any) {
            dispatch({
                type: DRUG_BATCH_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const deleteDrugBatchById = (id: number) => {
    return async (dispatch: Dispatch<DrugBatchDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                DRUG_BATCH_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.deleteDrugBatch(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DRUG_BATCH_STORE.SUCCESS,
                error: e,
                loading: false
            });
        }
    };
};

export const saveDrugBatchToService = (drugBatch: DrugBatch) => {
    return async (dispatch: Dispatch<DrugBatchDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                DRUG_BATCH_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.saveDrugBatch(drugBatch),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DRUG_BATCH_STORE.SUCCESS,
                error: e,
                loading: false
            });
        }
    };
};
