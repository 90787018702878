import React from "react";
import {WithServiceState} from "store-fetch-wrappers";
import DrugStoreTable from "./DrugStoreTable";
import {useAppSelector} from "../Hooks/useReduxHooks";

const ServiceWrapper = WithServiceState(DrugStoreTable);

const DrugStore = () => {
    const drugBatchPagedStore = useAppSelector((state) => state.drugBatchPaged);
    return (
        <React.Fragment>
            <ServiceWrapper
                showLoadingText={true}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                placeholderBlockCount={1}
                {...drugBatchPagedStore}
            />
        </React.Fragment>
    );
};

export default DrugStore;
