import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {
    fetchDrugBatchById,
    nullifyDrugBatchStore
} from "../../../store/drugBatch/actions/DrugBatchActions";
import {RootStore} from "../../../store/Store";
import EditStockManagementForm from "./Components/EditStockManagementForm";
import {useParams} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";
import {WithServiceState} from "store-fetch-wrappers";

const ServiceWrapper = WithServiceState(EditStockManagementForm);

const EditStockManagement = () => {
    const dispatch = useDispatch();
    const drugBatchStore = useSelector((state: RootStore) => state.drugBatch);
    const params: any = useParams();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        const queries = window.location.href.split("?")[1];
        const search = queries ? `?${queries}` : undefined;
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.stockManagementEdit.name,
                path: `${routeNames.stockManagementEdit.path}/${+params.id}${search}`
            }
        ];

        dispatch(setNavigationItems(nav));

        dispatch(fetchDrugBatchById(+params.id));

        return async function () {
            dispatch(nullifyDrugBatchStore());
        };
    }, []);

    return (
        <div className="page-container mt-4">
            <ServiceWrapper
                loaderWheelType={"three-ring"}
                loaderType={"overlay"}
                showLoadingText={true}
                placeholderBlockCount={1}
                {...drugBatchStore}
            />
        </div>
    );
};

export default EditStockManagement;
