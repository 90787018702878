import {DrugAuditHistoryOverview} from "../../../api/mm";
import {AUDIT_LIST_HISTORIC_STORE} from "../actions/AuditListHistoricActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugAuditHistoryOverview>(null);

const auditListHistoricReducer = (
    state: StoreServiceData<DrugAuditHistoryOverview> = defaultState,
    action: ServiceActionTypes<DrugAuditHistoryOverview>
) => createReducer(state, action, AUDIT_LIST_HISTORIC_STORE, () => showErrorToast(action.error));

export default auditListHistoricReducer;
