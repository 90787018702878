import React from "react";

const PrintableP = (props: PrintableH6Props) => {
    return (
        <p
            style={{
                marginTop: `0`,
                marginBottom: `0`,
                fontSize: props.fontSize,
                fontWeight: props.isBold ? "bold" : "normal"
            }}
        >
            {props.headerName}
        </p>
    );
};

export default PrintableP;

interface PrintableH6Props {
    headerName: string | number;
    fontSize?: string;
    isBold?: boolean;
}
