import React, {useEffect, useState} from "react";
import {DrugPackWithContents} from "../../../../../../api/mm";
import {toArray} from "../../../../../../utils/sortingUtils";
import moment from "moment";
import Tooltip from "../../../../../Tooltip/Tooltip";
import DrugPackPreviewModal from "../DrugPackPreviewModal";

export function useDrugPackTable(props: DrugPackWithContents[]) {
    const [items, setItems] = useState<TableRow[]>([]);
    useEffect(() => {
        setItems(toTableRow(props));
    }, [props]);

    function toTableRow(entity: DrugPackWithContents[]): TableRow[] {
        return toArray(entity).map((item) => {
            return {
                status: getStatusColumn(item),
                name: item.name,
                sealNumber: item.sealNumber,
                actions: getActionColumn(item)
            };
        });
    }

    function getStatusColumn(item: DrugPackWithContents): React.ReactNode {
        const {className, tooltipText} = getStatusInfo(item);
        return (
            <React.Fragment>
                <Tooltip
                    tooltipText={tooltipText}
                    size={"md"}
                    place={"right"}
                    theme={"dark"}
                    wrapperClassName={"float-left mt-1 mr-2"}
                >
                    <div className={className} />
                </Tooltip>
            </React.Fragment>
        );
    }

    function getActionColumn(item: DrugPackWithContents): React.ReactNode {
        return (
            <React.Fragment>
                <div className="row ml-0 ml-0">
                    <div className="icon-tooltip-wrapper-md mr-1">
                        <Tooltip
                            tooltipText={"Preview Drug Pack Contents"}
                            size={"md"}
                            place={"left"}
                        >
                            <DrugPackPreviewModal {...item} />
                        </Tooltip>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    function getStatusInfo(item: DrugPackWithContents): StatusInfo {
        const now = moment();
        const oneWeekInFuture = now.clone().add(1, "week").unix();
        const oneMonthInFuture = now.clone().add(1, "month").unix();

        const orderedAscDrugs = item.drugs.sort(
            (a, b) => (a.batchInfo?.expiryDate || 0) - (b.batchInfo?.expiryDate || 0)
        );

        for (const drug of orderedAscDrugs) {
            //If the drug doesn't exist, continue the loop, not a bother.
            if (!drug.batchInfo) continue;

            //Return red if drug has expired
            if (drug.batchInfo.expiryDate < now.unix()) {
                return {
                    className: "pack-base-status expiring-in-week d-flex",
                    tooltipText: "One or more drugs have expired in this pack. Attention needed now"
                };
            }

            //Expiring in a week
            if (
                drug.batchInfo.expiryDate > now.unix() &&
                drug.batchInfo.expiryDate < oneWeekInFuture
            ) {
                return {
                    className: "pack-base-status expiring-in-week d-flex",
                    tooltipText:
                        "One or more drugs have are expiring in the next week. Attention needed soon"
                };
            }

            //Expiring in a month
            if (
                drug.batchInfo.expiryDate > now.unix() &&
                drug.batchInfo.expiryDate < oneMonthInFuture
            ) {
                return {
                    className: "pack-base-status expiring-in-month d-flex",
                    tooltipText:
                        "One or more drugs have are expiring in the next month. Attention needed soon"
                };
            }
        }

        if (item.drugs.length === 0) {
            return {
                className: "pack-base-status no-drugs d-flex",
                tooltipText: "There are no drugs in this drug pack. No action needed."
            };
        }

        return {
            className: "pack-base-status no-action-needed d-flex",
            tooltipText: "There are no drugs expiring soon. No action needed."
        };
    }

    return {
        items
    };
}

interface TableRow {
    status: React.ReactNode;
    name: string;
    sealNumber: string;
    actions: React.ReactNode;
}

interface StatusInfo {
    className: string;
    tooltipText: string;
}
