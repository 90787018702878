import {Dispatch} from "redux";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {LOCATION_STORE, LocationDispatchTypes} from "./LocationActionsTypes";
import {Location} from "../../../api/mm";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {showErrorToast} from "../../../utils/toastUtils";

export const nullifyLocationStore = () => {
    return async (dispatch: Dispatch<LocationDispatchTypes>) => {
        dispatch({
            type: LOCATION_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const createNewLocation = () => {
    return async (dispatch: Dispatch<LocationDispatchTypes>) => {
        dispatch({
            type: LOCATION_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                id: 0,
                name: ""
            }
        });
    };
};

export const setLocation = (data: Location) => {
    return async (dispatch: Dispatch<LocationDispatchTypes>) => {
        dispatch({
            type: LOCATION_STORE.SUCCESS,
            loading: false,
            error: null,
            data
        });
    };
};

export const fetchAuditLocationById = (id: number) => {
    return async (dispatch: Dispatch<LocationDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                LOCATION_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getAuditLocation(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: LOCATION_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const deleteAuditLocationById = (id: number) => {
    return async (dispatch: Dispatch<LocationDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                LOCATION_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.deleteAuditLocation(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: LOCATION_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

export const saveAuditLocationToService = (location: Location) => {
    return async (dispatch: Dispatch<LocationDispatchTypes>) => {
        const valid = validateLocation(location);
        if (!valid) return;
        try {
            return await postDataToServiceWithRedux(
                LOCATION_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.saveAuditLocation(location),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: LOCATION_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};

function validateLocation(location: Location): boolean {
    if (location.name.length < 3) {
        showErrorToast("Location Name cannot be less than 3 characters.");
        return false;
    }

    return true;
}
