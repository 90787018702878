import React, {useEffect, useState} from "react";
import {DrugBatch} from "../../../../../api/mm";
import {DonutChart, getDonutStat} from "../../../../../utils/donutChartUtils";
import {
    vibrantGreenPrimary,
    vibrantRedPrimary,
    vibrantYellowPrimary
} from "../../../../../utils/colourUtils";
import {DrugBatchExpiries} from "../Helpers/medicineManagementStatsHelpers";
import DonutChartList from "../../../../DonutChart/DonutChartList";

const StatisticsForMedicineManagementDonutChartListWrapper = (props: DrugBatchExpiries) => {
    const [donutList, setDonutList] = useState<DonutChart[]>([]);

    useEffect(() => {
        const localDonutList: DonutChart[] = [];
        const {activeBatches, expiringInTheMonth, expiringInTheWeek, expiredDrugs} = props;

        //Active
        const totalActiveDrugStats: DonutChart = getDonutStatFromDrugBatchExpiry(
            "Number of drugs in date",
            activeBatches,
            vibrantGreenPrimary
        );
        localDonutList.push(totalActiveDrugStats);

        //30 days until expiry
        const numberOfDrugsExpiringInTheMonth = getDonutStatFromDrugBatchExpiry(
            "Number of drugs expiring within the next 30 days",
            expiringInTheMonth,
            vibrantYellowPrimary
        );
        localDonutList.push(numberOfDrugsExpiringInTheMonth);

        //7 days until expiry
        const numberOfDrugsExpiringInTheWeek = getDonutStatFromDrugBatchExpiry(
            "Number of drugs expiring within the next 7 days",
            expiringInTheWeek,
            vibrantRedPrimary
        );
        localDonutList.push(numberOfDrugsExpiringInTheWeek);

        //expired
        const numberOfDrugsExpired = getDonutStatFromDrugBatchExpiry(
            "Drugs needing action",
            expiredDrugs,
            vibrantRedPrimary
        );
        localDonutList.push(numberOfDrugsExpired);

        setDonutList(localDonutList);
    }, [props]);

    function getDonutStatFromDrugBatchExpiry(
        label: string,
        drugs: DrugBatch[],
        color: string
    ): DonutChart {
        const uniqueDrugIds = getUniqueDrugBatchesExpiries(drugs);
        return getDonutStat(
            uniqueDrugIds.length.toString(),
            label,
            uniqueDrugIds.length,
            uniqueDrugIds.length,
            color
        );
    }

    function getUniqueDrugBatchesExpiries(drugBatches: DrugBatch[]): number[] {
        return drugBatches
            .map((el: DrugBatch) => el.id)
            .filter((value: any, index: any, self: any) => self.indexOf(value) === index);
    }

    return (
        <React.Fragment>
            <DonutChartList chartData={donutList} headerText={"General drug statistics"} />
        </React.Fragment>
    );
};

export default StatisticsForMedicineManagementDonutChartListWrapper;
