import React from "react";
import {PieChart} from "react-minimal-pie-chart";
import FormHeader from "../Form/FormHeader";
import {DonutChart} from "../../utils/donutChartUtils";

const DonutChartList = ({headerText, chartData}: Props) => {
    return (
        <React.Fragment>
            <FormHeader headerName={headerText} />
            <div className="stat-item-wrapper">
                {chartData.map((item, index) => {
                    return (
                        <div className="stat-item mb-4" key={index}>
                            <PieChart
                                animationDuration={500}
                                animationEasing={"ease-out"}
                                background={"#cdcdcd"}
                                center={[50, 50]}
                                data={item.donutChart}
                                paddingAngle={0}
                                viewBoxSize={undefined}
                                lineWidth={20}
                                startAngle={0}
                                totalValue={item.totalValue}
                                className="w-100"
                                label={({dataEntry}) => item.innerLabel}
                                labelStyle={(idx) => ({
                                    fill: "#1a2a35",
                                    fontSize: "10px",
                                    fontFamily: "Magistral"
                                })}
                                labelPosition={0}
                            />
                            <p className="text-center header-font pt-2">
                                <b>{item.outerLabel}</b>
                            </p>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default DonutChartList;

interface Props {
    chartData: DonutChart[];
    headerText: string;
}
