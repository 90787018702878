import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    fetchAllActiveDrugBatches,
    nullifyDrugBatchListStore
} from "../../../store/drugBatchList/actions/DrugBatchListActions";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {RootStore} from "../../../store/Store";
import {DrugBatch} from "../../../api/mm";
import {getRemainingStock} from "../StockManagementList/Helpers/stockManagementListHelpers";
import {useHistory} from "react-router-dom";
import {WithServiceState} from "store-fetch-wrappers";
import ActiveStockTable from "./Components/ActiveStockTable";
import FormActionContainer from "../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";

const ServiceWrapper = WithServiceState(ActiveStockTable);

const ActiveStock = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const activeDrugBatchStore = useSelector((state: RootStore) => state.drugBatchList);

    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            }
        ];

        dispatch(setNavigationItems(nav));
        dispatch(fetchAllActiveDrugBatches());

        return function () {
            dispatch(nullifyDrugBatchListStore());
        };
    }, []);

    const backToStockManagement = () => {
        history.push({
            pathname: routeNames.stockManagement.path,
            search: window.location.search
        });
    };

    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    {...activeDrugBatchStore}
                />
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Back"}
                        onClick={backToStockManagement}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
};

export default ActiveStock;

function getActiveDrugsOnly(drugBatches: DrugBatch[] | null | undefined): DrugBatch[] {
    if (!drugBatches) return [];

    return drugBatches
        .sort((a, b) => a.name.localeCompare(b.name))
        .filter((item: DrugBatch) => getRemainingStock(item) > 0);
}

export function getPageCount<T>(array: T[], numberPerPage: number): number {
    const total = Math.ceil(array.length / numberPerPage);
    return isNaN(total) ? 1 : total;
}
