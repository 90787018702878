import React, {useEffect} from "react";
import {DrugBatch} from "../../../../api/mm";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {nullifyDrugPacksWithContentsListStore} from "../../../../store/drugPackWithContentsList/actions/DrugPackWithContentsListActions";
import {useDispatch} from "react-redux";
import {useQuery} from "../../../Hooks/useQuery";
import DrugDetails from "./DrugDetails";
import DrugAuditActions from "./DrugAuditActions";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";

const StockManagementAuditTable = (props: DrugBatch) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const query = useQuery();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        return async function () {
            dispatch(nullifyDrugPacksWithContentsListStore());
        };
    }, []);

    const backToPreviousPage = () => {
        const ads = query.get("ADS");
        const queries = window.location.href.split("?")[1];
        const search = queries ? `?${queries}` : undefined;
        //We recently came from the administration audit report go back there
        if (ads) {
            history.push({pathname: routeNames.administrationReports.path, search});
            return;
        }

        history.push({pathname: routeNames.stockManagement.path, search});
    };

    return (
        <React.Fragment>
            <DrugDetails {...props} />
            <DrugAuditActions {...props} />
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Back"}
                    onClick={backToPreviousPage}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default StockManagementAuditTable;
