import React from "react";
import {DrugBatch} from "../../../../../api/mm";
import PrintableTable from "../../../../Print/Components/PrintableTable";
import ActiveStockReportHeader from "./ActiveStockReportHeader";
import {toArray} from "../../../../../utils/sortingUtils";
import ActiveStockReportRow from "./ActiveStockReportRow";
import PrintableTableBody from "../../../../Print/Components/PrintableTableBody";
import {getRemainingStock} from "../../../StockManagementList/Helpers/stockManagementListHelpers";
import {orderBatchesByNameAsc} from "../ActiveStockTable";

const PrintActiveStockReport = (props: DrugBatch[]) => {
    return (
        <React.Fragment>
            <PrintableTable width={"800px"} marginTop={"15px"}>
                <ActiveStockReportHeader />
                <PrintableTableBody>
                    {orderBatchesByNameAsc(toArray(props))
                        .filter((item) => getRemainingStock(item) > 0)
                        .map((item, index) => {
                            return <ActiveStockReportRow index={index} key={index} report={item} />;
                        })}
                </PrintableTableBody>
            </PrintableTable>
        </React.Fragment>
    );
};

export default PrintActiveStockReport;
