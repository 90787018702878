import {
    DrugAdministrationMethod,
    DrugBatchAdministration,
    DrugBatchAdministrationChangeRecord,
    GrsLocation
} from "../../../api/mm";
import {StaffLink} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const ADMINISTRATION_REPORTS_STORE_STATE = createStoreState("administration_report_store");

interface AdministrationReportsLoading extends StoreServiceData<AdministrationReport[]> {
    type: typeof ADMINISTRATION_REPORTS_STORE_STATE.LOADING;
}
interface AdministrationReportsError extends StoreServiceData<AdministrationReport[]> {
    type: typeof ADMINISTRATION_REPORTS_STORE_STATE.ERROR;
}
interface AdministrationReportsSuccess extends StoreServiceData<AdministrationReport[]> {
    type: typeof ADMINISTRATION_REPORTS_STORE_STATE.SUCCESS;
}

export type AdministrationReportsDispatchTypes =
    | AdministrationReportsLoading
    | AdministrationReportsError
    | AdministrationReportsSuccess;

export interface AdministrationReport {
    drugBatchName: string;
    drugBatchId: number;
    administrationDate: number;
    expiryDate: number;
    batchNumber: string;
    qty: number;
    staffMember: StaffLink;
    witness?: StaffLink;
    drugPackName: string;
    location?: GrsLocation;
    qa: boolean;
    dosage?: string;
    wastage?: string;
    history?: DrugBatchAdministrationChangeRecord[];
    routeAdministration: DrugAdministrationMethod;
}

export interface DrugBatchAdministrationWithDrugBatchDetails extends DrugBatchAdministration {
    drugBatchName: string;
    drugBatchId: number;
    expiryDate: number;
    batchNumber: string;
}
