import {
    DrugBatchOrder,
    DrugBatchPageRequest,
    DrugBatchPageResponse,
    StringOperator,
    StringSearch
} from "../../../api/mm";
import {useDispatch} from "react-redux";
import {useEffect, useState} from "react";
import {pagedRequestConfig} from "../../Filters/helpers/filterHelpers";
import {fetchPagedDrugBatches} from "../../../store/drugBatchPaged/actions/DrugBatchPagedActions";

export function useDrugStore(props: DrugBatchPageResponse) {
    const dispatch = useDispatch();
    const [drugBatchPagedRequest, setDrugBatchPagedRequest] = useState<DrugBatchPageRequest>();

    useEffect(() => {
        fetchData({
            pageNum: 0,
            numPerPage: pagedRequestConfig.resultsPerPage,
            sortOrder: DrugBatchOrder.DrugNameAscending
        });
    }, []);

    // Fetches Audits
    const fetchData = (request: DrugBatchPageRequest) => {
        setDrugBatchPagedRequest(request);
        dispatch(fetchPagedDrugBatches(request));
    };

    const getStringSearch = (value: string): StringSearch | undefined => {
        if (value.length === 0) {
            return undefined;
        }

        return {
            searchString: value,
            operator: StringOperator.Contains
        };
    };

    return {
        getStringSearch,
        fetchData,
        drugBatchPagedRequest
    };
}
