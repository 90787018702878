import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import EditLocationForm from "./Components/EditLocationForm";
import {createNewLocation} from "../../../store/location/actions/LocationActions";
import {WithServiceState} from "store-fetch-wrappers";

const ServiceWrapper = WithServiceState(EditLocationForm);

const AddLocation = () => {
    const dispatch = useDispatch();
    const locationStore = useSelector((state: RootStore) => state.location);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            },
            {
                name: routeNames.auditLocationList.name,
                path: routeNames.auditLocationList.path
            }
        ];
        dispatch(setNavigationItems(navItems));

        dispatch(createNewLocation());
    }, []);
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    {...locationStore}
                />
            </div>
        </React.Fragment>
    );
};

export default AddLocation;
