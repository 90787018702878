import {
    DRUG_PACK_CATEGORY_STORE,
    DrugPackCategoryDispatchTypes
} from "./DrugPackCategoryActionTypes";
import {Dispatch} from "redux";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {DrugPackCategory} from "../../../api/mm";
import store from "../../Store";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Removes stale data from the store */
export const nullifyDrugPackCategoryStore = () => {
    return async (dispatch: Dispatch<DrugPackCategoryDispatchTypes>) => {
        dispatch({
            type: DRUG_PACK_CATEGORY_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

/** Creating a new Drug Pack Category */
export const createNewDrugPackCategory = () => {
    return async (dispatch: Dispatch<DrugPackCategoryDispatchTypes>) => {
        const category: DrugPackCategory = {
            name: "",
            id: 0
        };

        dispatch({
            type: DRUG_PACK_CATEGORY_STORE.SUCCESS,
            error: null,
            loading: false,
            data: category
        });
    };
};

/** Used to update the current Drug Pack Category that is loaded into the store. */
export const setDrugPackCategory = (category: DrugPackCategory) => {
    return async (dispatch: Dispatch<DrugPackCategoryDispatchTypes>) => {
        dispatch({
            type: DRUG_PACK_CATEGORY_STORE.SUCCESS,
            error: null,
            loading: false,
            data: category
        });
    };
};

/** Get Drug Pack Category from service */
export const fetchDrugPackCategory = (id: number) => {
    return async (dispatch: Dispatch<DrugPackCategoryDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                DRUG_PACK_CATEGORY_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getDrugPackCategory(id),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().drugPackCategory.data;

                if (!data) return null;

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: DRUG_PACK_CATEGORY_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Delete Drug Pack Category on service */
export const deleteDrugPackCategoryById = (id: number) => {
    return async (dispatch: Dispatch<DrugPackCategoryDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                DRUG_PACK_CATEGORY_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.deleteDrugPackCategory(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DRUG_PACK_CATEGORY_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Save Drug Pack Category to service */
export const saveDrugPackCategory = (category: DrugPackCategory) => {
    return async (dispatch: Dispatch<DrugPackCategoryDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                DRUG_PACK_CATEGORY_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.saveDrugPackCategory(category),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DRUG_PACK_CATEGORY_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const mockLoading = () => async (dispatch: Dispatch<DrugPackCategoryDispatchTypes>) => {
    dispatch({
        type: DRUG_PACK_CATEGORY_STORE.LOADING,
        error: null,
        loading: true
    });

    setTimeout(() => {
        dispatch({
            type: DRUG_PACK_CATEGORY_STORE.SUCCESS,
            error: null,
            loading: true,
            data: null
        });
    }, 8000);
};
