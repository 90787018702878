import React from "react";
import PrintableTableHead from "../../../../../Print/Components/PrintableTableHead";
import PrintableTableRow from "../../../../../Print/Components/PrintableTableRow";
import PrintableTD from "../../../../../Print/Components/PrintableTD";
import {formatUnixToDDMMYYYY} from "../../../../../../utils/momentUtils";
import PrintableP from "../../../../../Print/Components/PrintableP";
import {DisposalReport} from "../../../../../../store/disposalReports/actions/DisposalReportsActionTypes";
import {getLocationForReport} from "../Components/DisposalReportTable";
import {ReportRowPrintProps} from "../../../../../Print/helpers/printableHelpers";

const DisposalReportRow = ({report, index}: ReportRowPrintProps<DisposalReport>) => (
    <React.Fragment>
        <PrintableTableHead>
            <PrintableTableRow rowType={"bodyRow"} index={index}>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.drugBatchName} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.drugPackName} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.batchNumber} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP
                        headerName={formatUnixToDDMMYYYY(report.disposalDate)}
                        fontSize={"0.7rem"}
                    />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP
                        headerName={formatUnixToDDMMYYYY(report.expiryDate)}
                        fontSize={"0.7rem"}
                    />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.qty} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP
                        headerName={getLocationForReport(report.location)}
                        fontSize={"0.7rem"}
                    />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.staffMember.staffName} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP
                        headerName={report.witness ? report.witness.staffName : "Witness not added"}
                        fontSize={"0.7rem"}
                    />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.broken ? "Yes" : "No"} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.denatured ? "Yes" : "No"} fontSize={"0.7rem"} />
                </PrintableTD>
            </PrintableTableRow>
        </PrintableTableHead>
    </React.Fragment>
);

export default DisposalReportRow;
