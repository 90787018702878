import {Dispatch} from "redux";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {MOVE_DRUGS_STORE, MoveDrugsDispatchTypes} from "./MoveDrugsActionTypes";
import {MoveDrugsRequest} from "../../../api/mm";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const moveDrugs = (request: MoveDrugsRequest) => {
    return async (dispatch: Dispatch<MoveDrugsDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                MOVE_DRUGS_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.moveDrugs(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: MOVE_DRUGS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
