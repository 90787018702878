import {DrugAuditPagedResponse} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const AUDIT_LIST_PAGED_STORE = createStoreState("audit_list_paged");

interface AuditListPagedLoading extends StoreServiceData<DrugAuditPagedResponse> {
    type: typeof AUDIT_LIST_PAGED_STORE.LOADING;
}
interface AuditListPagedSuccess extends StoreServiceData<DrugAuditPagedResponse> {
    type: typeof AUDIT_LIST_PAGED_STORE.SUCCESS;
}

interface AuditListPagedError extends StoreServiceData<DrugAuditPagedResponse> {
    type: typeof AUDIT_LIST_PAGED_STORE.ERROR;
}

export type AuditListPagedDispatchTypes =
    | AuditListPagedError
    | AuditListPagedSuccess
    | AuditListPagedLoading;
