import React from "react";
import PrintableTable from "../../../../../Print/Components/PrintableTable";
import {AdministrationReport} from "../../../../../../store/administrationReports/actions/AdministrationReportsActionTypes";
import AdministrationReportHeader from "./AdministrationReportHeader";
import AdministrationReportRow from "./AdministrationReportRow";
import {toArray} from "../../../../../../utils/sortingUtils";

const PrintAdministrationReport = (props: AdministrationReport[]) => {
    return (
        <React.Fragment>
            <PrintableTable width={"100px"} marginTop={"15px"}>
                {toArray(props).map((item, index) => {
                    return (
                        <React.Fragment key={index}>
                            <AdministrationReportHeader />
                            <AdministrationReportRow report={item} index={index} />
                        </React.Fragment>
                    );
                })}
            </PrintableTable>
        </React.Fragment>
    );
};

export default PrintAdministrationReport;
