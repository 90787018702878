import {useEffect} from "react";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../Navigation/routeNames";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {
    fetchAllDrugPackCategories,
    nullifyDrugPackCategoryListStore
} from "../../../../store/drugPackCategoryList/actions/DrugPackCategoryListActions";
import {
    fetchAllActiveDrugBatches,
    nullifyDrugBatchListStore
} from "../../../../store/drugBatchList/actions/DrugBatchListActions";
import {getAuditLocations} from "../../../../store/locationList/actions/LocationListActions";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {useHistory} from "react-router-dom";
import {usePageUrl} from "../../../Hooks/usePageUrl";

export function useMedicineManagement() {
    const dispatch = useDispatch();
    const activeDrugBatchStore = useSelector((state: RootStore) => state.drugBatchList);
    const history = useHistory();
    const {searchQueries} = usePageUrl();

    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: `${routeNames.medicineManagement.path}${searchQueries}`
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            },
            {
                name: routeNames.auditLocationList.name,
                path: routeNames.auditLocationList.path
            }
        ];

        dispatch(setNavigationItems(nav));

        dispatch(fetchAllDrugPackCategories());
        dispatch(fetchAllActiveDrugBatches());
        dispatch(getAuditLocations());

        return function () {
            dispatch(nullifyDrugPackCategoryListStore());
            dispatch(nullifyDrugBatchListStore());
        };
    }, []);

    function createNewDrugPack() {
        history.push({
            pathname: routeNames.drugPackWithContentsAdd.path,
            search: searchQueries
        });
    }

    function viewNewDrugPackCategories() {
        const path = routeNames.drugPackCategoryList.path;

        history.push({
            pathname: path,
            search: searchQueries
        });
    }

    return {
        activeDrugBatchStore,
        viewNewDrugPackCategories,
        createNewDrugPack
    };
}
