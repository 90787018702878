import {Dispatch} from "redux";
import {AUDIT_STORE, AuditDispatchTypes} from "./AuditActionTypes";
import {ClinicalGrade, StaffAccessLevel, UserData} from "../../../api/staff";
import Store from "../../Store";
import {RootStore} from "../../Store";
import {getAuditLocations} from "../../locationList/actions/LocationListActions";
import {DrugAudit, DrugAuditType, DrugPack, DrugPackCategory, StaffLink} from "../../../api/mm";
import moment from "moment";
import {Answer, AuditForm, DrugPackCategoryInfo, DrugPackInfo} from "../helpers/auditHelpers";
import {nanoid} from "nanoid";
import {
    ControlDrugAudit,
    ControlDrugAuditBaseQuestions,
    ControlDrugItem,
    generateNewControlDrugAudit
} from "../helpers/controlDrugAuditHelpers";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {generateNewWeeklyAudit, WeeklyAudit} from "../helpers/weeklyDrugBagAuditHelpers";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithData,
    postDataToServiceWithData
} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {showErrorToast} from "../../../utils/toastUtils";

export const nullifyAuditStore = () => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const createNewAudit = (user: UserData) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(getAuditLocations());
            const drugPacks = await getAllDrugPacks();
            const drugPackCategories = await getAllDrugPackCategories();
            const drugPackCategoryInfo = await getDrugPackInfo(drugPacks, drugPackCategories);
            dispatch({
                type: AUDIT_STORE.SUCCESS,
                loading: false,
                error: null,
                data: {
                    drugAudit: createBlankAudit(user),
                    drugPackCategoryInfo
                }
            });
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                loading: false,
                error: null
            });
        }
    };
};

export const setAudit = (audit: DrugAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        const auditStore = state().audit.data;
        if (!auditStore) return;
        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                ...auditStore,
                drugAudit: audit
            }
        });
    };
};

export const setAuditType = (auditType: DrugAuditType) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });

        const auditStore = state().audit.data;
        if (!auditStore) return;

        const auditForm: AuditForm = JSON.parse(auditStore.drugAudit.payload);

        const updatedAuditForm: AuditForm = addSpecifiedAuditToAuditForm(
            auditType,
            auditForm,
            auditStore.drugPackCategoryInfo,
            auditStore.drugAudit.locationId
        );

        // Update the audit type
        const updatedAudit: DrugAudit = {
            ...auditStore.drugAudit,
            type: auditType
        };

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                ...auditStore,
                drugAudit: stringifyPayload(updatedAudit, updatedAuditForm)
            }
        });
    };
};

export const setWeeklyAudit = (args: SetWeeklyAuditArgs) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const auditStore = state().audit.data;
        if (!auditStore) return;

        const auditForm: AuditForm = JSON.parse(auditStore.drugAudit.payload);

        const weeklyAudit: WeeklyAudit | undefined = auditForm.weeklyAudit;

        if (!weeklyAudit) return;

        const categoryIndex = weeklyAudit.drugPackAudit.drugsPacksWithCategories.findIndex(
            (item) => item.categoryId === args.categoryId
        );

        if (categoryIndex < 0) return;

        weeklyAudit.drugPackAudit.drugsPacksWithCategories[categoryIndex].drugPackInfoList[
            args.drugPackInfoIndex
        ] = args.drugPackInfo;

        const updatedAuditForm: AuditForm = {
            ...auditForm,
            weeklyAudit
        };

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                ...auditStore,
                drugAudit: stringifyPayload(auditStore.drugAudit, updatedAuditForm)
            }
        });
    };
};

export const setControlDrugAuditBaseQuestions = (baseQuestions: ControlDrugAuditBaseQuestions) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const auditStore = state().audit.data;
        if (!auditStore) return;

        const auditForm: AuditForm = JSON.parse(auditStore.drugAudit.payload);

        const controlDrugAudit: ControlDrugAudit | undefined = auditForm.controlDrugAudit;

        if (!controlDrugAudit) return;

        controlDrugAudit.controlDrugAuditBaseQuestions = baseQuestions;

        const updatedAuditForm: AuditForm = {
            ...auditForm,
            controlDrugAudit
        };

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                ...auditStore,
                drugAudit: stringifyPayload(auditStore.drugAudit, updatedAuditForm)
            }
        });
    };
};

export const addControlDrugRow = () => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const auditStore = state().audit.data;
        if (!auditStore) return;

        const auditForm: AuditForm = JSON.parse(auditStore.drugAudit.payload);

        const controlDrugAudit: ControlDrugAudit | undefined = auditForm.controlDrugAudit;

        if (!controlDrugAudit) return;

        controlDrugAudit.controlDrugItemList.items.push({
            controlDrug: "",
            expiryDate: moment().unix(),
            batchNumber: "",
            stockBalance: 0
        });

        const updatedAuditForm: AuditForm = {
            ...auditForm,
            controlDrugAudit
        };

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                ...auditStore,
                drugAudit: stringifyPayload(auditStore.drugAudit, updatedAuditForm)
            }
        });
    };
};

interface SetControlDrugArgs {
    drugRowIndex: number;
    newItem: ControlDrugItem;
}

export const setControlDrugRow = (args: SetControlDrugArgs) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const auditStore = state().audit.data;
        if (!auditStore) return;

        const auditForm: AuditForm = JSON.parse(auditStore.drugAudit.payload);

        const controlDrugAudit: ControlDrugAudit | undefined = auditForm.controlDrugAudit;

        if (!controlDrugAudit) return;

        controlDrugAudit.controlDrugItemList.items[args.drugRowIndex] = args.newItem;

        const updatedAuditForm: AuditForm = {
            ...auditForm,
            controlDrugAudit
        };

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                ...auditStore,
                drugAudit: stringifyPayload(auditStore.drugAudit, updatedAuditForm)
            }
        });
    };
};

export const removeControlDrugRow = (index: number) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        dispatch({
            type: AUDIT_STORE.LOADING,
            loading: true,
            error: null
        });
        const auditStore = state().audit.data;
        if (!auditStore) return;

        const auditForm: AuditForm = JSON.parse(auditStore.drugAudit.payload);

        const controlDrugAudit: ControlDrugAudit | undefined = auditForm.controlDrugAudit;

        if (!controlDrugAudit) return;

        controlDrugAudit.controlDrugItemList.items.splice(index, 1);

        const updatedAuditForm: AuditForm = {
            ...auditForm,
            controlDrugAudit
        };

        dispatch({
            type: AUDIT_STORE.SUCCESS,
            loading: false,
            error: null,
            data: {
                ...auditStore,
                drugAudit: stringifyPayload(auditStore.drugAudit, updatedAuditForm)
            }
        });
    };
};

function createBlankAudit(user: UserData): DrugAudit {
    return {
        dateCreated: moment().unix(),
        dateModified: moment().unix(),
        id: 0,
        locationId: 0,
        locationName: "",
        modifiedAuthor: toStaffLink(user),
        originalAuthor: toStaffLink(user),
        reportingManager: toStaffLink(user),
        type: DrugAuditType.None,
        payload: JSON.stringify(generateAuditForm())
    };
}

function addSpecifiedAuditToAuditForm(
    auditType: DrugAuditType,
    auditForm: AuditForm,
    drugPackCategoryInfo: DrugPackCategoryInfo[],
    locationId: number
): AuditForm {
    switch (auditType) {
        case DrugAuditType.WeeklyDrugPackAudit:
            return addWeeklyAuditToAuditForm(auditForm, drugPackCategoryInfo, locationId);
        case DrugAuditType.ControlledDrugAudit:
            return addControlDrugAuditToAuditForm(auditForm);
        default:
            return auditForm;
    }
}

/** Add the general audit to the audit form whilst nullifying the rest of the audits */
function addWeeklyAuditToAuditForm(
    auditForm: AuditForm,
    drugPackCategoryInfo: DrugPackCategoryInfo[],
    locationId: number
): AuditForm {
    return {
        ...auditForm,
        weeklyAudit: generateNewWeeklyAudit(drugPackCategoryInfo, locationId),
        controlDrugAudit: undefined
    };
}

/** Add the general audit to the audit form whilst nullifying the rest of the audits */
function addControlDrugAuditToAuditForm(auditForm: AuditForm): AuditForm {
    return {
        ...auditForm,
        controlDrugAudit: generateNewControlDrugAudit(),
        weeklyAudit: undefined
    };
}

export function toStaffLink(userData: UserData): StaffLink {
    return {
        staffName: `${userData.firstName} ${userData.lastName}`,
        staffId: userData.username
    };
}

/** Takes the audit form and stringifies the payload */
function stringifyPayload(audit: DrugAudit, auditForm: AuditForm): DrugAudit {
    return {
        ...audit,
        payload: JSON.stringify(auditForm)
    };
}

function generateAuditForm(): AuditForm {
    return {
        id: nanoid()
    };
}

async function getAllDrugPacks(): Promise<DrugPack[]> {
    try {
        const request = await MedicineManagementApiModel.drugApi.getAllDrugPacks();

        if (request.status === 200 || request.status === 204) {
            return request.data;
        }

        return [];
    } catch (e: any) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        showErrorToast("Could not get drug packs from service");
        return [];
    }
}

async function getAllDrugPackCategories(): Promise<DrugPackCategory[]> {
    try {
        const request = await MedicineManagementApiModel.drugApi.getAllDrugPackCategories();

        if (request.status === 200 || request.status === 204) {
            return request.data;
        }

        return [];
    } catch (e: any) {
        showErrorToast("Could not get drug pack categories from service");
        return [];
    }
}

async function getDrugPackInfo(
    drugPacks: DrugPack[],
    drugPackCategories: DrugPackCategory[]
): Promise<DrugPackCategoryInfo[]> {
    const drugPackInfo: DrugPackCategoryInfo[] = [];

    for (const category of drugPackCategories) {
        const drugPacksForCategory = drugPacks.filter((item) => item.categoryId === category.id);
        drugPackInfo.push(toDrugPackInfo(drugPacksForCategory, category));
    }

    return drugPackInfo;
}

function toDrugPackInfo(drugPacks: DrugPack[], category: DrugPackCategory): DrugPackCategoryInfo {
    return {
        categoryId: category.id,
        categoryName: category.name,
        drugPackInfoList: drugPacks.map((item) => {
            return {
                answer: Answer.NotApplicable,
                drugPackName: item.name,
                drugPackId: item.id,
                locationId: item.locationId
            };
        })
    };
}

export interface SetWeeklyAuditArgs {
    categoryId: number;
    drugPackInfo: DrugPackInfo;
    drugPackInfoIndex: number;
}

export const saveAuditToService = (drugAudit: DrugAudit) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>, state: () => RootStore) => {
        try {
            const auditStore = state().audit.data;
            if (!auditStore) return;
            const valid = validateAudit(drugAudit);
            if (!valid) return;

            const savedAudit: DrugAudit | undefined | null = await postDataToServiceWithData(
                AUDIT_STORE,
                dispatch,
                () =>
                    MedicineManagementApiModel.drugApi.saveDrugAudit(
                        updateAuditOutgoing(drugAudit)
                    ),
                statusCodeCallback
            );

            dispatch({
                type: AUDIT_STORE.SUCCESS,
                error: null,
                loading: false,
                data: {
                    ...auditStore,
                    drugAudit: savedAudit ? savedAudit : auditStore.drugAudit
                }
            });

            return true;
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const getAuditFromService = (id: number) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(getAuditLocations());
            const drugPacks = await getAllDrugPacks();
            const drugPackCategories = await getAllDrugPackCategories();
            const drugPackCategoryInfo = await getDrugPackInfo(drugPacks, drugPackCategories);
            const drugAudit = await getDataFromServiceWithData(
                AUDIT_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getDrugAudit(id),
                statusCodeCallback
            );

            if (!drugAudit) return;

            dispatch({
                type: AUDIT_STORE.SUCCESS,
                loading: false,
                error: null,
                data: {
                    drugAudit: drugAudit,
                    drugPackCategoryInfo
                }
            });
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const getHistoricAuditFromService = (auditId: number, historicAuditId: number) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(getAuditLocations());
            const drugPacks = await getAllDrugPacks();
            const drugPackCategories = await getAllDrugPackCategories();
            const drugPackCategoryInfo = await getDrugPackInfo(drugPacks, drugPackCategories);
            const drugAudit = await getDataFromServiceWithData(
                AUDIT_STORE,
                dispatch,
                () =>
                    MedicineManagementApiModel.drugApi.getHistoricDrugAuditList_1(
                        auditId,
                        historicAuditId
                    ),
                statusCodeCallback
            );

            if (!drugAudit) return;

            dispatch({
                type: AUDIT_STORE.SUCCESS,
                loading: false,
                error: null,
                data: {
                    drugAudit: drugAudit,
                    drugPackCategoryInfo
                }
            });
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const deleteAuditFromService = (id: number) => {
    return async (dispatch: Dispatch<AuditDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                AUDIT_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.deleteDrugAudit(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

function validateAudit(drugAudit: DrugAudit): boolean {
    let valid = true;
    if (drugAudit.locationId === 0) {
        valid = false;
        showErrorToast("A location must be selected.");
    }
    if (drugAudit.type === DrugAuditType.None) {
        valid = false;
        showErrorToast("An audit must be selected.");
    }
    return valid;
}

function updateAuditOutgoing(audit: DrugAudit): DrugAudit {
    const currentUser = Store.getState().auth.data || createBlankUser();
    const modifiedBy = toStaffLink(currentUser);
    const now = moment();
    return {
        ...audit,
        modifiedAuthor: audit.id > 0 ? modifiedBy : audit.modifiedAuthor,
        dateCreated: audit.id > 0 ? audit.dateCreated : now.unix(),
        dateModified: moment().unix()
    };
}

function createBlankUser(): UserData {
    return {
        paye: false,
        accessLevel: StaffAccessLevel.Staff,
        clinicalGrade: ClinicalGrade.None,
        email: "",
        firstName: "",
        lastName: "",
        username: ""
    };
}
