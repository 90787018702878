import React from "react";
import {DrugExpiry} from "../../../../../../store/expiryReports/actions/ExpiryReportActionTypes";
import {formatUnixToDDMMYYYY} from "../../../../../../utils/momentUtils";

const ExpiryReportDetails = (props: DrugExpiry) => {
    return (
        <tr className="pulse-table-row">
            <td align="left" className="pulse-table-row-item">
                {props.drugBatchName}
            </td>
            <td align="left" className="pulse-table-row-item">
                {props.drugPackName}
            </td>
            <td align="left" className="pulse-table-row-item">
                {props.batchNumber}
            </td>
            <td align="left" className="pulse-table-row-item">
                {formatUnixToDDMMYYYY(props.expiryDate)}
            </td>
            <td align="left" className="pulse-table-row-item">
                {props.qty}
            </td>
            <td align="left" className="pulse-table-row-item">
                N/A
            </td>
        </tr>
    );
};

export default ExpiryReportDetails;
