import React, {useEffect} from "react";
import {DrugBatch} from "../../../../api/mm";
import {nullifyDrugBatchListStore} from "../../../../store/drugBatchList/actions/DrugBatchListActions";
import {useDispatch} from "react-redux";
import {PulseTableWithLocalPagination} from "pulse_table";
import {pagedRequestConfig} from "../../../Filters/helpers/filterHelpers";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {getRemainingStock} from "../../StockManagementList/Helpers/stockManagementListHelpers";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {toArray} from "../../../../utils/sortingUtils";
import Tooltip from "../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../Icon/Icon";
import {
    getDrugExpiry,
    getExpiryIconClassName,
    renderLowStockQty
} from "../../StockManagementList/Components/StockManagementTable";
import FormActionContainer from "../../../Form/FormActionContainer";
import PrintButton from "../../../Print/PrintButton";
import PrintActiveStockReport from "./Print/PrintActiveStockReport";

const ActiveStockTable = (props: DrugBatch[]) => {
    const dispatch = useDispatch();
    useEffect(() => {
        return function () {
            dispatch(nullifyDrugBatchListStore());
        };
    }, []);

    return (
        <React.Fragment>
            <FormActionContainer>
                <div className="col d-flex justify-content-end pr-0">
                    <PrintButton
                        printButtonText={"Print Active Stock Report"}
                        reportHeaderText={"Active Stock Report"}
                        toPrint={<PrintActiveStockReport {...props} />}
                    />
                </div>
            </FormActionContainer>
            <PulseTableWithLocalPagination
                resultsPerPage={pagedRequestConfig.resultsPerPage}
                items={toTableRow(toArray(props))}
                headers={{
                    drug: "Drug Name",
                    drugType: "Drug Type",
                    batchNumber: "Batch Number",
                    quantity: "Quantity",
                    activeStock: "Active Stock",
                    lowStockQty: "Low Stock Quantity",
                    expiryDate: "Expiry Date"
                }}
                noItemsSection={<NoItemsMessage message={"There are no active drugs in stock"} />}
                customRenderers={{
                    drug: (item: TableRow) => (
                        <React.Fragment>
                            <p className="mb-0">{item.drug.name}</p>
                        </React.Fragment>
                    ),
                    lowStockQty: (item: TableRow) => {
                        const lowStockWarning = getRemainingStock(item.drug) <= item.lowStockQty;
                        return (
                            <React.Fragment>
                                {lowStockWarning ? (
                                    <React.Fragment>{renderLowStockQty(item.drug)}</React.Fragment>
                                ) : (
                                    <p className="mb-0">{item.lowStockQty}</p>
                                )}
                            </React.Fragment>
                        );
                    },
                    expiryDate: (item: TableRow) => {
                        const {drug} = item;
                        const expiring = getDrugExpiry(drug.expiryDate, drug.name);
                        return (
                            <React.Fragment>
                                <p className="mb-0">
                                    <span className="mr-2">{item.expiryDate}</span>
                                    {expiring && (
                                        <React.Fragment>
                                            <Tooltip
                                                tooltipText={expiring.tooltipMessage}
                                                size={"md"}
                                                place={"right"}
                                                theme={"dark"}
                                            >
                                                <Icon
                                                    rootElement={"Span"}
                                                    icon={IconType.DrugExpiry}
                                                    size={"Medium"}
                                                    className={`cursor-pointer ${getExpiryIconClassName(
                                                        expiring
                                                    )}`}
                                                />
                                            </Tooltip>
                                        </React.Fragment>
                                    )}
                                </p>
                            </React.Fragment>
                        );
                    }
                }}
            />
        </React.Fragment>
    );
};

export default ActiveStockTable;

interface TableRow {
    drug: DrugBatch;
    drugType: string;
    batchNumber: string;
    quantity: number;
    activeStock: number;
    lowStockQty: number;
    expiryDate: string;
}

function toTableRow(batches: DrugBatch[]): TableRow[] {
    const rows: TableRow[] = [];

    const orderedBatches = orderBatchesByNameAsc(batches);

    for (const batch of orderedBatches) {
        if (getRemainingStock(batch) === 0) continue;

        rows.push({
            drug: batch,
            drugType: getUiFriendlyText(batch.type),
            batchNumber: batch.batchNumber,
            quantity: batch.qty,
            activeStock: getRemainingStock(batch),
            lowStockQty: batch.lowStockQty,
            expiryDate: formatUnixToDDMMYYYY(batch.expiryDate)
        });
    }

    return rows;
}

export function orderBatchesByNameAsc(batches: DrugBatch[]): DrugBatch[] {
    return batches.sort((a, b) => {
        return a.name.localeCompare(b.name);
    });
}
