import {DRUG_BATCH_STORE} from "../actions/DrugBatchActionTypes";
import {DrugBatch} from "../../../api/mm";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugBatch>(null);

const drugBatchReducer = (
    state: StoreServiceData<DrugBatch> = defaultState,
    action: ServiceActionTypes<DrugBatch>
): StoreServiceData<DrugBatch> =>
    createReducer(state, action, DRUG_BATCH_STORE, () => showErrorToast(action.error));

export default drugBatchReducer;
