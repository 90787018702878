import {VENUE_LIST_STORE, VenueListDispatchTypes} from "./VenueListActionTypes";
import {Dispatch} from "redux";
import GrsApiModel from "../../apiModel/GrsApiModel";
import store from "../../Store";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyVenueListStore = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        dispatch({
            type: VENUE_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const fetchAllVenues = () => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                VENUE_LIST_STORE,
                dispatch,
                () => GrsApiModel.venueApi.getAllVenues(),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().venueList.data;

                if (!data) return [];

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: VENUE_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const getVenuesForCalendarId = (calendarId: number) => {
    return async (dispatch: Dispatch<VenueListDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                VENUE_LIST_STORE,
                dispatch,
                () => GrsApiModel.venueApi.getVenuesForCalendarId(calendarId),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().venueList.data;

                if (!data) return [];

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: VENUE_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
