import React from "react";
import {Enum} from "../../utils/enumUtils";
import Select from "react-select";
import {usePulseEnumDropdown} from "./Hooks/usePulseEnumDropdown";
import {BaseDropdownProps, DropdownOption} from "./Helpers/dropdownUtils";

function PulseEnumDropdown(props: PulseDropdownProps) {
    const {options, handleOptionChanged, selectedOption} = usePulseEnumDropdown(props);
    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={options}
                onChange={handleOptionChanged}
                isSearchable={false}
                value={selectedOption}
                placeholder={props.placeholder}
                noOptionsMessage={() => "No Options Found"}
                isDisabled={props.disabled}
                isClearable={props.clearable}
                menuPortalTarget={props.appendToBody ? document.body : null}
            />
        </React.Fragment>
    );
}

export default PulseEnumDropdown;

export interface PulseDropdownProps extends BaseDropdownProps<DropdownOption> {
    value?: string | number;
    enumOptions: Enum<any>;
    appendToBody?: boolean;
}
