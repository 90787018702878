import {STAFF_LIST_STORE, StaffListDispatchTypes} from "./StaffListActionTypes";
import {Dispatch} from "redux";
import StaffApiModel from "../../apiModel/StaffApiModel";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import Store from "../../Store";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const getAllStaffMembers = () => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                STAFF_LIST_STORE,
                dispatch,
                () => StaffApiModel.getUsersApi().getAllUsers(),
                statusCodeCallback
            );

            if (success) {
                const staffList = Store.getState().staffList.data;
                return staffList || [];
            }
        } catch (e: any) {
            dispatch({
                type: STAFF_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const getStaffMembersByAccessLevel = (accessLevels?: StaffAccessLevel[]) => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                STAFF_LIST_STORE,
                dispatch,
                () => StaffApiModel.getUsersApi().getAllUsers(),
                statusCodeCallback
            );

            if (success) {
                const staffList = Store.getState().staffList.data;

                //Shouldn't be null if we are successful. Needed null check because of typescript "Quirks"
                if (!staffList) return [];

                if (!accessLevels) return staffList;

                // Return filtered staff list.
                return staffList.filter((el: UserData) => accessLevels.includes(el.accessLevel));
            }
        } catch (e: any) {
            dispatch({
                type: STAFF_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const nullifyStaffListStore = () => {
    return async (dispatch: Dispatch<StaffListDispatchTypes>) => {
        dispatch({
            type: STAFF_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};
