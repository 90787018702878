//Getting enum as a generic. Accepts string/numeric/heterogeneous

import {DrugAdministrationMethod, DrugType} from "../api/mm";

export type Enum<E> = Record<keyof E, number | string> & {[k: number]: string};

export function getDrugTypeFromString(value: string): DrugType {
    return DrugType[value as keyof typeof DrugType];
}

export const getRouteOfAdministrationFromString = (value: string) => {
    return DrugAdministrationMethod[value as keyof typeof DrugAdministrationMethod];
};
