import {useEffect, useState} from "react";
import {DrugBatchAdministration} from "../../../../api/mm";

export function useAdministrationForm(props: DrugBatchAdministration) {
    const [administration, setAdministration] = useState<DrugBatchAdministration>(props);
    const [administeredOnLocation, setAdministeredOnLocation] = useState<boolean>(false);

    useEffect(() => {
        setAdministration(props);
        setAdministeredOnLocation(!!props.grsLocation);
    }, [props]);

    function updateAdministration(entity: DrugBatchAdministration) {
        setAdministration(entity);
    }

    function toggleAdministrationLocation(toggled: boolean) {
        setAdministeredOnLocation((toggled = !toggled));

        //Reset because we are not using grs location
        if (!toggled) {
            updateAdministration({
                ...administration,
                staffId: undefined,
                staffName: undefined,
                grsLocation: undefined,
                cadNumber: undefined,
                witnessName: undefined,
                witnessId: undefined
            });
        }
    }

    return {
        toggleAdministrationLocation,
        administeredOnLocation,
        updateAdministration,
        administration
    };
}
