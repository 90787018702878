import React from "react";

const ExpiryReportHeader = () => {
    return (
        <thead>
            <tr className="pulse-table-header">
                <th align="left" className="pulse-table-header-item">
                    Drug Name
                </th>
                <th align="left" className="pulse-table-header-item">
                    Drug Pack
                </th>
                <th align="left" className="pulse-table-header-item">
                    Batch
                </th>
                <th align="left" className="pulse-table-header-item">
                    Expiry Date
                </th>
                <th align="left" className="pulse-table-header-item">
                    Quantity
                </th>
                <th align="left" className="pulse-table-header-item">
                    Actions
                </th>
            </tr>
        </thead>
    );
};

export default ExpiryReportHeader;
