import React, {useEffect} from "react";
import {Location} from "../../../../api/mm";
import {useDispatch} from "react-redux";
import {
    nullifyLocationStore,
    saveAuditLocationToService,
    setLocation
} from "../../../../store/location/actions/LocationActions";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {showSuccessToast} from "../../../../utils/toastUtils";

const EditLocationForm = (props: Location) => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        return () => {
            dispatch(nullifyLocationStore());
        };
    }, []);

    const cancel = () => {
        history.push(routeNames.auditLocationList.path);
    };

    const save = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveAuditLocationToService(props));
        if (!success) return;

        showSuccessToast("Saved Location");

        cancel();
    };

    return (
        <React.Fragment>
            <FormHeader headerName={"Location Form"} />
            <FormRow rowName={"Location Name"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    value={props.name}
                    onChange={(event) => {
                        const {value} = event.target;

                        dispatch(
                            setLocation({
                                ...props,
                                name: value
                            })
                        );
                    }}
                    className="input-fields"
                    placeholder={"Enter Location Name..."}
                />
            </FormRow>
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Save"}
                    onClick={save}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Cancel"}
                    onClick={cancel}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default EditLocationForm;
