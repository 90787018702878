export function minutesToMilliseconds(x: number) {
    return x * 60000; //60000 = 1 minute in milliseconds
}

// Clamp number between two values with the following line:
export function clamp(num: number, min: number, max: number) {
    return Math.min(Math.max(num, min), max);
}

export function isEven(value: number): boolean {
    return value % 2 === 0;
}

export function getRandomNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}
