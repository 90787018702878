import React from "react";
import {ReportRowPrintProps} from "../../../../Print/helpers/printableHelpers";
import {DrugBatch} from "../../../../../api/mm";
import PrintableTableHead from "../../../../Print/Components/PrintableTableHead";
import PrintableTableRow from "../../../../Print/Components/PrintableTableRow";
import PrintableTD from "../../../../Print/Components/PrintableTD";
import PrintableP from "../../../../Print/Components/PrintableP";
import {formatUnixToDDMMYYYY} from "../../../../../utils/momentUtils";
import {getRemainingStock} from "../../../StockManagementList/Helpers/stockManagementListHelpers";

const ActiveStockReportRow = ({index, report}: ReportRowPrintProps<DrugBatch>) => (
    <React.Fragment>
        <PrintableTableHead>
            <PrintableTableRow rowType={"bodyRow"} index={index}>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.name} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.type} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.batchNumber} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={getRemainingStock(report)} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.lowStockQty} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP
                        headerName={formatUnixToDDMMYYYY(report.expiryDate)}
                        fontSize={"0.7rem"}
                    />
                </PrintableTD>
            </PrintableTableRow>
        </PrintableTableHead>
    </React.Fragment>
);

export default ActiveStockReportRow;
