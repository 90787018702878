import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../../../store/Store";
import {
    fetchDrugPacksWithContentByCategoryId,
    fetchDrugPacksWithContentByLocationId
} from "../../../../../../store/drugPackWithContentsList/actions/DrugPackWithContentsListActions";
import {useHistory} from "react-router-dom";
import {DrugPackCategory, Location} from "../../../../../../api/mm";
import {useQuery} from "../../../../../Hooks/useQuery";
import LocationDropdown from "../../../../../Dropdown/LocationDropdown";
import DrugPackCategoryDropdown from "../../../../../Dropdown/DrugPackCategoryDropdown";
import {NavigationItem} from "../../../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../../../Navigation/routeNames";
import {setNavigationItems} from "../../../../../../store/navigation/actions/NavigationActions";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";

export function useDrugPackTableContainer() {
    const drugPackWithContentsStore = useSelector(
        (state: RootStore) => state.drugPackWithContentsList
    );
    const locationListStore = useSelector((state: RootStore) => state.locationList);
    const drugPackCategoryListStore = useSelector((state: RootStore) => state.drugPackCategoryList);

    const history = useHistory();
    const dispatch = useDispatch();
    const [requestId, setRequestId] = useState<number>();
    const [dropdownToShow, setDropdownToShow] = useState<SelectedDropdown>();
    const query = useQuery();
    const {searchQueries} = usePageUrl();

    useEffect(() => {
        const locationId = query.get("locationId");
        if (locationId) {
            setDropdownToShow("Location");
            setRequestId(+locationId);
            dispatch(fetchDrugPacksWithContentByLocationId(+locationId));
            updateRoutes(searchQueries);
            return;
        }
        const categoryId = query.get("categoryId");
        if (categoryId) {
            setDropdownToShow("Category");
            setRequestId(+categoryId);
            dispatch(fetchDrugPacksWithContentByCategoryId(+categoryId));
            updateRoutes(searchQueries);
            return;
        }

        //Default it for now
        setDropdownToShow("Category");
    }, []);

    function onLocationFilterChanged(value: Location | undefined | null) {
        if (!value) return;
        const {id} = value;
        const search = `?locationId=${id}`;
        setRequestId(id);
        history.push({
            search: search
        });

        dispatch(fetchDrugPacksWithContentByLocationId(+id));
        updateRoutes(search);
    }

    function onDrugPackCategoryFilterChanged(value: DrugPackCategory | undefined | null) {
        if (!value) return;
        const {id} = value;
        const search = `?categoryId=${id}`;
        setRequestId(id);
        history.push({
            search: search
        });
        dispatch(fetchDrugPacksWithContentByCategoryId(+id));
        updateRoutes(search);
    }

    function onSelectedDropdownChanged(type: SelectedDropdown): void {
        const firstLocation = getFirstOrNull(locationListStore.data);
        const firstDrugPackCategory = getFirstOrNull(drugPackCategoryListStore.data);

        switch (type) {
            case "Location":
                onLocationFilterChanged(firstLocation);
                break;
            case "Category":
                onDrugPackCategoryFilterChanged(firstDrugPackCategory);
                break;
        }
    }

    function updateRoutes(search: string) {
        dispatch(setNavigationItems(getRoutes(search)));
    }

    function getFirstOrNull<T>(array: T[] | undefined | null): T | undefined | null {
        if (!array) return;

        return array[0];
    }

    function renderDropdown() {
        switch (dropdownToShow) {
            case "Location":
                return (
                    <LocationDropdown locationId={requestId} onChange={onLocationFilterChanged} />
                );
            case "Category":
                return (
                    <DrugPackCategoryDropdown
                        drugPackCategoryId={requestId}
                        onChange={onDrugPackCategoryFilterChanged}
                    />
                );
            default:
                return;
        }
    }

    function getRoutes(search: string): NavigationItem[] {
        return [
            getOverviewRoute(search),
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            },
            {
                name: routeNames.auditLocationList.name,
                path: routeNames.auditLocationList.path
            }
        ];
    }

    function getOverviewRoute(search: string): NavigationItem {
        const {medicineManagement} = routeNames;
        const overviewPath = `${medicineManagement.path}${search}`;
        return {
            name: medicineManagement.name,
            path: overviewPath
        };
    }

    return {
        onLocationFilterChanged,
        drugPackWithContentsStore,
        renderDropdown,
        dropdownToShow,
        setDropdownToShow,
        locationListStore,
        drugPackCategoryListStore,
        onSelectedDropdownChanged
    };
}

export type SelectedDropdown = "Category" | "Location";
