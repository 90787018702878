import React from "react";
import {clamp} from "../../../utils/mathUtils";
import FormRow from "../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import {Loading} from "store-fetch-wrappers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import {
    MoveDrugBatchFormProps,
    MoveLocation,
    useMoveDrugBatchForm
} from "./Hooks/useMoveDrugBatchForm";
import DrugPackWithContentsDropdown from "../../Dropdown/DrugPackWithContentsDropdown";

const MoveDrugBatchForm = (props: MoveDrugBatchFormProps) => {
    const {
        movement,
        updateMovement,
        moveDrugsStore,
        renderPreMoveInstructions,
        moveDrug,
        moveLocation,
        selectedDrugPack,
        setSelectedDrugPack,
        drugPack
    } = useMoveDrugBatchForm(props);
    return (
        <React.Fragment>
            {renderPreMoveInstructions()}

            {moveLocation === MoveLocation.ToDrugPack && (
                <FormRow
                    rowName={"Select Drug Pack"}
                    columnDetailClassName={"pl-0 pr-0"}
                    formRowClassName={"mt-3"}
                >
                    <DrugPackWithContentsDropdown
                        selectedDrugPack={selectedDrugPack}
                        removeCurrentDrugPack={drugPack}
                        onChange={(option) => {
                            setSelectedDrugPack(option);
                            updateMovement({
                                ...movement,
                                toDrugPackId: option?.id
                            });
                        }}
                        placeholder={"Select drug pack"}
                    />
                </FormRow>
            )}

            {moveLocation && (
                <React.Fragment>
                    <FormRow rowName={"Amount to move"} columnDetailClassName={"pl-0 pr-0"}>
                        <div className="input-group suffix">
                            <input
                                value={movement.qty}
                                onChange={(event) => {
                                    const value = isNaN(+event.target.value)
                                        ? 0
                                        : +event.target.value;
                                    //The max value that can be moved. We alter the max depending on the object.
                                    const clampedValue = clamp(value, 0, props.maxQty);
                                    updateMovement({
                                        ...movement,
                                        qty: clampedValue
                                    });
                                }}
                                className="input-fields"
                                placeholder="Enter amount to move...."
                                required={true}
                                type="tel"
                                min={0}
                                step={1}
                                max={props.drugBatch.qty}
                            />
                            <span className="input-group-addon ">
                                <b>Max: {props.maxQty}</b>
                            </span>
                        </div>
                    </FormRow>
                    <FormRow rowName={"Comments"} columnDetailClassName={"pl-0 pr-0"}>
                        <DebounceInput
                            debounceTimeout={300}
                            element="textarea"
                            value={movement.comments}
                            onChange={(event) => {
                                const {value} = event.target;
                                updateMovement({
                                    ...movement,
                                    comments: value
                                });
                            }}
                            className="input-fields text-area-inputs"
                            placeholder="Enter comments here..."
                        />
                    </FormRow>
                    <div className="row ml-0 mr-0 mt-5">
                        {moveDrugsStore.loading ? (
                            <Loading type={"three-ring"} showLoadingText={false} />
                        ) : (
                            <React.Fragment>
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={
                                        moveLocation === MoveLocation.ToStock
                                            ? "Move to Stock"
                                            : "Move to Drug Pack"
                                    }
                                    onClick={moveDrug}
                                    colour={ButtonColourOptions.Yellow}
                                    roundedCorner
                                />
                                <MCButton
                                    size={ButtonSize.Large}
                                    innerValue={"Cancel"}
                                    onClick={props.onClose}
                                    colour={ButtonColourOptions.DarkBlue}
                                    roundedCorner
                                />
                            </React.Fragment>
                        )}
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default MoveDrugBatchForm;
