import {fetchGeneralReports} from "../../generalReports/actions/GeneralReportActions";
import {StaffLink} from "../../../api/grs";
import {Dispatch} from "redux";
import store from "../../Store";
import {DrugPack, GeneralReportRequest, GeneralReportResponse} from "../../../api/mm";
import {
    ADMINISTRATION_REPORTS_STORE_STATE,
    AdministrationReport,
    AdministrationReportsDispatchTypes,
    DrugBatchAdministrationWithDrugBatchDetails
} from "./AdministrationReportsActionTypes";

export const nullifyAdministrationReportStore = () => {
    return async (dispatch: Dispatch<AdministrationReportsDispatchTypes>) => {
        dispatch({
            type: ADMINISTRATION_REPORTS_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
};

export const getAdministrationReport =
    (request: GeneralReportRequest) =>
    async (dispatch: Dispatch<AdministrationReportsDispatchTypes>) => {
        try {
            dispatch({
                type: ADMINISTRATION_REPORTS_STORE_STATE.LOADING,
                error: null,
                loading: true
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const generalReports: GeneralReportResponse = await store.dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fetchGeneralReports(request)
            );

            if (!generalReports) {
                dispatch({
                    type: ADMINISTRATION_REPORTS_STORE_STATE.ERROR,
                    error: `Could not generate administration reports`,
                    loading: false
                });
                return;
            }

            dispatch({
                type: ADMINISTRATION_REPORTS_STORE_STATE.SUCCESS,
                error: null,
                loading: false,
                data: generateAdministrationReport(generalReports)
            });
        } catch (e: any) {
            dispatch({
                type: ADMINISTRATION_REPORTS_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };

export function generateAdministrationReport(data: GeneralReportResponse): AdministrationReport[] {
    if (!data.administrationDate) return [];
    const administrations: DrugBatchAdministrationWithDrugBatchDetails[] = [];
    const {drugPacks, drugBatches} = data;

    for (const drugBatch of drugBatches) {
        for (const administration of drugBatch.administrations) {
            administrations.push({
                ...administration,
                drugBatchId: drugBatch.id,
                drugBatchName: drugBatch.name,
                expiryDate: drugBatch.expiryDate,
                batchNumber: drugBatch.batchNumber
            });
        }
    }

    const administrationReportItems: AdministrationReport[] = [];
    for (const administrationDetails of administrations) {
        const index = drugPacks.findIndex(
            (el: DrugPack) => el.id === administrationDetails.drugPackId
        );

        const {
            date,
            expiryDate,
            grsLocation,
            qty,
            staffId,
            staffName,
            drugBatchName,
            drugBatchId,
            batchNumber,
            witnessId,
            witnessName,
            qa,
            dosage,
            wastage,
            routeAdministration,
            history
        } = administrationDetails;

        //If the date of the administration is not in range, we skip over this iteration
        if (date < data.administrationDate.startDate || date > data.administrationDate.endDate) {
            continue;
        }

        const witness: StaffLink | undefined =
            witnessId && witnessName
                ? {
                      staffName: witnessName,
                      staffId: witnessId
                  }
                : undefined;
        administrationReportItems.push({
            drugBatchName,
            drugBatchId,
            administrationDate: date,
            expiryDate: expiryDate,
            qty,
            staffMember: {
                staffId: staffId ? staffId : "No Staff Member Selected",
                staffName: staffName ? staffName : "No Staff Member Selected"
            },
            witness,
            drugPackName: index > -1 ? drugPacks[index].name : "Stock",
            location: grsLocation,
            batchNumber,
            qa,
            wastage,
            dosage,
            routeAdministration,
            history: history ? history.reverse() : undefined //reverse the list so it shows the changes by most recent at the top. otherwise, if it doesn't exist. Make it undefined.
        });
    }

    return administrationReportItems.sort((a, b) => {
        if (a.administrationDate > b.administrationDate) {
            return 1;
        }
        if (a.administrationDate < b.administrationDate) {
            return -1;
        }
        return 0;
    });
}
