import React from "react";
import PrintableTable from "../../../../../Print/Components/PrintableTable";
import DisposalReportHeader from "./DisposalReportHeader";
import PrintableTableBody from "../../../../../Print/Components/PrintableTableBody";
import {toArray} from "../../../../../../utils/sortingUtils";
import DisposalReportRow from "./DisposalReportRow";
import {DisposalReport} from "../../../../../../store/disposalReports/actions/DisposalReportsActionTypes";

const PrintDisposalReport = (props: DisposalReport[]) => {
    return (
        <React.Fragment>
            <PrintableTable width={"800px"} marginTop={"15px"}>
                <DisposalReportHeader />
                <PrintableTableBody>
                    {toArray(props).map((item, index) => {
                        return <DisposalReportRow report={item} index={index} key={index} />;
                    })}
                </PrintableTableBody>
            </PrintableTable>
        </React.Fragment>
    );
};

export default PrintDisposalReport;
