import React from "react";
import {DrugBatchActionFormProps} from "../Helpers/drugActionFormHelpers";
import AdministrationStage1 from "./Components/AdministrationStage1";
import AdministrationStage2 from "./Components/AdministrationStage2";

function DrugBatchAdministrationFormContainer(props: DrugBatchActionFormProps) {
    return (
        <React.Fragment>
            {!props.nominalMovementId?.length ? (
                <AdministrationStage1 {...props} />
            ) : (
                <AdministrationStage2 {...props} />
            )}
        </React.Fragment>
    );
}

export default DrugBatchAdministrationFormContainer;
