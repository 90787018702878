import React from "react";
import ReportHeader from "./ReportHeader";
import ReportFooter from "./ReportFooter";

const PrintFormWrapper = ({
    reportHeaderText,
    children,
    footerMargin,
    hideHeader
}: PrintFormWrapperProps) => {
    return (
        <React.Fragment>
            {!hideHeader && (
                <ReportHeader
                    padding={"0.8rem 1rem"}
                    marginTop={"0px"}
                    alignment={"right"}
                    reportHeaderText={`Medicare EMS Group UK ${
                        reportHeaderText ? `- ${reportHeaderText}` : ""
                    } `}
                />
            )}

            {children}
            <ReportFooter marginTop={footerMargin} />
        </React.Fragment>
    );
};

export default PrintFormWrapper;

interface PrintFormWrapperProps {
    reportHeaderText?: string;
    children: JSX.Element | JSX.Element[];
    footerMargin?: string;
    hideHeader?: boolean;
}
