import React from "react";
import PrintableTableHead from "./PrintableTableHead";
import PrintableTableRow from "./PrintableTableRow";
import PrintableTable from "./PrintableTable";
import PrintableTD from "./PrintableTD";

const PrintableFormRow = (props: PrintableFormRowProps) => {
    return (
        <PrintableTable width={"800px"}>
            <React.Fragment>
                {props.showHead && (
                    <PrintableTableHead>
                        <PrintableTableRow rowType={"bodyRow"}>
                            <PrintableTD
                                alignment={"left"}
                                padding={".35rem .75rem"}
                                maxWidth={"1000px"}
                            >
                                <p style={{marginBottom: `0`, marginTop: `0`, fontWeight: `bold`}}>
                                    {props.rowName}
                                </p>
                            </PrintableTD>
                        </PrintableTableRow>
                    </PrintableTableHead>
                )}
                <PrintableTableHead>
                    <PrintableTableRow rowType={"bodyRow"}>
                        <PrintableTD
                            alignment={"left"}
                            padding={".35rem .75rem"}
                            maxWidth={"1000px"}
                        >
                            <p style={{marginBottom: `0`, marginTop: `0`, fontWeight: `bold`}}>
                                {props.rowContent}
                            </p>
                        </PrintableTD>
                    </PrintableTableRow>
                </PrintableTableHead>
            </React.Fragment>
        </PrintableTable>
    );
};

export default PrintableFormRow;

interface PrintableFormRowProps {
    rowName: string;
    rowContent: any;
    showHead: boolean;
}
