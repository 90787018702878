import {useState} from "react";
import {
    DrugBatchAdministrationChangeRecord,
    GeneralReportRequest,
    GrsLocation
} from "../../../../../../api/mm";
import {ReportFilterProps} from "../../Components/ReportFilters";
import {
    GeneralReportType,
    generateReportRequest,
    getQueryStringsForGeneralReportRequest
} from "../../../Helpers/reportHelpers";
import {getAdministrationReport} from "../../../../../../store/administrationReports/actions/AdministrationReportsActions";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../../../Navigation/routeNames";
import {AdministrationReport} from "../../../../../../store/administrationReports/actions/AdministrationReportsActionTypes";
import {jsonToCsv} from "../../../../../../utils/csvUtils";
import {formatUnixToDDMMYYYY, formatUnixToMMMMYYYY} from "../../../../../../utils/momentUtils";
import {CalendarTypeEnum} from "../../../../../../api/grs";

export function useAdministrationReportTable() {
    const dispatch = useDispatch();
    const history = useHistory();
    const [reportRequest, setReportRequest] = useState<GeneralReportRequest>();

    const onRequestChanged = (filters: ReportFilterProps) => {
        const request = generateReportRequest(
            GeneralReportType.Administration,
            filters.startTime,
            filters.endTime
        );

        if (JSON.stringify(request) === JSON.stringify(reportRequest)) return;

        setReportRequest(request);
        fetchData(request);
    };

    const fetchData = (request: GeneralReportRequest | undefined) => {
        if (!request) return;
        const search = getQueryStringsForGeneralReportRequest(request);
        history.push({search});
        dispatch(getAdministrationReport(request));
    };

    const backToReports = () => {
        history.replace(routeNames.generalReports.path);
    };

    function downloadCsv(rows: AdministrationReport[]) {
        const startDate = reportRequest?.administrationDate?.startDate || 0;
        const filename = `Administration Report - Medicare Group UK - ${formatUnixToMMMMYYYY(
            startDate
        )}`;
        const csvPayload = generateDownloadableCsv(rows);
        jsonToCsv(filename, csvPayload);
    }
    function generateDownloadableCsv(rows: AdministrationReport[]) {
        const values: any[] = [getAdministrationCsvHeader()];

        for (const row of rows) {
            values.push(getAdministrationReportRow(row));

            //skip to save time
            if (!row.history || !row.history.length) continue;

            values.push(getHistoricAdministrationCsvHeader());
            for (const historicRow of row.history) {
                values.push(getHistoricAdministrationReportRow(historicRow));
            }

            values.push(getCsvBlankLine());
        }

        return values;
    }

    function getAdministrationReportRow({
        drugBatchName,
        drugPackName,
        administrationDate,
        qty,
        location,
        staffMember,
        witness,
        qa,
        dosage,
        wastage,
        routeAdministration
    }: AdministrationReport): (string | number | undefined)[] {
        return [
            "Current",
            drugBatchName,
            drugPackName,
            formatUnixToDDMMYYYY(administrationDate),
            qty,
            getLocationName(location),
            staffMember.staffName,
            witness?.staffName || "N/A",
            qa ? "Yes" : "No",
            dosage,
            wastage,
            routeAdministration
        ];
    }

    function getHistoricAdministrationReportRow({
        changedByStaffName,
        changedDate,
        qty,
        grsLocation,
        staffName,
        witnessName,
        qa,
        dosage,
        wastage,
        routeAdministration
    }: DrugBatchAdministrationChangeRecord): (string | number | undefined)[] {
        return [
            "History",
            changedByStaffName,
            "",
            formatUnixToDDMMYYYY(changedDate),
            qty,
            getLocationName(grsLocation),
            staffName,
            witnessName || "N/A",
            qa ? "Yes" : "No",
            dosage,
            wastage,
            routeAdministration
        ];
    }

    function getLocationName(location: GrsLocation | undefined) {
        if (!location) return "N/A";
        if (!location.calendarType) return "N/A";

        return location.calendarType === CalendarTypeEnum.Frontline
            ? location.entryName
            : location.venueName;
    }

    function getAdministrationCsvHeader() {
        return [
            "",
            "Drug Batch",
            "Drug Pack",
            "Administration Date",
            "Quantity",
            "Location",
            "Administered by",
            "Witnessed by",
            "Quality Assured",
            "Dosage",
            "Wastage",
            "Route of Administration"
        ];
    }

    function getHistoricAdministrationCsvHeader() {
        return [
            "",
            "Change by",
            "",
            "Administration date change",
            "Quantity change",
            "Location change",
            "Administered by change",
            "Witnessed by change",
            "Quality Assured change",
            "Dosage change",
            "Wastage change",
            "Route of Administration change",
            ""
        ];
    }
    function getCsvBlankLine() {
        return ["", "", "", "", "", "", "", "", "", "", "", ""];
    }

    return {
        onRequestChanged,
        backToReports,
        downloadCsv
    };
}
