import PrintableTable from "../../../../../Print/Components/PrintableTable";
import PrintableTableBody from "../../../../../Print/Components/PrintableTableBody";
import PrintableTD from "../../../../../Print/Components/PrintableTD";
import PrintableP from "../../../../../Print/Components/PrintableP";
import React from "react";

const ExpiryReportKey = () => {
    return (
        <PrintableTable width={"800px"} marginTop={"15px"}>
            <PrintableTableBody>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={"Key"} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={"(A): Amount Administered"} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={"(D): Amount Disposed"} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={"(P): Amount In Packs"} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={"(S): Amount In Stock"} fontSize={"0.7rem"} />
                </PrintableTD>
            </PrintableTableBody>
        </PrintableTable>
    );
};
export default ExpiryReportKey;
