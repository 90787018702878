import {DateRange, GeneralReportRequest} from "../../../../api/mm";
import {Moment} from "moment";
import {routeNames} from "../../../Navigation/routeNames";

export function generateReportRequest(
    requestType: GeneralReportType,
    startDate?: Moment,
    endDate?: Moment
): GeneralReportRequest | undefined {
    switch (requestType) {
        case GeneralReportType.Disposal:
            return {disposalDate: getDateRange(startDate, endDate)};
        case GeneralReportType.Expiry:
            return {expiryDate: getDateRange(startDate, endDate)};
        case GeneralReportType.Administration:
            return {administrationDate: getDateRange(startDate, endDate)};
        case GeneralReportType.Status:
            return {creationDate: getDateRange(startDate, endDate)};
    }
}

/** Generates the query strings needed for request. All 4 reports use this function. One field is always guaranteed to be filled, otherwise, request fails. */
export function getQueryStringsForGeneralReportRequest(request: GeneralReportRequest) {
    const queryStrings: string[] = [];

    if (request.creationDate) {
        queryStrings.push(`CDS=${request.creationDate.startDate}`);
        queryStrings.push(`CDE=${request.creationDate.endDate}`);
    }

    if (request.expiryDate) {
        queryStrings.push(`EDS=${request.expiryDate.startDate}`);
        queryStrings.push(`EDE=${request.expiryDate.endDate}`);
    }

    if (request.disposalDate) {
        queryStrings.push(`DDS=${request.disposalDate.startDate}`);
        queryStrings.push(`DDE=${request.disposalDate.endDate}`);
    }

    if (request.administrationDate) {
        queryStrings.push(`ADS=${request.administrationDate.startDate}`);
        queryStrings.push(`ADE=${request.administrationDate.endDate}`);
    }

    return `?${queryStrings.join("&")}`;
}

// eslint-disable-next-line no-shadow
export enum GeneralReportType {
    Expiry = "Expiry",
    Status = "Status",
    Administration = "Administration",
    Disposal = "Disposal"
}

//Printable stuff
export const headerFontSize = 0.5;
export const contentFontSize = 0.4;

//
export interface PrintableReport<T> {
    report: T;
    dateStart: number;
    dateEnd: number;
}

interface ReportButtons {
    reportType: GeneralReportType;
    internalUrl: string;
}

export const MMReportButtons: ReportButtons[] = [
    {
        reportType: GeneralReportType.Expiry,
        internalUrl: routeNames.expiryReports.path
    },
    {
        reportType: GeneralReportType.Status,
        internalUrl: routeNames.statusReports.path
    },
    {
        reportType: GeneralReportType.Administration,
        internalUrl: routeNames.administrationReports.path
    },
    {
        reportType: GeneralReportType.Disposal,
        internalUrl: routeNames.disposalReports.path
    }
];

function getDateRange(
    startDate: Moment | undefined,
    endDate: Moment | undefined
): DateRange | undefined {
    if (!startDate) return undefined;
    if (!endDate) return undefined;

    return {
        startDate: startDate.unix(),
        endDate: endDate.unix()
    };
}
