import React from "react";
import PrintableTableRow from "../../../../../Print/Components/PrintableTableRow";
import PrintableTD from "../../../../../Print/Components/PrintableTD";
import {DrugExpiryRollout} from "../../../../../../store/expiryReports/actions/ExpiryReportActionTypes";
import {ReportRowPrintProps} from "../../../../../Print/helpers/printableHelpers";
import PrintableP from "../../../../../Print/Components/PrintableP";
import {TotalQuantity} from "../../../../../../store/expiryReports/actions/ExpiryReportActions";
import {formatUnixToDDMMYYYY} from "../../../../../../utils/momentUtils";

const ExpiryReportRow = ({index, report}: ReportRowPrintProps<DrugExpiryRollout>) => {
    return (
        <React.Fragment>
            <PrintableTableRow rowType={"bodyRow"} index={index}>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.drugName} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={""} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={""} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={""} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    {getMarkupForQuantity(report.quantity)}
                </PrintableTD>
            </PrintableTableRow>
            <React.Fragment>
                {report.drugs.map((item, j) => {
                    return (
                        <PrintableTableRow key={j} rowType={"bodyRow"} index={index}>
                            <PrintableTD
                                alignment={"left"}
                                padding={".35rem .75rem"}
                                maxWidth={`100px`}
                            >
                                <PrintableP headerName={item.drugBatchName} fontSize={"0.7rem"} />
                            </PrintableTD>
                            <PrintableTD
                                alignment={"left"}
                                padding={".35rem .75rem"}
                                maxWidth={`100px`}
                            >
                                <PrintableP headerName={item.drugPackName} fontSize={"0.7rem"} />
                            </PrintableTD>
                            <PrintableTD
                                alignment={"left"}
                                padding={".35rem .75rem"}
                                maxWidth={`100px`}
                            >
                                <PrintableP headerName={item.batchNumber} fontSize={"0.7rem"} />
                            </PrintableTD>
                            <PrintableTD
                                alignment={"left"}
                                padding={".35rem .75rem"}
                                maxWidth={`100px`}
                            >
                                <PrintableP
                                    headerName={formatUnixToDDMMYYYY(item.expiryDate)}
                                    fontSize={"0.7rem"}
                                />
                            </PrintableTD>
                            <PrintableTD
                                alignment={"left"}
                                padding={".35rem .75rem"}
                                maxWidth={`100px`}
                            >
                                <PrintableP headerName={item.qty || 0} fontSize={"0.7rem"} />
                            </PrintableTD>
                        </PrintableTableRow>
                    );
                })}
            </React.Fragment>
        </React.Fragment>
    );
};

export default ExpiryReportRow;

function getMarkupForQuantity({
    inStock,
    administered,
    inDrugPacks,
    disposed
}: TotalQuantity): JSX.Element {
    return (
        <React.Fragment>
            <div style={{display: "inline-block", marginRight: `4px`}}>
                <PrintableP headerName={`${administered} (A)`} fontSize={"0.7rem"} />
            </div>
            <div style={{display: "inline-block", marginRight: `4px`}}>
                <PrintableP headerName={`${disposed} (D)`} fontSize={"0.7rem"} />
            </div>
            <div style={{display: "inline-block", marginRight: `4px`}}>
                <PrintableP headerName={`${inDrugPacks} (P)`} fontSize={"0.7rem"} />
            </div>
            <div style={{display: "inline-block"}}>
                <PrintableP headerName={`${inStock} (S)`} fontSize={"0.7rem"} />
            </div>
        </React.Fragment>
    );
}
