import React from "react";
import {useDrugPackTableContainer} from "./Hooks/useDrugPackTableContainer";
import DrugPackTable from "./DrugPackTable";
import {WithServiceState} from "store-fetch-wrappers";
import FormRowWithAction from "../../../../Form/FormRowWithAction";
import {IconType} from "../../../../Icon/Icon";

const ServiceWrapper = WithServiceState(DrugPackTable);

function DrugPackTableContainer(): JSX.Element {
    const {
        drugPackWithContentsStore,
        renderDropdown,
        dropdownToShow,
        setDropdownToShow,
        onSelectedDropdownChanged
    } = useDrugPackTableContainer();

    return (
        <React.Fragment>
            <FormRowWithAction
                showIcon={true}
                tooltipText={`Toggle view of Drug packs. Currently viewing by ${dropdownToShow}`}
                onClick={() => {
                    if (!dropdownToShow) return;

                    switch (dropdownToShow) {
                        case "Category":
                            setDropdownToShow("Location");
                            onSelectedDropdownChanged("Location");
                            break;
                        case "Location":
                            setDropdownToShow("Category");
                            onSelectedDropdownChanged("Category");
                            break;
                    }
                }}
                icon={IconType.Switch}
                rowName={`Filter by ${
                    dropdownToShow && dropdownToShow === "Location"
                        ? "Location"
                        : "Drug Pack Category"
                }`}
                columnDetailClassName={"pl-0 pr-0"}
            >
                {renderDropdown()}
            </FormRowWithAction>
            <ServiceWrapper
                {...drugPackWithContentsStore}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                showLoadingText={false}
            />
        </React.Fragment>
    );
}

export default DrugPackTableContainer;
