import {DrugBatchDisposal, GrsLocation} from "../../../api/mm";
import {StaffLink} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DISPOSAL_REPORTS_STORE_STATE = createStoreState("disposal_reports_store");

interface DisposalReportsLoading extends StoreServiceData<DisposalReport[]> {
    type: typeof DISPOSAL_REPORTS_STORE_STATE.LOADING;
}
interface DisposalReportsError extends StoreServiceData<DisposalReport[]> {
    type: typeof DISPOSAL_REPORTS_STORE_STATE.ERROR;
}
interface DisposalReportsSuccess extends StoreServiceData<DisposalReport[]> {
    type: typeof DISPOSAL_REPORTS_STORE_STATE.SUCCESS;
}

export type DisposalReportsDispatchTypes =
    | DisposalReportsLoading
    | DisposalReportsError
    | DisposalReportsSuccess;

export interface DisposalReport {
    drugBatchName: string;
    drugBatchId: number;
    disposalDate: number;
    expiryDate: number;
    batchNumber: string;
    qty: number;
    staffMember: StaffLink;
    witness?: StaffLink;
    drugPackName: string;
    location?: GrsLocation;
    broken: boolean;
    denatured: boolean;
}

export interface DrugBatchDisposalWithDrugBatchDetails extends DrugBatchDisposal {
    drugBatchName: string;
    drugBatchId: number;
    expiryDate: number;
    batchNumber: string;
}
