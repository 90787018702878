import {Dispatch} from "redux";
import {DRUG_PACK_LIST_STORE, DrugPackListDispatchTypes} from "./DrugPackListActionTypes";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import store from "../../Store";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const fetchAllDrugPacks = () => {
    return async (dispatch: Dispatch<DrugPackListDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                DRUG_PACK_LIST_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getAllDrugPacks(),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().drugPackList.data;

                if (!data) return [];

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: DRUG_PACK_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
