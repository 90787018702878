import {Dispatch} from "redux";
import {DRUG_BATCH_LIST_STORE, DrugBatchListDispatchTypes} from "./DrugBatchListActionTypes";
import store from "../../Store";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
export const nullifyDrugBatchListStore =
    () => async (dispatch: Dispatch<DrugBatchListDispatchTypes>) => {
        dispatch({
            type: DRUG_BATCH_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };

export const fetchAllDrugBatchesWithinDrugPack = (drugPackId: number) => {
    return async (dispatch: Dispatch<DrugBatchListDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                DRUG_BATCH_LIST_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getDrugBatchesWithinPack(drugPackId),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().drugBatchList.data;

                if (!data) return [];

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: DRUG_BATCH_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const fetchAllActiveDrugBatches = () => {
    return async (dispatch: Dispatch<DrugBatchListDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                DRUG_BATCH_LIST_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getAllActiveDrugBatches(),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().drugBatchList.data;

                if (!data) return [];

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: DRUG_BATCH_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
