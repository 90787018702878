import React from "react";
import {DrugPackInfo} from "../../../../../store/audit/helpers/auditHelpers";
import QuestionAndAnswer from "../Question/QuestionAndAnswer";
import {useDispatch} from "react-redux";
import {setWeeklyAudit, SetWeeklyAuditArgs} from "../../../../../store/audit/actions/AuditActions";

const DrugPackWithAnswer = (props: DrugPackWithAnswerProps) => {
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <QuestionAndAnswer
                onChange={(item) => {
                    const args: SetWeeklyAuditArgs = {
                        drugPackInfo: {
                            ...props.drugPackInfo,
                            answer: item
                        },
                        categoryId: props.categoryId,
                        drugPackInfoIndex: props.index
                    };
                    dispatch(setWeeklyAudit(args));
                }}
                question={props.drugPackInfo.drugPackName}
                answer={props.drugPackInfo.answer}
                scoreChange={0}
            />
        </React.Fragment>
    );
};

export default DrugPackWithAnswer;

interface DrugPackWithAnswerProps {
    index: number;
    drugPackInfo: DrugPackInfo;
    categoryId: number;
}
