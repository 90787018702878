import {GeneralReportResponse} from "../../../api/mm";
import {GENERAL_REPORT_STORE_STATE} from "../actions/GeneralReportActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<GeneralReportResponse>(null);

const generalReportsReducer = (
    state: StoreServiceData<GeneralReportResponse> = defaultState,
    action: ServiceActionTypes<GeneralReportResponse>
): StoreServiceData<GeneralReportResponse> =>
    createReducer(state, action, GENERAL_REPORT_STORE_STATE, () => showErrorToast(action.error));

export default generalReportsReducer;
