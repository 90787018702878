import React from "react";
import {routeNames} from "../../Navigation/routeNames";
import {getAuditLocations} from "../../../store/locationList/actions/LocationListActions";
import {WithServiceState} from "store-fetch-wrappers";
import IdNameTable from "../../Table/IdNameTable/IdNameTable";
import {deleteAuditLocationById} from "../../../store/location/actions/LocationActions";
import {useLocationList} from "./Hooks/useLocationList";

const ServiceWrapper = WithServiceState(IdNameTable);

const LocationList = () => {
    const {locationListStore, locationStore} = useLocationList();
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    {...locationListStore}
                    data={{
                        values: locationListStore.data || [],
                        reduxStore: locationStore,
                        editItemRoute: routeNames.auditLocationEdit.path,
                        newItemRoute: routeNames.auditLocationAdd.path,
                        itemName: "Location",
                        getAll: () => getAuditLocations(),
                        deleteById: (id: number) => deleteAuditLocationById(id)
                    }}
                />
            </div>
        </React.Fragment>
    );
};

export default LocationList;
