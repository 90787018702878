import {Enum} from "../../../utils/enumUtils";
import {StaffAccessLevel, UserData} from "../../../api/staff";
import {
    CalendarTypeEnum,
    EventCalendarEntry,
    FrontlineCalendarEntry,
    StaffLink,
    Venue
} from "../../../api/grs";
import moment from "moment";
import {formatUnixToHHmmddd} from "../../../utils/momentUtils";
import {DrugPackCategory} from "../../../api/mm";

/** Props used for the staff dropdown */

export interface EntryAssignedStaffDropdownProps {
    staffId: string;
    entry: EventCalendarEntry | FrontlineCalendarEntry;
    searchable: boolean;
    accessLevelFilter?: StaffAccessLevel;
    changeOption: (staffMember?: StaffLink) => void;
    clearable: boolean;
}

export interface StaffDropdownProps {
    username: string | undefined;
    searchable: boolean;
    accessLevelFilter?: StaffAccessLevel;
    changeOption: (staffMember?: StaffLink) => void;
    clearable: boolean;
    appendToBody?: boolean;
}

export interface LocationDropdownProps {
    clearable: boolean;
    changeOption: (id: number | string) => void;
    id: number | string;
    disabled: boolean;
    appendToBody?: boolean;
}

export interface CalendarEntryDropdownProps extends BasicDropdownProps {
    startDate: number;
    changeOption: (options: NominalActionLocationDetails | undefined) => void;
    searchable: boolean;
}

//Sorts the raw list of entries into a usable format for the dropdown component
export function sortCalendarEntriesToDropdownProps(
    entries: EventCalendarEntry[] | FrontlineCalendarEntry[],
    venues: Venue[]
): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const entry of entries) {
        if (!entry.description) continue; //Not possible due to validation in GRS not allowing this.

        const label =
            entry.calendarType === CalendarTypeEnum.Frontline
                ? entry.description
                : getVenueNameForEntry(entry, venues);

        ddProps.push({
            value: entry.id,
            label
        });
    }

    return ddProps;
}

//Checks to see if the entry is a front line event. If so, return the entry name, otherwise, get the venue name
export function getVenueNameForEntry(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    venues: Venue[]
) {
    if (!entry.description) return "";
    const index = venues.findIndex((el: Venue) => el.id === entry.venueId);

    //If the index is -1, return the entry description as a fallback
    if (index < 0) {
        return entry.description;
    }

    return venues[index].name;
}

/** Props used for the venue dropdown */
export interface VenueDropdownProps {
    calendarId?: number;
    initialVenue: Venue;
    changeOption: (venue: Venue) => void;
    clearable: boolean;
}

export interface NominalActionLocationDetails {
    venue: Venue;
    entry: EventCalendarEntry | FrontlineCalendarEntry;
}

/** Props used for the generic type dropdown */
export interface GenericTypeDropDownProps<E extends Enum<E>> extends ExtendedDropdownProps {
    enumOptions: E;
    splitByCapitalLetter: boolean;
    appendToBody?: boolean;
}

export interface DrugPackCategoryDropdownProps {
    clearable: boolean;
    searchable: boolean;
    initialId: number;
    onChange: (category: DrugPackCategory | undefined) => void;
    disabled: boolean;
}

/** Props used for the extended props for dropdown props */
export interface ExtendedDropdownProps extends DropDownProps {
    clearable: boolean;
    searchable: boolean;
}

/** Interface for Time Dropdown. Adds clearable field */
export interface TimeDropdownProps extends LimitedDropdownProps {
    clearable: boolean;
    entry: EventCalendarEntry | FrontlineCalendarEntry;
    optionsPerHour: number;
    daysToGenerate: number;
    isSearchable: boolean;
}

/** Props used for the extended props for basic dropdown props */
export interface DropDownProps extends BasicDropdownProps {
    changeOption: (id: number | string) => void;
}

/** limited access (id = undefined) and basic needed for dropdown props */
export interface LimitedDropdownProps {
    id?: number | string;
    disabled: boolean;
    changeOption: (id?: number | string) => void;
}

/** bare minimum needed for dropdown props */
export interface BasicDropdownProps {
    id: number | string | undefined;
    disabled: boolean;
}

/** Dropdown component takes these as arguments for its object. */
export interface DDProps {
    value: number | string;
    label: string;
}

/** Sorts the list of venues into dropdown props to be used by staff dropdown */
export function sortVenuesToDropdownProps(venues: Venue[]): DDProps[] {
    const ddProps: DDProps[] = [];
    for (const venue of venues) {
        ddProps.push({
            label: venue.name,
            value: venue.id
        });
    }

    return ddProps;
}

/** Sorts the list of assigned staff members in an entry into dropdown props to be used by staff dropdown */
export function sortAssignedStaffInEntryToDropdownProps(
    entry: EventCalendarEntry | FrontlineCalendarEntry
): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const section of entry.requiredStaff.sections) {
        for (const group of section.groupings) {
            for (const assignment of group.assignments) {
                const staff = assignment.staffMember;

                if (staff && staff.staffId.length > 0 && staff.staffName.length > 0) {
                    ddProps.push({
                        label: staff.staffName,
                        value: staff.staffId
                    });
                }
            }
        }
    }

    return ddProps;
}

export function sortUserDataToDropdownProps(users: UserData[]): DDProps[] {
    const ddProps: DDProps[] = [];

    for (const user of users) {
        ddProps.push({
            label: `${user.firstName} ${user.lastName}`,
            value: user.username
        });
    }

    return ddProps;
}

/** Get the selected item of the dropdown props from the id */
export function getSelectedDropdownOptionByValue(
    id: string | number | undefined,
    options: DDProps[]
): DDProps | undefined {
    if (options.length === 0) {
        return;
    }

    const index = options.findIndex((el: DDProps) => id === el.value);

    if (index < 0) {
        return;
    }

    return options[index];
}

/** Get the selected item of the dropdown props from the id */
export function getSelectedDropdownOption(id: string | number | undefined, options: DDProps[]) {
    const index = options.findIndex((el: DDProps) => id === el.value);

    if (index < 0) {
        return;
    }

    return options[index];
}

/** Generates time options for the entry */
export function generateDailyTimeOptions(
    entry: EventCalendarEntry | FrontlineCalendarEntry,
    optionsPerHour: number,
    numberOfDaysInHours = 24
): DDProps[] {
    const startDate = moment.unix(entry.startDate).startOf("day").unix();
    const options = generateTimeOptions(optionsPerHour, numberOfDaysInHours).map(
        (time: number) => startDate + time
    );
    const ddProps: DDProps[] = [];
    for (const timeOption of options) {
        ddProps.push({
            value: timeOption,
            label: formatUnixToHHmmddd(+timeOption)
        });
    }

    return ddProps;
}

/** Math functions to get epoch time in a readable format for the UI */
function generateTimeOptions(optionsPerHour: number, numberOfDaysInHours: number): number[] {
    const timeOptions: number[] = [];

    //3600 = 1 Hour
    const timeSegmentsPerHour = 3600 / optionsPerHour;
    let timeOffset = 0;

    //24 = Number of hours in a day.
    for (let i = 0; i < numberOfDaysInHours * optionsPerHour; ++i) {
        timeOptions.push(timeOffset);
        //Increment after it has been pushed to the list to get the starting hour of the day
        timeOffset += timeSegmentsPerHour;
    }
    return timeOptions;
}

export function getSelectedDropdownOptionWithUndefined(id: string | number, options: DDProps[]) {
    const index = options.findIndex((el: DDProps) => id === el.value);

    if (index < 0) {
        return;
    }

    return options[index];
}

export interface BaseDropdownProps<T> {
    searchable?: boolean;
    clearable?: boolean;
    disabled?: boolean;
    onChange: (entity: T | null | undefined) => void;
    placeholder?: string;
    noItemsMessage?: string;
    appendToBody?: boolean;
}

/** Dropdown component takes these as arguments for its object. */
export interface DropdownOption {
    value: number | string;
    label: string;
}
