import React from "react";
import {AuditForm} from "../../../../store/audit/helpers/auditHelpers";
import WeeklyAuditForm from "./WeeklyAuditForm/WeeklyAuditForm";
import ControlDrugAuditForm from "./ControlDrugAuditForm/ControlDrugAuditForm";

const AuditFormWrapper = (props: AuditForm) => {
    return (
        <React.Fragment>
            {props.weeklyAudit && <WeeklyAuditForm {...props.weeklyAudit} />}
            {props.controlDrugAudit && <ControlDrugAuditForm {...props.controlDrugAudit} />}
        </React.Fragment>
    );
};

export default AuditFormWrapper;
