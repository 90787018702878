import {useIsHistoricAudit} from "./useIsHistoricAudit";
import {useCanUserEditAudit} from "./useCanUserEditAudit";

export function useIsAuditEditable() {
    const isHistoric = useIsHistoricAudit();
    const canUserEdit = useCanUserEditAudit();

    if (isHistoric) return false;

    return canUserEdit;
}
