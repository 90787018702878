import {useState} from "react";
import {DisposeDrugsRequest, DrugBatchDisposal} from "../../../../api/mm";
import moment from "moment";
import {v4 as uuidv4} from "uuid";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {
    DrugBatchActionFormProps,
    isDrugDisposalActionValid,
    refreshPagedRequest
} from "../../Helpers/drugActionFormHelpers";
import {disposeDrugs} from "../../../../store/disposeDrugs/actions/DisposeDrugActions";
import {showSuccessToast} from "../../../../utils/toastUtils";

export function useDisposeDrugBatchForm(props: DrugBatchActionFormProps) {
    const drugPackId = useSelector((state: RootStore) => state.drugPackWithContents.data?.id || 0);
    const [disposal, setDisposal] = useState<DrugBatchDisposal>(getDefaultDisposal());
    const [disposedOnLocation, setDisposedOnLocation] = useState<boolean>(false);
    const dispatch = useDispatch();
    const drugBatchPagedStore = useSelector((state: RootStore) => state.drugBatchPaged);

    function getDefaultDisposal(): DrugBatchDisposal {
        return {
            broken: false,
            date: moment().unix(),
            denatured: false,
            id: uuidv4(),
            qty: 0,
            staffId: "",
            staffName: "",
            witnessId: undefined,
            witnessName: undefined,
            comments: undefined,
            grsLocation: undefined,
            drugPackId: drugPackId > 0 ? drugPackId : undefined
        };
    }

    function updateDisposal(entity: DrugBatchDisposal) {
        setDisposal(entity);
    }

    function toggleDisposalLocation(toggled: boolean) {
        setDisposedOnLocation((toggled = !toggled));

        //Reset because we are not using grs location
        if (!toggled) {
            updateDisposal({
                ...disposal,
                staffId: "",
                staffName: "",
                grsLocation: undefined,
                witnessName: undefined,
                witnessId: undefined
            });
        }
    }

    async function disposeDrug() {
        const request: DisposeDrugsRequest = {
            drugBatchId: props.drugBatch.id,
            disposal
        };
        const isValid = isDrugDisposalActionValid(request, props.maxQtyAvailable);
        if (!isValid) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const disposed: boolean = await dispatch(disposeDrugs(request));

        if (!disposed) {
            return;
        }

        await refreshPagedRequest({
            drugPackId,
            actionFormProps: props,
            pagedStore: drugBatchPagedStore,
            dispatch
        });
        const qtyDisposed = request.disposal.qty;
        showSuccessToast(`Disposed ${qtyDisposed} of ${props.drugBatch.name}`);
    }

    return {
        disposal,
        updateDisposal,
        disposedOnLocation,
        toggleDisposalLocation,
        disposeDrug
    };
}
