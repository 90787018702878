import React, {useEffect} from "react";
import {useDispatch} from "react-redux";
import {
    nullifyAuditStore,
    saveAuditToService,
    setAudit
} from "../../../../store/audit/actions/AuditActions";
import AuditTypeButtons from "./AuditTypeButtons";
import {DrugAuditWithDrugInfo} from "../../../../store/audit/actions/AuditActionTypes";
import AuditFormWrapper from "./AuditFormWrapper";
import {useHistory, useParams} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import AuditComments from "./AuditComments";
import {useIsHistoricAudit} from "../../../Hooks/useIsHistoricAudit";
import HistoricAuditWarning from "./HistoricAuditWarning";
import {useCanUserEditAudit} from "../../../Hooks/useCanUserEditAudit";
import {useIsAuditEditable} from "../../../Hooks/useIsAuditEditable";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import LocationDropdown from "../../../Dropdown/LocationDropdown";
import {showSuccessToast} from "../../../../utils/toastUtils";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";

const EditAuditForm = (props: DrugAuditWithDrugInfo) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const isHistoric = useIsHistoricAudit();
    const isAuditEditable = useIsAuditEditable();
    const canUserEditAudit = useCanUserEditAudit();
    const params: any = useParams();

    useEffect(() => {
        return function () {
            dispatch(nullifyAuditStore());
        };
    }, []);

    const saveAudit = async () => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(saveAuditToService(props.drugAudit));

        if (!success) return;

        showSuccessToast("Saved Audit");

        backToAuditList();
    };

    const backToAuditList = () => {
        if (isHistoric) {
            const path = `${routeNames.historicAuditList.path}/${+params.auditId}`;
            history.push({
                pathname: path,
                search: location.search
            });
            return;
        }
        history.push(routeNames.mmAuditsList.path);
    };
    return (
        <React.Fragment>
            {!canUserEditAudit && (
                <HistoricAuditWarning
                    warningText={
                        "You are viewing a read only version of this audit. To make changes, contact a system administrator"
                    }
                />
            )}
            {isHistoric && (
                <React.Fragment>
                    <HistoricAuditWarning
                        warningText={
                            "You are viewing a historic version of this audit. To make changes, find the latest version and change that."
                        }
                    />
                </React.Fragment>
            )}
            <FormHeader headerName={"Audit"} />
            {props.drugAudit.id > 0 && (
                <React.Fragment>
                    <FormRow rowName={"Original Author"}>
                        <p className="mb-0">{props.drugAudit.originalAuthor.staffName}</p>
                        <p className="mb-0">{formatUnixToDDMMYYYY(props.drugAudit.dateCreated)}</p>
                    </FormRow>
                    <FormRow rowName={"Modified Author"}>
                        <p className="mb-0">{props.drugAudit.modifiedAuthor.staffName}</p>
                        <p className="mb-0">{formatUnixToDDMMYYYY(props.drugAudit.dateModified)}</p>
                    </FormRow>
                </React.Fragment>
            )}
            <FormRow rowName={"Reporting Manager"}>
                <p className="mb-0">{props.drugAudit.reportingManager.staffName}</p>
            </FormRow>
            <FormRow rowName={"Location"} columnDetailClassName={"pl-0 pr-0"}>
                <LocationDropdown
                    clearable={true}
                    onChange={(value) => {
                        if (!value) {
                            dispatch(
                                setAudit({
                                    ...props.drugAudit,
                                    locationId: 0,
                                    locationName: ""
                                })
                            );
                            return;
                        }

                        dispatch(
                            setAudit({
                                ...props.drugAudit,
                                locationId: value.id,
                                locationName: value.name
                            })
                        );
                    }}
                    locationId={props.drugAudit.locationId}
                    disabled={!isAuditEditable || props.drugAudit.id > 0}
                />
            </FormRow>
            <AuditTypeButtons {...props.drugAudit} />
            <AuditFormWrapper {...JSON.parse(props.drugAudit.payload)} />
            <AuditComments {...props.drugAudit} />
            <FormActionContainer>
                <React.Fragment>
                    {isAuditEditable && (
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Save"}
                            onClick={saveAudit}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                    )}
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={backToAuditList}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </React.Fragment>
            </FormActionContainer>
        </React.Fragment>
    );
};

export default EditAuditForm;
