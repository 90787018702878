import {Location} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const LOCATION_LIST_STORE = createStoreState("location_list");

interface LocationListLoading extends StoreServiceData<Location[]> {
    type: typeof LOCATION_LIST_STORE.LOADING;
}
interface LocationListError extends StoreServiceData<Location[]> {
    type: typeof LOCATION_LIST_STORE.LOADING;
}
interface LocationListSuccess extends StoreServiceData<Location[]> {
    type: typeof LOCATION_LIST_STORE.LOADING;
}

export type LocationListDispatchTypes =
    | LocationListLoading
    | LocationListError
    | LocationListSuccess;
