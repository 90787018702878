import React from "react";
import {ActionType, DrugBatchAudit} from "../Helpers/stockManagementAuditHelpers";
import FormHeader from "../../../Form/FormHeader";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../Table/NoItemsMessage";

const StockManagementAuditStats = ({
    drugActions,
    maxQty,
    remainingStockQty
}: StockManagementAuditStatsProps) => {
    return (
        <React.Fragment>
            <FormHeader headerName={"Drug Batch Audit Stats"} />
            <PulseTable
                items={toTableRow({drugActions, maxQty, remainingStockQty})}
                headers={{
                    drugBatchInformation: "Drug Batch Information",
                    quantity: "Quantity"
                }}
                noItemsSection={
                    <NoItemsMessage
                        message={"There are no stats to generate for this drug batch"}
                    />
                }
            />
        </React.Fragment>
    );
};

export default StockManagementAuditStats;

interface StockManagementAuditStatsProps {
    drugActions: DrugBatchAudit[];
    maxQty: number;
    remainingStockQty: number;
}

interface TableRow {
    drugBatchInformation: string;
    quantity: string;
}

function toTableRow({
    drugActions,
    maxQty,
    remainingStockQty
}: StockManagementAuditStatsProps): TableRow[] {
    const administrationCount = getQtyOfActionFromDrugAudit(drugActions, ActionType.Administration);
    const disposalCount = getQtyOfActionFromDrugAudit(drugActions, ActionType.Disposal);

    //Get all the unique drug pack ids.
    const uniqueDrugPackIds = [
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...new Set(drugActions.map((el: DrugBatchAudit) => el.drugPackId))
    ];

    return [
        ...getRemainingDrugCountInDrugPack(uniqueDrugPackIds, drugActions),
        {
            drugBatchInformation: "Amount Administered",
            quantity: administrationCount.toString()
        },
        {
            drugBatchInformation: "Amount Disposed",
            quantity: disposalCount.toString()
        },
        {
            drugBatchInformation: "Amount Available In Stock",
            quantity: `${remainingStockQty} / ${maxQty}`
        }
    ];
}

function getRemainingDrugCountInDrugPack(ids: number[], audits: DrugBatchAudit[]): TableRow[] {
    const rows: TableRow[] = [];
    for (const id of ids) {
        const actionsForDrugPack = audits.filter((el: DrugBatchAudit) => el.drugPackId === id);

        let qty = 0;

        for (const actions of actionsForDrugPack) {
            switch (actions.actionType) {
                case ActionType.MoveToStock:
                case ActionType.Disposal:
                case ActionType.Administration:
                case ActionType.MoveToAnotherDrugPack:
                    qty -= actions.qty;
                    break;
                case ActionType.MoveToDrugPack:
                    qty += actions.qty;
            }
        }
        //This means it was actioned in Stock management (Disposal, Administration)
        if (id === 0) continue;
        const name = actionsForDrugPack[0].drugPackName.split("->")[0];
        rows.push({
            drugBatchInformation: `Amount in ${name} Drug Pack`,
            quantity: qty.toString()
        });
    }

    return rows;
}

function getQtyOfActionFromDrugAudit(audit: DrugBatchAudit[], actionType: ActionType): number {
    const auditAction = audit.filter((el: DrugBatchAudit) => el.actionType === actionType);

    return auditAction.reduce((a, b) => a + b.qty, 0);
}
