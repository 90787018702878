import React from "react";
import {ComponentChildrenProps} from "../../../utils/componentUtils";

const PrintableTH = (props: PrintableTH) => {
    return (
        <th
            style={{
                minWidth: props.minWidth || `0`,
                maxWidth: props.maxWidth,
                fontFamily: `"Roboto", "Helvetica", "Arial", sans-serif`,
                color: props.color ? colorMap.get(props.color) : "#eeeeee",
                fontSize: props.fontSize ? props.fontSize : "1em",
                padding: props.padding
            }}
            align={props.alignment}
        >
            {props.children}
        </th>
    );
};

export default PrintableTH;

interface PrintableTH extends ComponentChildrenProps {
    alignment: "left" | "center" | "right" | "justify" | "char" | undefined;
    padding: string;
    minWidth?: string;
    maxWidth: string;
    fontSize?: string;
    color?: FontColor;
}

type FontColor = "blue" | "white";

const colorMap = new Map<string, string>([
    ["white", "#eeeeee"],
    ["blue", "#1a2a35"]
]);
