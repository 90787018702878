import {DRUG_PACK_LIST_STORE} from "../actions/DrugPackListActionTypes";
import {DrugPack} from "../../../api/mm";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugPack[]>([]);

const drugPackListReducer = (
    state: StoreServiceData<DrugPack[]> = defaultState,
    action: ServiceActionTypes<DrugPack[]>
): StoreServiceData<DrugPack[]> =>
    createReducer(state, action, DRUG_PACK_LIST_STORE, () => showErrorToast(action.error));

export default drugPackListReducer;
