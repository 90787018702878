import {GeneralReportResponse} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const GENERAL_REPORT_STORE_STATE = createStoreState("general_report");
interface GeneralReportLoading extends StoreServiceData<GeneralReportResponse> {
    type: typeof GENERAL_REPORT_STORE_STATE.LOADING;
}
interface GeneralReportError extends StoreServiceData<GeneralReportResponse> {
    type: typeof GENERAL_REPORT_STORE_STATE.ERROR;
}
interface GeneralReportSuccess extends StoreServiceData<GeneralReportResponse> {
    type: typeof GENERAL_REPORT_STORE_STATE.SUCCESS;
}

export type GeneralReportDispatchTypes =
    | GeneralReportLoading
    | GeneralReportError
    | GeneralReportSuccess;
