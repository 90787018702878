import React from "react";
import {ControlDrugAuditBaseQuestions} from "../../../../../store/audit/helpers/controlDrugAuditHelpers";
import QuestionAndAnswerAndComments from "../Question/QuestionAndAnswerAndComments";
import {useDispatch} from "react-redux";
import {setControlDrugAuditBaseQuestions} from "../../../../../store/audit/actions/AuditActions";

const ControlDrugAuditBaseQuestionsForm = (props: ControlDrugAuditBaseQuestions) => {
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <QuestionAndAnswerAndComments
                {...props.keyToSafeLocked}
                answerEnum={YesNoAnswer}
                onChange={(answer, comments) => {
                    dispatch(
                        setControlDrugAuditBaseQuestions({
                            ...props,
                            keyToSafeLocked: {
                                ...props.keyToSafeLocked,
                                answer,
                                comments
                            }
                        })
                    );
                }}
            />
            <QuestionAndAnswerAndComments
                {...props.safeLocked}
                answerEnum={YesNoAnswer}
                onChange={(answer, comments) => {
                    dispatch(
                        setControlDrugAuditBaseQuestions({
                            ...props,
                            safeLocked: {
                                ...props.safeLocked,
                                answer,
                                comments
                            }
                        })
                    );
                }}
            />
            <QuestionAndAnswerAndComments
                {...props.controlDrugBookWithSafe}
                answerEnum={YesNoAnswer}
                onChange={(answer, comments) => {
                    dispatch(
                        setControlDrugAuditBaseQuestions({
                            ...props,
                            controlDrugBookWithSafe: {
                                ...props.controlDrugBookWithSafe,
                                answer,
                                comments
                            }
                        })
                    );
                }}
            />
            <QuestionAndAnswerAndComments
                {...props.stockBalanceCorrect}
                answerEnum={YesNoAnswer}
                onChange={(answer, comments) => {
                    dispatch(
                        setControlDrugAuditBaseQuestions({
                            ...props,
                            stockBalanceCorrect: {
                                ...props.stockBalanceCorrect,
                                answer,
                                comments
                            }
                        })
                    );
                }}
            />
            <QuestionAndAnswerAndComments
                {...props.controlDrugBooksAccurate}
                answerEnum={YesNoAnswer}
                onChange={(answer, comments) => {
                    dispatch(
                        setControlDrugAuditBaseQuestions({
                            ...props,
                            controlDrugBooksAccurate: {
                                ...props.controlDrugBooksAccurate,
                                answer,
                                comments
                            }
                        })
                    );
                }}
            />
            <QuestionAndAnswerAndComments
                {...props.controlBooksLegible}
                answerEnum={YesNoAnswer}
                onChange={(answer, comments) => {
                    dispatch(
                        setControlDrugAuditBaseQuestions({
                            ...props,
                            controlBooksLegible: {
                                ...props.controlBooksLegible,
                                answer,
                                comments
                            }
                        })
                    );
                }}
            />
            <QuestionAndAnswerAndComments
                {...props.correctSignatures}
                answerEnum={YesNoAnswer}
                onChange={(answer, comments) => {
                    dispatch(
                        setControlDrugAuditBaseQuestions({
                            ...props,
                            correctSignatures: {
                                ...props.correctSignatures,
                                answer,
                                comments
                            }
                        })
                    );
                }}
            />
            <QuestionAndAnswerAndComments
                {...props.allLogsWitnessed}
                answerEnum={YesNoAnswer}
                onChange={(answer, comments) => {
                    dispatch(
                        setControlDrugAuditBaseQuestions({
                            ...props,
                            allLogsWitnessed: {
                                ...props.allLogsWitnessed,
                                answer,
                                comments
                            }
                        })
                    );
                }}
            />
            <QuestionAndAnswerAndComments
                {...props.cctvInPlaceAndFunctioning}
                answerEnum={YesNoAnswer}
                onChange={(answer, comments) => {
                    dispatch(
                        setControlDrugAuditBaseQuestions({
                            ...props,
                            cctvInPlaceAndFunctioning: {
                                ...props.cctvInPlaceAndFunctioning,
                                answer,
                                comments
                            }
                        })
                    );
                }}
            />
        </React.Fragment>
    );
};

export default ControlDrugAuditBaseQuestionsForm;

// eslint-disable-next-line no-shadow
enum YesNoAnswer {
    Yes = "Yes",
    No = "No"
}
