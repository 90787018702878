import {DrugAuditPagedResponse} from "../../../api/mm";
import {AUDIT_LIST_PAGED_STORE} from "../actions/AuditListPagedActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugAuditPagedResponse>(
    createBlankDrugAuditPagedResponse()
);

const auditListPagedReducer = (
    state: StoreServiceData<DrugAuditPagedResponse> = defaultState,
    action: ServiceActionTypes<DrugAuditPagedResponse>
) => createReducer(state, action, AUDIT_LIST_PAGED_STORE, () => showErrorToast(action.error));

export default auditListPagedReducer;

export function createBlankDrugAuditPagedResponse(): DrugAuditPagedResponse {
    return {
        results: [],
        totalResults: 0
    };
}
