import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {
    createNewDrugPackWithContents,
    nullifyDrugPacksWithContentsStore
} from "../../../store/drugPackWithContents/actions/DrugPackWithContentsActions";
import {RootStore} from "../../../store/Store";
import {WithServiceState} from "store-fetch-wrappers";
import EditDrugPackWithContentsForm from "./Components/EditDrugPackWithContentsForm";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {useQuery} from "../../Hooks/useQuery";

const ServiceWrapper = WithServiceState(EditDrugPackWithContentsForm);

const AddDrugPackWithContents = () => {
    const dispatch = useDispatch();
    const drugPackWithContentsStore = useSelector((state: RootStore) => state.drugPackWithContents);
    const {searchQueries} = usePageUrl();
    const query = useQuery();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: `${routeNames.medicineManagement.path}${searchQueries}`
            },
            {
                name: routeNames.drugPackWithContentsAdd.name,
                path: routeNames.drugPackWithContentsAdd.path
            }
        ];

        dispatch(setNavigationItems(nav));

        const locationId = query.get("locationId");
        const categoryId = query.get("categoryId");

        dispatch(
            createNewDrugPackWithContents(
                categoryId ? +categoryId : 0,
                locationId ? +locationId : undefined
            )
        );

        return async function () {
            dispatch(nullifyDrugPacksWithContentsStore());
        };
    }, []);

    return (
        <div className="mt-4 page-container">
            <ServiceWrapper
                showLoadingText={true}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                placeholderBlockCount={1}
                {...drugPackWithContentsStore}
            />
        </div>
    );
};

export default AddDrugPackWithContents;
