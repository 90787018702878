import React from "react";
import {GrsLocation} from "../../../api/mm";
import FormRow from "../../Form/FormRow";
import CalendarEntryDropdown from "../../Dropdown/CalendarEntryDropdown";
import EntryAssignedStaffDropdown from "../../Dropdown/EntryAssignedStaffDropdown";
import {useStaffWitnessSection} from "./Hooks/useStaffWitnessSection";

function StaffWitnessWithLocationSection<T extends StaffWitnessEntrySectionProps>({
    action,
    updateRequest,
    request
}: Props<T>) {
    const {getMainStaffHeader, getGrsLocation} = useStaffWitnessSection();
    return (
        <React.Fragment>
            <FormRow rowName={"Location"} columnDetailClassName={"pl-0 pr-0"}>
                <CalendarEntryDropdown
                    entryId={request.grsLocation?.entryId || 0}
                    disabled={false}
                    searchable={false}
                    startDate={request.date}
                    changeOption={(item) => {
                        const newLocation = item ? getGrsLocation(item) : undefined;
                        updateRequest({
                            ...request,
                            grsLocation: newLocation
                        });
                    }}
                />
            </FormRow>
            {request.grsLocation && (
                <React.Fragment>
                    <FormRow
                        rowName={getMainStaffHeader(action)}
                        columnDetailClassName={"pl-0 pr-0"}
                    >
                        <EntryAssignedStaffDropdown
                            staffId={request.staffId || ""}
                            searchable={true}
                            entryId={request.grsLocation.entryId}
                            clearable={true}
                            changeOption={(staffMember) => {
                                updateRequest({
                                    ...request,
                                    staffId: staffMember?.staffId,
                                    staffName: staffMember?.staffName
                                });
                            }}
                        />
                    </FormRow>
                    <FormRow rowName={"Witnessed by?"} columnDetailClassName={"pl-0 pr-0"}>
                        <EntryAssignedStaffDropdown
                            staffId={request.witnessId || ""}
                            searchable={true}
                            entryId={request.grsLocation.entryId}
                            clearable={true}
                            changeOption={(witness) => {
                                updateRequest({
                                    ...request,
                                    witnessId: witness?.staffId,
                                    witnessName: witness?.staffName
                                });
                            }}
                        />
                    </FormRow>
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
export default StaffWitnessWithLocationSection;

interface Props<T> {
    request: T;
    clearable?: boolean;
    updateRequest: (updated: T) => void;
    action: NominalActionType;
}

// eslint-disable-next-line no-shadow
export enum NominalActionType {
    Administration = "Administration",
    Disposal = "Disposal"
}

interface StaffWitnessEntrySectionProps {
    grsLocation?: GrsLocation | undefined;
    staffId?: string | undefined;
    staffName?: string | undefined;
    witnessId?: string | undefined;
    witnessName?: string | undefined;
    date: number;
    cadNumber?: string | undefined;
}
