import React from "react";
import Tooltip from "../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../Icon/Icon";
import {DrugBatch} from "../../../../../api/mm";
import {Modal, useModal} from "pulse-modal";
import DisposeDrugBatchForm from "../../../../DrugBatchActionForms/DisposeDrugBatchForm/DisposeDrugBatchForm";

const DisposeDrugAction = (props: DrugActionProps) => {
    const {isShown, toggle} = useModal();

    return (
        <React.Fragment>
            <Tooltip
                wrapperClassName={"ml-2"}
                tooltipText={`Dispose ${props.batchInfo.name}`}
                place={"left"}
                theme={"dark"}
                size={"lg"}
            >
                <Icon
                    rootElement={"Span"}
                    icon={IconType.Disposal}
                    size={"Large"}
                    className={"cursor-pointer"}
                    onClick={toggle}
                />
            </Tooltip>
            <Modal
                isShown={isShown}
                onClose={toggle}
                modalSize={"md"}
                title={"Dispose Drug"}
                bodyChildren={
                    <React.Fragment>
                        {props.batchInfo && (
                            <DisposeDrugBatchForm
                                drugBatch={props.batchInfo}
                                maxQtyAvailable={props.maxQty ? props.maxQty : props.batchInfo.qty} //If the optional value isn't null, we use that instead.
                                onClose={toggle}
                            />
                        )}
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default DisposeDrugAction;

export interface DrugActionProps {
    nominalMovementId: string;
    batchInfo: DrugBatch;
    maxQty?: number;
}
