import {Venue} from "../../../api/grs";
import {VENUE_LIST_STORE} from "../actions/VenueListActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<Venue[]>([]);

const venueListReducer = (
    state: StoreServiceData<Venue[]> = defaultState,
    action: ServiceActionTypes<Venue[]>
): StoreServiceData<Venue[]> =>
    createReducer(state, action, VENUE_LIST_STORE, () => showErrorToast(action.error));

export default venueListReducer;
