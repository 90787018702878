import React, {useEffect} from "react";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getNavigationItemsForUser} from "../AuditList/Helpers/auditListHelpers";
import {useDispatch, useSelector} from "react-redux";
import {useUserData} from "../../Hooks/useUserData";
import {createNewAudit} from "../../../store/audit/actions/AuditActions";
import {RootStore} from "../../../store/Store";
import EditAuditForm from "./Components/EditAuditForm";
import {WithServiceState} from "store-fetch-wrappers";
import useMcConfig from "../../Hooks/useMcConfig";

const ServiceWrapper = WithServiceState(EditAuditForm);

const NewAudit = () => {
    const dispatch = useDispatch();
    const user = useUserData();
    const auditStore = useSelector((state: RootStore) => state.audit);
    const {config} = useMcConfig();

    useEffect(() => {
        if (user.username.length === 0) return;
        dispatch(setNavigationItems(getNavigationItemsForUser(user, config)));

        dispatch(createNewAudit(user));
    }, [user]);
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    {...auditStore}
                />
            </div>
        </React.Fragment>
    );
};

export default NewAudit;
