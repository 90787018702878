import React from "react";
import {
    Answer,
    QuestionWithAnswerAndComments
} from "../../../../../store/audit/helpers/auditHelpers";
import {useIsAuditEditable} from "../../../../Hooks/useIsAuditEditable";
import FormRow from "../../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import PulseEnumDropdown from "../../../../Dropdown/PulseEnumDropdown";

const QuestionAndAnswerAndComments = (props: QuestionWithAnswerAndCommentsWithCallback) => {
    const isAuditEditable = useIsAuditEditable();
    /** Gets the value of the Answer enum from a string  */
    const getAnswerFromString = (value: string): Answer => {
        return Answer[value as keyof typeof Answer];
    };
    return (
        <React.Fragment>
            <FormRow rowName={props.question} columnDetailClassName={"pl-0 pr-0"}>
                <PulseEnumDropdown
                    enumOptions={props.answerEnum}
                    onChange={(option) => {
                        if (!option) return;
                        props.onChange(
                            getAnswerFromString(option.value.toString()),
                            props.comments
                        );
                    }}
                    value={props.answer}
                    disabled={!isAuditEditable}
                    searchable={false}
                    clearable={false}
                />
            </FormRow>
            <FormRow rowName={"Details"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    onChange={(event) => {
                        const {value} = event.target;
                        props.onChange(props.answer, value);
                    }}
                    value={props.comments}
                    element={"textarea"}
                    disabled={!isAuditEditable}
                    className={"input-fields text-area-inputs"}
                    placeholder="Enter details here..."
                />
            </FormRow>
        </React.Fragment>
    );
};

export default QuestionAndAnswerAndComments;

/** Interface containing new answer in a callback*/
interface QuestionWithAnswerAndCommentsWithCallback extends QuestionWithAnswerAndComments {
    onChange: (newAnswer: Answer, comments: string | undefined) => void;
    answerEnum: any;
}
