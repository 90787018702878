import React from "react";
import {WeeklyAudit} from "../../../../../store/audit/helpers/weeklyDrugBagAuditHelpers";
import DrugPackCategoryInfoList from "./DrugPackCategoryInfoList";
import FormHeader from "../../../../Form/FormHeader";

const WeeklyAuditForm = (props: WeeklyAudit) => {
    return (
        <React.Fragment>
            <FormHeader headerName={"Weekly Drug Pack Audit"} />
            {props.drugPackAudit.drugsPacksWithCategories.map((item, index) => {
                return <DrugPackCategoryInfoList {...item} key={index} />;
            })}
        </React.Fragment>
    );
};

export default WeeklyAuditForm;
