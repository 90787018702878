import {Dispatch} from "redux";
import {GENERAL_REPORT_STORE_STATE, GeneralReportDispatchTypes} from "./GeneralReportActionTypes";
import store from "../../Store";
import {GeneralReportRequest} from "../../../api/mm";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyGeneralReportStore = () => {
    return async (dispatch: Dispatch<GeneralReportDispatchTypes>) => {
        dispatch({
            type: GENERAL_REPORT_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const fetchGeneralReports = (request: GeneralReportRequest) => {
    return async (dispatch: Dispatch<GeneralReportDispatchTypes>) => {
        try {
            const success = await postDataToServiceWithRedux(
                GENERAL_REPORT_STORE_STATE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getGeneralReport(request),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().generalReports.data;

                if (!data) return null;

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: GENERAL_REPORT_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
