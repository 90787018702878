import {StaffLink} from "../../../api/grs";
import {
    DrugAdministrationMethod,
    DrugBatchAdministrationChangeRecord,
    GrsLocation
} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const STATUS_REPORT_STORE_STATE = createStoreState("status_store");

interface StatusReportLoading extends StoreServiceData<StatusReport[]> {
    type: typeof STATUS_REPORT_STORE_STATE.LOADING;
}
interface StatusReportError extends StoreServiceData<StatusReport[]> {
    type: typeof STATUS_REPORT_STORE_STATE.ERROR;
}
interface StatusReportSuccess extends StoreServiceData<StatusReport[]> {
    type: typeof STATUS_REPORT_STORE_STATE.SUCCESS;
}

export type StatusReportDispatchTypes =
    | StatusReportLoading
    | StatusReportError
    | StatusReportSuccess;

export interface StatusReport {
    drugBatchName: string;
    drugBatchId: number;
    creationDate: number;
    expiryDate: number;
    batchNumber: string;
    action: DrugBatchAction;
    qty: number;
    actionedBy?: StaffLink;
    witnessedBy?: StaffLink;
    broken?: boolean;
    denatured?: boolean;
    wastage?: string;
    administrationMethod?: DrugAdministrationMethod;
    dosage?: string;
    qa?: boolean;
    drugPackName?: string;
    drugPackId?: number;
    grsLocation?: GrsLocation;
    comments?: string;
    history?: DrugBatchAdministrationChangeRecord[];
}

// eslint-disable-next-line no-shadow
export enum DrugBatchAction {
    AddedToDrugPack = "Added To Drug Pack",
    RemovedFromDrugPack = "Removed From Drug Pack",
    MovedBetweenDrugPacks = "Moved between Drug Packs",
    Administered = "Administered",
    Disposed = "Disposed",
    Stock = "Stock"
}
