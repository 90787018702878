import {
    DrugBatchActionFormProps,
    isDrugAdministrationStage1ActionValid,
    refreshPagedRequest
} from "../../Helpers/drugActionFormHelpers";
import {useEffect, useState} from "react";
import {AdministerDrugsRequest, DrugAdministrationMethod} from "../../../../api/mm";
import {v4 as uuidv4} from "uuid";
import moment from "moment";
import {administerDrugs} from "../../../../store/administerDrugs/actions/AdministerDrugsActions";
import {showSuccessToast} from "../../../../utils/toastUtils";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";

export function useAdministrationStage1(props: DrugBatchActionFormProps) {
    const drugPackId = useSelector((state: RootStore) => state.drugPackWithContents.data?.id || 0);
    const [request, setRequest] = useState<AdministerDrugsRequest>(getDefaultRequest(props));
    const dispatch = useDispatch();
    const drugBatchPagedStore = useSelector((state: RootStore) => state.drugBatchPaged);

    useEffect(() => {
        setRequest(getDefaultRequest(props));
    }, [props]);

    function getDefaultRequest(entity: DrugBatchActionFormProps): AdministerDrugsRequest {
        return {
            drugBatchId: entity.drugBatch.id,
            administration: {
                id: uuidv4(),
                staffId: undefined,
                staffName: undefined,
                witnessId: undefined,
                witnessName: undefined,
                cadNumber: undefined,
                qty: 0,
                drugPackId: drugPackId > 0 ? drugPackId : undefined,
                dosage: undefined,
                wastage: undefined,
                routeAdministration: DrugAdministrationMethod.Other,
                qa: false,
                date: moment().startOf("day").unix(),
                comments: undefined,
                grsLocation: undefined
            }
        };
    }

    const actionStage1 = async (entity: AdministerDrugsRequest) => {
        const isValid = isDrugAdministrationStage1ActionValid(request);

        if (!isValid) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        const administered: boolean = await dispatch(administerDrugs(entity));

        if (!administered) return;

        await refreshPagedRequest({
            drugPackId,
            actionFormProps: props,
            pagedStore: drugBatchPagedStore,
            dispatch
        });

        showSuccessToast("Completed Administration Stage 1");
    };

    return {
        request,
        actionStage1
    };
}
