import React from "react";
import PrintableTableHead from "../../../../../Print/Components/PrintableTableHead";
import PrintableTableRow from "../../../../../Print/Components/PrintableTableRow";
import PrintableTD from "../../../../../Print/Components/PrintableTD";
import {formatUnixToDDMMYYYY} from "../../../../../../utils/momentUtils";
import PrintableP from "../../../../../Print/Components/PrintableP";
import {
    DrugBatchAction,
    StatusReport
} from "../../../../../../store/statusReport/actions/StatusReportActionTypes";
import {ReportRowPrintProps} from "../../../../../Print/helpers/printableHelpers";
import {getLocationForReport} from "../../Disposal/Components/DisposalReportTable";
import PrintableDivRow from "../../../../../Print/Components/PrintableDivRow";

const StatusReportRow = ({report, index}: ReportRowPrintProps<StatusReport>) => (
    <React.Fragment>
        <PrintableTableHead>
            <PrintableTableRow rowType={"bodyRow"} index={index}>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.drugBatchName} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.batchNumber} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP
                        headerName={formatUnixToDDMMYYYY(report.creationDate)}
                        fontSize={"0.7rem"}
                    />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP
                        headerName={formatUnixToDDMMYYYY(report.expiryDate)}
                        fontSize={"0.7rem"}
                    />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableP headerName={report.qty ? report.qty : "N/A"} fontSize={"0.7rem"} />
                </PrintableTD>
                <PrintableTD alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    {generateStatusMarkup(report)}
                </PrintableTD>
            </PrintableTableRow>
        </PrintableTableHead>
    </React.Fragment>
);

export default StatusReportRow;

function generateStatusMarkup(status: StatusReport): JSX.Element {
    switch (status.action) {
        case DrugBatchAction.Stock:
            return <PrintableP headerName={"In Stock"} isBold fontSize={"0.7rem"} />;
        case DrugBatchAction.MovedBetweenDrugPacks:
            return (
                <React.Fragment>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP
                            headerName={"Moved between Drug Packs by"}
                            fontSize={"0.7rem"}
                            isBold
                        />
                        <PrintableP
                            headerName={
                                status.actionedBy
                                    ? status.actionedBy.staffName
                                    : "Staff member not specified"
                            }
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                        <PrintableP headerName={"Drug Pack"} fontSize={"0.7rem"} isBold />
                        <PrintableP headerName={status.drugPackName || "N/A"} fontSize={"0.7rem"} />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                        <PrintableP headerName={"Comments"} fontSize={"0.7rem"} isBold />
                        <PrintableP headerName={status.comments || "N/A"} fontSize={"0.7rem"} />
                    </PrintableDivRow>
                </React.Fragment>
            );
        case DrugBatchAction.AddedToDrugPack:
            return (
                <React.Fragment>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP
                            headerName={"Added to Drug Pack by"}
                            fontSize={"0.7rem"}
                            isBold
                        />
                        <PrintableP
                            headerName={
                                status.actionedBy
                                    ? status.actionedBy.staffName
                                    : "Staff member not specified"
                            }
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                        <PrintableP headerName={"Drug Pack"} fontSize={"0.7rem"} isBold />
                        <PrintableP headerName={status.drugPackName || "N/A"} fontSize={"0.7rem"} />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                        <PrintableP headerName={"Comments"} fontSize={"0.7rem"} isBold />
                        <PrintableP headerName={status.comments || "N/A"} fontSize={"0.7rem"} />
                    </PrintableDivRow>
                </React.Fragment>
            );
        case DrugBatchAction.RemovedFromDrugPack:
            return (
                <React.Fragment>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP
                            headerName={"Removed from Drug Pack by"}
                            fontSize={"0.7rem"}
                            isBold
                        />
                        <PrintableP
                            headerName={
                                status.actionedBy
                                    ? status.actionedBy.staffName
                                    : "Staff member not specified"
                            }
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                        <PrintableP headerName={"Drug Pack"} fontSize={"0.7rem"} isBold />
                        <PrintableP headerName={status.drugPackName || "N/A"} fontSize={"0.7rem"} />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                        <PrintableP headerName={"Comments"} fontSize={"0.7rem"} isBold />
                        <PrintableP headerName={status.comments || "N/A"} fontSize={"0.7rem"} />
                    </PrintableDivRow>
                </React.Fragment>
            );
        case DrugBatchAction.Administered:
            return (
                <React.Fragment>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP headerName={"Administered by"} fontSize={"0.7rem"} isBold />
                        <PrintableP
                            headerName={
                                status.actionedBy?.staffName || "Staff member not specified"
                            }
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    {status.witnessedBy && (
                        <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                            <PrintableP headerName={"Witnessed by"} fontSize={"0.7rem"} isBold />
                            <PrintableP
                                headerName={
                                    status.witnessedBy?.staffName || "Staff member not specified"
                                }
                                fontSize={"0.7rem"}
                            />
                        </PrintableDivRow>
                    )}
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP headerName={"Administered from"} fontSize={"0.7rem"} isBold />
                        <PrintableP
                            headerName={status.drugPackName || "Drug pack not specified"}
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                        <PrintableP headerName={"Location"} fontSize={"0.7rem"} isBold />
                        <PrintableP
                            headerName={getLocationForReport(status.grsLocation)}
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP headerName={"Quality Assured"} fontSize={"0.7rem"} isBold />
                        <PrintableP headerName={status.qa ? "Yes" : "No"} fontSize={"0.7rem"} />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                        <PrintableP headerName={"Dosage"} fontSize={"0.7rem"} isBold />
                        <PrintableP
                            headerName={status.dosage || "Dosage not specified"}
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP headerName={"Wastage"} fontSize={"0.7rem"} isBold />
                        <PrintableP
                            headerName={status.wastage || "Wastage not specified"}
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP
                            headerName={"Route of Administration"}
                            fontSize={"0.7rem"}
                            isBold
                        />
                        <PrintableP
                            headerName={status.administrationMethod || "N/A"}
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP headerName={"Comments"} fontSize={"0.7rem"} isBold />
                        <PrintableP headerName={status.comments || "N/A"} fontSize={"0.7rem"} />
                    </PrintableDivRow>
                </React.Fragment>
            );
        case DrugBatchAction.Disposed:
            return (
                <React.Fragment>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP headerName={"Disposed by"} fontSize={"0.7rem"} isBold />
                        <PrintableP
                            headerName={
                                status.actionedBy?.staffName || "Staff member not specified"
                            }
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>

                    {status.witnessedBy && (
                        <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                            <PrintableP headerName={"Witnessed by"} fontSize={"0.7rem"} isBold />
                            <PrintableP
                                headerName={
                                    status.witnessedBy?.staffName || "Staff member not specified"
                                }
                                fontSize={"0.7rem"}
                            />
                        </PrintableDivRow>
                    )}
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP headerName={"Disposed from"} fontSize={"0.7rem"} isBold />
                        <PrintableP
                            headerName={status.drugPackName || "Drug pack not specified"}
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                        <PrintableP headerName={"Location"} fontSize={"0.7rem"} isBold />
                        <PrintableP
                            headerName={getLocationForReport(status.grsLocation)}
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP headerName={"Broken"} fontSize={"0.7rem"} isBold />
                        <PrintableP headerName={status.broken ? "Yes" : "No"} fontSize={"0.7rem"} />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"grey"}>
                        <PrintableP headerName={"Denatured"} fontSize={"0.7rem"} isBold />
                        <PrintableP
                            headerName={status.denatured ? "Yes" : "No"}
                            fontSize={"0.7rem"}
                        />
                    </PrintableDivRow>
                    <PrintableDivRow padding={"2px 0"} rowColour={"white"}>
                        <PrintableP headerName={"Comments"} fontSize={"0.7rem"} isBold />
                        <PrintableP headerName={status.comments || "N/A"} fontSize={"0.7rem"} />
                    </PrintableDivRow>
                </React.Fragment>
            );
    }
}
