import React from "react";
import {ControlDrugAudit} from "../../../../../store/audit/helpers/controlDrugAuditHelpers";
import ControlDrugAuditBaseQuestionsForm from "./ControlDrugAuditBaseQuestionsForm";
import ControlDrugList from "./ControlDrugList";
import FormHeader from "../../../../Form/FormHeader";

const ControlDrugAuditForm = (props: ControlDrugAudit) => {
    return (
        <React.Fragment>
            <FormHeader headerName={"Control Drug Audit"} />
            <ControlDrugAuditBaseQuestionsForm {...props.controlDrugAuditBaseQuestions} />
            <ControlDrugList {...props.controlDrugItemList} />
        </React.Fragment>
    );
};

export default ControlDrugAuditForm;
