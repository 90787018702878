import {DrugPackDrugWithBatch} from "../../../../api/mm";

/** Sorts the drug batches in a drug pack alphabetically from A-Z */
export function sortDrugBatchesInDrugPackAlphabetically(
    drugs: DrugPackDrugWithBatch[]
): DrugPackDrugWithBatch[] {
    return drugs.sort((a: DrugPackDrugWithBatch, b: DrugPackDrugWithBatch) => {
        if (!a.batchInfo || !b.batchInfo) return 0;
        if (a.batchInfo.name.toLowerCase() > b.batchInfo.name.toLowerCase()) return 1;
        if (a.batchInfo.name.toLowerCase() < b.batchInfo.name.toLowerCase()) return -1;
        return 0;
    });
}
