import React from "react";
import PrintableTableRow from "../../../../../Print/Components/PrintableTableRow";
import PrintableTD from "../../../../../Print/Components/PrintableTD";
import {formatUnixToDDMMYYYY} from "../../../../../../utils/momentUtils";
import PrintableP from "../../../../../Print/Components/PrintableP";
import {ReportRowPrintProps} from "../../../../../Print/helpers/printableHelpers";
import {AdministrationReport} from "../../../../../../store/administrationReports/actions/AdministrationReportsActionTypes";
import {getLocationForReport} from "../../Disposal/Components/DisposalReportTable";
import AdministrationReportHistoryHeader from "./AdministrationReportHistoryHeader";
import AdministrationReportHistoryRow from "./AdministrationReportHistoryRow";

const AdministrationReportRow = ({report, index}: ReportRowPrintProps<AdministrationReport>) => (
    <React.Fragment>
        <PrintableTableRow rowType={"bodyRow"} index={index}>
            <PrintableTD
                alignment={"left"}
                fontSize={"0.5rem"}
                padding={".35rem .75rem"}
                minWidth={"75px"}
                maxWidth={`200px`}
            >
                <PrintableP headerName={report.drugBatchName} isBold />
                <PrintableP headerName={`(${report.batchNumber})`} />
            </PrintableTD>
            <PrintableTD
                alignment={"left"}
                fontSize={"0.5rem"}
                padding={".35rem .75rem"}
                minWidth={"125px"}
                maxWidth={`200px`}
            >
                <PrintableP headerName={report.drugPackName} />
            </PrintableTD>
            <PrintableTD
                alignment={"left"}
                fontSize={"0.5rem"}
                padding={".35rem .75rem"}
                maxWidth={`200px`}
            >
                <PrintableP headerName={formatUnixToDDMMYYYY(report.administrationDate)} />
            </PrintableTD>
            <PrintableTD
                alignment={"left"}
                fontSize={"0.5rem"}
                padding={".35rem .75rem"}
                maxWidth={`200px`}
            >
                <PrintableP headerName={report.qty} />
            </PrintableTD>
            <PrintableTD
                alignment={"left"}
                fontSize={"0.5rem"}
                padding={".35rem .75rem"}
                minWidth={"40px"}
                maxWidth={`200px`}
            >
                <PrintableP headerName={getLocationForReport(report.location)} />
            </PrintableTD>
            <PrintableTD
                alignment={"left"}
                fontSize={"0.5rem"}
                padding={".35rem .75rem"}
                maxWidth={`200px`}
            >
                <PrintableP headerName={report.staffMember.staffName} />
            </PrintableTD>
            <PrintableTD
                alignment={"left"}
                fontSize={"0.5rem"}
                padding={".35rem .75rem"}
                maxWidth={`200px`}
            >
                <PrintableP
                    headerName={report.witness ? report.witness.staffName : "Witness not specified"}
                />
            </PrintableTD>
            <PrintableTD
                alignment={"left"}
                fontSize={"0.5rem"}
                padding={".35rem .75rem"}
                maxWidth={`100px`}
            >
                <PrintableP headerName={report.qa ? "Yes" : "No"} />
            </PrintableTD>
            <PrintableTD
                alignment={"left"}
                fontSize={"0.5rem"}
                padding={".35rem .75rem"}
                maxWidth={`200px`}
            >
                <PrintableP headerName={report.dosage || "Dosage not specified"} />
            </PrintableTD>
            <PrintableTD
                alignment={"left"}
                fontSize={"0.5rem"}
                padding={".35rem .75rem"}
                maxWidth={`100px`}
            >
                <PrintableP headerName={report.wastage || "Wastage not specified"} />
            </PrintableTD>
            <PrintableTD
                fontSize={"0.5rem"}
                alignment={"left"}
                padding={".35rem .75rem"}
                maxWidth={`200px`}
            >
                <PrintableP headerName={report.routeAdministration} />
            </PrintableTD>
        </PrintableTableRow>
        {report.history && report.history.length > 0 && (
            <React.Fragment>
                <AdministrationReportHistoryHeader />
                {report.history.map((item, key) => {
                    return <AdministrationReportHistoryRow index={key} key={key} report={item} />;
                })}
            </React.Fragment>
        )}
    </React.Fragment>
);

export default AdministrationReportRow;
