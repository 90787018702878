import {Dispatch} from "redux";
import {
    CALENDAR_ENTRY_LIST_STORE,
    CalendarEntryListDispatchTypes
} from "./CalendarEntryListActionTypes";
import {CalendarEntryListRequest} from "../../../api/grs";
import GrsApiModel from "../../apiModel/GrsApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import store from "../../Store";

export const getCalendarEntriesList = (args: CalendarEntryListRequest) => {
    return async (dispatch: Dispatch<CalendarEntryListDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                CALENDAR_ENTRY_LIST_STORE,
                dispatch,
                () => GrsApiModel.calendarApi.listCalendarEntries(args),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().calendarEntryList.data;

                return data ?? [];
            }
        } catch (e: any) {
            dispatch({
                type: CALENDAR_ENTRY_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const nullifyCalendarEntriesListStore = () => {
    return async (dispatch: Dispatch<CalendarEntryListDispatchTypes>) => {
        dispatch({
            type: CALENDAR_ENTRY_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};
