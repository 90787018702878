import {DrugPack} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DRUG_PACK_LIST_STORE = createStoreState("drug_pack_list");

interface DrugPackListLoading extends StoreServiceData<DrugPack[]> {
    type: typeof DRUG_PACK_LIST_STORE.LOADING;
}
interface DrugPackListError extends StoreServiceData<DrugPack[]> {
    type: typeof DRUG_PACK_LIST_STORE.ERROR;
}
interface DrugPackListSuccess extends StoreServiceData<DrugPack[]> {
    type: typeof DRUG_PACK_LIST_STORE.SUCCESS;
}

export type DrugPackListDispatchTypes =
    | DrugPackListLoading
    | DrugPackListError
    | DrugPackListSuccess;
