import {DrugBatch} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DRUG_BATCH_STORE = createStoreState("drug_batch");

interface DrugBatchLoading extends StoreServiceData<DrugBatch> {
    type: typeof DRUG_BATCH_STORE.LOADING;
}
interface DrugBatchError extends StoreServiceData<DrugBatch> {
    type: typeof DRUG_BATCH_STORE.ERROR;
}
interface DrugBatchSuccess extends StoreServiceData<DrugBatch> {
    type: typeof DRUG_BATCH_STORE.SUCCESS;
}

export type DrugBatchDispatchTypes = DrugBatchSuccess | DrugBatchError | DrugBatchLoading;
