import {Location} from "../../../api/mm";
import {LOCATION_LIST_STORE} from "../actions/LocationListActionsTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<Location[]>([]);

const locationListReducer = (
    state: StoreServiceData<Location[]> = defaultState,
    action: ServiceActionTypes<Location[]>
): StoreServiceData<Location[]> =>
    createReducer<Location[]>(state, action, LOCATION_LIST_STORE, () =>
        showErrorToast(action.error)
    );

export default locationListReducer;
