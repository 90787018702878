import React from "react";
import {ControlDrugItemList} from "../../../../../store/audit/helpers/controlDrugAuditHelpers";
import {useDispatch} from "react-redux";
import {
    addControlDrugRow,
    removeControlDrugRow,
    setControlDrugRow
} from "../../../../../store/audit/actions/AuditActions";
import DatePicker from "react-datepicker";
import moment from "moment";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../Table/NoItemsMessage";
import DatePickerInputButton from "../../../../Button/DatePickerInputButton";
import DeleteTableItemAction from "../../../../Table/DeleteTableItemAction";
import FormHeader from "../../../../Form/FormHeader";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import FormActionContainer from "../../../../Form/FormActionContainer";
import {DebounceInput} from "react-debounce-input";
import {useIsAuditEditable} from "../../../../Hooks/useIsAuditEditable";

const ControlDrugList = (props: ControlDrugItemList) => {
    const isEditable = useIsAuditEditable();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            <FormHeader headerName={"Control Drug List"} />
            <PulseTable
                headers={{
                    controlDrug: "Control Drug",
                    expiryDate: "Expiry Date",
                    batchNumber: "Batch Number",
                    stockBalance: "Stock Balance",
                    actions: "Actions"
                }}
                items={drugItemListToTableRow(props)}
                noItemsSection={
                    <NoItemsMessage
                        message={
                            "There are no drugs added! Click the button below to add a new control drug row."
                        }
                    />
                }
                customRenderers={{
                    // eslint-disable-next-line react/display-name
                    controlDrug: (item: TableRow) => (
                        <React.Fragment>
                            <DebounceInput
                                debounceTimeout={300}
                                value={item.controlDrug}
                                className="input-fields"
                                placeholder={"Enter control drug here..."}
                                onChange={(evt) => {
                                    dispatch(
                                        setControlDrugRow({
                                            drugRowIndex: item.actions,
                                            newItem: {
                                                ...item,
                                                controlDrug: evt.target.value
                                            }
                                        })
                                    );
                                }}
                                disabled={!isEditable}
                            />
                        </React.Fragment>
                    ),
                    // eslint-disable-next-line react/display-name
                    expiryDate: (item: TableRow) => (
                        <React.Fragment>
                            <DatePicker
                                selected={moment.unix(item.expiryDate).toDate()}
                                onChange={(date) => {
                                    if (!date) return;
                                    dispatch(
                                        setControlDrugRow({
                                            drugRowIndex: item.actions,
                                            newItem: {
                                                ...item,
                                                expiryDate: moment(date).unix()
                                            }
                                        })
                                    );
                                }}
                                dateFormat="dd/MM/yyyy"
                                portalId="root-portal"
                                customInput={<DatePickerInputButton />}
                                disabled={!isEditable}
                            />
                        </React.Fragment>
                    ),
                    // eslint-disable-next-line react/display-name
                    batchNumber: (item: TableRow) => (
                        <React.Fragment>
                            <DebounceInput
                                debounceTimeout={300}
                                value={item.batchNumber}
                                className="input-fields"
                                placeholder={"Enter batch number here..."}
                                onChange={(evt) => {
                                    dispatch(
                                        setControlDrugRow({
                                            drugRowIndex: item.actions,
                                            newItem: {
                                                ...item,
                                                batchNumber: evt.target.value
                                            }
                                        })
                                    );
                                }}
                                disabled={!isEditable}
                            />
                        </React.Fragment>
                    ),
                    // eslint-disable-next-line react/display-name
                    stockBalance: (item: TableRow) => (
                        <React.Fragment>
                            <input
                                value={item.stockBalance}
                                className="input-fields"
                                placeholder={"Enter stock balance here..."}
                                type={"number"}
                                onChange={(evt) => {
                                    dispatch(
                                        setControlDrugRow({
                                            drugRowIndex: item.actions,
                                            newItem: {
                                                ...item,
                                                stockBalance: +evt.target.value
                                            }
                                        })
                                    );
                                }}
                                disabled={!isEditable}
                            />
                        </React.Fragment>
                    ),
                    // eslint-disable-next-line react/display-name
                    actions: (item: TableRow) => (
                        <React.Fragment>
                            {isEditable && (
                                <DeleteTableItemAction
                                    loading={false}
                                    modalTitle={"Delete Control Drug"}
                                    modalMessage={`Are you sure you want to delete ${item.controlDrug}?`}
                                    onConfirm={async () => {
                                        dispatch(removeControlDrugRow(item.actions));
                                        return true;
                                    }}
                                    itemName={item.controlDrug}
                                />
                            )}
                        </React.Fragment>
                    )
                }}
            />
            {isEditable && (
                <FormActionContainer>
                    <div className="col d-flex justify-content-center mb-5">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Add Row"}
                            onClick={() => dispatch(addControlDrugRow())}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                </FormActionContainer>
            )}
        </React.Fragment>
    );
};

export default ControlDrugList;

interface TableRow {
    controlDrug: string;
    expiryDate: number;
    batchNumber: string;
    stockBalance: number;
    actions: number;
}

function drugItemListToTableRow(list: ControlDrugItemList): TableRow[] {
    return list.items.map((item, index) => {
        return {
            controlDrug: item.controlDrug,
            expiryDate: item.expiryDate,
            batchNumber: item.batchNumber,
            stockBalance: item.stockBalance,
            actions: index
        };
    });
}
