import {AUDIT_STORE, DrugAuditWithDrugInfo} from "../actions/AuditActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugAuditWithDrugInfo>(null);

const auditReducer = (
    state: StoreServiceData<DrugAuditWithDrugInfo> = defaultState,
    action: ServiceActionTypes<DrugAuditWithDrugInfo>
) => createReducer(state, action, AUDIT_STORE, () => showErrorToast(action.error));

export default auditReducer;
