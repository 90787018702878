import React from "react";
import Tooltip from "../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../Icon/Icon";
import {Modal, useModal} from "pulse-modal";
import {DrugBatch} from "../../../../../api/mm";
import DrugBatchAdministrationFormContainer from "../../../../DrugBatchActionForms/AdministerDrugBatchForm/DrugBatchAdministrationFormContainer";

const AdministerDrugAction = (props: DrugActionProps) => {
    const {isShown, toggle} = useModal();

    return (
        <React.Fragment>
            <Tooltip
                tooltipText={
                    props.nominalMovementId.length > 0
                        ? "Quality Assure Administration"
                        : `Administer ${props.batchInfo.name}`
                }
                place={"left"}
                theme={"dark"}
                size={"lg"}
            >
                <Icon
                    rootElement={"Span"}
                    icon={IconType.Syringe}
                    size={"Large"}
                    className={"cursor-pointer"}
                    onClick={toggle}
                />
            </Tooltip>
            <Modal
                isShown={isShown}
                onClose={toggle}
                modalSize={"md"}
                title={"Administer Drug"}
                bodyChildren={
                    <React.Fragment>
                        {props.batchInfo && (
                            <DrugBatchAdministrationFormContainer
                                nominalMovementId={props.nominalMovementId}
                                drugBatch={props.batchInfo}
                                maxQtyAvailable={props.maxQty ? props.maxQty : props.batchInfo.qty} //If the optional value isn't null, we use that instead.
                                onClose={toggle}
                            />
                        )}
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default AdministerDrugAction;

export interface DrugActionProps {
    nominalMovementId: string;
    batchInfo: DrugBatch;
    maxQty?: number;
}
