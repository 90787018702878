import {DrugBatch, DrugBatchMovement, DrugPack, DrugPackDrug} from "../../../../../api/mm";
import {getRemainingStock} from "../../../StockManagementList/Helpers/stockManagementListHelpers";
import moment, {Moment} from "moment";
import {calculateDrugPackDrugs} from "../../../../../utils/movementUtils";

export interface DrugExpiryItem {
    drugName: string;
    drugId: number;
    batchNumber: string;
    expiry: number;
    drugPackName: string;
    drugPackId: number;
    drugExpiryType: DrugExpiryType;
}

// eslint-disable-next-line no-shadow
export enum DrugExpiryType {
    Expired = "Expired",
    Week = "Week",
    Month = "Month"
}

/** Displays items that are expiring within drug packs in medicine management */
export function getExpiryItemsForTableStats(drugBatches: DrugBatch[], drugPacks: DrugPack[]) {
    const drugExpiryItems: DrugExpiryItem[] = [];
    for (const drug of drugBatches) {
        //Checks if there are any drugs in the stockroom, if there are, we add this as a location
        const remainingStock = getRemainingStock(drug);
        if (remainingStock > 0) {
            const expiringDrug = getWeeklyOrMonthlyBatchExpiry(drug, moment());
            if (expiringDrug.length > 0) {
                drugExpiryItems.push(...expiringDrug);
            }
        }

        //Get all movements and actions
        const movements = calculateDrugPackDrugs(drug.id, drug.movements, [], []);
        const actions = calculateDrugPackDrugs(drug.id, [], drug.administrations, drug.disposals);
        const sortedNominals: DrugPackDrug[] = [];

        //Get the unique movements to the drug packs
        const uniqueMovementDrugPackIds = drug.movements
            .map((el: DrugBatchMovement) => el.toDrugPackId)
            .filter((value: any, index: any, self: any) => self.indexOf(value) === index);

        //Iterate through drug packs
        for (const drugPackId of uniqueMovementDrugPackIds) {
            if (!drugPackId) continue;
            //Get the movement and action for drugpack in question
            const move = movements.find((el: DrugPackDrug) => el.drugPackId === drugPackId);
            const action = actions.find((el: DrugPackDrug) => el.drugPackId === drugPackId);
            if (!move) continue;
            //Push to sorted nominals. Move should exists but action may not exist
            sortedNominals.push({
                ...move,
                qty: action ? move.qty - action.qty : move.qty
            });
        }

        //cycle through all nominials.
        for (const nominals of sortedNominals) {
            //If the qty within the drug pack is 0, it means there are no drugs present in the drug pack and we want to ignore showing it.
            if (nominals.qty === 0) continue;
            const index = drugPacks.findIndex((el: DrugPack) => el.id === nominals.drugPackId);
            //Cannot find a matching drug pack for a drug batch, ignore this entry in the movements array
            if (index < 0) continue;

            const expiringDrug = getWeeklyOrMonthlyBatchExpiry(drug, moment(), drugPacks[index]);
            if (expiringDrug.length > 0) {
                drugExpiryItems.push(...expiringDrug);
            }
        }
    }

    return drugExpiryItems;
}

export function getWeeklyOrMonthlyBatchExpiry(
    drug: DrugBatch,
    startingFrom: Moment,
    drugPack?: DrugPack
): DrugExpiryItem[] {
    const oneWeekInFuture = moment.unix(startingFrom.unix()).add(1, "week");
    const oneMonthInFuture = moment.unix(startingFrom.unix()).add(1, "month");
    const items: DrugExpiryItem[] = [];

    if (startingFrom.unix() > drug.expiryDate) {
        items.push({
            drugPackName: drugPack ? drugPack.name : "Stock",
            drugPackId: drugPack ? drugPack.id : 0,
            drugId: drug.id,
            drugName: drug.name,
            batchNumber: drug.batchNumber,
            expiry: drug.expiryDate,
            drugExpiryType: DrugExpiryType.Expired
        });
    }

    if (drug.expiryDate > startingFrom.unix() && drug.expiryDate < oneWeekInFuture.unix()) {
        items.push({
            drugPackName: drugPack ? drugPack.name : "Stock",
            drugPackId: drugPack ? drugPack.id : 0,
            drugId: drug.id,
            drugName: drug.name,
            batchNumber: drug.batchNumber,
            expiry: drug.expiryDate,
            drugExpiryType: DrugExpiryType.Week
        });
    }

    if (drug.expiryDate > startingFrom.unix() && drug.expiryDate < oneMonthInFuture.unix()) {
        items.push({
            drugPackName: drugPack ? drugPack.name : "Stock",
            drugPackId: drugPack ? drugPack.id : 0,
            drugId: drug.id,
            drugName: drug.name,
            batchNumber: drug.batchNumber,
            expiry: drug.expiryDate,
            drugExpiryType: DrugExpiryType.Month
        });
    }
    return items;
}

//Scans through the drug batches and picks out any expiring in the week or the month
export function getDrugBatchExpiries(
    batches: DrugBatch[],
    startingFrom: Moment
): DrugBatchExpiries {
    const oneWeekInFuture = moment.unix(startingFrom.unix()).add(1, "week");
    const oneMonthInFuture = moment.unix(startingFrom.unix()).add(1, "month");
    const expiredDrugs: DrugBatch[] = [];
    const expiringInTheWeek: DrugBatch[] = [];
    const expiringInTheMonth: DrugBatch[] = [];

    for (const drug of batches) {
        //If the drug has expired, we want to add it and continue
        if (startingFrom.unix() > drug.expiryDate) {
            expiredDrugs.push(drug);
            continue;
        }
        //If it expires in the week, it needs to be added to the month list
        if (drug.expiryDate > startingFrom.unix() && drug.expiryDate < oneWeekInFuture.unix()) {
            expiringInTheWeek.push(drug);
            expiringInTheMonth.push(drug);
            continue;
        }

        if (drug.expiryDate > startingFrom.unix() && drug.expiryDate < oneMonthInFuture.unix()) {
            expiringInTheMonth.push(drug);
        }
    }

    return {
        expiredDrugs,
        expiringInTheWeek,
        expiringInTheMonth,
        activeBatches: batches
    };
}

export interface DrugBatchExpiries {
    expiredDrugs: DrugBatch[];
    expiringInTheWeek: DrugBatch[];
    expiringInTheMonth: DrugBatch[];
    activeBatches: DrugBatch[];
}
