import React, {useEffect, useState} from "react";
import {DrugActionFormHelpersProps, refreshPagedRequest} from "../../Helpers/drugActionFormHelpers";
import {
    DrugBatchBasic,
    DrugBatchMovement,
    DrugPackWithContents,
    MoveDrugsRequest
} from "../../../../api/mm";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {v4 as uuidv4} from "uuid";
import moment from "moment";
import {showErrorToast, showSuccessToast} from "../../../../utils/toastUtils";
import {useUserData} from "../../../Hooks/useUserData";
import {moveDrugs} from "../../../../store/moveDrugs/actions/MoveDrugsActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";

export function useMoveDrugBatchForm(props: MoveDrugBatchFormProps) {
    const drugPack = useSelector((state: RootStore) => state.drugPackWithContents.data);
    const dispatch = useDispatch();
    const moveDrugsStore = useSelector((state: RootStore) => state.moveDrugs);
    const drugBatchPagedStore = useSelector((state: RootStore) => state.drugBatchPaged);
    const {username, firstName, lastName} = useUserData();
    const [movement, setMovement] = useState<DrugBatchMovement>(getDefaultMovement());
    const [moveLocation, setMoveLocation] = useState<MoveLocation>();
    const [selectedDrugPack, setSelectedDrugPack] = useState<
        DrugPackWithContents | null | undefined
    >();

    useEffect(() => {
        if (!props.moveToStore) {
            setMoveLocation(MoveLocation.FromStock);
            return;
        }
        if (!props.locationId) setMoveLocation(MoveLocation.ToStock);
    }, [props.moveToStore, props.locationId]);

    function renderPreMoveInstructions() {
        // If we aren't moving drugs from stock to a drugpack, we don't want to render this section
        if (!props.moveToStore) return;
        return (
            <React.Fragment>
                <p>
                    Select location to move drug: (Note: A location must be set to move to other
                    drug packs).
                </p>
                <div className="d-flex flex-row">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Stock"}
                        onClick={() => {
                            setMoveLocation(MoveLocation.ToStock);
                            resetMovement();
                        }}
                        colour={
                            moveLocation === MoveLocation.ToStock
                                ? ButtonColourOptions.Yellow
                                : ButtonColourOptions.DarkBlue
                        }
                        className="w-50"
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Drug pack"}
                        onClick={() => {
                            setMoveLocation(MoveLocation.ToDrugPack);
                            resetMovement();
                        }}
                        colour={
                            moveLocation === MoveLocation.ToDrugPack
                                ? ButtonColourOptions.Yellow
                                : ButtonColourOptions.DarkBlue
                        }
                        disabled={!props.locationId}
                        className="w-50"
                        roundedCorner
                        title={"Boo"}
                    />
                </div>
            </React.Fragment>
        );
    }

    function getDefaultMovement(): DrugBatchMovement {
        if (props.moveToStore) {
            return {
                id: uuidv4(),
                staffId: "",
                staffName: "",
                date: moment().unix(),
                qty: 0,
                fromDrugPackId: drugPack?.id,
                comments: undefined
            };
        }

        return {
            id: uuidv4(),
            staffId: "",
            staffName: "",
            date: moment().unix(),
            qty: 0,
            toDrugPackId: drugPack?.id,
            comments: undefined
        };
    }

    function resetMovement() {
        setSelectedDrugPack(null);
        setMovement(getDefaultMovement());
    }

    function updateMovement(entity: DrugBatchMovement) {
        setMovement(entity);
    }

    async function moveDrug() {
        const valid = isMovementValid();

        if (!valid) return;

        const request = getSanitisedRequest();

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(moveDrugs(request));

        if (!success) return;

        await refreshPagedRequest({
            drugPackId: drugPack?.id || 0,
            actionFormProps: props,
            pagedStore: drugBatchPagedStore,
            dispatch
        });

        const onSuccessMessage = getMessageOnSuccess();
        showSuccessToast(onSuccessMessage);
    }

    function getMessageOnSuccess() {
        const {drugBatch} = props;
        // if (moveLocation === MoveLocation.ToDrugPack) {
        //     return `Moved ${props.drugBatch.name} from ${drugPack?.name} to ${selectedDrugPack?.name}`;
        // }
        if (!moveLocation) return "Moved drug";

        const prefix = `Moved ${movement.qty} of ${drugBatch.name}`;

        switch (moveLocation) {
            case MoveLocation.ToDrugPack:
                return `${prefix} from ${drugPack?.name} to ${selectedDrugPack?.name}`;
            case MoveLocation.ToStock:
                return `${prefix} to stock room`;
            case MoveLocation.FromStock:
                return `${prefix} to ${drugPack?.name}`;
        }
    }

    function isMovementValid() {
        if (!moveLocation) {
            showErrorToast("Movement location must be specified.");
            return false;
        }
        if (movement.qty === 0) {
            showErrorToast("Amount to move must be more than 0.");
            return false;
        }

        if (moveLocation === MoveLocation.ToDrugPack && !movement.toDrugPackId) {
            showErrorToast("A drug pack must be selected if moving to drug to another drug pack.");
            return false;
        }

        return true;
    }

    function getSanitisedRequest(): MoveDrugsRequest {
        return {
            drugBatchId: props.drugBatch.id,
            movement: {
                ...movement,
                staffId: username,
                staffName: `${firstName} ${lastName}`
            }
        };
    }

    return {
        moveDrugsStore,
        movement,
        updateMovement,
        moveDrug,
        renderPreMoveInstructions,
        moveLocation,
        selectedDrugPack,
        setSelectedDrugPack,
        drugPack
    };
}

export interface MoveDrugBatchFormProps extends DrugActionFormHelpersProps {
    drugBatch: DrugBatchBasic;
    moveToStore: boolean;
    maxQty: number;
    locationId?: number;
}

// eslint-disable-next-line no-shadow
export enum MoveLocation {
    ToStock = "ToStock",
    FromStock = "FromStock",
    ToDrugPack = "ToDrugPack"
}
