import React from "react";
import Icon, {IconSize, IconType} from "../Icon/Icon";
import Tooltip from "../Tooltip/Tooltip";

const FormHeaderWithAction = ({
    headerName,
    icon,
    showIcon,
    onClick,
    tooltipText,
    iconClassName,
    iconSize
}: FormHeaderProps) => (
    <div className="row ml-0 mr-0 mc-form-heading">
        <div className="col">
            <h5 className="mb-0">
                {headerName}
                {showIcon && (
                    <Tooltip
                        tooltipText={tooltipText}
                        wrapperClassName={"ml-2 cursor-pointer"}
                        size={"md"}
                        place={"right"}
                        theme={"light"}
                    >
                        <Icon
                            rootElement={"Span"}
                            icon={icon}
                            className={iconClassName}
                            size={iconSize || "Medium"}
                            onClick={onClick}
                        />
                    </Tooltip>
                )}
            </h5>
        </div>
    </div>
);

export default FormHeaderWithAction;

interface FormHeaderProps {
    headerName: string;
    icon: IconType;
    onClick?: () => void;
    showIcon: boolean;
    tooltipText: string;
    iconClassName?: string;
    iconSize?: IconSize;
}
