import {Dispatch} from "redux";
import store from "../../Store";
import {DrugPack, GeneralReportRequest, GeneralReportResponse} from "../../../api/mm";
import {
    DISPOSAL_REPORTS_STORE_STATE,
    DisposalReport,
    DisposalReportsDispatchTypes,
    DrugBatchDisposalWithDrugBatchDetails
} from "./DisposalReportsActionTypes";
import {fetchGeneralReports} from "../../generalReports/actions/GeneralReportActions";
import {StaffLink} from "../../../api/grs";

export const nullifyDisposalReportStore = () => {
    return async (dispatch: Dispatch<DisposalReportsDispatchTypes>) => {
        dispatch({
            type: DISPOSAL_REPORTS_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
};

export const getDisposalReport = (request: GeneralReportRequest) => {
    return async (dispatch: Dispatch<DisposalReportsDispatchTypes>) => {
        try {
            dispatch({
                type: DISPOSAL_REPORTS_STORE_STATE.LOADING,
                error: null,
                loading: true
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const generalReports: GeneralReportResponse = await store.dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fetchGeneralReports(request)
            );

            if (!generalReports) {
                dispatch({
                    type: DISPOSAL_REPORTS_STORE_STATE.ERROR,
                    error: `Could not generate disposal reports`,
                    loading: false
                });
                return;
            }

            dispatch({
                type: DISPOSAL_REPORTS_STORE_STATE.SUCCESS,
                error: null,
                loading: false,
                data: generateDisposalReport(generalReports)
            });
        } catch (e: any) {
            dispatch({
                type: DISPOSAL_REPORTS_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export function generateDisposalReport(data: GeneralReportResponse): DisposalReport[] {
    if (!data.disposalDate) return [];
    //Get all the disposal with some extra details
    const disposals: DrugBatchDisposalWithDrugBatchDetails[] = [];
    const {drugPacks, drugBatches} = data;
    //Push all disposals to a list.
    for (const drugBatch of drugBatches) {
        for (const disposal of drugBatch.disposals) {
            disposals.push({
                ...disposal,
                drugBatchId: drugBatch.id,
                drugBatchName: drugBatch.name,
                expiryDate: drugBatch.expiryDate,
                batchNumber: drugBatch.batchNumber
            });
        }
    }

    const disposalReportItems: DisposalReport[] = [];
    for (const disposalDetails of disposals) {
        const index = drugPacks.findIndex((el: DrugPack) => el.id === disposalDetails.drugPackId);

        const {
            broken,
            date,
            expiryDate,
            grsLocation,
            denatured,
            qty,
            staffId,
            staffName,
            drugBatchName,
            drugBatchId,
            batchNumber,
            witnessId,
            witnessName
        } = disposalDetails;

        //If the date of the disposal is not in range, we skip over this iteration
        if (date < data.disposalDate.startDate || date > data.disposalDate.endDate) {
            continue;
        }

        const witness: StaffLink | undefined =
            witnessId && witnessName
                ? {
                      staffName: witnessName,
                      staffId: witnessId
                  }
                : undefined;

        disposalReportItems.push({
            drugBatchName,
            drugBatchId,
            disposalDate: date,
            expiryDate: expiryDate,
            qty,
            staffMember: {
                staffId,
                staffName
            },
            witness,
            drugPackName: index > -1 ? drugPacks[index].name : "Stock",
            location: grsLocation,
            broken,
            denatured,
            batchNumber
        });
    }
    return disposalReportItems.sort((a, b) => {
        if (a.disposalDate > b.disposalDate) {
            return 1;
        }
        if (a.disposalDate < b.disposalDate) {
            return -1;
        }

        return 0;
    });
}
