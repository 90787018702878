import React from "react";
import PrintableTableHead from "../../../../../Print/Components/PrintableTableHead";
import PrintableTableRow from "../../../../../Print/Components/PrintableTableRow";
import PrintableTD from "../../../../../Print/Components/PrintableTD";
import {formatUnixToDDMMYYYY, formatUnixToLLL} from "../../../../../../utils/momentUtils";
import PrintableP from "../../../../../Print/Components/PrintableP";
import {ReportRowPrintProps} from "../../../../../Print/helpers/printableHelpers";
import {getLocationForReport} from "../../Disposal/Components/DisposalReportTable";
import {DrugBatchAdministrationChangeRecord} from "../../../../../../api/mm";

const AdministrationReportHistoryRow = ({
    report,
    index
}: ReportRowPrintProps<DrugBatchAdministrationChangeRecord>) => (
    <React.Fragment>
        <PrintableTableHead>
            <PrintableTableRow rowType={"bodyRow"} index={index}>
                <td />
                <PrintableTD
                    alignment={"left"}
                    fontSize={`0.5rem`}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                >
                    <PrintableP
                        headerName={report.staffName || "Staff member not specified"}
                        isBold
                    />
                    <PrintableP headerName={formatUnixToLLL(report.changedDate)} />
                </PrintableTD>
                <PrintableTD
                    fontSize={`0.5rem`}
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                >
                    <PrintableP headerName={formatUnixToDDMMYYYY(report.changedDate)} />
                </PrintableTD>
                <PrintableTD
                    alignment={"left"}
                    fontSize={`0.5rem`}
                    padding={".35rem .75rem"}
                    maxWidth={`100px`}
                >
                    <PrintableP headerName={report.qty} />
                </PrintableTD>
                <PrintableTD
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    fontSize={`0.5rem`}
                    maxWidth={`100px`}
                >
                    <PrintableP headerName={getLocationForReport(report.grsLocation)} />
                </PrintableTD>
                <PrintableTD
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    fontSize={`0.5rem`}
                    maxWidth={`100px`}
                >
                    <PrintableP headerName={report.staffName || "Staff member not specified"} />
                </PrintableTD>
                <PrintableTD
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    fontSize={`0.5rem`}
                    maxWidth={`100px`}
                >
                    <PrintableP
                        headerName={
                            report.witnessName ? report.witnessName : "Witness not specified"
                        }
                    />
                </PrintableTD>
                <PrintableTD
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    fontSize={`0.5rem`}
                    maxWidth={`100px`}
                >
                    <PrintableP headerName={report.qa ? "Yes" : "No"} />
                </PrintableTD>
                <PrintableTD
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    fontSize={`0.5rem`}
                    maxWidth={`100px`}
                >
                    <PrintableP headerName={report.dosage || "Dosage not specified"} />
                </PrintableTD>
                <PrintableTD
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    fontSize={`0.5rem`}
                    maxWidth={`100px`}
                >
                    <PrintableP headerName={report.wastage || "Wastage not specified"} />
                </PrintableTD>
                <PrintableTD
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    fontSize={`0.5rem`}
                    maxWidth={`100px`}
                >
                    <PrintableP headerName={report.routeAdministration} />
                </PrintableTD>
            </PrintableTableRow>
        </PrintableTableHead>
    </React.Fragment>
);

export default AdministrationReportHistoryRow;
