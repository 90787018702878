import {useUserData} from "./useUserData";
import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {StaffAccessLevel} from "../../api/staff";
import {getUserAccessLevel} from "../../utils/userDataUtils";
import useMcConfig from "./useMcConfig";

export function useCanUserEditAudit() {
    const user = useUserData();
    const audit = useSelector((state: RootStore) => state.audit.data);
    const {config} = useMcConfig();
    const systemAccessLevel = getUserAccessLevel(user, config);

    if (!audit) return false;

    if (user.username.length === 0) return false;

    return !(systemAccessLevel === StaffAccessLevel.DutyManager && audit.drugAudit.id > 0);
}
