import React from "react";
import DrugPackPreview from "../Preview/DrugPackPreview";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../Button/MCButton";
import PrintButton from "../../../../Print/PrintButton";
import DrugPackLabel from "../../../../DrugPackActions/DrugPackLabel/DrugPackLabel";
import DrugPackInsert from "../../../../DrugPackActions/DrugPackInsert/DrugPackInsert";
import {Modal} from "pulse-modal";
import {DrugPackWithContents} from "../../../../../api/mm";
import Icon, {IconType} from "../../../../Icon/Icon";
import {useDrugPackPreviewModal} from "./Hooks/useDrugPackPreviewModal";

function DrugPackPreviewModal(props: DrugPackWithContents) {
    const {editDrugPack, orderDrugsAlphabetically, toggle, isShown, user} =
        useDrugPackPreviewModal(props);
    return (
        <React.Fragment>
            <Icon
                onClick={toggle}
                rootElement={"Span"}
                className={"cursor-pointer"}
                icon={IconType.Eye}
                size={"Large"}
            />

            <Modal
                modalSize={"xl"}
                title={`Preview ${props.name}`}
                bodyChildren={<DrugPackPreview {...props} />}
                onClose={toggle}
                isShown={isShown}
                footerChildren={
                    <div className="row ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Edit Drug Pack"}
                            onClick={editDrugPack}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                        <PrintButton
                            toPrint={
                                <DrugPackLabel drugPack={orderDrugsAlphabetically()} user={user} />
                            }
                            printButtonText={"Print Drug Pack Label"}
                            reportHeaderText={""}
                            buttonColour={ButtonColourOptions.DarkBlue}
                            footerMargin={"0"}
                            hideHeader
                        />
                        <PrintButton
                            toPrint={
                                <DrugPackInsert drugPack={orderDrugsAlphabetically()} user={user} />
                            }
                            printButtonText={"Print Drug Pack Insert"}
                            reportHeaderText={""}
                            buttonColour={ButtonColourOptions.DarkBlue}
                            footerMargin={"0"}
                            hideHeader
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Close"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
}

export default DrugPackPreviewModal;
