import React from "react";
import {BaseDropdownProps} from "./Helpers/dropdownUtils";
import {DrugPackWithContents} from "../../api/mm";
import Select from "react-select";
import {useDropdown} from "./Hooks/useDropdown";

function DrugPackWithContentsDropdown({
    selectedDrugPack,
    onChange,
    removeCurrentDrugPack,
    ...rest
}: PulseDrugPackWithContentsDropdown) {
    const {options, selectedOption, handleDropdownChange} = useDropdown<DrugPackWithContents>({
        storeName: "drugPackWithContentsList",
        onChange,
        id: selectedDrugPack?.id,
        storeKeyLabel: "name",
        storeKeyValue: "id"
    });

    function getOptions() {
        if (removeCurrentDrugPack) {
            return options.filter((opt) => opt.value !== removeCurrentDrugPack.id);
        }

        return options;
    }

    return (
        <React.Fragment>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={getOptions()}
                onChange={handleDropdownChange}
                isSearchable={rest.searchable}
                value={selectedOption}
                noOptionsMessage={() => rest.noItemsMessage}
                placeholder={rest.placeholder}
                isDisabled={rest.disabled}
                isClearable={rest.clearable}
                menuPortalTarget={document.body}
            />
        </React.Fragment>
    );
}

export default DrugPackWithContentsDropdown;

export interface PulseDrugPackWithContentsDropdown extends BaseDropdownProps<DrugPackWithContents> {
    selectedDrugPack?: DrugPackWithContents | null;
    removeCurrentDrugPack?: DrugPackWithContents | null;
}
