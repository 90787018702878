import {
    DrugAdministrationMethod,
    DrugBatchAdministration,
    DrugBatchDisposal,
    DrugBatchMovement,
    DrugPackDrug
} from "../api/mm";

export function calculateDrugPackDrugs(
    batchId: number,
    movements: Array<DrugBatchMovement>,
    administrations: Array<DrugBatchAdministration>,
    disposals: Array<DrugBatchDisposal>
): Array<DrugPackDrug> {
    const packs = new Map<number, DrugPackDrug>();
    const nominalMoves = new Array<NominalMovement>();

    for (const movement of movements) {
        const actions = fromMovement(movement);
        if (!actions || actions.length === 0) continue;
        nominalMoves.push(...actions);
    }

    for (const administration of administrations) {
        const action = fromAdministration(administration);
        nominalMoves.push(action);
    }
    for (const disposal of disposals) {
        const action = fromDisposal(disposal);
        nominalMoves.push(action);
    }

    for (const movement of nominalMoves) {
        const existingDrug = packs.get(movement.drugPackId);

        if (existingDrug) {
            packs.set(movement.drugPackId, {
                ...existingDrug,
                qty: existingDrug.qty + movement.qtyChange
            });
        } else {
            packs.set(movement.drugPackId, {
                drugBatchId: batchId,
                drugPackId: movement.drugPackId,
                qty: movement.qtyChange
            });
        }
    }

    //return packs;
    return Array.from(packs.values());
}

export function fromMovement(movement: DrugBatchMovement): Array<NominalMovement> {
    const results = Array<NominalMovement>();

    if (movement.fromDrugPackId != null && movement.qty > 0) {
        results.push({
            date: movement.date,
            qtyChange: movement.qty,
            drugPackId: movement.fromDrugPackId
        });
    }
    if (movement.toDrugPackId != null && movement.qty > 0) {
        results.push({
            date: movement.date,
            qtyChange: -movement.qty,
            drugPackId: movement.toDrugPackId
        });
    }

    return results;
}

//If the drug was administered from the stock list, packId will be undefined, give it a value of 0 for sorting
export function fromAdministration(administration: DrugBatchAdministration): NominalMovement {
    return {
        date: administration.date,
        qtyChange: -administration.qty,
        drugPackId: administration.drugPackId ? administration.drugPackId : 0
    };
}
//If the drug was disposed from the stock list, packId will be undefined, give it a value of 0 for sorting
export function fromDisposal(disposal: DrugBatchDisposal): NominalMovement {
    return {
        date: disposal.date,
        qtyChange: -disposal.qty,
        drugPackId: disposal.drugPackId ? disposal.drugPackId : 0
    };
}

//VVV For Test Suite VVV
export function getMovement(
    date: number,
    qty: number,
    toPackId?: number,
    fromPackId?: number
): DrugBatchMovement {
    return {
        id: "",
        staffName: "Staff 1",
        staffId: "Staff1",
        date,
        qty,
        fromDrugPackId: fromPackId,
        toDrugPackId: toPackId
    };
}

export function getAdministration(
    date: number,
    qty: number,
    packId?: number
): DrugBatchAdministration {
    return {
        id: "",
        staffName: "Staff 1",
        staffId: "Staff1",
        date,
        qty,
        drugPackId: packId,
        routeAdministration: DrugAdministrationMethod.Inhaled,
        qa: false
    };
}

export function getDisposal(date: number, qty: number, packId?: number): DrugBatchDisposal {
    return {
        id: "",
        staffName: "Staff 1",
        staffId: "Staff1",
        date,
        qty,
        drugPackId: packId,
        broken: false,
        denatured: false
    };
}

//Interface to track all movements
export interface NominalMovement {
    date: number;
    qtyChange: number;
    drugPackId: number;
}
