import {DefaultApi as DrugApi} from "../../api/mm";

export default class MedicineManagementApiModel {
    private static _instance: MedicineManagementApiModel;
    private static _drugApi: DrugApi;

    private constructor() {
        if (MedicineManagementApiModel._instance) {
            throw new Error("Use MedicineManagementApiModel.instance instead of new");
        }
        MedicineManagementApiModel._instance = this;
    }

    static get instance() {
        return (
            MedicineManagementApiModel._instance ??
            (MedicineManagementApiModel._instance = new MedicineManagementApiModel())
        );
    }

    static get drugApi(): DrugApi {
        if (!MedicineManagementApiModel._drugApi) {
            MedicineManagementApiModel._drugApi = new DrugApi({
                basePath: process.env.REACT_APP_MM_API
            });
        }

        return MedicineManagementApiModel._drugApi;
    }
}
