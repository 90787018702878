import {DrugExpiryRollout} from "../actions/ExpiryReportActionTypes";
import {EXPIRY_REPORT_STORE_STATE} from "../actions/ExpiryReportActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugExpiryRollout[]>([]);

const expiryReportReducer = (
    state: StoreServiceData<DrugExpiryRollout[]> = defaultState,
    action: ServiceActionTypes<DrugExpiryRollout[]>
): StoreServiceData<DrugExpiryRollout[]> =>
    createReducer(state, action, EXPIRY_REPORT_STORE_STATE, () => showErrorToast(action.error));

export default expiryReportReducer;
