import {STATUS_REPORT_STORE_STATE, StatusReport} from "../actions/StatusReportActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<StatusReport[]>([]);

const statusReportsReducer = (
    state: StoreServiceData<StatusReport[]> = defaultState,
    action: ServiceActionTypes<StatusReport[]>
): StoreServiceData<StatusReport[]> =>
    createReducer(state, action, STATUS_REPORT_STORE_STATE, () => showErrorToast(action.error));

export default statusReportsReducer;
