import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {useEffect} from "react";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../Navigation/routeNames";
import {setNavigationItems} from "../../../../store/navigation/actions/NavigationActions";
import {getAuditLocations} from "../../../../store/locationList/actions/LocationListActions";

export function useLocationList() {
    const dispatch = useDispatch();
    const locationListStore = useSelector((state: RootStore) => state.locationList);
    const locationStore = useSelector((state: RootStore) => state.location);

    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            },
            {
                name: routeNames.auditLocationList.name,
                path: routeNames.auditLocationList.path
            }
        ];
        dispatch(setNavigationItems(navItems));

        dispatch(getAuditLocations());
    }, []);

    return {
        locationListStore,
        locationStore
    };
}
