import React from "react";
import PrintableTable from "../../Print/Components/PrintableTable";
import PrintableTableHead from "../../Print/Components/PrintableTableHead";
import PrintableTableRow from "../../Print/Components/PrintableTableRow";
import PrintableTH from "../../Print/Components/PrintableTH";
import {DrugPackLabelProps} from "../DrugPackLabel/DrugPackLabel";
import DrugPackPrintBody from "../Shared/DrugPackPrintBody";
import PrintableTableBody from "../../Print/Components/PrintableTableBody";
import PrintableTD from "../../Print/Components/PrintableTD";

const DrugPackInsert = (props: DrugPackLabelProps) => {
    return (
        <React.Fragment>
            <PrintableTable width={"800px"}>
                <PrintableTableHead>
                    <PrintableTableRow rowType={"headerRow"}>
                        <PrintableTH alignment={"left"} padding={"1rem"} maxWidth={"1000px"}>
                            <React.Fragment>
                                Medicare EMS Ltd {props.drugPack.name} - (Seal Number:
                                {props.drugPack.sealNumber})
                            </React.Fragment>
                        </PrintableTH>
                    </PrintableTableRow>
                </PrintableTableHead>
            </PrintableTable>
            <PrintableTable width={"800px"}>
                <PrintableTableBody>
                    <PrintableTableRow rowType={"bodyRow"}>
                        <PrintableTD
                            fontSize={"1.2rem"}
                            alignment={"left"}
                            padding={"1rem"}
                            maxWidth={"1000px"}
                        >
                            <h6 style={{marginBottom: `0`}}>
                                <strong>Date:</strong>
                            </h6>
                        </PrintableTD>
                        <PrintableTD
                            fontSize={"1.2rem"}
                            alignment={"left"}
                            padding={"1rem"}
                            maxWidth={"1000px"}
                        >
                            <h6 style={{marginBottom: `0`}}>
                                <strong>Crew:</strong>
                            </h6>
                        </PrintableTD>
                        <PrintableTD
                            fontSize={"1.2rem"}
                            alignment={"left"}
                            padding={"1rem"}
                            maxWidth={"1000px"}
                        >
                            <h6 style={{marginBottom: `0`}}>
                                <strong>Callsign:</strong>
                            </h6>
                        </PrintableTD>
                    </PrintableTableRow>
                </PrintableTableBody>
            </PrintableTable>
            <DrugPackPrintBody {...props} />
        </React.Fragment>
    );
};

export default DrugPackInsert;
