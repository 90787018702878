import {DrugAudit} from "../../../api/mm";
import {DrugPackCategoryInfo} from "../helpers/auditHelpers";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const AUDIT_STORE = createStoreState("audit_store");

interface AuditLoading extends StoreServiceData<DrugAuditWithDrugInfo> {
    type: typeof AUDIT_STORE.LOADING;
}
interface AuditError extends StoreServiceData<DrugAuditWithDrugInfo> {
    type: typeof AUDIT_STORE.ERROR;
}
interface AuditSuccess extends StoreServiceData<DrugAuditWithDrugInfo> {
    type: typeof AUDIT_STORE.SUCCESS;
}

export type AuditDispatchTypes = AuditError | AuditLoading | AuditSuccess;

export interface DrugAuditWithDrugInfo {
    drugAudit: DrugAudit;
    drugPackCategoryInfo: DrugPackCategoryInfo[];
}
