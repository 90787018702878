import {DrugBatch} from "../../../../api/mm";
import moment from "moment";
import {showErrorToast} from "../../../../utils/toastUtils";

export function isDrugBatchFormValid(props: DrugBatch): boolean {
    if (props.name.length < 3) {
        showErrorToast("Drug Batch name must contain 3 or more characters.");
        return false;
    }

    if (props.batchNumber.length < 3) {
        showErrorToast("Batch Number name must contain 3 or more characters.");
        return false;
    }

    if (props.qty < 0) {
        showErrorToast("Quantity must be more than or equal to 0.");
        return false;
    }

    const now = moment();

    if (props.expiryDate < now.unix()) {
        showErrorToast("Expiry date cannot be before today's date.");
        return false;
    }

    if (props.lowStockQty < 0) {
        showErrorToast("Low stock quantity must be more than or equal to 0.");
        return false;
    }

    if (props.lowStockQty > props.qty) {
        showErrorToast("Low stock quantity can't be more than the max quantity in stock.");
        return false;
    }

    return true;
}
