import {Dispatch} from "redux";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {ADMINISTER_DRUGS_STORE, AdministerDrugsDispatchTypes} from "./AdministerDrugsActionTypes";
import {AdministerDrugsRequest, UpdateAdministration} from "../../../api/mm";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Allows for an administration to be marked */
export const administerDrugs = (request: AdministerDrugsRequest) => {
    return async (dispatch: Dispatch<AdministerDrugsDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                ADMINISTER_DRUGS_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.administerDrugs(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: ADMINISTER_DRUGS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

/** Allows for an administration to be updated */
export const updateAdministration =
    (request: UpdateAdministration) => async (dispatch: Dispatch<AdministerDrugsDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                ADMINISTER_DRUGS_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.updateAdministration(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: ADMINISTER_DRUGS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
