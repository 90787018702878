export function triggerPrint(content: HTMLElement) {
    //Use a timeout as some browsers will generate the document before it has generated.
    setTimeout(() => {
        const winPrint = window.open(
            "",
            "",
            "left=0,top=0,width=1260,height=900,toolbar=0,scrollbars=0,status=0"
        );
        if (!winPrint) return;

        winPrint.document.write(content.innerHTML);
        winPrint.document.close();
        winPrint.focus();
        winPrint.print();
    }, 500);
}

export interface ReportRowPrintProps<T> {
    index: number;
    report: T;
}
