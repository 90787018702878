import React from "react";
import {
    EntryAssignedDropdownProps,
    useEntryAssignedDropdown
} from "./Hooks/useEntryAssignedDropdown";
import Select from "react-select";
import {Loading} from "store-fetch-wrappers";

function EntryAssignedStaffDropdown(props: EntryAssignedDropdownProps) {
    const {selectedOption, handleOptionChange, options} = useEntryAssignedDropdown(props);
    return (
        <React.Suspense fallback={<Loading type={"three-ring"} showLoadingText={false} />}>
            <Select
                className="search-bar"
                classNamePrefix="select-options"
                options={options}
                onChange={handleOptionChange}
                isSearchable={props.searchable}
                value={selectedOption}
                noOptionsMessage={() => "No Staff Members Found"}
                placeholder="Select Staff Member"
                isDisabled={false}
                isClearable={props.clearable}
            />
        </React.Suspense>
    );
}

export default EntryAssignedStaffDropdown;
