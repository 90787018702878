import {ControlDrugAudit} from "./controlDrugAuditHelpers";
import {WeeklyAudit} from "./weeklyDrugBagAuditHelpers";

export interface AuditForm {
    id: string;
    weeklyAudit?: WeeklyAudit;
    controlDrugAudit?: ControlDrugAudit;
}

export interface QuestionWithAnswer {
    answer: Answer;
    question: string;
    scoreChange: number;
}

export interface QuestionWithAnswerAndComments {
    answer: Answer;
    comments?: string;
    question: string;
    scoreChange: number;
}

// eslint-disable-next-line no-shadow
export enum Answer {
    Yes = "Yes",
    No = "No",
    NotApplicable = "NotApplicable"
}

export interface DrugPackCategoryInfo {
    categoryId: number;
    categoryName: string;
    drugPackInfoList: DrugPackInfo[];
}

export interface DrugPackInfo {
    drugPackId: number;
    drugPackName: string;
    answer: Answer;
    locationId?: number;
}

/** Generates a basic question with answer */
export function newQuestionWithAnswer(
    answer: Answer,
    question: string,
    scoreChange?: number
): QuestionWithAnswer {
    return {
        question,
        answer,
        scoreChange: scoreChange ? scoreChange : 0
    };
}

export function newQuestionWithAnswerAndComments(
    answer: Answer,
    question: string,
    scoreChange?: number
): QuestionWithAnswerAndComments {
    return {
        question,
        answer,
        scoreChange: scoreChange ? scoreChange : 0,
        comments: ""
    };
}
