import {
    Answer,
    newQuestionWithAnswerAndComments,
    QuestionWithAnswerAndComments
} from "./auditHelpers";

export interface ControlDrugAudit {
    controlDrugAuditBaseQuestions: ControlDrugAuditBaseQuestions;
    controlDrugItemList: ControlDrugItemList;
}

export const controlDrugAuditBaseQuestionsOrder: string[] = [
    "keyToSafeLocked",
    "safeLocked",
    "controlDrugBookWithSafe",
    "stockBalanceCorrect",
    "controlBooksAccurate",
    "controlBooksLegible",
    "correctSignatures",
    "allLogsWitnessed",
    "cctvInPlaceAndFunctioning"
];

export interface ControlDrugAuditBaseQuestions {
    keyToSafeLocked: QuestionWithAnswerAndComments;
    safeLocked: QuestionWithAnswerAndComments;
    controlDrugBookWithSafe: QuestionWithAnswerAndComments;
    stockBalanceCorrect: QuestionWithAnswerAndComments;
    controlDrugBooksAccurate: QuestionWithAnswerAndComments;
    controlBooksLegible: QuestionWithAnswerAndComments;
    correctSignatures: QuestionWithAnswerAndComments;
    allLogsWitnessed: QuestionWithAnswerAndComments;
    cctvInPlaceAndFunctioning: QuestionWithAnswerAndComments;
}

export interface ControlDrugItemList {
    items: ControlDrugItem[];
}

export interface ControlDrugItem {
    controlDrug: string;
    expiryDate: number;
    batchNumber: string;
    stockBalance: number;
}

export function generateNewControlDrugAudit(): ControlDrugAudit {
    return {
        controlDrugAuditBaseQuestions: generateBaseQuestion(),
        controlDrugItemList: {items: []}
    };
}

function generateBaseQuestion(): ControlDrugAuditBaseQuestions {
    return {
        keyToSafeLocked: newQuestionWithAnswerAndComments(Answer.No, "Key to safe locked away?"),
        safeLocked: newQuestionWithAnswerAndComments(Answer.No, "Safe locked?"),
        controlDrugBookWithSafe: newQuestionWithAnswerAndComments(
            Answer.No,
            "Control Drug Book with the safe?"
        ),
        stockBalanceCorrect: newQuestionWithAnswerAndComments(Answer.No, "Stock Balance Correct?"),
        controlDrugBooksAccurate: newQuestionWithAnswerAndComments(
            Answer.No,
            "Control Drugs Book accurate?"
        ),
        controlBooksLegible: newQuestionWithAnswerAndComments(
            Answer.No,
            "Control Drugs Book legible?"
        ),
        correctSignatures: newQuestionWithAnswerAndComments(Answer.No, "Correct Signatures?"),
        allLogsWitnessed: newQuestionWithAnswerAndComments(Answer.No, "All logs witnessed?"),
        cctvInPlaceAndFunctioning: newQuestionWithAnswerAndComments(
            Answer.No,
            "CCTV in place and functioning?"
        )
    };
}
