import {
    DrugBatchActionFormProps,
    isDrugAdministrationStage2ActionValid,
    refreshDrugBatch
} from "../../Helpers/drugActionFormHelpers";
import {DrugBatchAdministration, UpdateAdministration} from "../../../../api/mm";
import {useEffect, useState} from "react";
import {updateAdministration} from "../../../../store/administerDrugs/actions/AdministerDrugsActions";
import {showSuccessToast} from "../../../../utils/toastUtils";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";

export function useAdministrationStage2(props: DrugBatchActionFormProps) {
    const dispatch = useDispatch();
    const drugBatchStore = useSelector((state: RootStore) => state.drugBatch);
    const drugPackId = useSelector((state: RootStore) => state.drugPackWithContents.data?.id || 0);
    const [request, setRequest] = useState<UpdateAdministration | undefined>();

    useEffect(() => {
        const originalAdministration = getStage1Administration(props);
        setRequest(originalAdministration);
    }, [props]);

    function getStage1Administration(
        entity: DrugBatchActionFormProps
    ): UpdateAdministration | undefined {
        // If it is a new administration, we want to stop here.
        if (!entity.nominalMovementId) return;
        if (entity.nominalMovementId.length === 0) return;

        const administration = entity.drugBatch.administrations.find(
            (el: DrugBatchAdministration) => el.id === entity.nominalMovementId
        );
        if (!administration) return;

        return {
            batchId: props.drugBatch.id,
            administration
        };
    }

    const actionStage2 = async (entity: UpdateAdministration) => {
        const isValid = isDrugAdministrationStage2ActionValid(entity, props.maxQtyAvailable);
        if (!isValid) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        const updatedAdministration: boolean = await dispatch(
            // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
            // @ts-ignore
            updateAdministration(entity)
        );

        if (!updatedAdministration) return;
        await refreshDrugBatch({
            drugPackId,
            actionFormProps: props,
            drugBatchStore,
            dispatch
        });

        showSuccessToast("Completed Administration Stage 2");
    };

    return {
        request,
        actionStage2
    };
}
