import {DrugAuditHistoryOverview} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const AUDIT_LIST_HISTORIC_STORE = createStoreState("audit_list_historic");

interface AuditListHistoricLoading extends StoreServiceData<DrugAuditHistoryOverview> {
    type: typeof AUDIT_LIST_HISTORIC_STORE.LOADING;
}
interface AuditListHistoricError extends StoreServiceData<DrugAuditHistoryOverview> {
    type: typeof AUDIT_LIST_HISTORIC_STORE.ERROR;
}
interface AuditListHistoricSuccess extends StoreServiceData<DrugAuditHistoryOverview> {
    type: typeof AUDIT_LIST_HISTORIC_STORE.SUCCESS;
}

export type AuditListHistoricDispatchTypes =
    | AuditListHistoricLoading
    | AuditListHistoricError
    | AuditListHistoricSuccess;
