import React, {useEffect, useState} from "react";
import {StoreServiceData} from "store-fetch-wrappers/dist";
import {useHistory} from "react-router-dom";
import {useDispatch} from "react-redux";
import TableItemAction from "../../TableItemAction";
import {IconType} from "../../../Icon/Icon";
import DeleteTableItemAction from "../../DeleteTableItemAction";

export function useIdNameTable<T>({
    values,
    itemName,
    editItemRoute,
    newItemRoute,
    getAll,
    reduxStore,
    deleteById
}: IdNameTableProps<T>) {
    const [items, setItems] = useState<IdNameTableRow[]>([]);
    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        setItems(getTableRows(values));
    }, [values]);

    function getTableRows(entity: IdName[]): IdNameTableRow[] {
        return entity.map((item) => {
            return {
                name: item.name,
                action: getActions(item)
            };
        });
    }

    function getHeaders() {
        return {
            name: "Name",
            action: "Action"
        };
    }

    function getActions({id, name}: IdName) {
        return (
            <React.Fragment>
                <TableItemAction
                    tooltipText={`Edit ${name}`}
                    onClick={() => editItem(id)}
                    icon={IconType.Edit}
                />
                <DeleteTableItemAction
                    itemName={name}
                    loading={reduxStore.loading}
                    modalTitle={`Delete ${itemName}`}
                    modalMessage={`Are you sure you want to delete ${name}?`}
                    onConfirm={async () => {
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        const deleted: boolean = await dispatch(deleteById(id));
                        if (!deleted) return false;
                        await dispatch(getAll());
                        return true;
                    }}
                />
            </React.Fragment>
        );
    }

    function editItem(id: number) {
        const path = `${editItemRoute}/${id}`;
        history.push(path);
    }

    function newItem() {
        history.push(newItemRoute);
    }

    return {
        headers: getHeaders(),
        items,
        newItem
    };
}

export interface IdNameTableProps<T> {
    values: IdName[];
    editItemRoute: string;
    newItemRoute: string;
    itemName: string;
    getAll: () => Promise<void>;
    deleteById: (id: number) => Promise<boolean>;
    reduxStore: StoreServiceData<T>;
}

interface IdName {
    name: string;
    id: number;
}

interface IdNameTableRow {
    name: string;
    action: React.ReactNode;
}

interface DynamicKeyValue {
    [key: string]: string;
}
