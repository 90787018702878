import {UserData} from "../../../api/staff";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const STAFF_LIST_STORE = createStoreState("staff_list");

export interface StaffListLoading extends StoreServiceData<UserData[]> {
    type: typeof STAFF_LIST_STORE.LOADING;
}
export interface StaffListError extends StoreServiceData<UserData[]> {
    type: typeof STAFF_LIST_STORE.ERROR;
}
export interface StaffListSuccess extends StoreServiceData<UserData[]> {
    type: typeof STAFF_LIST_STORE.SUCCESS;
}

export type StaffListDispatchTypes = StaffListSuccess | StaffListError | StaffListLoading;
