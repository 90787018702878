import {NominalActionType} from "../StaffWitnessWithLocationSection";
import {NominalActionLocationDetails} from "../../../Dropdown/Helpers/dropdownUtils";
import {GrsLocation} from "../../../../api/mm";

export function useStaffWitnessSection() {
    function getGrsLocation({venue, entry}: NominalActionLocationDetails): GrsLocation {
        return {
            venueId: venue.id,
            venueName: venue.name,
            entryName: entry.description || "",
            entryId: entry.id,
            calendarType: entry.calendarType.toString()
        };
    }

    function getMainStaffHeader(action: NominalActionType) {
        switch (action) {
            case NominalActionType.Administration:
                return "Administered by?";
            case NominalActionType.Disposal:
                return "Disposed by?";
        }
    }

    return {
        getGrsLocation,
        getMainStaffHeader
    };
}
