import {DISPOSAL_REPORTS_STORE_STATE, DisposalReport} from "../actions/DisposalReportsActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DisposalReport[]>([]);

const disposalReportsReducer = (
    state: StoreServiceData<DisposalReport[]> = defaultState,
    action: ServiceActionTypes<DisposalReport[]>
): StoreServiceData<DisposalReport[]> =>
    createReducer(state, action, DISPOSAL_REPORTS_STORE_STATE, () => showErrorToast(action.error));

export default disposalReportsReducer;
