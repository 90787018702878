import {Dispatch} from "redux";
import {DRUG_BATCH_PAGED_STORE, DrugBatchPagedDispatchTypes} from "./DrugBatchPagedActionTypes";
import store from "../../Store";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {DrugBatchPageRequest} from "../../../api/mm";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {createBlankDrugBatchPageResponse} from "../reducer/DrugBatchPagedReducer";

export const nullifyDrugBatchPagedStore = () => {
    return async (dispatch: Dispatch<DrugBatchPagedDispatchTypes>) => {
        dispatch({
            type: DRUG_BATCH_PAGED_STORE.SUCCESS,
            error: null,
            loading: false,
            data: createBlankDrugBatchPageResponse()
        });
    };
};

export const fetchPagedDrugBatches = (request: DrugBatchPageRequest) => {
    return async (dispatch: Dispatch<DrugBatchPagedDispatchTypes>) => {
        try {
            const success = await postDataToServiceWithRedux(
                DRUG_BATCH_PAGED_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.pageDrugBatches(request),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().drugBatchPaged.data;

                if (!data) return null;

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: DRUG_BATCH_PAGED_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
