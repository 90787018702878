import React from "react";
import {DrugBatchAdministrationChangeRecord, DrugPack} from "../../../../api/mm";
import {formatUnixToDDMMYYYY, formatUnixToLLL} from "../../../../utils/momentUtils";
import {useSelector} from "react-redux";
import {RootStore} from "../../../../store/Store";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {getDrugPackName} from "../Helpers/stockManagementAuditHelpers";
import {CalendarTypeEnum} from "../../../../api/grs";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../Table/NoItemsMessage";

const AdministrationHistoryTable = (props: Props) => {
    const drugPackList = useSelector((state: RootStore) => state.drugPackList.data || []);
    return (
        <React.Fragment>
            <PulseTable
                items={toTableRow(props, drugPackList)}
                headers={{
                    changedDetails: "Changed Details",
                    drugPack: "Drug Pack",
                    administrationDate: "Administration Date",
                    qty: "Quantity",
                    location: "Location",
                    administeredBy: "Administered By",
                    witnessedBy: "Witnessed By",
                    qualityAssured: "Quality Assured",
                    dosage: "Dosage",
                    wastage: "Wastage",
                    routeOfAdministration: "Route of Administration"
                }}
                customRenderers={{
                    changedDetails: (item: TableRow) => (
                        <React.Fragment>{item.changedDetails}</React.Fragment>
                    )
                }}
                noItemsSection={<NoItemsMessage message={"There is no history to show"} />}
            />
        </React.Fragment>
    );
};

export default AdministrationHistoryTable;

interface Props {
    history: DrugBatchAdministrationChangeRecord[];
}

interface TableRow {
    changedDetails: JSX.Element;
    drugPack: string;
    administrationDate: string;
    qty: number;
    location: string;
    administeredBy: string;
    witnessedBy: string;
    qualityAssured: string;
    dosage: string;
    wastage: string;
    routeOfAdministration: string;
}

function toTableRow(props: Props, drugPackList: DrugPack[]): TableRow[] {
    return props.history.map((item) => {
        return {
            changedDetails: getChangedDetails(item),
            drugPack: getDrugPackNameForTable(item, drugPackList),
            administrationDate: formatUnixToDDMMYYYY(item.date),
            qty: item.qty,
            location: getLocationName(item),
            administeredBy: item.staffName || "Administered by not specified",
            witnessedBy: item.witnessName || "Witness not specified",
            qualityAssured: item.qa ? "Yes" : "No",
            dosage: item.dosage || "Dosage not specified",
            wastage: item.wastage || "Wastage not specified",
            routeOfAdministration: getUiFriendlyText(item.routeAdministration)
        };
    });
}

function getChangedDetails(administration: DrugBatchAdministrationChangeRecord): JSX.Element {
    return (
        <React.Fragment>
            <h6 className="mb-0">{administration.changedByStaffName}</h6>
            <p className="mb-0">{formatUnixToLLL(administration.changedDate)}</p>
        </React.Fragment>
    );
}

function getDrugPackNameForTable(
    item: DrugBatchAdministrationChangeRecord,
    drugPackList: DrugPack[]
): string {
    const drugPackId = item.drugPackId ? item.drugPackId : 0;

    return getDrugPackName(drugPackId, drugPackList);
}

function getLocationName(item: DrugBatchAdministrationChangeRecord): string {
    if (!item.grsLocation) return "Location not specified";
    switch (item.grsLocation.calendarType) {
        case CalendarTypeEnum.Frontline:
            return item.grsLocation.entryName;
        case CalendarTypeEnum.Event:
            return item.grsLocation.venueName;
        default:
            return "Location not specified";
    }
}
