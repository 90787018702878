import React from "react";
import {DrugPackWithContents} from "../../../../../api/mm";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../Table/NoItemsMessage";
import {useDrugPackTable} from "./Hooks/useDrugPackTable";

function DrugPackTable(props: DrugPackWithContents[]): React.ReactNode {
    const {items} = useDrugPackTable(props);
    return (
        <div className="pb-5">
            <PulseTable
                items={items}
                headers={{
                    status: "Status",
                    name: "Name",
                    sealNumber: "Seal Number",
                    actions: "Actions"
                }}
                customRenderers={{
                    status: (item) => item.status,
                    actions: (item) => item.actions
                }}
                noItemsSection={<NoItemsMessage message={"There are no Drug Packs here."} />}
            />
        </div>
    );
}

export default DrugPackTable;
