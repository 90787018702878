import React from "react";
import PrintableTableHead from "../../../../../Print/Components/PrintableTableHead";
import PrintableTableRow from "../../../../../Print/Components/PrintableTableRow";
import PrintableTH from "../../../../../Print/Components/PrintableTH";
import PrintableH6 from "../../../../../Print/Components/PrintableH6";

const AdministrationReportHeader = () => {
    return (
        <PrintableTableHead>
            <PrintableTableRow rowType={"headerRow"}>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    minWidth={"75px"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Drug Batch"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    minWidth={"125px"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Drug Pack"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Administration Date"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Qty"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    minWidth={"40px"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Location"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Administered By"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Witnessed By"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Quality Assured"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Dosage"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Wastage"} />
                </PrintableTH>
                <PrintableTH
                    alignment={"left"}
                    padding={".35rem .75rem"}
                    maxWidth={`200px`}
                    fontSize={`0.7rem`}
                >
                    <PrintableH6 headerName={"Route of Administration"} />
                </PrintableTH>
            </PrintableTableRow>
        </PrintableTableHead>
    );
};

export default AdministrationReportHeader;
