import {Dispatch} from "redux";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import store from "../../Store";
import {
    DRUG_PACK_WITH_CONTENTS_STORE,
    DrugPackWithContentsDispatchTypes
} from "./DrugPackWithContentsActionTypes";
import {DrugPack, DrugPackWithContents} from "../../../api/mm";
import {
    deleteDataFromServiceWithRedux,
    getDataFromServiceWithRedux,
    postDataToServiceWithRedux
} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import Store from "../../Store";
import {getAllStaffMembers} from "../../staffList/actions/StaffListActions";
import {getAuditLocations} from "../../locationList/actions/LocationListActions";
import {fetchAllDrugPackCategories} from "../../drugPackCategoryList/actions/DrugPackCategoryListActions";
import {fetchDrugPacksWithContentByLocationId} from "../../drugPackWithContentsList/actions/DrugPackWithContentsListActions";

export const nullifyDrugPacksWithContentsStore = () => {
    return async (dispatch: Dispatch<DrugPackWithContentsDispatchTypes>) => {
        dispatch({
            type: DRUG_PACK_WITH_CONTENTS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };
};

export const createNewDrugPackWithContents = (categoryId?: number, locationId?: number) => {
    return async (dispatch: Dispatch<DrugPackWithContentsDispatchTypes>) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await Store.dispatch(getAllStaffMembers());

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await Store.dispatch(getAuditLocations());

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await Store.dispatch(fetchAllDrugPackCategories());

        const drugPackWithContents: DrugPackWithContents = {
            id: 0,
            categoryId: categoryId || 0,
            locationId,
            name: "",
            sealNumber: "",
            drugs: []
        };

        dispatch({
            type: DRUG_PACK_WITH_CONTENTS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: drugPackWithContents
        });
    };
};

export const setDrugPackWithContents = (drugPack: DrugPackWithContents) => {
    return async (dispatch: Dispatch<DrugPackWithContentsDispatchTypes>) => {
        dispatch({
            type: DRUG_PACK_WITH_CONTENTS_STORE.SUCCESS,
            error: null,
            loading: false,
            data: drugPack
        });
    };
};

export const fetchDrugPackById =
    (id: number) => async (dispatch: Dispatch<DrugPackWithContentsDispatchTypes>) => {
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(getAllStaffMembers());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(getAuditLocations());

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await Store.dispatch(fetchAllDrugPackCategories());

            const success = await getDataFromServiceWithRedux(
                DRUG_PACK_WITH_CONTENTS_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getDrugPack(id),
                statusCodeCallback
            );

            if (!success) return null;

            const data = store.getState().drugPackWithContents.data;
            if (!data) return null;

            if (data.locationId) {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await store.dispatch(fetchDrugPacksWithContentByLocationId(data.locationId));
            }
            return data;
        } catch (e: any) {
            dispatch({
                type: DRUG_PACK_WITH_CONTENTS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };

export const deleteDrugPackById = (id: number) => {
    return async (dispatch: Dispatch<DrugPackWithContentsDispatchTypes>) => {
        try {
            return await deleteDataFromServiceWithRedux(
                DRUG_PACK_WITH_CONTENTS_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.deleteDrugPack(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DRUG_PACK_WITH_CONTENTS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const saveDrugPackToService = (drugPack: DrugPack) => {
    return async (dispatch: Dispatch<DrugPackWithContentsDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                DRUG_PACK_WITH_CONTENTS_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.saveDrugPack(drugPack),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DRUG_PACK_WITH_CONTENTS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
