import React from "react";
import {UserData} from "../../../api/staff";
import {DrugPackWithContents} from "../../../api/mm";
import PrintableFormRow from "../../Print/Components/PrintableFormRow";
import PrintableTable from "../../Print/Components/PrintableTable";
import PrintableTableHead from "../../Print/Components/PrintableTableHead";
import PrintableTableRow from "../../Print/Components/PrintableTableRow";
import PrintableTH from "../../Print/Components/PrintableTH";
import DrugPackPrintBody from "../Shared/DrugPackPrintBody";

const DrugPackLabel = (props: DrugPackLabelProps) => {
    const telephoneNumber = " 0330 056 2211"; //Tactical On Call
    return (
        <React.Fragment>
            <PrintableTable width={"800px"}>
                <PrintableTableHead>
                    <PrintableTableRow rowType={"headerRow"}>
                        <PrintableTH alignment={"left"} padding={"1rem"} maxWidth={"1000px"}>
                            <React.Fragment>
                                Medicare EMS Ltd {props.drugPack.name} - (Seal Number:
                                {props.drugPack.sealNumber})
                            </React.Fragment>
                        </PrintableTH>
                    </PrintableTableRow>
                </PrintableTableHead>
            </PrintableTable>
            <PrintableFormRow
                showHead={false}
                rowName={""}
                rowContent={`IF FOUND hand into nearest Police station or call ${telephoneNumber}.`}
            />
            <DrugPackPrintBody {...props} />
        </React.Fragment>
    );
};

export default DrugPackLabel;

export interface DrugPackLabelProps {
    user: UserData;
    drugPack: DrugPackWithContents;
}
