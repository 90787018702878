import {DrugBatch} from "../../../../api/mm";
import {calculateDrugPackDrugs} from "../../../../utils/movementUtils";

/** Gets the active stock that is available to be taken from the drug store. */
export function getRemainingStock(drugBatch: DrugBatch) {
    const {id, movements, administrations, disposals} = drugBatch;

    //Check for movements separately as movements will always contain a drugpack Id and we need to track all movements regardless of if the drugPackId === 0
    //Track for actions separately as for remaining stock, we don't want to account for any actions made within a drugpack, only in the store.
    const drugPackMovements = calculateDrugPackDrugs(id, movements, [], []);
    const drugPackActions = calculateDrugPackDrugs(id, [], administrations, disposals);

    let availableStock = 0;

    for (const actions of drugPackActions) {
        if (actions.drugPackId > 0) continue;
        availableStock += actions.qty;
    }

    for (const moves of drugPackMovements) {
        availableStock += moves.qty;
    }
    //Making sure value being subtracted isn't a negative number. will cause a positive output
    return drugBatch.qty - Math.abs(availableStock);
}

export function getStockInDrugPackDrugWithBatch(drugBatch: DrugBatch) {
    const {id, movements} = drugBatch;
    const drugPackMovements = calculateDrugPackDrugs(id, movements, [], []);

    let availableStock = 0;

    for (const moves of drugPackMovements) {
        availableStock += moves.qty;
    }
    //Making sure value being subtracted isn't a negative number. will cause a positive output
    return drugBatch.qty - Math.abs(availableStock);
}
