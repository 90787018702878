import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {useParams} from "react-router-dom";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {getHistoricAuditFromService} from "../../../store/audit/actions/AuditActions";
import EditAuditForm from "./Components/EditAuditForm";
import {usePageUrl} from "../../Hooks/usePageUrl";
import {WithServiceState} from "store-fetch-wrappers";

const ServiceWrapper = WithServiceState(EditAuditForm);

const HistoricAudit = () => {
    const dispatch = useDispatch();
    const auditStore = useSelector((state: RootStore) => state.audit);
    const params: any = useParams();
    const {fullPath} = usePageUrl();
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            },
            {
                name: routeNames.generalReports.name,
                path: routeNames.generalReports.path
            },
            {
                name: routeNames.mmAuditsList.name,
                path: routeNames.mmAuditsList.path
            },
            {
                name: routeNames.historicAuditView.name,
                path: fullPath
            },
            {
                name: routeNames.auditLocationList.name,
                path: routeNames.auditLocationList.path
            }
        ];

        dispatch(setNavigationItems(navItems));

        dispatch(getHistoricAuditFromService(+params.auditId, +params.historicId));
    }, []);
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    {...auditStore}
                />
            </div>
        </React.Fragment>
    );
};

export default HistoricAudit;
