import React from "react";
import {NominalActionLocationDetails} from "./Helpers/dropdownUtils";
import {useCalendarEntryDropdown} from "./Hooks/useCalendarEntryDropdown";
import {Loading} from "store-fetch-wrappers";
import Select from "react-select";

const CalendarEntryDropdown = (props: CalendarEntryDropdownProps) => {
    const {selectedOption, calendarEntryListStore, options, onOptionChanged} =
        useCalendarEntryDropdown(props);
    return (
        <React.Fragment>
            {calendarEntryListStore.loading ? (
                <Loading type={"three-ring"} showLoadingText={false} />
            ) : (
                <Select
                    className="search-bar"
                    classNamePrefix="select-options"
                    options={options}
                    onChange={onOptionChanged}
                    isSearchable={props.searchable}
                    value={selectedOption}
                    noOptionsMessage={() => "No Entries Found"}
                    placeholder="Select Entry..."
                    isDisabled={props.disabled}
                    isClearable={false}
                />
            )}
        </React.Fragment>
    );
};

export default CalendarEntryDropdown;

export interface CalendarEntryDropdownProps {
    startDate: number;
    changeOption: (options: NominalActionLocationDetails | undefined) => void;
    searchable: boolean;
    entryId: number | undefined;
    disabled: boolean;
}
