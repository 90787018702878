import {
    AdministerDrugsRequest,
    DisposeDrugsRequest,
    DrugBatch,
    DrugBatchPageRequest,
    DrugBatchPageResponse,
    UpdateAdministration
} from "../../../api/mm";
import {CalendarTypeEnum} from "../../../api/grs";
import {fetchDrugPackById} from "../../../store/drugPackWithContents/actions/DrugPackWithContentsActions";
import {fetchPagedDrugBatches} from "../../../store/drugBatchPaged/actions/DrugBatchPagedActions";
import {Dispatch} from "redux";
import moment from "moment";
import {fetchDrugBatchById} from "../../../store/drugBatch/actions/DrugBatchActions";
import {StoreServiceData} from "store-fetch-wrappers/dist";
import {pagedRequestConfig} from "../../Filters/helpers/filterHelpers";
import {showErrorToast} from "../../../utils/toastUtils";

export interface DrugActionFormHelpersProps {
    onClose: () => void;
}

export interface DrugBatchActionFormProps extends DrugActionFormHelpersProps {
    nominalMovementId?: string;
    drugBatch: DrugBatch;
    maxQtyAvailable: number;
}

export function isDrugAdministrationStage1ActionValid(request: AdministerDrugsRequest): boolean {
    return true;
}

export function isDrugAdministrationStage2ActionValid(
    request: UpdateAdministration,
    maxQty: number
): boolean {
    const now = moment().unix();
    if (request.batchId === 0) {
        showErrorToast(
            "Drug batch ID is 0, this drug is not in the system. Contact system administrator for help."
        );
        return false;
    }

    if (request.administration.date > now) {
        showErrorToast("Administration date cannot be in the future.");
        return false;
    }

    if (!request.administration.dosage) {
        showErrorToast("Dosage must be specified.");
        return false;
    }

    if (!request.administration.wastage) {
        showErrorToast("Wastage must be specified.");
        return false;
    }

    if (request.administration.date === 0) {
        showErrorToast("Administration date cannot be blank.");
        return false;
    }

    if (request.administration.qty > maxQty) {
        showErrorToast(
            "Quantity administered cannot be more than the max amount in the drug pack."
        );
        return false;
    }

    if (request.administration.witnessId && request.administration.staffId) {
        if (request.administration.witnessId === request.administration.staffId) {
            showErrorToast("Selected staff member cannot be their own witness.");
            return false;
        }
    }

    if (request.administration.grsLocation && request.administration.grsLocation.calendarType) {
        if (request.administration.grsLocation.calendarType === CalendarTypeEnum.Frontline) {
            const cadNumber = request.administration.cadNumber;
            if (!cadNumber || cadNumber.length === 0) {
                showErrorToast("Frontline location cannot contain an empty CAD number.");
                return false;
            }
        }

        if (request.administration.grsLocation.venueId === 0) {
            showErrorToast("Venue could not be loaded, refresh the page.");
            return false;
        }

        if (request.administration.grsLocation.entryId === 0) {
            showErrorToast("Shift could not be loaded, refresh the page.");
            return false;
        }
    }

    if (!request.administration.staffId && !request.administration.staffName) {
        showErrorToast("Administered by staff member must be populated.");
        return false;
    }

    return true;
}

export function isDrugDisposalActionValid(request: DisposeDrugsRequest, maxQty: number): boolean {
    const now = moment().unix();

    if (request.drugBatchId === 0) {
        showErrorToast(
            "Drug batch ID is 0, this drug is not in the system. Contact system administrator for help."
        );
        return false;
    }

    if (request.disposal.date > now) {
        showErrorToast("Disposal date cannot be in the future.");
        return false;
    }

    if (request.disposal.date === 0) {
        showErrorToast("Disposed date cannot be blank.");
        return false;
    }

    if (request.disposal.qty === 0) {
        showErrorToast("Quantity disposed cannot be 0.");
        return false;
    }

    if (request.disposal.qty > maxQty) {
        showErrorToast("Quantity disposed cannot be more than the max amount in the drug pack.");
        return false;
    }

    if (request.disposal.witnessId) {
        if (request.disposal.witnessId === request.disposal.staffId) {
            showErrorToast("Selected staff member cannot be their own witness.");
            return false;
        }
    }

    if (!request.disposal.staffName || !request.disposal.staffId) {
        showErrorToast("Disposed by must contain a staff member.");
        return false;
    }

    return true;
}

export async function refreshPagedRequest(args: RefreshPagedRequestProps) {
    const {drugPackId, actionFormProps, pagedStore, dispatch} = args;

    if (drugPackId > 0) {
        await Promise.all([dispatch(fetchDrugPackById(drugPackId))]);
    }
    const pagedRequest = pagedStore.data;

    if (!pagedRequest) {
        actionFormProps.onClose();
        return;
    }
    //Get the search parameters of the paged request from the store.
    const req: DrugBatchPageRequest = {
        pageNum: pagedRequest.pageNum,
        numPerPage: pagedRequestConfig.resultsPerPage,
        type: pagedRequest.type,
        creationDateMinInclusive: pagedRequest.creationDateMinInclusive,
        creationDateMaxExclusive: pagedRequest.creationDateMaxExclusive,
        expiryDateMinInclusive: pagedRequest.expiryDateMinInclusive,
        expiryDateMaxExclusive: pagedRequest.expiryDateMaxExclusive,
        sortOrder: pagedRequest.sortOrder,
        drugName: pagedRequest.drugName,
        batchNumber: pagedRequest.batchNumber
    };
    await Promise.all([dispatch(fetchPagedDrugBatches(req))]);
    actionFormProps.onClose();
}

export async function refreshDrugBatch(args: RefreshDrugBatchRequest) {
    const {drugPackId, actionFormProps, drugBatchStore, dispatch} = args;

    if (drugPackId > 0) {
        await Promise.all([dispatch(fetchDrugPackById(drugPackId))]);
    }

    const drugBatch = drugBatchStore.data;

    if (!drugBatch) {
        actionFormProps.onClose();
        return;
    }

    await Promise.all([dispatch(fetchDrugBatchById(drugBatch.id))]);
    actionFormProps.onClose();
}

interface RefreshPagedRequestProps extends RefreshRequestBase {
    pagedStore: StoreServiceData<DrugBatchPageResponse>;
    dispatch: Dispatch<any>;
}

interface RefreshDrugBatchRequest extends RefreshRequestBase {
    drugBatchStore: StoreServiceData<DrugBatch>;
    dispatch: Dispatch<any>;
}

interface RefreshRequestBase {
    drugPackId: number;
    actionFormProps: DrugActionFormHelpersProps;
}
