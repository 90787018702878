import React from "react";
import PrintableTable from "../../../../../Print/Components/PrintableTable";
import StatusReportHeader from "./StatusReportHeader";
import PrintableTableBody from "../../../../../Print/Components/PrintableTableBody";
import {toArray} from "../../../../../../utils/sortingUtils";
import StatusReportRow from "./StatusReportRow";
import {StatusReport} from "../../../../../../store/statusReport/actions/StatusReportActionTypes";

const PrintStatusReport = (props: StatusReport[]) => {
    return (
        <React.Fragment>
            <PrintableTable width={"800px"} marginTop={"15px"}>
                <StatusReportHeader />
                <PrintableTableBody>
                    {toArray(props).map((item, index) => {
                        return <StatusReportRow report={item} index={index} key={index} />;
                    })}
                </PrintableTableBody>
            </PrintableTable>
        </React.Fragment>
    );
};

export default PrintStatusReport;
