import React from "react";
import {Answer, QuestionWithAnswer} from "../../../../../store/audit/helpers/auditHelpers";
import {useIsAuditEditable} from "../../../../Hooks/useIsAuditEditable";
import FormRow from "../../../../Form/FormRow";
import PulseEnumDropdown from "../../../../Dropdown/PulseEnumDropdown";

const QuestionAndAnswer = (props: QuestionWithAnswerWithCallback) => {
    const isAuditEditable = useIsAuditEditable();
    /** Gets the value of the Answer enum from a string  */
    const getAnswerFromString = (value: string): Answer => {
        return Answer[value as keyof typeof Answer];
    };
    return (
        <React.Fragment>
            <FormRow rowName={props.question} columnDetailClassName="pl-0 pr-0">
                <PulseEnumDropdown
                    enumOptions={Answer}
                    onChange={(option) => {
                        if (!option) return;
                        props.onChange(getAnswerFromString(option.value.toString()));
                    }}
                    value={props.answer}
                    disabled={!isAuditEditable}
                    clearable={false}
                    searchable={false}
                />
            </FormRow>
        </React.Fragment>
    );
};

export default QuestionAndAnswer;

/** Interface containing new answer in a callback*/
interface QuestionWithAnswerWithCallback extends QuestionWithAnswer {
    onChange: (newAnswer: Answer) => void;
}
