import React, {useEffect} from "react";
import {DrugPackCategory} from "../../../../api/mm";
import {useDispatch} from "react-redux";
import {
    nullifyDrugPackCategoryStore,
    saveDrugPackCategory,
    setDrugPackCategory
} from "../../../../store/drugPackCategory/actions/DrugPackCategoryActions";
import {isDrugPackCategoryFormValid} from "../Helpers/drugPackCategoryFormHelpers";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {showSuccessToast} from "../../../../utils/toastUtils";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";

const EditDrugPackCategoryForm = (props: DrugPackCategory) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const {searchQueries} = usePageUrl();

    useEffect(() => {
        return function () {
            dispatch(nullifyDrugPackCategoryStore());
        };
    }, []);

    const save = async () => {
        const isValid = isDrugPackCategoryFormValid(props);

        if (!isValid) {
            return;
        }
        // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
        // @ts-ignore

        const saved: boolean = await dispatch(saveDrugPackCategory(props));

        if (!saved) {
            return;
        }

        back();

        showSuccessToast("Saved Drug Pack Category");
    };

    //Check for CID query. If it exists, append to the path
    const back = () => {
        const path = routeNames.drugPackCategoryList.path;

        history.push({
            pathname: path,
            search: searchQueries
        });
    };

    return (
        <React.Fragment>
            <FormHeader headerName={"Drug Pack Category Form"} />
            <FormRow rowName={"Drug Pack Category Name"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    value={props.name}
                    onChange={(event) => {
                        const {value} = event.target;

                        dispatch(
                            setDrugPackCategory({
                                ...props,
                                name: value
                            })
                        );
                    }}
                    className="input-fields"
                    placeholder="Enter drug pack category name here..."
                />
            </FormRow>
            <FormActionContainer>
                <React.Fragment>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={save}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={back}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </React.Fragment>
            </FormActionContainer>
        </React.Fragment>
    );
};

export default EditDrugPackCategoryForm;
