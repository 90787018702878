/** Interface to interact with the react-minimal-pie-chart package */
import {DataEntry} from "react-minimal-pie-chart/types/commonTypes";

export interface ChartData {
    percentageDataEntries: DataEntry[];
    rawValueDataEntries: DataEntry[];
    uids: string[];
    innerLabel: string;
    outerLabel: string;
    totalValue: number;
}

/** Generates donut stat (Single stat only) */
export function getDonutStat(
    innerLabel: string,
    outerLabel: string,
    leftVal: number,
    rightVal: number,
    colour: string
): DonutChart {
    const value = leftVal;

    //One segment of solid colour
    const data: DataEntry[] = [
        {
            title: outerLabel,
            value,
            color: colour
        }
    ];

    // Get the total value to be the same as the right value.
    const totalValue = rightVal === 0 ? 1 : rightVal;

    return {
        innerLabel,
        outerLabel,
        donutChart: data,
        totalValue
    };
}

/** Interface to interact with the react-minimal-pie-chart package */
export interface DonutChart {
    donutChart: DataEntry[];
    innerLabel: string;
    outerLabel: string;
    totalValue: number;
}
