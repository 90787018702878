import {
    ExpiryReportDispatchTypes,
    EXPIRY_REPORT_STORE_STATE,
    DrugExpiryRollout,
    DrugBatchExpiryWithDrugBatchDetails,
    DrugExpiry
} from "./ExpiryReportActionTypes";
import {Dispatch} from "redux";
import store from "../../Store";
import {
    DateRange,
    DrugBatch,
    DrugPack,
    DrugPackDrug,
    GeneralReportRequest,
    GeneralReportResponse
} from "../../../api/mm";
import {fetchGeneralReports} from "../../generalReports/actions/GeneralReportActions";
import {getRemainingStock} from "../../../components/Pages/StockManagementList/Helpers/stockManagementListHelpers";
import {calculateDrugPackDrugs} from "../../../utils/movementUtils";

export const nullifyExpiryReportStore = () => {
    return async (dispatch: Dispatch<ExpiryReportDispatchTypes>) => {
        dispatch({
            type: EXPIRY_REPORT_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
};

export const getExpiryReport = (request: GeneralReportRequest) => {
    return async (dispatch: Dispatch<ExpiryReportDispatchTypes>) => {
        try {
            dispatch({
                type: EXPIRY_REPORT_STORE_STATE.LOADING,
                error: null,
                loading: true
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const generalReports: GeneralReportResponse = await store.dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fetchGeneralReports(request)
            );

            if (!generalReports) {
                dispatch({
                    type: EXPIRY_REPORT_STORE_STATE.LOADING,
                    error: `Could not generate expiry reports.`,
                    loading: false
                });
                return;
            }

            dispatch({
                type: EXPIRY_REPORT_STORE_STATE.SUCCESS,
                error: null,
                loading: false,
                data: getExpiriesFromReportResponse(generalReports)
            });
        } catch (e: any) {
            dispatch({
                type: EXPIRY_REPORT_STORE_STATE.LOADING,
                error: e,
                loading: false
            });
        }
    };
};

export function getExpiriesFromReportResponse(
    response: GeneralReportResponse
): DrugExpiryRollout[] {
    const {drugBatches, expiryDate, drugPacks} = response;

    const expiringDrugs = drugBatches.filter((item) =>
        doesDrugExpireBetweenRanges(expiryDate, item)
    );
    const uniqueDrugNames = getUniqueDrugNames(expiringDrugs);

    return uniqueDrugNames.map((name) => {
        const uniqueFilteredExpiringBatches = expiringDrugs.filter((drug) => drug.name === name);
        return {
            drugName: name,
            drugPack: undefined,
            batch: undefined,
            expiryDate: undefined,
            quantity: getQuantity(uniqueFilteredExpiringBatches),
            drugs: expiringDrugList(sortByExpiryDateAsc(uniqueFilteredExpiringBatches), drugPacks)
        };
    });
}

function sortByExpiryDateAsc(drugs: DrugBatch[]): DrugBatch[] {
    return drugs.sort((a, b) => a.expiryDate - b.expiryDate);
}

export function getQuantity(batches: DrugBatch[]): TotalQuantity {
    return {
        inStock: getInStockAmount(batches),
        inDrugPacks: getAmountInDrugPacks(batches),
        administered: getAmountAdministered(batches),
        disposed: getAmountDisposed(batches),
        averageLowStock: getAverageLowStockQty(batches)
    };
}

export function getInStockAmount(batches: DrugBatch[]): number {
    return batches.reduce((a, b) => a + getRemainingStock(b), 0);
}

export function getAverageLowStockQty(batches: DrugBatch[]): number {
    return batches.reduce((a, b) => a + b.lowStockQty, 0) / batches.length;
}

export function getAmountInDrugPacks(batches: DrugBatch[]): number {
    let amount = 0;
    for (const batch of batches) {
        const {id, movements, administrations, disposals} = batch;
        const drugPackDrugsMovements = calculateDrugPackDrugs(id, movements, [], []);

        //We only care about drugs disposed and administered from a drug pack. So we filter them before processing them
        const filteredAdministrations = administrations.filter(
            (item) => item.drugPackId !== undefined && item.drugPackId > 0
        );
        const filteredDisposals = disposals.filter(
            (item) => item.drugPackId !== undefined && item.drugPackId > 0
        );

        const drugPackDrugsActions = calculateDrugPackDrugs(
            id,
            [],
            filteredAdministrations,
            filteredDisposals
        );

        //Get the amounts separately
        const movementAmount = addQuantitiesInDrugPackDrug(drugPackDrugsMovements);
        const actionAmount = addQuantitiesInDrugPackDrug(drugPackDrugsActions, false);
        amount += movementAmount + actionAmount;
    }
    return amount;
}

export function getAmountAdministered(batches: DrugBatch[]): number {
    let amount = 0;
    for (const batch of batches) {
        const {id, administrations} = batch;
        const drugPackAdministrations = calculateDrugPackDrugs(id, [], administrations, []);
        const administrationAmount = addQuantitiesInDrugPackDrug(drugPackAdministrations);

        amount += administrationAmount;
    }
    return amount;
}

export function getAmountDisposed(batches: DrugBatch[]): number {
    let amount = 0;
    for (const batch of batches) {
        const {id, disposals} = batch;
        const drugPackDisposals = calculateDrugPackDrugs(id, [], [], disposals);
        const disposalAmount = addQuantitiesInDrugPackDrug(drugPackDisposals);

        amount += disposalAmount;
    }
    return amount;
}

// Adds the qty of movements when calculating drug pack drugs. Force positive value by default. (Movements)
// else, administrations and disposals will need a negative amount
function addQuantitiesInDrugPackDrug(
    drugPackDrugs: DrugPackDrug[],
    forcePositiveValue = true
): number {
    if (forcePositiveValue) {
        return drugPackDrugs.reduce((a, b) => a + Math.abs(b.qty), 0);
    }
    return drugPackDrugs.reduce((a, b) => a + b.qty, 0);
}

/** Checks if drug expires between date range */
export function doesDrugExpireBetweenRanges(
    expiryDate: DateRange | undefined,
    drugBatch: DrugBatch
): boolean {
    if (!expiryDate) return false;
    return !(
        drugBatch.expiryDate < expiryDate.startDate || drugBatch.expiryDate > expiryDate.endDate
    );
}

function getUniqueDrugNames(drugBatches: DrugBatch[]): string[] {
    return toUniqueArray(drugBatches.map((drug) => drug.name));
}

function toUniqueArray<T>(incomingArray: T[]): T[] {
    return incomingArray.filter((item, i, array) => array.indexOf(item) === i);
}

export interface TotalQuantity {
    inStock: number;
    inDrugPacks: number;
    administered: number;
    disposed: number;
    averageLowStock: number;
}

function getDrugPackName(drugPackId: number | undefined, drugPacks: DrugPack[]): string {
    if (!drugPackId) {
        return "Stock";
    }
    const index = drugPacks.findIndex((el: DrugPack) => el.id === drugPackId);

    return index > -1 ? drugPacks[index].name : "Deleted Drug Pack";
}

//
// export function generateExpiryReport(data: GeneralReportResponse): ExpiryReport[] {
//     const expiries = getExpiriesFromReportResponse(data);
//     const {drugPacks} = data;
//
//     const expiryReport: ExpiryReport[] = [];
//     for (const expiry of expiries) {
//         let drugPackName = "";
//         if (!expiry.drugPackId) {
//             drugPackName = "Stock";
//         } else {
//             const index = drugPacks.findIndex((el: DrugPack) => el.id === expiry.drugPackId);
//
//             drugPackName = index > -1 ? drugPacks[index].name : "Deleted Drug Pack";
//         }
//
//         const {drugBatchName, batchNumber, expiryDate, drugBatchId, qty} = expiry;
//
//         expiryReport.push({
//             drugBatchName,
//             batchNumber,
//             drugBatchId,
//             drugPackId: expiry.drugPackId,
//             qty,
//             expiryDate,
//             drugPackName //If we can't find the drugPack name, the drug pack was most likely deleted.
//         });
//     }
//     //Return a sorted order of reports in expiry date ascending.
//     return expiryReport.sort((a, b) => {
//         if (a.expiryDate > b.expiryDate) {
//             return 1;
//         }
//         if (a.expiryDate < b.expiryDate) {
//             return -1;
//         }
//
//         return 0;
//     });
// }
//
function expiringDrugList(batches: DrugBatch[], drugPacks: DrugPack[]): DrugExpiry[] {
    const expiries: DrugBatchExpiryWithDrugBatchDetails[] = [];

    for (const drugBatch of batches) {
        //Push the OG drug to the list to account for stock count.
        expiries.push({
            drugBatchId: drugBatch.id,
            drugBatchName: drugBatch.name,
            expiryDate: drugBatch.expiryDate,
            batchNumber: drugBatch.batchNumber,
            qty: getRemainingStock(drugBatch) //Get accurate count of drugs remaining in stock.
        });

        //Get movements from drugBatch. Function makes it so there aren't any new entries when a movement is applied. will append new record.
        const drugPacksCalc = calculateDrugPackDrugs(drugBatch.id, drugBatch.movements, [], []);
        //iterate through all the movements calculated for this drug batch. Attach an absoluted qty and drug pack id to the expiry.
        for (const drugPack of drugPacksCalc) {
            expiries.push({
                drugBatchId: drugBatch.id,
                drugBatchName: drugBatch.name,
                expiryDate: drugBatch.expiryDate,
                batchNumber: drugBatch.batchNumber,
                qty: Math.abs(drugPack.qty),
                drugPackId: drugPack.drugPackId
            });
        }
    }
    return getDrugExpiry(expiries, drugPacks);
}

export function getDrugExpiry(
    expiries: DrugBatchExpiryWithDrugBatchDetails[],
    drugPacks: DrugPack[]
): DrugExpiry[] {
    const expiryReport: DrugExpiry[] = [];
    for (const expiry of expiries) {
        const drugPackName = getDrugPackName(expiry.drugPackId, drugPacks);

        const {drugBatchName, batchNumber, expiryDate, drugBatchId, qty} = expiry;

        expiryReport.push({
            drugBatchName,
            batchNumber,
            drugBatchId,
            drugPackId: expiry.drugPackId,
            qty,
            expiryDate,
            drugPackName //If we can't find the drugPack name, the drug pack was most likely deleted.
        });
    }
    //Return a sorted order of reports in expiry date ascending.
    return expiryReport;
}
