import {DrugPackCategory} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DRUG_PACK_CATEGORY_STORE = createStoreState("drug_pack_category");

interface DrugPackCategoryLoading extends StoreServiceData<DrugPackCategory> {
    type: typeof DRUG_PACK_CATEGORY_STORE.LOADING;
}
interface DrugPackCategoryError extends StoreServiceData<DrugPackCategory> {
    type: typeof DRUG_PACK_CATEGORY_STORE.ERROR;
}
interface DrugPackCategorySuccess extends StoreServiceData<DrugPackCategory> {
    type: typeof DRUG_PACK_CATEGORY_STORE.SUCCESS;
}

export type DrugPackCategoryDispatchTypes =
    | DrugPackCategoryLoading
    | DrugPackCategoryError
    | DrugPackCategorySuccess;
