import React from "react";
import Tooltip from "../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../Icon/Icon";
import {Modal, useModal} from "pulse-modal";
import {DrugBatchAudit} from "../Helpers/stockManagementAuditHelpers";
import AdministrationHistoryTable from "./AdministrationHistoryTable";

const ViewAdministrationHistory = (props: DrugBatchAudit) => {
    const {toggle, isShown} = useModal();
    return (
        <React.Fragment>
            <Tooltip
                tooltipText={"View Administration History"}
                place={"left"}
                theme={"dark"}
                size={"lg"}
            >
                <Icon
                    rootElement={"Span"}
                    icon={IconType.ViewHistory}
                    size={"Large"}
                    className={"cursor-pointer"}
                    onClick={toggle}
                />
            </Tooltip>
            <Modal
                isShown={isShown}
                onClose={toggle}
                modalSize={"xl"}
                title={"Administration History"}
                bodyChildren={
                    <React.Fragment>
                        {props.administration && props.administration.history && (
                            <AdministrationHistoryTable history={props.administration.history} />
                        )}
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default ViewAdministrationHistory;
