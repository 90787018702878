import React, {useEffect, useState} from "react";
import moment, {Moment} from "moment";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../../Button/DatePickerInputButton";
import {RequestFilterProps} from "../../../../Filters/helpers/filterHelpers";
import {useQuery} from "../../../../Hooks/useQuery";
import {GeneralReportType} from "../../Helpers/reportHelpers";

const ReportFilters = (props: Props<ReportFilterProps>) => {
    //Filters for creation date range and expiry date range.
    const [startDate, setStartDate] = useState<Moment>();
    const query = useQuery();
    useEffect(() => {
        const dds = query.get("DDS");
        const cds = query.get("CDS");
        const eds = query.get("EDS");
        const ads = query.get("ADS");
        // If Disposal date start exists, set start time to it and return
        if (dds) {
            setStartDate(moment.unix(+dds));
            return;
        }
        // If creation date start exists, set start time to it and return
        if (cds) {
            setStartDate(moment.unix(+cds));
            return;
        }
        // If expiry date start exists, set start time to it and return
        if (eds) {
            setStartDate(moment.unix(+eds));
            return;
        }
        // If administration date start exists, set start time to it and return
        if (ads) {
            setStartDate(moment.unix(+ads));
            return;
        }

        setStartDate(moment());
    }, []);

    useEffect(() => {
        if (!startDate) return;
        props.onRequestChanged({
            startTime: startDate.clone().startOf("month"),
            endTime: startDate.clone().endOf("month")
        });
    }, [startDate]);

    const getLabelHeading = () => {
        switch (props.reportType) {
            case GeneralReportType.Disposal:
                return "Disposal Date Range";
            case GeneralReportType.Administration:
                return "Administration Date Range";
            case GeneralReportType.Status:
                return "Created Date Range";
            case GeneralReportType.Expiry:
                return "Expiry Date Range";
        }
    };
    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>{getLabelHeading()}</h6>
                    <DatePicker
                        selected={startDate?.toDate()}
                        onChange={(date) => {
                            setStartDate(moment(date));
                        }}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                        portalId="root-portal"
                        customInput={<DatePickerInputButton />}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default ReportFilters;

export interface ReportFilterProps {
    startTime: Moment;
    endTime: Moment;
}

interface Props<T> extends RequestFilterProps<T> {
    reportType: GeneralReportType;
}
