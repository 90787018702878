import {DrugBatch} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const ADMINISTER_DRUGS_STORE = createStoreState("administer_drugs");

interface AdministerDrugsLoading extends StoreServiceData<DrugBatch> {
    type: typeof ADMINISTER_DRUGS_STORE.LOADING;
}
interface AdministerDrugsError extends StoreServiceData<DrugBatch> {
    type: typeof ADMINISTER_DRUGS_STORE.ERROR;
}
interface AdministerDrugsSuccess extends StoreServiceData<DrugBatch> {
    type: typeof ADMINISTER_DRUGS_STORE.SUCCESS;
}

export type AdministerDrugsDispatchTypes =
    | AdministerDrugsSuccess
    | AdministerDrugsError
    | AdministerDrugsLoading;
