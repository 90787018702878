import {
    DrugBatch,
    DrugBatchAdministration,
    DrugBatchDisposal,
    DrugBatchMovement,
    DrugPack,
    GrsLocation
} from "../../../../api/mm";

export interface DrugBatchAudit {
    nominalMovementId: string;
    date: number;
    staffId: string;
    staffName: string;
    grsLocation?: GrsLocation;
    actionType: ActionType;
    qty: number;
    drugPackName: string;
    drugPackId: number;
    drugBatchId: number;
    comments?: string;
    movement?: DrugBatchMovement;
    administration?: DrugBatchAdministration;
    disposal?: DrugBatchDisposal;
}

// eslint-disable-next-line no-shadow
export enum ActionType {
    Disposal = "Disposal",
    Administration = "Administration",
    MoveToStock = "Move To Stock",
    MoveToDrugPack = "Move To Drug Pack",
    MoveToAnotherDrugPack = "Move To Another Drug Pack"
}

//Converts all actions in that drug batch.
export function getAllActionsForDrugBatchAudit(
    drugBatch: DrugBatch,
    drugPacks: DrugPack[]
): DrugBatchAudit[] {
    const auditActions: DrugBatchAudit[] = [];
    const {movements, administrations, disposals} = drugBatch;

    //Only check for one or the other. As they cannot move without either the toDrugPackId or fromDrugPackId from being populated.
    for (const movement of movements) {
        if (movement.toDrugPackId) {
            const drugPackName = getDrugPackName(movement.toDrugPackId, drugPacks);

            auditActions.push({
                nominalMovementId: movement.id,
                date: movement.date,
                staffId: movement.staffId,
                staffName: movement.staffName,
                actionType: ActionType.MoveToDrugPack,
                qty: movement.qty,
                drugPackName,
                drugPackId: movement.toDrugPackId,
                drugBatchId: drugBatch.id,
                comments: movement.comments,
                movement
            });
        }
        if (movement.fromDrugPackId) {
            const fromDrugPackName = getDrugPackName(movement.fromDrugPackId, drugPacks);
            const toDrugPackName = movement.toDrugPackId
                ? getDrugPackName(movement.toDrugPackId, drugPacks)
                : undefined;

            const drugPackName = `${fromDrugPackName} ${
                toDrugPackName ? ` -> ${toDrugPackName}` : undefined
            }`;
            const action = movement.toDrugPackId
                ? ActionType.MoveToAnotherDrugPack
                : ActionType.MoveToStock;

            auditActions.push({
                nominalMovementId: movement.id,
                date: movement.date,
                staffId: movement.staffId,
                staffName: movement.staffName,
                actionType: action,
                qty: movement.qty,
                drugPackName,
                drugPackId: movement.fromDrugPackId,
                drugBatchId: drugBatch.id,
                comments: movement.comments,
                movement
            });
        }
    }
    auditActions.push(...getAdministrations(administrations, drugPacks, drugBatch));
    auditActions.push(...getDisposals(disposals, drugPacks, drugBatch));
    //Sort by DrugPackId descending
    return auditActions.sort((a, b) => b.date - a.date);
}

function getAdministrations(
    administrations: DrugBatchAdministration[],
    drugPacks: DrugPack[],
    drugBatch: DrugBatch
): DrugBatchAudit[] {
    return administrations.map((administration) => {
        const drugPackName = getDrugPackName(administration.drugPackId || 0, drugPacks);
        return {
            nominalMovementId: administration.id,
            date: administration.date,
            staffId: administration.staffId
                ? administration.staffId
                : "No Staff Member was selected",
            staffName: administration.staffName
                ? administration.staffName
                : "No Staff Member was selected",
            actionType: ActionType.Administration,
            qty: administration.qty,
            grsLocation: administration.grsLocation,
            drugPackName,
            drugPackId: administration.drugPackId || 0,
            drugBatchId: drugBatch.id,
            comments: administration.comments,
            administration
        };
    });
}

function getDisposals(
    disposals: DrugBatchDisposal[],
    drugPacks: DrugPack[],
    drugBatch: DrugBatch
): DrugBatchAudit[] {
    return disposals.map((disposal) => {
        const drugPackName = getDrugPackName(disposal.drugPackId || 0, drugPacks);

        return {
            nominalMovementId: disposal.id,
            date: disposal.date,
            staffId: disposal.staffId,
            staffName: disposal.staffName,
            actionType: ActionType.Disposal,
            qty: disposal.qty,
            grsLocation: disposal.grsLocation,
            drugPackName,
            drugPackId: disposal.drugPackId || 0,
            drugBatchId: drugBatch.id,
            comments: disposal.comments,
            disposal
        };
    });
}

export function getDrugPackName(id: number, drugPacks: DrugPack[]) {
    if (id === 0) {
        return "Stock";
    }

    const index = drugPacks.findIndex((el: DrugPack) => el.id === id);
    return index > -1 ? drugPacks[index].name : "Deleted Drug Pack";
}
