import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useParams} from "react-router-dom";
import {
    fetchDrugPackById,
    nullifyDrugPacksWithContentsStore
} from "../../../store/drugPackWithContents/actions/DrugPackWithContentsActions";
import EditDrugPackWithContentsForm from "./Components/EditDrugPackWithContentsForm";
import {WithServiceState} from "store-fetch-wrappers";
import {usePageUrl} from "../../Hooks/usePageUrl";

const ServiceWrapper = WithServiceState(EditDrugPackWithContentsForm);

const EditDrugPackWithContents = () => {
    const dispatch = useDispatch();
    const drugPackWithContentsStore = useSelector((state: RootStore) => state.drugPackWithContents);
    const params: any = useParams();
    const {fullPath} = usePageUrl();
    const {searchQueries} = usePageUrl();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: `${routeNames.medicineManagement.path}${searchQueries}`
            },
            {
                name: routeNames.drugPackWithContentsEdit.name,
                path: fullPath
            }
        ];

        dispatch(setNavigationItems(nav));

        dispatch(fetchDrugPackById(+params.id));

        return async function () {
            dispatch(nullifyDrugPacksWithContentsStore());
        };
    }, []);

    return (
        <div className="mt-4 page-container">
            <ServiceWrapper
                showLoadingText={true}
                loaderType={"overlay"}
                loaderWheelType={"three-ring"}
                placeholderBlockCount={1}
                {...drugPackWithContentsStore}
            />
        </div>
    );
};

export default EditDrugPackWithContents;
