import React from "react";
import {DrugPackWithContents} from "../../../../api/mm";

import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import DrugPackCategoryDropdown from "../../../Dropdown/DrugPackCategoryDropdown";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import LocalDeleteActionButton from "../../../Button/LocalDeleteActionButton";
import DrugPackWithContentsDrugTable from "./DrugPackWithContentsDrugTable";
import LocationDropdown from "../../../Dropdown/LocationDropdown";
import {useEditDrugPackWithContentsForm} from "../Hooks/useEditDrugPackWithContentsForm";

const EditDrugPackWithContentsForm = (props: DrugPackWithContents) => {
    const {updateDrugPack, save, onConfirmDeletion, backToMedicineManagement} =
        useEditDrugPackWithContentsForm(props);

    return (
        <React.Fragment>
            <FormHeader headerName={"Drug Pack Form"} />
            <FormRow rowName={"Drug Pack Name"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    value={props.name}
                    onChange={(event) => {
                        const {value} = event.target;
                        updateDrugPack({
                            ...props,
                            name: value
                        });
                    }}
                    className="input-fields"
                    placeholder="Enter drug pack name here..."
                />
            </FormRow>
            <FormRow rowName={"Seal Number"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    value={props.sealNumber}
                    onChange={(event) => {
                        const {value} = event.target;
                        updateDrugPack({
                            ...props,
                            sealNumber: value
                        });
                    }}
                    className="input-fields"
                    placeholder="Enter seal number here..."
                />
            </FormRow>
            <FormRow rowName={"Location"} columnDetailClassName={"pl-0 pr-0"}>
                <LocationDropdown
                    clearable={true}
                    onChange={(value) => {
                        updateDrugPack({
                            ...props,
                            locationId: value ? value.id : undefined
                        });
                    }}
                    locationId={props.locationId || -1}
                    disabled={false}
                    appendToBody
                />
            </FormRow>
            <FormRow rowName={"Drug Pack Category"} columnDetailClassName={"pl-0 pr-0"}>
                <DrugPackCategoryDropdown
                    clearable={false}
                    searchable={false}
                    onChange={(category) => {
                        if (!category) return;
                        updateDrugPack({
                            ...props,
                            categoryId: category.id
                        });
                    }}
                    disabled={false}
                    drugPackCategoryId={props.categoryId}
                />
            </FormRow>
            {props.id > 0 && <DrugPackWithContentsDrugTable {...props} />}
            <FormActionContainer>
                <React.Fragment>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={async () => await save(props)}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={backToMedicineManagement}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                    {props.id > 0 && (
                        <LocalDeleteActionButton
                            buttonText={"Delete"}
                            action={async () => onConfirmDeletion(props)}
                            itemName={props.name}
                        />
                    )}
                </React.Fragment>
            </FormActionContainer>
        </React.Fragment>
    );
};

export default EditDrugPackWithContentsForm;
