import React, {useEffect, useState} from "react";
import {triggerPrint} from "./helpers/printableHelpers";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import PrintFormWrapper from "./Components/PrintFormWrapper";

const PrintButton = ({
    toPrint,
    printButtonText,
    reportHeaderText,
    buttonColour,
    footerMargin,
    hideHeader
}: PrintButtonProps) => {
    const [print, setPrint] = useState<boolean>(false);

    useEffect(() => {
        const content = document.getElementById("printable");
        if (!content) return;
        triggerPrint(content);
        setTimeout(() => {
            setPrint(false);
        }, 1500);
    }, [print]);
    return (
        <React.Fragment>
            <MCButton
                size={ButtonSize.Large}
                roundedCorner
                innerValue={printButtonText}
                onClick={() => setPrint(true)}
                colour={buttonColour || ButtonColourOptions.Yellow}
            />
            {print && (
                <div id="printable" className="d-none">
                    <PrintFormWrapper
                        footerMargin={footerMargin}
                        reportHeaderText={reportHeaderText}
                        hideHeader={hideHeader}
                    >
                        {toPrint}
                    </PrintFormWrapper>
                </div>
            )}
        </React.Fragment>
    );
};

export default PrintButton;

interface PrintButtonProps {
    toPrint: JSX.Element;
    printButtonText: string;
    reportHeaderText: string;
    buttonColour?: ButtonColourOptions;
    footerMargin?: string;
    hideHeader?: boolean;
}
