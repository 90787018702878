import {Dispatch} from "redux";
import {LOCATION_LIST_STORE, LocationListDispatchTypes} from "./LocationListActionsTypes";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const nullifyLocationListStore = () => {
    return async (dispatch: Dispatch<LocationListDispatchTypes>) => {
        dispatch({
            type: LOCATION_LIST_STORE.ERROR,
            loading: false,
            error: null,
            data: null
        });
    };
};

export const getAuditLocations = () => {
    return async (dispatch: Dispatch<LocationListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                LOCATION_LIST_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getAllAuditLocations(),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: LOCATION_LIST_STORE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
