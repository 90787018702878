import {DRUG_PACK_CATEGORY_LIST_STORE} from "../actions/DrugPackCategoryListActionTypes";
import {DrugPackCategory} from "../../../api/mm";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugPackCategory[]>([]);

const drugPackCategoryListReducer = (
    state: StoreServiceData<DrugPackCategory[]> = defaultState,
    action: ServiceActionTypes<DrugPackCategory[]>
): StoreServiceData<DrugPackCategory[]> =>
    createReducer(state, action, DRUG_PACK_CATEGORY_LIST_STORE, () => showErrorToast(action.error));

export default drugPackCategoryListReducer;
