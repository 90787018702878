import {Venue} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const VENUE_LIST_STORE = createStoreState("venue_list");

interface VenueListStoreLoading extends StoreServiceData<Venue[]> {
    type: typeof VENUE_LIST_STORE.LOADING;
}
interface VenueListStoreError extends StoreServiceData<Venue[]> {
    type: typeof VENUE_LIST_STORE.ERROR;
}
interface VenueListStoreSuccess extends StoreServiceData<Venue[]> {
    type: typeof VENUE_LIST_STORE.SUCCESS;
}

export type VenueListDispatchTypes =
    | VenueListStoreError
    | VenueListStoreSuccess
    | VenueListStoreLoading;
