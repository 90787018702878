import {DrugPackWithContents} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DRUG_PACK_WITH_CONTENTS_LIST_STORE = createStoreState("drug_pack_with_contents_list");

interface DrugPackWithContentsListLoading extends StoreServiceData<DrugPackWithContents[]> {
    type: typeof DRUG_PACK_WITH_CONTENTS_LIST_STORE.LOADING;
}
interface DrugPackWithContentsListError extends StoreServiceData<DrugPackWithContents[]> {
    type: typeof DRUG_PACK_WITH_CONTENTS_LIST_STORE.ERROR;
}
interface DrugPackWithContentsListSuccess extends StoreServiceData<DrugPackWithContents[]> {
    type: typeof DRUG_PACK_WITH_CONTENTS_LIST_STORE.SUCCESS;
}

export type DrugPackWithContentsListDispatchTypes =
    | DrugPackWithContentsListLoading
    | DrugPackWithContentsListError
    | DrugPackWithContentsListSuccess;
