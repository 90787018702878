import {Location} from "../../../api/mm";
import {LOCATION_STORE} from "../actions/LocationActionsTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<Location>(null);

const locationReducer = (
    state: StoreServiceData<Location> = defaultState,
    action: ServiceActionTypes<Location>
): StoreServiceData<Location> =>
    createReducer<Location>(state, action, LOCATION_STORE, () => showErrorToast(action.error));

export default locationReducer;
