import React, {useState} from "react";
import {
    DrugBatchAction,
    StatusReport
} from "../../../../../../store/statusReport/actions/StatusReportActionTypes";
import FilterContainer from "../../../../../Filters/FilterContainer";
import ReportFilters, {ReportFilterProps} from "../../Components/ReportFilters";
import {
    GeneralReportType,
    generateReportRequest,
    getQueryStringsForGeneralReportRequest
} from "../../../Helpers/reportHelpers";
import {useDispatch} from "react-redux";
import {useHistory} from "react-router-dom";
import {GeneralReportRequest} from "../../../../../../api/mm";
import {routeNames} from "../../../../../Navigation/routeNames";
import {getStatusReport} from "../../../../../../store/statusReport/actions/StatusReportActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../../../Button/MCButton";
import FormActionContainer from "../../../../../Form/FormActionContainer";
import {toArray} from "../../../../../../utils/sortingUtils";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../../Table/NoItemsMessage";
import {formatUnixToDDMMYYYY} from "../../../../../../utils/momentUtils";
import {getLocationForReport} from "../../Disposal/Components/DisposalReportTable";
import PrintButton from "../../../../../Print/PrintButton";
import PrintStatusReport from "../Print/PrintStatusReport";

const StatusReportTable = (props: StatusReport[]) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [reportRequest, setReportRequest] = useState<GeneralReportRequest>();

    const onRequestChanged = (filters: ReportFilterProps) => {
        const request = generateReportRequest(
            GeneralReportType.Status,
            filters.startTime,
            filters.endTime
        );

        if (!reportRequest) {
            setReportRequest(request);
            fetchData(request);
            return;
        }

        if (JSON.stringify(request) === JSON.stringify(reportRequest)) return;

        setReportRequest(request);
        fetchData(request);
    };

    // Fetches Audits
    const fetchData = (request: GeneralReportRequest | undefined) => {
        if (!request) return;
        const search = getQueryStringsForGeneralReportRequest(request);
        history.push({search});
        dispatch(getStatusReport(request));
    };

    const backToReports = () => {
        history.replace(routeNames.generalReports.path);
    };

    return (
        <React.Fragment>
            <FilterContainer closed={false}>
                <ReportFilters
                    reportType={GeneralReportType.Status}
                    onRequestChanged={onRequestChanged}
                />
            </FilterContainer>
            <div className="mt-4">
                {toArray(props).length > 0 && (
                    <FormActionContainer>
                        <div className="col pr-0 pl-0 d-flex justify-content-end">
                            <PrintButton
                                toPrint={<PrintStatusReport {...props} />}
                                printButtonText={"Print Status Report"}
                                reportHeaderText={"Status Report"}
                            />
                        </div>
                    </FormActionContainer>
                )}
                <PulseTable
                    items={toTableRow(toArray(props))}
                    headers={{
                        drugName: "Drug Name",
                        batchNumber: "Batch Number",
                        creationDate: "Creation Date",
                        expiryDate: "Expiry Date",
                        qty: "Qty",
                        status: "Status"
                    }}
                    customRenderers={{
                        status: (item: TableRow) => generateStatusMarkup(item.status)
                    }}
                    noItemsSection={
                        <NoItemsMessage
                            message={"There are no status reports recorded for this time frame."}
                        />
                    }
                />
            </div>
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Back"}
                    onClick={backToReports}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default StatusReportTable;

interface TableRow {
    drugName: string;
    batchNumber: string;
    creationDate: string;
    expiryDate: string;
    qty: number;
    status: StatusReport;
}

function toTableRow(props: StatusReport[]): TableRow[] {
    return props.map((item) => {
        return {
            drugName: item.drugBatchName,
            batchNumber: item.batchNumber,
            creationDate: formatUnixToDDMMYYYY(item.creationDate),
            expiryDate: formatUnixToDDMMYYYY(item.expiryDate),
            qty: item.qty,
            status: item
        };
    });
}

function generateStatusMarkup(status: StatusReport): JSX.Element {
    switch (status.action) {
        case DrugBatchAction.Stock:
            return <h6 className="mb-0">In Stock</h6>;
        case DrugBatchAction.MovedBetweenDrugPacks:
            return (
                <React.Fragment>
                    <div className="status-info">
                        <h6 className="mb-0">Moved between Drug Packs by</h6>
                        <p className="mb-0">{status.actionedBy?.staffName}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Drug Pack</h6>
                        <p className="mb-0">{status.drugPackName}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Comments?</h6>
                        <p className="mb-0">{status.comments || "N/A"}</p>
                    </div>
                </React.Fragment>
            );
        case DrugBatchAction.AddedToDrugPack:
            return (
                <React.Fragment>
                    <div className="status-info">
                        <h6 className="mb-0">Added to Drug Pack by</h6>
                        <p className="mb-0">{status.actionedBy?.staffName}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Drug Pack</h6>
                        <p className="mb-0">{status.drugPackName}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Comments?</h6>
                        <p className="mb-0">{status.comments || "N/A"}</p>
                    </div>
                </React.Fragment>
            );
        case DrugBatchAction.RemovedFromDrugPack:
            return (
                <React.Fragment>
                    <div className="status-info">
                        <h6 className="mb-0">Removed from Drug Pack by</h6>
                        <p className="mb-0">{status.actionedBy?.staffName}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Drug Pack</h6>
                        <p className="mb-0">{status.drugPackName}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Comments?</h6>
                        <p className="mb-0">{status.comments || "N/A"}</p>
                    </div>
                </React.Fragment>
            );
        case DrugBatchAction.Administered:
            return (
                <React.Fragment>
                    <div className="status-info">
                        <h6 className="mb-0">Administered by</h6>
                        <p className="mb-0">{status.actionedBy?.staffName}</p>
                    </div>

                    {status.witnessedBy && (
                        <div className="status-info">
                            <h6 className="mb-0">Witnessed by</h6>
                            <p className="mb-0">{status.witnessedBy?.staffName}</p>
                        </div>
                    )}
                    <div className="status-info">
                        <h6 className="mb-0">Administered from</h6>
                        <p className="mb-0">{status.drugPackName}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Location</h6>
                        <p className="mb-0">{getLocationForReport(status.grsLocation)}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Quality Assured</h6>
                        <p className="mb-0">{status.qa ? "Yes" : "No"}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Dosage</h6>
                        <p className="mb-0">{status.dosage || "Dosage not specified"}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Wastage</h6>
                        <p className="mb-0">{status.wastage || "Wastage not specified"}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Route of Administration</h6>
                        <p className="mb-0">{status.administrationMethod || "N/A"}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Comments?</h6>
                        <p className="mb-0">{status.comments || "N/A"}</p>
                    </div>
                </React.Fragment>
            );
        case DrugBatchAction.Disposed:
            return (
                <React.Fragment>
                    <div className="status-info">
                        <h6 className="mb-0">Disposed by</h6>
                        <p className="mb-0">{status.actionedBy?.staffName}</p>
                    </div>

                    {status.witnessedBy && (
                        <div className="status-info">
                            <h6 className="mb-0">Witnessed by</h6>
                            <p className="mb-0">{status.witnessedBy?.staffName}</p>
                        </div>
                    )}
                    <div className="status-info">
                        <h6 className="mb-0">Disposed from</h6>
                        <p className="mb-0">{status.drugPackName}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Location</h6>
                        <p className="mb-0">{getLocationForReport(status.grsLocation)}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Broken</h6>
                        <p className="mb-0">{status.broken ? "Yes" : "No"}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Denatured</h6>
                        <p className="mb-0">{status.denatured ? "Yes" : "No"}</p>
                    </div>
                    <div className="status-info">
                        <h6 className="mb-0">Comments?</h6>
                        <p className="mb-0">{status.comments || "N/A"}</p>
                    </div>
                </React.Fragment>
            );
    }
}
