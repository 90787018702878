import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";
import {useEffect, useState} from "react";
import {
    DDProps,
    DropdownOption,
    getSelectedDropdownOption,
    sortUserDataToDropdownProps,
    getSelectedDropdownOptionByValue,
    StaffDropdownProps
} from "../Helpers/dropdownUtils";
import {SingleValue} from "react-select";
import {UserData} from "../../../api/staff";
import {getStaffLinkOrNull} from "./useEntryAssignedDropdown";

export function useStaffDropdown(props: StaffDropdownProps) {
    const [options, setOptions] = useState<DropdownOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<DropdownOption | undefined>(undefined);

    const staffListStore = useSelector((state: RootStore) => state.staffList);

    /** Get the staff list upon mounting */
    useEffect(() => {
        if (!staffListStore.data) return;
        processStaffListIncoming(getFilteredStaffList(staffListStore.data));
    }, [staffListStore.data]);

    /** Get the staff list upon mounting */
    useEffect(() => {
        const found = getSelectedDropdownOption(props.username, options);
        setSelectedOption(found);
    }, [props.username, options]);

    const getFilteredStaffList = (staffList: UserData[]) => {
        if (props.accessLevelFilter) {
            return staffList.filter(
                (item: UserData) => item.accessLevel === props.accessLevelFilter
            );
        }
        return staffList;
    };

    /** Sort the staff list into dropdown props */
    const processStaffListIncoming = (users: UserData[]) => {
        const generated = sortUserDataToDropdownProps(users);
        setOptions(generated);

        if (options.length === 0 || props.clearable) {
            setSelectedOption(undefined);
            return;
        }
        const option = getSelectedDropdownOptionByValue(props.username, options);
        if (!option) {
            setSelectedOption(undefined);
            return;
        }
        setSelectedOption(option);
    };

    function handleOptionChange(newValue: SingleValue<DDProps>) {
        if (!newValue) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        //Using the data from the staffList
        const option = getSelectedDropdownOption(newValue.value, options);
        setSelectedOption(option);
        const staffLink = getStaffLinkOrNull(option);
        props.changeOption(staffLink);
    }

    return {
        selectedOption,
        handleOptionChange,
        options,
        staffListStore
    };
}
