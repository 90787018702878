import React from "react";
import FormRow from "../../Form/FormRow";
import {useStaffWitnessSection} from "./Hooks/useStaffWitnessSection";
import StaffDropdown from "../../Dropdown/StaffDropdown";
import {NominalActionType} from "./StaffWitnessWithLocationSection";

function StaffWitnessSection<T extends StaffWitnessEntrySectionProps>({
    action,
    updateRequest,
    request
}: Props<T>) {
    const {getMainStaffHeader} = useStaffWitnessSection();
    return (
        <React.Fragment>
            <FormRow rowName={getMainStaffHeader(action)} columnDetailClassName={"pl-0 pr-0"}>
                <StaffDropdown
                    username={request?.staffId}
                    searchable={true}
                    clearable={true}
                    changeOption={(staffMember) => {
                        updateRequest({
                            ...request,
                            staffId: staffMember?.staffId,
                            staffName: staffMember?.staffName
                        });
                    }}
                />
            </FormRow>
            <FormRow rowName={"Witnessed by?"} columnDetailClassName={"pl-0 pr-0"}>
                <StaffDropdown
                    username={request.witnessId}
                    searchable={true}
                    clearable={true}
                    changeOption={(witness) => {
                        updateRequest({
                            ...request,
                            witnessId: witness?.staffId,
                            witnessName: witness?.staffName
                        });
                    }}
                />
            </FormRow>
        </React.Fragment>
    );
}
export default StaffWitnessSection;

interface Props<T> {
    request: T;
    clearable?: boolean;
    updateRequest: (updated: T) => void;
    action: NominalActionType;
}

interface StaffWitnessEntrySectionProps {
    staffId?: string | undefined;
    staffName?: string | undefined;
    witnessId?: string | undefined;
    witnessName?: string | undefined;
}
