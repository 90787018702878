import React from "react";
import {AdministrationReport} from "../../../../../../../store/administrationReports/actions/AdministrationReportsActionTypes";
import {formatUnixToDDMMYYYY, formatUnixToLLL} from "../../../../../../../utils/momentUtils";
import {getLocationForReport} from "../../../Disposal/Components/DisposalReportTable";
import Tooltip from "../../../../../../Tooltip/Tooltip";
import Icon, {IconType} from "../../../../../../Icon/Icon";
import {routeNames} from "../../../../../../Navigation/routeNames";
import {usePageUrl} from "../../../../../../Hooks/usePageUrl";
import {DrugBatchAdministrationChangeRecord} from "../../../../../../../api/mm";

const Header = () => {
    return (
        <tr className="pulse-table-header">
            <th align="left" className="pulse-table-header-item">
                Drug Batch
            </th>
            <th align="left" className="pulse-table-header-item">
                Drug Pack
            </th>
            <th align="left" className="pulse-table-header-item">
                Administration Date
            </th>
            <th align="left" className="pulse-table-header-item">
                Qty
            </th>
            <th align="left" className="pulse-table-header-item">
                Location
            </th>
            <th align="left" className="pulse-table-header-item">
                Administered By
            </th>
            <th align="left" className="pulse-table-header-item">
                Witnessed By
            </th>
            <th align="left" className="pulse-table-header-item">
                Quality Assured
            </th>
            <th align="left" className="pulse-table-header-item">
                Dosage
            </th>
            <th align="left" className="pulse-table-header-item">
                Wastage
            </th>
            <th align="left" className="pulse-table-header-item">
                Route of Administration
            </th>
            <th align="left" className="pulse-table-header-item">
                Actions
            </th>
        </tr>
    );
};

const Row = (props: AdministrationReport) => {
    const {searchQueries} = usePageUrl();
    const goToStockAudit = () => {
        const path = `${routeNames.stockManagementAudit.path}/${props.drugBatchId}`;

        const search = `${searchQueries}`;
        window.open(`${path}${search}`);
    };
    return (
        <React.Fragment>
            <tr className="pulse-table-row">
                <td align="left" className="pulse-table-row-item">
                    <h6 className="mb-0">{props.drugBatchName}</h6>
                    <p className="mb-0"> ({props.batchNumber})</p>
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.drugPackName}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {formatUnixToDDMMYYYY(props.administrationDate)}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.qty}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {getLocationForReport(props.location)}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.staffMember.staffName}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.witness ? <span>{props.witness.staffName}</span> : <span> N/A </span>}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.qa ? <span>Yes</span> : <span> No </span>}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.dosage ? <span>{props.dosage}</span> : <span>Dosage not specified</span>}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.wastage ? (
                        <span>{props.wastage}</span>
                    ) : (
                        <span>Wastage not specified</span>
                    )}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.routeAdministration}
                </td>
                <td align="left" className="pulse-table-row-item">
                    <Tooltip
                        tooltipText={`View ${props.drugBatchName} (${props.batchNumber}) Audit History`}
                        size={"lg"}
                        place={"left"}
                        theme={"dark"}
                    >
                        <Icon
                            rootElement={"Span"}
                            icon={IconType.Eye}
                            className="cursor-pointer"
                            size={"Large"}
                            onClick={goToStockAudit}
                        />
                    </Tooltip>
                </td>
            </tr>
            {props.history && props.history.length > 0 && (
                <React.Fragment>
                    <HistoryHeader />
                    {props.history.map((item, index) => {
                        return <HistoryRow {...item} key={index} />;
                    })}
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

const HistoryHeader = () => {
    return (
        <tr className="pulse-table-header">
            <th align="left" className="pulse-table-header-item">
                History:
            </th>
            <th align="left" className="pulse-table-header-item">
                Change By
            </th>
            <th align="left" className="pulse-table-header-item">
                Administration Date Change
            </th>
            <th align="left" className="pulse-table-header-item">
                Qty Change
            </th>
            <th align="left" className="pulse-table-header-item">
                Location Change
            </th>
            <th align="left" className="pulse-table-header-item">
                Administered By Change
            </th>
            <th align="left" className="pulse-table-header-item">
                Witnessed By Change
            </th>
            <th align="left" className="pulse-table-header-item">
                Quality Assured Change
            </th>
            <th align="left" className="pulse-table-header-item">
                Dosage Change
            </th>
            <th align="left" className="pulse-table-header-item">
                Wastage Change
            </th>
            <th align="left" className="pulse-table-header-item">
                Route of Administration Change
            </th>
            <th align="left" className="pulse-table-header-item" />
        </tr>
    );
};

const HistoryRow = (props: DrugBatchAdministrationChangeRecord) => {
    return (
        <React.Fragment>
            <tr className="pulse-table-row">
                <td align="left" className="pulse-table-row-item">
                    <Icon rootElement={"Span"} icon={IconType.ViewHistory} size={"Large"} />
                </td>
                <td align="left" className="pulse-table-row-item">
                    <h6 className="mb-0">{props.changedByStaffName}</h6>
                    <p className="mb-0">{formatUnixToLLL(props.changedDate)}</p>
                </td>
                <td align="left" className="pulse-table-row-item">
                    {formatUnixToDDMMYYYY(props.changedDate)}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.qty}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {getLocationForReport(props.grsLocation)}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.staffName ? (
                        <span>{props.staffName}</span>
                    ) : (
                        <span>Staff Member Not Selected</span>
                    )}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.witnessName ? (
                        <span>{props.witnessName}</span>
                    ) : (
                        <span>Staff Member Not Selected</span>
                    )}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.qa ? <span>Yes</span> : <span>No</span>}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.dosage ? <span>{props.dosage}</span> : <span>Dosage not specified</span>}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.wastage ? (
                        <span>{props.wastage}</span>
                    ) : (
                        <span>Wastage not specified</span>
                    )}
                </td>
                <td align="left" className="pulse-table-row-item">
                    {props.routeAdministration}
                </td>
                <td align="left" className="pulse-table-row-item" />
            </tr>
        </React.Fragment>
    );
};

export default {Header, Row};
