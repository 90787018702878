import React from "react";
import {DrugAdministrationMethod, DrugBatchAdministration} from "../../../../api/mm";
import FormRow from "../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import Toggle, {ToggleSize} from "../../../Toggle/Toggle";
import PulseEnumDropdown from "../../../Dropdown/PulseEnumDropdown";
import {getRouteOfAdministrationFromString} from "../../../../utils/enumUtils";

function AdministrationStage2Fields({administration, onChange}: Props) {
    return (
        <React.Fragment>
            <FormRow rowName={"Route of Administration"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseEnumDropdown
                    enumOptions={DrugAdministrationMethod}
                    onChange={(option) => {
                        if (!option) return;
                        const routeAdministration = getRouteOfAdministrationFromString(
                            option.value.toString()
                        );

                        onChange({
                            ...administration,
                            routeAdministration
                        });
                    }}
                    value={administration.routeAdministration}
                    disabled={false}
                    clearable={false}
                    searchable={false}
                />
            </FormRow>
            <FormRow rowName={"Dosage?"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    value={administration.dosage}
                    onChange={(event) => {
                        const {value} = event.target;
                        onChange({
                            ...administration,
                            dosage: value
                        });
                    }}
                    className="input-fields"
                    placeholder="Enter dosage here..."
                />
            </FormRow>
            <FormRow rowName={"Wastage?"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    value={administration.wastage}
                    onChange={(event) => {
                        const {value} = event.target;
                        onChange({
                            ...administration,
                            wastage: value
                        });
                    }}
                    className="input-fields"
                    placeholder="Enter wastage here..."
                />
            </FormRow>
            <FormRow rowName={"Quality Assured?"}>
                <Toggle
                    checked={administration.qa}
                    onChange={() => {
                        let toggled = administration.qa;

                        onChange({
                            ...administration,
                            qa: (toggled = !toggled)
                        });
                    }}
                    size={ToggleSize.Large}
                />
            </FormRow>
        </React.Fragment>
    );
}

export default AdministrationStage2Fields;

interface Props {
    administration: DrugBatchAdministration;
    onChange: (entity: DrugBatchAdministration) => void;
}
