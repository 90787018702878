import {ComponentChildrenProps} from "../../utils/componentUtils";
import React from "react";
import Tooltip from "../Tooltip/Tooltip";
import Icon, {IconType} from "../Icon/Icon";

const FormRowWithAction = ({
    children,
    rowName,
    rowClassName,
    formRowClassName,
    columnHeaderClassName,
    columnDetailClassName,
    icon,
    showIcon,
    onClick,
    tooltipText
}: FormRowWithActionProps) => {
    return (
        <div className={`mc-form-row ${formRowClassName}`}>
            <div className={`row ${rowClassName}`}>
                <div className={`col ${columnHeaderClassName}`}>
                    <h6 className="mb-0">
                        {rowName}
                        {showIcon && (
                            <Tooltip
                                tooltipText={tooltipText}
                                wrapperClassName={"ml-2 cursor-pointer"}
                                size={"md"}
                                place={"right"}
                                theme={"dark"}
                            >
                                <Icon
                                    rootElement={"Span"}
                                    icon={icon}
                                    size={"Medium"}
                                    onClick={onClick}
                                />
                            </Tooltip>
                        )}
                    </h6>
                </div>
            </div>
            <div className={`row ${rowClassName}`}>
                <div className={`col ${columnDetailClassName}`}>{children}</div>
            </div>
        </div>
    );
};

export default FormRowWithAction;

interface FormRowWithActionProps extends ComponentChildrenProps {
    rowName: string;
    rowClassName?: string;
    columnHeaderClassName?: string;
    columnDetailClassName?: string;
    formRowClassName?: string;
    icon: IconType;
    onClick: () => void;
    showIcon: boolean;
    tooltipText: string;
}
