import {
    ADMINISTRATION_REPORTS_STORE_STATE,
    AdministrationReport
} from "../actions/AdministrationReportsActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<AdministrationReport[]>([]);

const administrationReportsReducer = (
    state: StoreServiceData<AdministrationReport[]> = defaultState,
    action: ServiceActionTypes<AdministrationReport[]>
): StoreServiceData<AdministrationReport[]> =>
    createReducer(state, action, ADMINISTRATION_REPORTS_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default administrationReportsReducer;
