import {CalendarApi, ReportsApi, StaffApi, VenueApi} from "../../api/grs";

export default class GrsApiModel {
    private static _instance: GrsApiModel;
    private static _calendarApi: CalendarApi;
    private static _reportsApi: ReportsApi;
    private static _staffApi: StaffApi;
    private static _venueApi: VenueApi;

    private constructor() {
        if (GrsApiModel._instance) {
            throw new Error("Use GrsApiModel.instance instead of new");
        }
        GrsApiModel._instance = this;
    }

    static get instance(): GrsApiModel {
        return GrsApiModel._instance ?? (GrsApiModel._instance = new GrsApiModel());
    }

    static get calendarApi(): CalendarApi {
        return (
            GrsApiModel._calendarApi ??
            (GrsApiModel._calendarApi = new CalendarApi({
                basePath: process.env.REACT_APP_GRS_API
            }))
        );
    }

    static get reportsApi(): ReportsApi {
        return (
            GrsApiModel._reportsApi ??
            (GrsApiModel._reportsApi = new ReportsApi({
                basePath: process.env.REACT_APP_GRS_API
            }))
        );
    }

    static get staffApi(): StaffApi {
        return (
            GrsApiModel._staffApi ??
            (GrsApiModel._staffApi = new StaffApi({
                basePath: process.env.REACT_APP_GRS_API
            }))
        );
    }

    static get venueApi(): VenueApi {
        return (
            GrsApiModel._venueApi ??
            (GrsApiModel._venueApi = new VenueApi({
                basePath: process.env.REACT_APP_GRS_API
            }))
        );
    }
}
