import React from "react";
import {DrugBatchBasic} from "../../../../../api/mm";
import Icon, {IconType} from "../../../../Icon/Icon";
import Tooltip from "../../../../Tooltip/Tooltip";
import {Modal, useModal} from "pulse-modal";
import MoveDrugBatchForm from "../../../../DrugBatchActionForms/MoveDrugBatchForm/MoveDrugBatchForm";

const MoveStockAction = (props: Props) => {
    const {isShown, toggle} = useModal();

    return (
        <React.Fragment>
            <Tooltip
                wrapperClassName={"ml-2"}
                tooltipText={`Move to${props.moveToStore ? "..." : " Drug Pack"}`}
                place={"left"}
                theme={"dark"}
                size={"lg"}
            >
                <Icon
                    rootElement={"Span"}
                    icon={IconType.MoveStock}
                    size={"Large"}
                    className={"cursor-pointer"}
                    onClick={toggle}
                />
            </Tooltip>
            <Modal
                isShown={isShown}
                onClose={toggle}
                modalSize={"sm"}
                title={"Move Drug Batch"}
                bodyChildren={
                    <React.Fragment>
                        <MoveDrugBatchForm
                            moveToStore={props.moveToStore}
                            drugBatch={props.drugBatch}
                            maxQty={props.maxQty}
                            onClose={toggle}
                            locationId={props.locationId}
                        />
                    </React.Fragment>
                }
            />
        </React.Fragment>
    );
};

export default MoveStockAction;

interface Props {
    drugBatch: DrugBatchBasic;
    moveToStore: boolean;
    maxQty: number;
    locationId?: number;
}
