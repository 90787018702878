import {DrugBatch} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const MOVE_DRUGS_STORE = createStoreState("move_drugs");

interface MoveDrugsLoading extends StoreServiceData<DrugBatch> {
    type: typeof MOVE_DRUGS_STORE.LOADING;
}
interface MoveDrugsError extends StoreServiceData<DrugBatch> {
    type: typeof MOVE_DRUGS_STORE.ERROR;
}
interface MoveDrugsSuccess extends StoreServiceData<DrugBatch> {
    type: typeof MOVE_DRUGS_STORE.SUCCESS;
}

export type MoveDrugsDispatchTypes = MoveDrugsError | MoveDrugsLoading | MoveDrugsSuccess;
