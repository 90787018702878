import {StaffAccessLevel, UserData} from "../../../../api/staff";
import {NavigationItem} from "../../../../store/navigation/actions/NavigationActionTypes";
import {routeNames} from "../../../Navigation/routeNames";
import {McConfig} from "../../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../../utils/userDataUtils";

export function getNavigationItemsForUser(userData: UserData, config: McConfig): NavigationItem[] {
    const systemAccessLevel = getUserAccessLevel(userData, config);

    switch (systemAccessLevel) {
        case StaffAccessLevel.Staff:
            return [];
        case StaffAccessLevel.DutyManager:
            return [
                {
                    name: routeNames.mmAuditsList.name,
                    path: routeNames.mmAuditsList.path
                }
            ];
        case StaffAccessLevel.SystemAdministrator:
            return [
                {
                    name: routeNames.medicineManagement.name,
                    path: routeNames.medicineManagement.path
                },
                {
                    name: routeNames.stockManagement.name,
                    path: routeNames.stockManagement.path
                },
                {
                    name: routeNames.generalReports.name,
                    path: routeNames.generalReports.path
                },
                {
                    name: routeNames.mmAuditsList.name,
                    path: routeNames.mmAuditsList.path
                },
                {
                    name: routeNames.auditLocationList.name,
                    path: routeNames.auditLocationList.path
                }
            ];
    }
}
