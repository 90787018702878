import {DrugPackWithContents} from "../../../../../../api/mm";
import {useHistory} from "react-router-dom";
import {usePageUrl} from "../../../../../Hooks/usePageUrl";
import {useUserData} from "../../../../../Hooks/useUserData";
import {useModal} from "pulse-modal";
import {routeNames} from "../../../../../Navigation/routeNames";
import {deepCopy} from "../../../../../../utils/sortingUtils";

export function useDrugPackPreviewModal(props: DrugPackWithContents) {
    const history = useHistory();
    const {searchQueries} = usePageUrl();
    const user = useUserData();
    const {toggle, isShown} = useModal();

    //Check for query. If it exists, append to the path
    const editDrugPack = () => {
        const path = `${routeNames.drugPackWithContentsEdit.path}/${props.id}`;

        history.push({
            pathname: path,
            search: searchQueries
        });
    };

    function orderDrugsAlphabetically(): DrugPackWithContents {
        const copy = deepCopy(props);
        return {
            ...copy,
            drugs: copy.drugs.sort((a, b) => {
                if (!a.batchInfo) return 0;
                if (!b.batchInfo) return 0;

                return a.batchInfo.name.localeCompare(b.batchInfo.name);
            })
        };
    }

    return {
        editDrugPack,
        orderDrugsAlphabetically,
        toggle,
        isShown,
        user
    };
}
