import {UserData} from "../../../api/staff";
import {STAFF_LIST_STORE} from "../actions/StaffListActionTypes";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<UserData[]>([]);

const staffListReducer = (
    state: StoreServiceData<UserData[]> = defaultState,
    action: ServiceActionTypes<UserData[]>
): StoreServiceData<UserData[]> =>
    createReducer(state, action, STAFF_LIST_STORE, () => showErrorToast(action.error));

export default staffListReducer;
