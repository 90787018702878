import React from "react";
import {ComponentChildrenProps} from "../../../utils/componentUtils";

const PrintableDivRow = ({children, rowColour, padding}: Props) => {
    return (
        <React.Fragment>
            <div
                style={{
                    background: rowColour === "white" ? "#ffffff" : "#eeeeee",
                    borderBottom: "1px solid transparent",
                    width: "100%",
                    padding
                }}
            >
                {children}
            </div>
        </React.Fragment>
    );
};

export default PrintableDivRow;

interface Props extends ComponentChildrenProps {
    rowColour: "white" | "grey";
    padding?: string;
}
