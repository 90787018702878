import React from "react";
import {DrugBatchActionFormProps} from "../../Helpers/drugActionFormHelpers";
import AdministrationForm from "./AdministrationForm";
import {useAdministrationStage2} from "../Hooks/useAdministrationStage2";

function AdministrationStage2(props: DrugBatchActionFormProps) {
    const {request, actionStage2} = useAdministrationStage2(props);
    return (
        <React.Fragment>
            {request && (
                <AdministrationForm
                    onClose={props.onClose}
                    initialAdministration={request.administration}
                    drugBatch={props.drugBatch}
                    onSave={async (administration) => {
                        await actionStage2({
                            ...request,
                            administration
                        });
                    }}
                    maxQtyAvailable={props.maxQtyAvailable}
                    isStage2
                />
            )}
        </React.Fragment>
    );
}

export default AdministrationStage2;
