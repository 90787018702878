import {
    DRUG_PACK_WITH_CONTENTS_LIST_STORE,
    DrugPackWithContentsListDispatchTypes
} from "./DrugPackWithContentsListActionTypes";
import {Dispatch} from "redux";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import store from "../../Store";

export const nullifyDrugPacksWithContentsListStore = () => {
    return async (dispatch: Dispatch<DrugPackWithContentsListDispatchTypes>) => {
        dispatch({
            type: DRUG_PACK_WITH_CONTENTS_LIST_STORE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
};

export const fetchDrugPacksWithContentByCategoryId = (id: number) => {
    return async (dispatch: Dispatch<DrugPackWithContentsListDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                DRUG_PACK_WITH_CONTENTS_LIST_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getDrugPacksWithinCategory(id),
                statusCodeCallback
            );

            if (success) {
                const data = store.getState().drugPackWithContentsList.data;

                if (!data) return [];

                return data;
            }
        } catch (e: any) {
            dispatch({
                type: DRUG_PACK_WITH_CONTENTS_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export const fetchDrugPacksWithContentByLocationId = (id: number) => {
    return async (dispatch: Dispatch<DrugPackWithContentsListDispatchTypes>) => {
        try {
            return await getDataFromServiceWithRedux(
                DRUG_PACK_WITH_CONTENTS_LIST_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getDrugPacksWithinLocation(id),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DRUG_PACK_WITH_CONTENTS_LIST_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
