import {CalendarEntry, StaffLink} from "../../../api/grs";
import {StaffAccessLevel} from "../../../api/staff";
import {useEffect, useState} from "react";
import GrsApiModel from "../../../store/apiModel/GrsApiModel";
import {
    DDProps,
    DropdownOption,
    getSelectedDropdownOption,
    sortAssignedStaffInEntryToDropdownProps
} from "../Helpers/dropdownUtils";
import {SingleValue} from "react-select";
import {showErrorToast} from "../../../utils/toastUtils";

export function useEntryAssignedDropdown(props: EntryAssignedDropdownProps) {
    const [options, setOptions] = useState<DropdownOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<DropdownOption>();
    useEffect(() => {
        getEntry(props.entryId);
    }, [props.entryId]);

    async function getEntry(id: number) {
        try {
            const resp = await GrsApiModel.calendarApi.getCalendarEntryById(id);
            if (resp.data && resp.status === 200) {
                processEntryIncoming(resp.data);
            }
        } catch (e: any) {
            showErrorToast("Could not get entry for selected date");
        }
    }

    function processEntryIncoming(entity: CalendarEntry) {
        const generatedOptions = sortAssignedStaffInEntryToDropdownProps(entity);
        setOptions(generatedOptions);

        if (props.clearable && props.staffId.length === 0) {
            setSelectedOption(undefined);
            return;
        }

        const found = getSelectedDropdownOption(props.staffId, options);
        setSelectedOption(found);
    }

    useEffect(() => {
        const found = getSelectedDropdownOption(props.staffId, options);

        setSelectedOption(found);
    }, [options, props.staffId]);

    function handleOptionChange(newValue: SingleValue<DDProps>) {
        if (!newValue) {
            props.changeOption(undefined);
            setSelectedOption(undefined);
            return;
        }
        //Using the data from the staffList
        const option = getSelectedDropdownOption(newValue.value, options);
        setSelectedOption(option);
        const staffLink = getStaffLinkOrNull(option);
        props.changeOption(staffLink);
    }

    return {
        handleOptionChange,
        selectedOption,
        options
    };
}

export interface EntryAssignedDropdownProps {
    staffId: string;
    entryId: number;
    searchable: boolean;
    accessLevelFilter?: StaffAccessLevel;
    changeOption: (staffMember?: StaffLink) => void;
    clearable: boolean;
}

export function getStaffLinkOrNull(option?: DropdownOption): StaffLink | undefined {
    if (!option) return;
    return {
        staffId: option.value.toString(),
        staffName: option.label
    };
}
