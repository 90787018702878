import React from "react";
import {DrugAuditHistoryOverview} from "../../../../api/mm";
import {formatUnixToDDMMYYYY} from "../../../../utils/momentUtils";
import {IconType} from "../../../Icon/Icon";
import {routeNames} from "../../../Navigation/routeNames";
import {useHistory, useParams} from "react-router-dom";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../Table/NoItemsMessage";
import TableItemAction from "../../../Table/TableItemAction";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";

const HistoricAuditListTable = (props: DrugAuditHistoryOverview) => {
    const history = useHistory();
    const params: any = useParams();
    const viewHistoricAuditForAudit = (item: TableRow) => {
        const auditId = +params.auditId;
        const path = `${routeNames.historicAuditView.path}/${auditId}/${item.actions}`;

        history.push({
            pathname: path,
            search: location.search
        });
    };

    const backToAuditListPaged = () => {
        const path = routeNames.mmAuditsList.path;

        history.push({
            pathname: path,
            search: location.search
        });
    };
    return (
        <React.Fragment>
            <PulseTable
                items={drugAuditOverviewToTableRow(props)}
                headers={{
                    modifiedAuthor: "Modified Author",
                    modifiedDate: "Modified Date",
                    reportingManager: "Reporting Manager",
                    comments: "Comments",
                    actions: "Actions"
                }}
                noItemsSection={
                    <NoItemsMessage message={"There are no historic audits for this audit"} />
                }
                customRenderers={{
                    // eslint-disable-next-line react/display-name
                    actions: (item: TableRow) => (
                        <React.Fragment>
                            <TableItemAction
                                tooltipText={`View Version of Historic Audit`}
                                onClick={() => viewHistoricAuditForAudit(item)}
                                icon={IconType.Eye}
                            />
                        </React.Fragment>
                    )
                }}
            />
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Back"}
                    onClick={backToAuditListPaged}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default HistoricAuditListTable;

interface TableRow {
    modifiedAuthor: string;
    modifiedDate: string;
    reportingManager: string;
    comments?: string;
    actions: number;
}

function drugAuditOverviewToTableRow(payload: DrugAuditHistoryOverview): TableRow[] {
    if (!payload.results) return [];
    return payload.results.map((item) => {
        return {
            modifiedAuthor: item.modifiedAuthor.staffName,
            modifiedDate: formatUnixToDDMMYYYY(item.dateModified),
            reportingManager: item.reportingManager.staffName,
            comments: item.comments,
            actions: item.id
        };
    });
}
