import {EventCalendarEntry, FrontlineCalendarEntry} from "../../../api/grs";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const CALENDAR_ENTRY_LIST_STORE = createStoreState("calendar_entry_list");

interface CalendarEntryListStoreLoading
    extends StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> {
    type: typeof CALENDAR_ENTRY_LIST_STORE.LOADING;
}

interface CalendarEntryListStoreError
    extends StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> {
    type: typeof CALENDAR_ENTRY_LIST_STORE.ERROR;
}

interface CalendarEntryListStoreSuccess
    extends StoreServiceData<EventCalendarEntry[] | FrontlineCalendarEntry[]> {
    type: typeof CALENDAR_ENTRY_LIST_STORE.SUCCESS;
}

export type CalendarEntryListDispatchTypes =
    | CalendarEntryListStoreLoading
    | CalendarEntryListStoreError
    | CalendarEntryListStoreSuccess;
