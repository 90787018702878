import {ADMINISTER_DRUGS_STORE} from "../actions/AdministerDrugsActionTypes";
import {DrugBatch} from "../../../api/mm";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugBatch>(null);

const administerDrugsReducer = (
    state: StoreServiceData<DrugBatch> = defaultState,
    action: ServiceActionTypes<DrugBatch>
): StoreServiceData<DrugBatch> =>
    createReducer(state, action, ADMINISTER_DRUGS_STORE, () => showErrorToast(action.error));

export default administerDrugsReducer;
