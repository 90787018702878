import React from "react";
import {DrugPackWithContents} from "../../../../../api/mm";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../../../../Table/NoItemsMessage";
import {formatUnixToDDMMYYYY} from "../../../../../utils/momentUtils";
import Tooltip from "../../../../Tooltip/Tooltip";
import {
    DrugExpiry,
    getDayDifference,
    getDrugExpiry
} from "../../../StockManagementList/Components/StockManagementTable";
import moment from "moment";
import {DrugExpiryType} from "../Helpers/medicineManagementStatsHelpers";

const DrugPackPreview = (props: DrugPackWithContents) => {
    const getExpiryClassName = (expiry: DrugExpiry | undefined) => {
        if (!expiry) return "expiry-indicator-base no-action-needed";

        switch (expiry.expiryType) {
            case DrugExpiryType.Week:
                return "expiry-indicator-base expires-in-week";
            case DrugExpiryType.Month:
                return "expiry-indicator-base expires-in-month";
        }
    };
    const getTooltipText = (expiry: DrugExpiry | undefined, expiryDate: number) => {
        if (!expiry) {
            const now = moment();
            const dayDiff = getDayDifference(expiryDate, now);
            return `This drug batch will expire in ${dayDiff} days.`;
        }
        return expiry.tooltipMessage;
    };
    return (
        <React.Fragment>
            <PulseTable
                items={toTableRow(props)}
                headers={{
                    drugName: "Drug Name",
                    drugType: "Drug Type",
                    batchNumber: "Batch Number",
                    quantity: "Quantity",
                    expiryDate: "Expiry Date"
                }}
                customRenderers={{
                    expiryDate: (item: TableRow) => {
                        const expiring = getDrugExpiry(item.expiryDate, item.drugName);
                        return (
                            <React.Fragment>
                                {item.expiryDate > 0 ? (
                                    <p className="mb-0">
                                        {formatUnixToDDMMYYYY(item.expiryDate)}
                                        <React.Fragment>
                                            <Tooltip
                                                tooltipText={getTooltipText(
                                                    expiring,
                                                    item.expiryDate
                                                )}
                                                size={"md"}
                                                place={"left"}
                                                theme={"dark"}
                                                wrapperClassName={"float-left mt-1 mr-2"}
                                            >
                                                <span
                                                    className={`${getExpiryClassName(expiring)}`}
                                                />
                                            </Tooltip>
                                        </React.Fragment>
                                    </p>
                                ) : (
                                    <p className="mb-0">Could not get drug information</p>
                                )}
                            </React.Fragment>
                        );
                    }
                }}
                noItemsSection={
                    <NoItemsMessage
                        message={
                            "There are no drugs in this drug pack. Click the Edit Drug Pack button to add drugs."
                        }
                    />
                }
            />
        </React.Fragment>
    );
};

export default DrugPackPreview;

interface TableRow {
    drugName: string;
    drugType: string;
    batchNumber: string;
    quantity: number;
    expiryDate: number;
}

function toTableRow(props: DrugPackWithContents): TableRow[] {
    return props.drugs.map((drug) => {
        return {
            drugName: drug.batchInfo?.name || "Could not get drug information",
            drugType: drug.batchInfo?.type || "Could not get drug information",
            batchNumber: drug.batchInfo?.batchNumber || "Could not get drug information",
            quantity: drug.qty,
            expiryDate: drug.batchInfo?.expiryDate || 0
        };
    });
}
