import React from "react";
import {DrugAudit} from "../../../../api/mm";
import {setAudit} from "../../../../store/audit/actions/AuditActions";
import {useDispatch} from "react-redux";
import {useIsAuditEditable} from "../../../Hooks/useIsAuditEditable";
import {DebounceInput} from "react-debounce-input";
import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";

const AuditComments = (props: DrugAudit) => {
    const dispatch = useDispatch();
    const isAuditEditable = useIsAuditEditable();
    return (
        <React.Fragment>
            <FormHeader headerName={"Manager Comments & Details"} />
            <FormRow rowName={"Comments"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    onChange={(event) => {
                        const {value} = event.target;
                        dispatch(setAudit({...props, comments: value}));
                    }}
                    value={props.comments}
                    element={"textarea"}
                    disabled={!isAuditEditable}
                    className={"input-fields text-area-inputs"}
                    placeholder="Enter comments here..."
                />
            </FormRow>
        </React.Fragment>
    );
};

export default AuditComments;
