import React from "react";
import {DrugPackLabelProps} from "../DrugPackLabel/DrugPackLabel";
import PrintableTable from "../../Print/Components/PrintableTable";
import PrintableTableHead from "../../Print/Components/PrintableTableHead";
import PrintableTableRow from "../../Print/Components/PrintableTableRow";
import PrintableTH from "../../Print/Components/PrintableTH";
import PrintableTableBody from "../../Print/Components/PrintableTableBody";
import PrintableTD from "../../Print/Components/PrintableTD";
import {formatUnixToDDMMYYYY} from "../../../utils/momentUtils";
import moment from "moment";

const DrugPackPrintBody = (props: DrugPackLabelProps) => {
    const telephoneNumber = " 0330 056 2211"; //Tactical On Call
    return (
        <React.Fragment>
            <PrintableTable width={"800px"}>
                <PrintableTableHead>
                    <PrintableTableRow rowType={"headerRow"}>
                        <PrintableTH
                            alignment={"left"}
                            padding={"0.25rem 1rem"}
                            maxWidth={"1000px"}
                            fontSize={"0.9em"}
                        >
                            Drug Name
                        </PrintableTH>
                        <PrintableTH
                            alignment={"left"}
                            padding={"0.25rem 1rem"}
                            maxWidth={"1000px"}
                            fontSize={"0.9em"}
                        >
                            Batch Number
                        </PrintableTH>
                        <PrintableTH
                            alignment={"left"}
                            padding={"0.25rem 1rem"}
                            maxWidth={"1000px"}
                            fontSize={"0.9em"}
                        >
                            Qty
                        </PrintableTH>
                        <PrintableTH
                            alignment={"left"}
                            padding={"0.25rem 1rem"}
                            maxWidth={"1000px"}
                            fontSize={"0.9em"}
                        >
                            Expiry Date
                        </PrintableTH>
                        <PrintableTH
                            alignment={"left"}
                            padding={"0.25rem 1rem"}
                            maxWidth={"1000px"}
                            fontSize={"0.9em"}
                        >
                            Checked
                        </PrintableTH>
                    </PrintableTableRow>
                </PrintableTableHead>
                <PrintableTableBody>
                    {props.drugPack.drugs.map((item, index) => {
                        return (
                            <PrintableTableRow key={index} index={index} rowType={"bodyRow"}>
                                <PrintableTD
                                    alignment={"left"}
                                    padding={"0 1rem"}
                                    maxWidth={"1000px"}
                                    fontSize={"0.8em"}
                                >
                                    {item.batchInfo?.name || ""}
                                </PrintableTD>
                                <PrintableTD
                                    alignment={"left"}
                                    padding={"0 1rem"}
                                    maxWidth={"1000px"}
                                    fontSize={"0.8em"}
                                >
                                    {item.batchInfo?.batchNumber || ""}
                                </PrintableTD>
                                <PrintableTD
                                    alignment={"left"}
                                    padding={"0 1rem"}
                                    maxWidth={"1000px"}
                                    fontSize={"0.8em"}
                                >
                                    {item.qty}
                                </PrintableTD>
                                <PrintableTD
                                    alignment={"left"}
                                    padding={"0 1rem"}
                                    maxWidth={"1000px"}
                                    fontSize={"0.8em"}
                                >
                                    {formatUnixToDDMMYYYY(item.batchInfo?.expiryDate || 0)}
                                </PrintableTD>
                                <PrintableTD
                                    alignment={"left"}
                                    padding={"0 1rem"}
                                    maxWidth={"1000px"}
                                    fontSize={"0.8em"}
                                >
                                    <div
                                        style={{
                                            width: `5px`,
                                            height: `5px`,
                                            borderTop: `1px solid #495057CC`,
                                            borderRight: `1px solid #495057CC`,
                                            borderBottom: `1px solid #495057CC`,
                                            borderLeft: `1px solid #495057CC`,
                                            marginTop: `2px`,
                                            marginBottom: `2px`
                                        }}
                                    />
                                </PrintableTD>
                            </PrintableTableRow>
                        );
                    })}
                </PrintableTableBody>
            </PrintableTable>

            <PrintableTable width={"800px"} marginTop={"15px"}>
                <PrintableTableHead>
                    <PrintableTableRow rowType={"headerRow"}>
                        <PrintableTH alignment={"left"} padding={"1rem"} maxWidth={"1000px"}>
                            <h6 style={{marginBottom: `0`}}>
                                If this bag is found, please hand into the
                                <b> nearest Police station or call {`${telephoneNumber}. `}</b>
                                The bag should be <b>secured with a seal.</b> The contents have been
                                packed and checked by Medicare Management. This bag contains
                                emergency medicines for use only by Paramedics and Doctors in
                                Pre-hospital care.
                                <b>
                                    {" "}
                                    DO NOT USE ANY OF THESE MEDICINES. THEY COULD SERIOUSLY HARM
                                    YOU.
                                </b>
                            </h6>
                        </PrintableTH>
                    </PrintableTableRow>
                </PrintableTableHead>
            </PrintableTable>
            <PrintableTable width={"800px"}>
                <PrintableTableHead>
                    <PrintableTableRow rowType={"headerRow"}>
                        <PrintableTH alignment={"left"} padding={"1rem"} maxWidth={"1000px"}>
                            <h6 style={{marginBottom: `0`}}>
                                Checked by: Clinical Governance Team
                            </h6>
                        </PrintableTH>
                        <PrintableTH alignment={"left"} padding={"1rem"} maxWidth={"1000px"}>
                            <h6 style={{marginBottom: `0`}}>
                                Signed: {props.user.firstName} {props.user.lastName}
                            </h6>
                        </PrintableTH>
                        <PrintableTH alignment={"left"} padding={"1rem"} maxWidth={"1000px"}>
                            <h6 style={{marginBottom: `0`}}>
                                Date: {formatUnixToDDMMYYYY(moment().unix())}
                            </h6>
                        </PrintableTH>
                    </PrintableTableRow>
                </PrintableTableHead>
            </PrintableTable>
        </React.Fragment>
    );
};

export default DrugPackPrintBody;
