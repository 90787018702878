import {combineReducers} from "redux";
import authReducer from "./auth/reducer/AuthReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import sidebarReducer from "./sidebar/reducer/sidebarReducer";
import mcConfigReducer from "./mcConfig/reducer/McConfigReducer";
import drugPackCategoryReducer from "./drugPackCategory/reducer/DrugPackCategoryReducer";
import drugPackCategoryListReducer from "./drugPackCategoryList/reducer/DrugPackCategoryListReducer";
import drugPackWithContentsReducer from "./drugPackWithContents/reducer/DrugPackWithContentsReducer";
import drugPackWithContentsListReducer from "./drugPackWithContentsList/reducer/DrugPackWithContentsListReducer";
import drugBatchReducer from "./drugBatch/reducer/DrugBatchReducer";
import drugBatchListReducer from "./drugBatchList/reducer/DrugBatchListReducer";
import drugBatchPagedReducer from "./drugBatchPaged/reducer/DrugBatchPagedReducer";
import moveDrugsReducer from "./moveDrugs/reducer/MoveDrugsReducer";
import administerDrugsReducer from "./administerDrugs/reducer/AdministerDrugsReducer";
import disposeDrugsReducer from "./disposeDrugs/reducer/DisposeDrugsReducer";
import calendarEntryListReducer from "./calendarEntryList/reducer/CalendarEntryListReducer";
import staffListReducer from "./staffList/reducer/StaffListReducer";
import venueListReducer from "./venueList/reducer/VenueListReducer";
import drugPackListReducer from "./drugPackList/reducer/DrugPackListReducer";
import generalReportsReducer from "./generalReports/reducer/generalReportsReducer";
import disposalReportsReducer from "./disposalReports/reducer/DisposalReportsReducer";
import administrationReportsReducer from "./administrationReports/reducer/AdministrationReportsReducer";
import expiryReportReducer from "./expiryReports/reducer/ExpiryReportReducer";
import statusReportsReducer from "./statusReport/reducer/StatusReportReducer";
import locationReducer from "./location/reducer/LocationReducer";
import locationListReducer from "./locationList/reducer/LocationListReducer";
import auditListPagedReducer from "./auditListPaged/reducer/AuditListPagedReducer";
import auditReducer from "./audit/reducer/AuditReducer";
import auditListHistoricReducer from "./auditListHistoric/reducer/AuditListHistoricReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    mcConfig: mcConfigReducer,
    drugPackCategory: drugPackCategoryReducer,
    drugPackCategoryList: drugPackCategoryListReducer,
    drugPackWithContents: drugPackWithContentsReducer,
    drugPackWithContentsList: drugPackWithContentsListReducer,
    drugBatch: drugBatchReducer,
    drugBatchList: drugBatchListReducer,
    drugBatchPaged: drugBatchPagedReducer,
    moveDrugs: moveDrugsReducer,
    administerDrugs: administerDrugsReducer,
    disposeDrugs: disposeDrugsReducer,
    calendarEntryList: calendarEntryListReducer,
    staffList: staffListReducer,
    venueList: venueListReducer,
    drugPackList: drugPackListReducer,
    generalReports: generalReportsReducer,
    disposalReports: disposalReportsReducer,
    administrationReports: administrationReportsReducer,
    expiryReport: expiryReportReducer,
    statusReports: statusReportsReducer,
    location: locationReducer,
    locationList: locationListReducer,
    auditListPaged: auditListPagedReducer,
    audit: auditReducer,
    auditListHistoric: auditListHistoricReducer
});

export default RootReducer;
