import React, {useEffect, useState} from "react";
import {
    DrugBatchOrder,
    DrugBatchPageRequest,
    DrugType,
    StringOperator,
    StringSearch
} from "../../../../api/mm";
import {pagedRequestConfig, RequestFilterProps} from "../../../Filters/helpers/filterHelpers";
import {useDispatch} from "react-redux";
import {useQuery} from "../../../Hooks/useQuery";
import {useHistory} from "react-router-dom";
import moment, {Moment} from "moment";
import {nullifyDrugBatchPagedStore} from "../../../../store/drugBatchPaged/actions/DrugBatchPagedActions";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import {DebounceInput} from "react-debounce-input";
import PulseEnumDropdown from "../../../Dropdown/PulseEnumDropdown";
import {DropdownOption} from "../../../Dropdown/Helpers/dropdownUtils";

const StockManagementFilters = (props: RequestFilterProps<DrugBatchPageRequest>) => {
    const dispatch = useDispatch();
    const query = useQuery();
    const history = useHistory();
    const [searchByName, setSearchByName] = useState<StringSearch>(getEmptySearchString());
    const [searchByBatch, setSearchByBatch] = useState<StringSearch>(getEmptySearchString());
    //Filters
    const [drugFilterType, setDrugFilterType] = useState<string>();
    const [drugBatchSortOrder, setDrugBatchSortOrder] = useState<string>();

    //Filters for creation date range and expiry date range.
    const [createdDateStart, setCreatedDateStart] = useState<Moment>();

    const [expiryDateStart, setExpiryDateStart] = useState<Moment>();

    const onDrugNameSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        setSearchByName({searchString: value, operator: StringOperator.Contains});
    };

    const onBatchNumberSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        const {value} = event.target;
        setSearchByBatch({searchString: value, operator: StringOperator.Contains});
    };

    const changeDrugBatchSortOrder = (option: DropdownOption | null | undefined) => {
        if (!option) return;
        setDrugBatchSortOrder(option.value.toString());
    };

    const changeDrugFilterType = (option: DropdownOption | null | undefined) => {
        if (!option) return;
        setDrugFilterType(option.value.toString());
    };

    useEffect(() => {
        const cdi = query.get("CDI");
        const edi = query.get("EDI");
        const dbso = query.get("DBSO");
        const dbt = query.get("DBT");
        const drg = query.get("DRG");
        const bat = query.get("BAT");

        //Search filter
        setSearchByName(
            drg
                ? {operator: StringOperator.Contains, searchString: drg}
                : {operator: StringOperator.Contains, searchString: ""}
        );
        setSearchByBatch(
            bat
                ? {operator: StringOperator.Contains, searchString: bat}
                : {operator: StringOperator.Contains, searchString: ""}
        );

        //Other Filters
        setDrugFilterType(dbt ? dbt : StockManagementTableDrugTypeFilter.None);

        //Time
        setCreatedDateStart(cdi ? moment.unix(+cdi) : undefined);
        setExpiryDateStart(edi ? moment.unix(+edi) : undefined);

        setDrugBatchSortOrder(dbso ? dbso : DrugBatchOrder.DrugNameAscending);
        return () => {
            dispatch(nullifyDrugBatchPagedStore());
        };
    }, []);

    useEffect(() => {
        if (!drugBatchSortOrder) return;
        if (!drugFilterType) return;

        const request = buildPagedDrugBatchRequest(
            drugBatchSortOrder,
            drugFilterType,
            searchByName.searchString,
            searchByBatch.searchString,
            createdDateStart?.unix(),
            expiryDateStart?.unix()
        );

        props.onRequestChanged(request);
        buildUrlHistory(request);
    }, [
        searchByName.searchString,
        searchByBatch.searchString,
        drugFilterType,
        drugBatchSortOrder,
        createdDateStart,
        expiryDateStart
    ]);

    const buildPagedDrugBatchRequest = (
        sortOrder: string,
        drugType: string,
        nameSearch?: string,
        batchSearch?: string,
        cds?: number,
        eds?: number
    ): DrugBatchPageRequest => {
        const cde = cds ? moment(cds).endOf("month").unix() : undefined;
        const ede = eds ? moment(eds).endOf("month").unix() : undefined;
        return {
            pageNum: 0,
            numPerPage: pagedRequestConfig.resultsPerPage,
            creationDateMinInclusive: cds,
            creationDateMaxExclusive: cde,
            expiryDateMinInclusive: eds,
            expiryDateMaxExclusive: ede,
            batchNumber: batchSearch
                ? {searchString: batchSearch, operator: StringOperator.Contains}
                : undefined,
            drugName: nameSearch
                ? {searchString: nameSearch, operator: StringOperator.Contains}
                : undefined,
            sortOrder: getSortOrderFromString(sortOrder),
            type: getDrugType(drugType)
        };
    };

    const buildUrlHistory = (request: DrugBatchPageRequest) => {
        const queryStrings: string[] = ["?"];

        if (request.sortOrder) {
            queryStrings.push(`DBSO=${request.sortOrder}`);
        }
        const type = request.type ? request.type : StockManagementTableDrugTypeFilter.None;

        queryStrings.push(`&DBT=${type}`);

        if (request.creationDateMinInclusive) {
            queryStrings.push(`&CDI=${request.creationDateMinInclusive}`);
        }
        if (request.creationDateMaxExclusive) {
            queryStrings.push(`&CDE=${request.creationDateMaxExclusive}`);
        }
        if (request.expiryDateMinInclusive) {
            queryStrings.push(`&EDI=${request.expiryDateMinInclusive}`);
        }
        if (request.expiryDateMaxExclusive) {
            queryStrings.push(`&EDE=${request.expiryDateMaxExclusive}`);
        }
        if (request.drugName) {
            queryStrings.push(`&DRG=${request.drugName.searchString}`);
        }
        if (request.batchNumber) {
            queryStrings.push(`&BAT=${request.batchNumber.searchString}`);
        }

        history.push({search: queryStrings.join("")});
    };

    const getSortOrderFromString = (value: string | undefined) => {
        if (!value) return DrugBatchOrder.CreationDateDescending;

        return DrugBatchOrder[value as keyof typeof DrugBatchOrder];
    };

    const getDrugType = (value: string | undefined) => {
        if (!value) return undefined;

        return DrugType[value as keyof typeof DrugType];
    };
    return (
        <React.Fragment>
            <div className="filter-inner-container-wrapper">
                <div className="filter-item">
                    <h6>Created Date</h6>
                    <DatePicker
                        selected={createdDateStart?.toDate()}
                        onChange={(date) => {
                            setCreatedDateStart(moment(date));
                        }}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                        portalId="root-portal"
                        customInput={<DatePickerInputButton />}
                    />
                </div>
                <div className="filter-item">
                    <h6>Expiry Date</h6>
                    <DatePicker
                        selected={expiryDateStart?.toDate()}
                        onChange={(date) => {
                            setExpiryDateStart(moment(date));
                        }}
                        showMonthYearPicker
                        dateFormat="MMMM yyyy"
                        portalId="root-portal"
                        customInput={<DatePickerInputButton />}
                    />
                </div>
                <div className="filter-item">
                    <h6>Drug Type</h6>
                    {drugFilterType && (
                        <PulseEnumDropdown
                            enumOptions={StockManagementTableDrugTypeFilter}
                            onChange={changeDrugFilterType}
                            value={drugFilterType}
                            disabled={false}
                            searchable={false}
                            clearable={false}
                            appendToBody
                        />
                    )}
                </div>
                <div className="filter-item">
                    <h6>Sort Order</h6>
                    {drugBatchSortOrder && (
                        <PulseEnumDropdown
                            enumOptions={DrugBatchOrder}
                            onChange={changeDrugBatchSortOrder}
                            value={drugBatchSortOrder}
                            disabled={false}
                            searchable={false}
                            clearable={false}
                            appendToBody
                        />
                    )}
                </div>
                <div className="filter-item">
                    <h6>Search By Name</h6>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields"}
                        onChange={onDrugNameSearch}
                        placeholder={"Search for drug by name..."}
                        value={searchByName.searchString}
                        disabled={false}
                    />
                </div>
                <div className="filter-item">
                    <h6>Search By Batch Number</h6>
                    <DebounceInput
                        debounceTimeout={300}
                        className={"input-fields"}
                        onChange={onBatchNumberSearch}
                        placeholder={"Search for drug by batch number..."}
                        value={searchByBatch.searchString}
                        disabled={false}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};

export default StockManagementFilters;

function getEmptySearchString(): StringSearch {
    return {
        searchString: "",
        operator: StringOperator.Contains
    };
}

// eslint-disable-next-line no-shadow
enum StockManagementTableDrugTypeFilter {
    None = "None",
    Normal = "Normal",
    Controlled = "Controlled"
}
