import {Dispatch} from "redux";
import {AUDIT_LIST_PAGED_STORE, AuditListPagedDispatchTypes} from "./AuditListPagedActionTypes";
import {DrugAuditPagedRequest} from "../../../api/mm";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";
import {createBlankDrugAuditPagedResponse} from "../reducer/AuditListPagedReducer";

export const nullifyAuditListPagedStore = () => {
    return async (dispatch: Dispatch<AuditListPagedDispatchTypes>) => {
        dispatch({
            type: AUDIT_LIST_PAGED_STORE.SUCCESS,
            error: null,
            loading: false,
            data: createBlankDrugAuditPagedResponse()
        });
    };
};

export const fetchAuditListPaged = (request: DrugAuditPagedRequest) => {
    return async (dispatch: Dispatch<AuditListPagedDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                AUDIT_LIST_PAGED_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.getDrugAuditsPaged(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: AUDIT_LIST_PAGED_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
