import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {TotalQuantity} from "./ExpiryReportActions";

export const EXPIRY_REPORT_STORE_STATE = createStoreState("expiry_store");

interface ExpiryReportLoading extends StoreServiceData<DrugExpiryRollout[]> {
    type: typeof EXPIRY_REPORT_STORE_STATE.LOADING;
}
interface ExpiryReportError extends StoreServiceData<DrugExpiryRollout[]> {
    type: typeof EXPIRY_REPORT_STORE_STATE.ERROR;
}
interface ExpiryReportSuccess extends StoreServiceData<DrugExpiryRollout[]> {
    type: typeof EXPIRY_REPORT_STORE_STATE.SUCCESS;
}

export type ExpiryReportDispatchTypes =
    | ExpiryReportLoading
    | ExpiryReportError
    | ExpiryReportSuccess;

export interface DrugExpiryRollout {
    drugName: string;
    drugPack: undefined;
    batch: undefined;
    expiryDate: undefined;
    quantity: TotalQuantity;
    drugs: DrugExpiry[];
}

export interface DrugExpiry {
    drugBatchName: string;
    drugBatchId: number;
    expiryDate: number;
    batchNumber: string;
    qty?: number;
    drugPackName: string;
    drugPackId?: number;
}

export interface DrugBatchExpiryWithDrugBatchDetails {
    drugBatchName: string;
    drugBatchId: number;
    expiryDate: number;
    batchNumber: string;
    qty?: number;
    drugPackId?: number;
}
