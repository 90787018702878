import React from "react";
import PrintableTableHead from "../../../../../Print/Components/PrintableTableHead";
import PrintableTableRow from "../../../../../Print/Components/PrintableTableRow";
import PrintableTH from "../../../../../Print/Components/PrintableTH";
import PrintableH6 from "../../../../../Print/Components/PrintableH6";

const ExpiryReportHeader = () => (
    <React.Fragment>
        <PrintableTableHead>
            <PrintableTableRow rowType={"headerRow"}>
                <PrintableTH alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableH6 headerName={"Drug Name"} />
                </PrintableTH>
                <PrintableTH alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableH6 headerName={"Drug Pack"} />
                </PrintableTH>
                <PrintableTH alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableH6 headerName={"Batch"} />
                </PrintableTH>
                <PrintableTH alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableH6 headerName={"Expiry Date"} />
                </PrintableTH>
                <PrintableTH alignment={"left"} padding={".35rem .75rem"} maxWidth={`100px`}>
                    <PrintableH6 headerName={"Quantity"} />
                </PrintableTH>
            </PrintableTableRow>
        </PrintableTableHead>
    </React.Fragment>
);

export default ExpiryReportHeader;
