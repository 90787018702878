import React from "react";
import {DrugBatch, DrugBatchPageResponse} from "../../api/mm";
import {getUiFriendlyText} from "../../utils/textUtils";
import {getRemainingStock} from "../Pages/StockManagementList/Helpers/stockManagementListHelpers";
import {formatUnixToDDMMYYYY} from "../../utils/momentUtils";
import {PulseTableWithServerPagination} from "pulse_table";
import {pagedRequestConfig} from "../Filters/helpers/filterHelpers";
import Tooltip from "../Tooltip/Tooltip";
import Icon, {IconType} from "../Icon/Icon";
import NoItemsMessage from "../Table/NoItemsMessage";
import {
    getDrugExpiry,
    getExpiryIconClassName
} from "../Pages/StockManagementList/Components/StockManagementTable";
import FormRow from "../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import MoveStockAction from "../Pages/DrugPackWithContents/Components/Actions/MoveStockAction";
import {useDrugStore} from "./Hooks/useDrugStore";

const DrugStoreTable = (props: DrugBatchPageResponse) => {
    const {drugBatchPagedRequest, getStringSearch, fetchData} = useDrugStore(props);

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <div className="col pl-0">
                    <FormRow rowName={"Drug Name"} columnDetailClassName={"pl-0 pr-0"}>
                        <DebounceInput
                            debounceTimeout={300}
                            value={drugBatchPagedRequest?.drugName?.searchString}
                            onChange={(event) => {
                                if (!drugBatchPagedRequest) return;
                                const {value} = event.target;
                                fetchData({
                                    ...drugBatchPagedRequest,
                                    pageNum: 0,
                                    drugName: getStringSearch(value)
                                });
                            }}
                            className="input-fields"
                            placeholder="Search by drug name..."
                        />
                    </FormRow>
                </div>
                <div className="col pr-0">
                    <FormRow rowName={"Batch Number"} columnDetailClassName={"pl-0 pr-0"}>
                        <DebounceInput
                            debounceTimeout={300}
                            value={drugBatchPagedRequest?.batchNumber?.searchString}
                            onChange={(event) => {
                                if (!drugBatchPagedRequest) return;
                                const {value} = event.target;
                                fetchData({
                                    ...drugBatchPagedRequest,
                                    pageNum: 0,
                                    batchNumber: getStringSearch(value)
                                });
                            }}
                            className="input-fields"
                            placeholder="Search by batch number..."
                        />
                    </FormRow>
                </div>
            </div>
            <FormRow rowName={"Available Drugs"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseTableWithServerPagination
                    resultsPerPage={pagedRequestConfig.resultsPerPage}
                    totalResults={props.totalCount || 0}
                    onPageChanged={(newPageNum) => {
                        if (!drugBatchPagedRequest) return;
                        fetchData({
                            ...drugBatchPagedRequest,
                            pageNum: newPageNum
                        });
                    }}
                    items={toTableRow(props)}
                    headers={{
                        drugName: "Drug Name",
                        drugType: "Drug Type",
                        batchNumber: "Batch Number",
                        quantity: "Quantity",
                        activeStock: "Active Stock",
                        expiryDate: "Expiry Date",
                        drug: "Actions"
                    }}
                    customRenderers={{
                        expiryDate: (item: TableRow) => {
                            const {drug} = item;
                            const expiring = getDrugExpiry(drug.expiryDate, drug.name);
                            return (
                                <React.Fragment>
                                    <p className="mb-0">
                                        <span className="mr-2">{item.expiryDate}</span>
                                        {expiring && (
                                            <React.Fragment>
                                                <Tooltip
                                                    tooltipText={expiring.tooltipMessage}
                                                    size={"md"}
                                                    place={"bottom"}
                                                    theme={"dark"}
                                                >
                                                    <Icon
                                                        rootElement={"Span"}
                                                        icon={IconType.DrugExpiry}
                                                        size={"Medium"}
                                                        className={`cursor-pointer ${getExpiryIconClassName(
                                                            expiring
                                                        )}`}
                                                    />
                                                </Tooltip>
                                            </React.Fragment>
                                        )}
                                    </p>
                                </React.Fragment>
                            );
                        },
                        drug: (item: TableRow) => {
                            return (
                                <React.Fragment>
                                    <MoveStockAction
                                        moveToStore={false}
                                        maxQty={getRemainingStock(item.drug)}
                                        drugBatch={item.drug}
                                    />
                                </React.Fragment>
                            );
                        }
                    }}
                    noItemsSection={
                        <NoItemsMessage message={"There are no drugs matching these filters"} />
                    }
                />
            </FormRow>
        </React.Fragment>
    );
};

export default DrugStoreTable;

interface TableRow {
    drugName: string;
    drugType: string;
    batchNumber: string;
    quantity: number;
    activeStock: number;
    expiryDate: string;
    drug: DrugBatch;
}

function toTableRow(resp: DrugBatchPageResponse): TableRow[] {
    return resp.results.map((batch) => {
        return {
            drugName: batch.name,
            drugType: getUiFriendlyText(batch.type),
            batchNumber: batch.batchNumber,
            quantity: batch.qty,
            activeStock: getRemainingStock(batch),
            expiryDate: formatUnixToDDMMYYYY(batch.expiryDate),
            drug: batch
        };
    });
}
