import {DrugBatch} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DISPOSE_DRUGS_STORE = createStoreState("dispose_drugs");

interface DisposeDrugsLoading extends StoreServiceData<DrugBatch> {
    type: typeof DISPOSE_DRUGS_STORE.LOADING;
}
interface DisposeDrugsError extends StoreServiceData<DrugBatch> {
    type: typeof DISPOSE_DRUGS_STORE.ERROR;
}
interface DisposeDrugsSuccess extends StoreServiceData<DrugBatch> {
    type: typeof DISPOSE_DRUGS_STORE.SUCCESS;
}

export type DisposeDrugsDispatchTypes =
    | DisposeDrugsSuccess
    | DisposeDrugsError
    | DisposeDrugsLoading;
