import {
    DrugAuditBulkResponse,
    DrugAuditPagedRequest,
    DrugAuditType,
    DrugDrugAuditBulkResponseLineAudit
} from "../../../../api/mm";
import {showErrorToast} from "../../../../utils/toastUtils";
import MedicineManagementApiModel from "../../../../store/apiModel/MedicineManagementApiModel";
import {useState} from "react";
import {StoreServiceData} from "store-fetch-wrappers";
import {formatUnixToDDMMYYYY, formatUnixToMMMMYYYY} from "../../../../utils/momentUtils";
import {jsonToCsv} from "../../../../utils/csvUtils";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {ControlDrugAudit} from "../../../../store/audit/helpers/controlDrugAuditHelpers";
import {
    AuditForm,
    QuestionWithAnswerAndComments
} from "../../../../store/audit/helpers/auditHelpers";

export function useControlDrugAuditDownload() {
    const [state, setState] = useState<StoreServiceData<DrugAuditBulkResponse>>({
        data: null,
        loading: false,
        error: null
    });

    async function downloadControlDrugAudits({
        createdDate
    }: Pick<DrugAuditPagedRequest, "createdDate">) {
        const filename = `Control Drug Audit - Medicare Group UK - ${formatUnixToMMMMYYYY(
            createdDate?.startDate || 0
        )}`;
        // The payload is the same, we don't want to make the request again and want to use the cache
        if (state.data && JSON.stringify(createdDate) === JSON.stringify(state.data.createdDate)) {
            downloadCsv(filename, state.data.lines || []);
            return;
        }

        setState({...state, loading: true});

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const audits: DrugAuditBulkResponse | undefined = await getControlDrugAudits({
            type: DrugAuditType.ControlledDrugAudit,
            createdDate,
            numPerPage: 10000,
            pageNum: 0
        });

        setState({...state, loading: false, data: audits});

        if (!audits) {
            showErrorToast("Could not download Control Drug Audit CSV");
            return;
        }
        downloadCsv(filename, audits.lines || []);
    }

    // Order rows by audit ID. If the audit ids are the same, we want to order by modified date to show the most recent modification first
    function sortLinesByModifiedDate(rows: DrugDrugAuditBulkResponseLineAudit[]) {
        return rows.sort((a, b) => {
            if (a.auditId === b.auditId) {
                return b.dateModified - a.dateModified;
            }

            return a.auditId - b.auditId;
        });
    }

    function downloadCsv(filename: string, rows: DrugDrugAuditBulkResponseLineAudit[]) {
        const csvPayload = generateDownloadableCsv(sortLinesByModifiedDate(rows));
        jsonToCsv(filename, csvPayload);
    }

    function generateDownloadableCsv(rows: DrugDrugAuditBulkResponseLineAudit[]) {
        const values: any[] = [getControlDrugAuditCsvHeader()];

        for (const row of rows) {
            values.push(getControlDrugAuditRow(row));
        }

        return values;
    }

    function getControlDrugAuditCsvHeader(): (string | number | undefined)[] {
        return [
            "",
            "Audit Type",
            "ID",
            "Date Created",
            "Created Author",
            "Date Modified",
            "Modified Author",
            "Reporting Manager",
            "Location",
            "Comments",
            "Key Locked Away",
            "Details",
            "Safe Locked",
            "Details",
            "Control Drug Book With Safe",
            "Details",
            "Stock Balance Correct",
            "Details",
            "Control Drugs Book Legible",
            "Details",
            "Correct Signatures",
            "Details",
            "All Logs Witnessed",
            "Details",
            "CCTV In Place",
            "Details"
        ];
    }

    function getControlDrugAuditRow({
        dateCreated,
        auditId,
        type,
        dateModified,
        originalAuthor,
        modifiedAuthor,
        locationName,
        comments,
        payload
    }: DrugDrugAuditBulkResponseLineAudit): (string | number | undefined)[] {
        const auditQuestions: AuditForm = JSON.parse(payload);
        return [
            "",
            getUiFriendlyText(type),
            auditId,
            formatUnixToDDMMYYYY(dateCreated),
            originalAuthor.staffName,
            formatUnixToDDMMYYYY(dateModified),
            modifiedAuthor.staffName,
            originalAuthor.staffName,
            locationName,
            comments || "N/A",
            ...getControlDrugAuditAnswers(auditQuestions.controlDrugAudit)
        ];
    }

    function getControlDrugAuditAnswers(audit: ControlDrugAudit | undefined) {
        // Should never hit this.
        if (!audit) return [];
        const {controlDrugAuditBaseQuestions} = audit;
        return Object.keys(controlDrugAuditBaseQuestions)
            .map((key) => {
                const question: QuestionWithAnswerAndComments =
                    controlDrugAuditBaseQuestions[
                        key as keyof typeof controlDrugAuditBaseQuestions
                    ];

                return [question.answer, question.comments];
            })
            .flat(1);
    }

    async function getControlDrugAudits(request: DrugAuditPagedRequest) {
        try {
            const response = await MedicineManagementApiModel.drugApi.getDrugAuditsBulk(request);

            return response.data;
        } catch (e: any) {
            showErrorToast("Could not get Control Drug Audit CSV");
        }
    }
    return {
        state,
        downloadControlDrugAudits
    };
}
