import React from "react";
import {DrugBatch, DrugType} from "../../../../api/mm";

import FormHeader from "../../../Form/FormHeader";
import FormRow from "../../../Form/FormRow";
import {DebounceInput} from "react-debounce-input";
import {useDispatch} from "react-redux";
import {
    saveDrugBatchToService,
    setDrugBatch
} from "../../../../store/drugBatch/actions/DrugBatchActions";
import {getDrugTypeFromString} from "../../../../utils/enumUtils";
import {clamp} from "../../../../utils/mathUtils";
import DatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {isDrugBatchFormValid} from "../Helpers/drugBatchFormHelpers";
import {routeNames} from "../../../Navigation/routeNames";
import {useHistory} from "react-router-dom";
import {showSuccessToast} from "../../../../utils/toastUtils";
import PulseEnumDropdown from "../../../Dropdown/PulseEnumDropdown";

const EditStockManagementForm = (props: DrugBatch) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const save = async () => {
        const isValid = isDrugBatchFormValid(props);

        if (!isValid) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        const saved: boolean = await dispatch(saveDrugBatchToService(props));

        if (!saved) return;

        backToStockManagement();
        showSuccessToast("Saved Drug Batch");
    };

    //This page can be accessed from medicine
    const backToStockManagement = () => {
        const queries = window.location.href.split("?")[1];

        //Check what the previous route was. If it is the stock management list, we check if it had queries, if so, append them to the route request.
        const previousRoute = localStorage.getItem("previous_route");
        if (previousRoute === routeNames.stockManagement.path) {
            const search = queries ? `?${queries}` : undefined;
            history.push({pathname: routeNames.stockManagement.path, search});
            return;
        }

        //Otherwise, we redirect back to here.
        history.push(routeNames.medicineManagement.path);
    };

    //https://codepen.io/chamsi/pen/LavooJ - For input prefixes
    return (
        <React.Fragment>
            <FormHeader headerName={"Stock Management Form"} />
            <FormRow rowName={"Drug Batch Name"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    value={props.name}
                    onChange={(event) => {
                        const {value} = event.target;

                        dispatch(
                            setDrugBatch({
                                ...props,
                                name: value
                            })
                        );
                    }}
                    className="input-fields"
                    placeholder="Enter drug batch name here..."
                />
            </FormRow>
            <FormRow rowName={"Drug Type"} columnDetailClassName={"pl-0 pr-0"}>
                <PulseEnumDropdown
                    enumOptions={DrugType}
                    onChange={(item) => {
                        if (!item) return;
                        dispatch(
                            setDrugBatch({
                                ...props,
                                type: getDrugTypeFromString(item.value.toString())
                            })
                        );
                    }}
                    value={props.type}
                    disabled={false}
                    searchable={false}
                    clearable={false}
                    appendToBody
                />
            </FormRow>
            <FormRow rowName={"Batch Number"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    value={props.batchNumber}
                    onChange={(event) => {
                        const {value} = event.target;

                        dispatch(
                            setDrugBatch({
                                ...props,
                                batchNumber: value
                            })
                        );
                    }}
                    className="input-fields"
                    placeholder="Enter batch number..."
                />
            </FormRow>
            <FormRow rowName={"Quantity"} columnDetailClassName={"pl-0 pr-0"}>
                <input
                    value={props.qty}
                    onChange={(event) => {
                        const {value} = event.target;
                        dispatch(
                            setDrugBatch({
                                ...props,
                                qty: +value
                            })
                        );
                    }}
                    className="input-fields"
                    placeholder="Enter quantity...."
                    type={"number"}
                />
            </FormRow>
            <FormRow rowName={"Expiry Date"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={moment(props.expiryDate * 1000).toDate()}
                    onChange={(date) => {
                        dispatch(
                            setDrugBatch({
                                ...props,
                                expiryDate: moment(date).unix()
                            })
                        );
                    }}
                    dateFormat="do MMMM yyyy"
                    portalId="root-portal"
                    customInput={<DatePickerInputButton />}
                />
            </FormRow>
            <FormRow
                rowName={"Low Stock Alert Level Threshold"}
                columnDetailClassName={"pl-0 pr-0"}
            >
                <input
                    value={props.lowStockQty}
                    onChange={(event) => {
                        const {value} = event.target;
                        dispatch(
                            setDrugBatch({
                                ...props,
                                lowStockQty: clamp(+value, 0, props.qty)
                            })
                        );
                    }}
                    className="input-fields"
                    placeholder="Enter low stock quantity...."
                    type={"number"}
                />
            </FormRow>
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Save"}
                    onClick={save}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Cancel"}
                    onClick={backToStockManagement}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default EditStockManagementForm;
