import React from "react";
import {IdNameTableProps, useIdNameTable} from "./Hooks/useIdNameTable";
import {PulseTable} from "pulse_table";
import NoItemsMessage from "../NoItemsMessage";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import TableActionContainer from "../TableActionContainer";

function IdNameTable<T>({itemName, ...rest}: IdNameTableProps<T>) {
    const {headers, items, newItem} = useIdNameTable({itemName, ...rest});
    return (
        <React.Fragment>
            <TableActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={`Add ${itemName}`}
                    onClick={newItem}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
            </TableActionContainer>
            <PulseTable
                items={items}
                headers={headers}
                noItemsSection={<NoItemsMessage message={`No ${itemName}(s) Exist`} />}
                customRenderers={{
                    action: (item) => item.action
                }}
            />
        </React.Fragment>
    );
}

export default IdNameTable;
