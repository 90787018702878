import {DrugPackWithContents} from "../../../../api/mm";
import {showErrorToast} from "../../../../utils/toastUtils";

export function isDrugPackWithContentsFormValid(props: DrugPackWithContents): boolean {
    //Check to see if the calendar name is invalid
    if (props.name.length < 3) {
        showErrorToast("Drug Pack name must contain 3 or more characters.");
        return false;
    }

    if (props.sealNumber.length < 3) {
        showErrorToast("Seal Number must contain 3 or more characters.");
        return false;
    }

    return true;
}
