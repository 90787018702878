import React from "react";
import {DrugPackCategoryInfo} from "../../../../../store/audit/helpers/auditHelpers";
import DrugPackWithAnswer from "./DrugPackWithAnswer";
import FormHeader from "../../../../Form/FormHeader";

const DrugPackCategoryInfoList = (props: DrugPackCategoryInfo) => {
    return (
        <React.Fragment>
            <FormHeader headerName={props.categoryName} />
            {props.drugPackInfoList.map((item, index) => {
                return (
                    <DrugPackWithAnswer
                        key={index}
                        index={index}
                        drugPackInfo={item}
                        categoryId={props.categoryId}
                    />
                );
            })}
        </React.Fragment>
    );
};

export default DrugPackCategoryInfoList;
