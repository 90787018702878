import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {
    createNewDrugBatch,
    nullifyDrugBatchStore
} from "../../../store/drugBatch/actions/DrugBatchActions";
import {RootStore} from "../../../store/Store";
import EditStockManagementForm from "./Components/EditStockManagementForm";
import {WithServiceState} from "store-fetch-wrappers";

const ServiceWrapper = WithServiceState(EditStockManagementForm);

const AddStockManagement = () => {
    const dispatch = useDispatch();
    const drugBatchStore = useSelector((state: RootStore) => state.drugBatch);

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    useEffect(() => {
        const nav: NavigationItem[] = [
            {
                name: routeNames.medicineManagement.name,
                path: routeNames.medicineManagement.path
            },
            {
                name: routeNames.stockManagement.name,
                path: routeNames.stockManagement.path
            }
        ];

        dispatch(setNavigationItems(nav));

        dispatch(createNewDrugBatch());

        return async function () {
            dispatch(nullifyDrugBatchStore());
        };
    }, []);

    return (
        <div className="page-container mt-4">
            <ServiceWrapper
                loaderWheelType={"three-ring"}
                loaderType={"overlay"}
                showLoadingText={true}
                placeholderBlockCount={1}
                {...drugBatchStore}
            />
        </div>
    );
};

export default AddStockManagement;
