import {useDispatch} from "react-redux";
import {DrugPackWithContents} from "../../../../api/mm";
import {
    deleteDrugPackById,
    saveDrugPackToService,
    setDrugPackWithContents
} from "../../../../store/drugPackWithContents/actions/DrugPackWithContentsActions";
import {isDrugPackWithContentsFormValid} from "../Helpers/drugPackWithContentsFormHelpers";
import {showSuccessToast} from "../../../../utils/toastUtils";
import {routeNames} from "../../../Navigation/routeNames";
import {useHistory} from "react-router-dom";
import {usePageUrl} from "../../../Hooks/usePageUrl";
import {useState} from "react";
import {
    fetchDrugPacksWithContentByLocationId,
    nullifyDrugPacksWithContentsListStore
} from "../../../../store/drugPackWithContentsList/actions/DrugPackWithContentsListActions";

export function useEditDrugPackWithContentsForm(props: DrugPackWithContents) {
    const dispatch = useDispatch();
    const history = useHistory();
    const {searchQueries} = usePageUrl();
    const [currentLocationId, setCurrentLocationId] = useState<number | undefined>(
        props.locationId
    );

    const save = async (candidate: DrugPackWithContents) => {
        const isValid = isDrugPackWithContentsFormValid(candidate);

        if (!isValid) {
            return;
        }

        // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
        // @ts-ignore
        const saved: boolean = await dispatch(saveDrugPackToService(candidate));

        if (!saved) {
            return;
        }
        backToMedicineManagement();
        showSuccessToast("Saved Drug Pack");
    };

    const onConfirmDeletion = async (candidate: DrugPackWithContents) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const deleted: boolean = await dispatch(deleteDrugPackById(candidate.id));

        if (!deleted) {
            return;
        }
        backToMedicineManagement();
        showSuccessToast("Deleted Drug Pack");
    };

    //Check for CID query. If it exists, append to the path
    const backToMedicineManagement = () => {
        const previousRoute = localStorage.getItem("previous_route");

        if (previousRoute && previousRoute.includes(routeNames.stockManagementAudit.path)) {
            history.push({pathname: previousRoute, search: searchQueries});
            return;
        }

        history.push({pathname: routeNames.medicineManagement.path, search: searchQueries});
    };

    function updateDrugPack(drugPack: DrugPackWithContents) {
        dispatch(setDrugPackWithContents(drugPack));

        if (drugPack.locationId === currentLocationId) return;
        setCurrentLocationId(drugPack.locationId);
        //If the location has been cleared, clear the store.
        if (!drugPack.locationId) {
            dispatch(nullifyDrugPacksWithContentsListStore());
            return;
        }
        dispatch(fetchDrugPacksWithContentByLocationId(drugPack.locationId));
    }

    return {
        updateDrugPack,
        onConfirmDeletion,
        save,
        backToMedicineManagement
    };
}
