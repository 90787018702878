import {DRUG_BATCH_PAGED_STORE} from "../actions/DrugBatchPagedActionTypes";
import {DrugBatchOrder, DrugBatchPageResponse} from "../../../api/mm";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugBatchPageResponse>(
    createBlankDrugBatchPageResponse()
);

const drugBatchPagedReducer = (
    state: StoreServiceData<DrugBatchPageResponse> = defaultState,
    action: ServiceActionTypes<DrugBatchPageResponse>
): StoreServiceData<DrugBatchPageResponse> =>
    createReducer(state, action, DRUG_BATCH_PAGED_STORE, () => showErrorToast(action.error));

export default drugBatchPagedReducer;

export function createBlankDrugBatchPageResponse(): DrugBatchPageResponse {
    return {
        numPerPage: 0,
        pageNum: 0,
        results: [],
        sortOrder: DrugBatchOrder.CreationDateAscending
    };
}
