import {DRUG_PACK_WITH_CONTENTS_STORE} from "../actions/DrugPackWithContentsActionTypes";
import {DrugPackWithContents} from "../../../api/mm";
import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<DrugPackWithContents>(null);

const drugPackWithContentsReducer = (
    state: StoreServiceData<DrugPackWithContents> = defaultState,
    action: ServiceActionTypes<DrugPackWithContents>
): StoreServiceData<DrugPackWithContents> =>
    createReducer(state, action, DRUG_PACK_WITH_CONTENTS_STORE, () => showErrorToast(action.error));

export default drugPackWithContentsReducer;
