import {DrugBatchPageResponse} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DRUG_BATCH_PAGED_STORE = createStoreState("drug_batch_paged");

interface DrugBatchPagedLoading extends StoreServiceData<DrugBatchPageResponse> {
    type: typeof DRUG_BATCH_PAGED_STORE.LOADING;
}
interface DrugBatchPagedError extends StoreServiceData<DrugBatchPageResponse> {
    type: typeof DRUG_BATCH_PAGED_STORE.ERROR;
}
interface DrugBatchPagedSuccess extends StoreServiceData<DrugBatchPageResponse> {
    type: typeof DRUG_BATCH_PAGED_STORE.SUCCESS;
}

export type DrugBatchPagedDispatchTypes =
    | DrugBatchPagedLoading
    | DrugBatchPagedError
    | DrugBatchPagedSuccess;
