import {DrugPackCategory} from "../../../../api/mm";
import {showErrorToast} from "../../../../utils/toastUtils";

export function isDrugPackCategoryFormValid(props: DrugPackCategory): boolean {
    //Check to see if the calendar name is invalid
    if (props.name.length < 3) {
        showErrorToast("Drug Pack Category name must contain 3 or more characters.");
        return false;
    }

    return true;
}
