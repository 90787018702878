import {Dispatch} from "redux";
import store from "../../Store";
import {
    DrugBatch,
    DrugBatchAdministration,
    DrugBatchDisposal,
    DrugBatchMovement,
    DrugPack,
    GeneralReportRequest,
    GeneralReportResponse
} from "../../../api/mm";
import {fetchGeneralReports} from "../../generalReports/actions/GeneralReportActions";
import {
    DrugBatchAction,
    STATUS_REPORT_STORE_STATE,
    StatusReport,
    StatusReportDispatchTypes
} from "./StatusReportActionTypes";
import {getRemainingStock} from "../../../components/Pages/StockManagementList/Helpers/stockManagementListHelpers";

export const nullifyStatusReportStore = () => {
    return async (dispatch: Dispatch<StatusReportDispatchTypes>) => {
        dispatch({
            type: STATUS_REPORT_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
};

export const getStatusReport = (request: GeneralReportRequest) => {
    return async (dispatch: Dispatch<StatusReportDispatchTypes>) => {
        try {
            dispatch({
                type: STATUS_REPORT_STORE_STATE.LOADING,
                error: null,
                loading: true
            });

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const generalReports: GeneralReportResponse = await store.dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                fetchGeneralReports(request)
            );

            if (!generalReports) {
                dispatch({
                    type: STATUS_REPORT_STORE_STATE.ERROR,
                    error: `Could not generate status reports`,
                    loading: false
                });
                return;
            }

            dispatch({
                type: STATUS_REPORT_STORE_STATE.SUCCESS,
                error: null,
                loading: false,
                data: generateStatusReport(generalReports)
            });
        } catch (e: any) {
            dispatch({
                type: STATUS_REPORT_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};

export function generateStatusReport(data: GeneralReportResponse): StatusReport[] {
    const {drugPacks, drugBatches} = data;
    const statusReport: StatusReport[] = [];

    for (const drugBatch of drugBatches) {
        //Push the drug batch here to the status report as this accounts for the drug that is in Stock Management and its status.
        statusReport.push({
            drugBatchName: drugBatch.name,
            drugBatchId: drugBatch.id,
            creationDate: drugBatch.creationDate,
            expiryDate: drugBatch.expiryDate,
            batchNumber: drugBatch.batchNumber,
            action: DrugBatchAction.Stock,
            qty: getRemainingStock(drugBatch),
            drugPackName: "Stock",
            drugPackId: 0
        });

        for (const movement of drugBatch.movements) {
            const movementStatus = getStatusReportForMovement(movement, drugBatch, drugPacks);
            //Should never be undefined. Due to branch statements
            if (!movementStatus) continue;

            statusReport.push(movementStatus);
        }

        for (const administration of drugBatch.administrations) {
            const administrationStatus = getStatusReportForAdministration(
                administration,
                drugBatch,
                drugPacks
            );
            statusReport.push(administrationStatus);
        }

        for (const disposal of drugBatch.disposals) {
            const disposalStatus = getStatusReportForDisposal(disposal, drugBatch, drugPacks);

            statusReport.push(disposalStatus);
        }
    }
    return statusReport;
}

//Gets drug pack based on the Id given to it, returns a version of a deleted drugPack if it cannot find the one in question
function getDrugPack(drugPackId: number, drugPacks: DrugPack[]): DrugPack {
    const index = drugPacks.findIndex((el: DrugPack) => el.id === drugPackId);

    return index > -1
        ? {
              ...drugPacks[index]
          }
        : {
              id: 0,
              categoryId: 0,
              sealNumber: "Deleted Drug Pack",
              name: "Deleted Drug Pack"
          };
}

//Gets movement of a drug batch and will convert into a status report object.
export function getStatusReportForMovement(
    movement: DrugBatchMovement,
    drugBatch: DrugBatch,
    drugPacks: DrugPack[]
): StatusReport | undefined {
    if (movement.fromDrugPackId) {
        const fromDrugPack = getDrugPack(movement.fromDrugPackId, drugPacks);
        const toDrugPack = movement.toDrugPackId
            ? getDrugPack(movement.toDrugPackId, drugPacks)
            : undefined;

        const drugPackName = `${fromDrugPack.name} ${
            toDrugPack ? ` -> ${toDrugPack.name}` : ""
        }`.trim();

        const action = movement.toDrugPackId
            ? DrugBatchAction.MovedBetweenDrugPacks
            : DrugBatchAction.RemovedFromDrugPack;

        return {
            drugBatchName: drugBatch.name,
            drugBatchId: drugBatch.id,
            creationDate: drugBatch.creationDate,
            expiryDate: drugBatch.expiryDate,
            batchNumber: drugBatch.batchNumber,
            action,
            qty: movement.qty,
            actionedBy: {staffName: movement.staffName, staffId: movement.staffId},
            drugPackName: drugPackName,
            drugPackId: fromDrugPack.id,
            comments: movement.comments
        };
    }

    if (movement.toDrugPackId) {
        const drugPack = getDrugPack(movement.toDrugPackId, drugPacks);

        return {
            drugBatchName: drugBatch.name,
            drugBatchId: drugBatch.id,
            creationDate: drugBatch.creationDate,
            expiryDate: drugBatch.expiryDate,
            batchNumber: drugBatch.batchNumber,
            action: DrugBatchAction.AddedToDrugPack,
            qty: movement.qty,
            actionedBy: {staffName: movement.staffName, staffId: movement.staffId},
            drugPackName: drugPack.name,
            drugPackId: drugPack.id,
            comments: movement.comments
        };
    }
}

//Gets administration of a drug batch and will convert into a status report object.
export function getStatusReportForAdministration(
    administration: DrugBatchAdministration,
    drugBatch: DrugBatch,
    drugPacks: DrugPack[]
): StatusReport {
    //Administered from stock
    if (!administration.drugPackId) {
        return {
            drugBatchName: drugBatch.name,
            drugBatchId: drugBatch.id,
            creationDate: drugBatch.creationDate,
            expiryDate: drugBatch.expiryDate,
            batchNumber: drugBatch.batchNumber,
            action: DrugBatchAction.Administered,
            qty: administration.qty,
            qa: administration.qa,
            dosage: administration.dosage,
            wastage: administration.wastage,
            grsLocation: administration.grsLocation,
            drugPackId: 0,
            drugPackName: "Stock",
            administrationMethod: administration.routeAdministration,
            comments: administration.comments,
            history: administration.history ? administration.history.reverse() : undefined,
            actionedBy: {
                staffId: administration.staffId
                    ? administration.staffId
                    : "No Staff Member Selected",
                staffName: administration.staffName
                    ? administration.staffName
                    : "No Staff Member Selected"
            },
            witnessedBy:
                administration.witnessId && administration.witnessName
                    ? {
                          staffId: administration.witnessId,
                          staffName: administration.witnessName
                      }
                    : undefined
        };
    }

    //Administered from drug pack
    const drugPack = getDrugPack(administration.drugPackId, drugPacks);
    return {
        drugBatchName: drugBatch.name,
        drugBatchId: drugBatch.id,
        creationDate: drugBatch.creationDate,
        expiryDate: drugBatch.expiryDate,
        batchNumber: drugBatch.batchNumber,
        action: DrugBatchAction.Administered,
        qty: administration.qty,
        qa: administration.qa,
        dosage: administration.dosage,
        wastage: administration.wastage,
        grsLocation: administration.grsLocation,
        drugPackId: drugPack.id,
        drugPackName: drugPack.name,
        administrationMethod: administration.routeAdministration,
        comments: administration.comments,
        history: administration.history ? administration.history.reverse() : undefined,
        actionedBy: {
            staffId: administration.staffId ? administration.staffId : "No Staff Member Selected",
            staffName: administration.staffName
                ? administration.staffName
                : "No Staff Member Selected"
        },
        witnessedBy:
            administration.witnessId && administration.witnessName
                ? {
                      staffId: administration.witnessId,
                      staffName: administration.witnessName
                  }
                : undefined
    };
}

//Gets disposal of a drug batch and will convert into a status report object.
export function getStatusReportForDisposal(
    disposal: DrugBatchDisposal,
    drugBatch: DrugBatch,
    drugPacks: DrugPack[]
): StatusReport {
    //Disposed from stock.
    if (!disposal.drugPackId) {
        return {
            drugBatchName: drugBatch.name,
            drugBatchId: drugBatch.id,
            creationDate: drugBatch.creationDate,
            expiryDate: drugBatch.expiryDate,
            batchNumber: drugBatch.batchNumber,
            action: DrugBatchAction.Disposed,
            qty: disposal.qty,
            broken: disposal.broken,
            denatured: disposal.denatured,
            grsLocation: disposal.grsLocation,
            drugPackId: 0,
            drugPackName: "Stock",
            comments: disposal.comments,
            actionedBy: {staffId: disposal.staffId, staffName: disposal.staffName},
            witnessedBy: {
                staffId: disposal.witnessId ? disposal.witnessId : "",
                staffName: disposal.witnessName ? disposal.witnessName : ""
            }
        };
    }

    //Disposed from drug pack
    const drugPack = getDrugPack(disposal.drugPackId, drugPacks);
    return {
        drugBatchName: drugBatch.name,
        drugBatchId: drugBatch.id,
        creationDate: drugBatch.creationDate,
        expiryDate: drugBatch.expiryDate,
        batchNumber: drugBatch.batchNumber,
        action: DrugBatchAction.Disposed,
        qty: disposal.qty,
        broken: disposal.broken,
        denatured: disposal.denatured,
        grsLocation: disposal.grsLocation,
        drugPackId: drugPack.id,
        drugPackName: drugPack.name,
        comments: disposal.comments,
        actionedBy: {staffId: disposal.staffId, staffName: disposal.staffName},
        witnessedBy: {
            staffId: disposal.witnessId ? disposal.witnessId : "",
            staffName: disposal.witnessName ? disposal.witnessName : ""
        }
    };
}
