import React from "react";

const PrintableH6 = (props: PrintableH6Props) => {
    return <h6 style={{marginBottom: `0`, fontWeight: `bold`}}>{props.headerName}</h6>;
};

export default PrintableH6;

interface PrintableH6Props {
    headerName: string;
}
