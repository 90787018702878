import {Location} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const LOCATION_STORE = createStoreState("location");

interface LocationLoading extends StoreServiceData<Location> {
    type: typeof LOCATION_STORE.LOADING;
}
interface LocationError extends StoreServiceData<Location> {
    type: typeof LOCATION_STORE.LOADING;
}
interface LocationSuccess extends StoreServiceData<Location> {
    type: typeof LOCATION_STORE.LOADING;
}

export type LocationDispatchTypes = LocationLoading | LocationError | LocationSuccess;
