import React from "react";
import {DrugAudit, DrugAuditType} from "../../../../api/mm";
import {getUiFriendlyText} from "../../../../utils/textUtils";
import {useDispatch} from "react-redux";
import {setAuditType} from "../../../../store/audit/actions/AuditActions";
import {useIsAuditEditable} from "../../../Hooks/useIsAuditEditable";
import FormHeader from "../../../Form/FormHeader";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {showErrorToast} from "../../../../utils/toastUtils";

const AuditTypeButtons = (props: DrugAudit) => {
    const dispatch = useDispatch();
    const isAuditEditable = useIsAuditEditable();
    const onAuditTypeSelected = (audit: string) => {
        if (props.locationId === 0) {
            showErrorToast("Please select a location before continuing.");
            return;
        }
        const selectedAudit = getAuditTypeFromString(audit);

        if (selectedAudit === props.type) return;

        dispatch(setAuditType(selectedAudit));
    };

    const canButtonBeShown = (audit: string) => {
        const correctEnum = getAuditTypeFromString(audit);
        switch (correctEnum) {
            case DrugAuditType.None:
                return false;
            default:
                return true;
        }
    };
    return (
        <React.Fragment>
            <FormHeader headerName={"Select Audit"} />
            <div className="mt-3 mb-3">
                <div className="row ml-0 mr-0">
                    {Object.keys(DrugAuditType).map((audit: string, index: number) => {
                        if (canButtonBeShown(audit)) {
                            return (
                                <div className="col-lg-6 report-button" key={index}>
                                    <MCButton
                                        size={ButtonSize.ExtraLarge}
                                        innerValue={getUiFriendlyText(audit)}
                                        className="w-100"
                                        onClick={() => onAuditTypeSelected(audit)}
                                        colour={
                                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                            // @ts-ignore
                                            props.type === DrugAuditType[audit]
                                                ? ButtonColourOptions.Yellow
                                                : ButtonColourOptions.DarkBlue
                                        }
                                        disabled={!isAuditEditable || props.id > 0}
                                    />
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        </React.Fragment>
    );
};

export default AuditTypeButtons;

function getAuditTypeFromString(value: string): DrugAuditType {
    return DrugAuditType[value as keyof typeof DrugAuditType];
}
