import React from "react";
import {DrugBatch, DrugBatchAdministration} from "../../../../api/mm";
import {useAdministrationForm} from "../Hooks/useAdministrationForm";
import FormRow from "../../../Form/FormRow";
import DatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputButton from "../../../Button/DatePickerInputButton";
import Toggle, {ToggleSize} from "../../../Toggle/Toggle";
import StaffWitnessWithLocationSection, {
    NominalActionType
} from "../../Shared/StaffWitnessWithLocationSection";
import StaffWitnessSection from "../../Shared/StaffWitnessSection";
import {clamp} from "../../../../utils/mathUtils";
import {DebounceInput} from "react-debounce-input";
import FormActionContainer from "../../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import AdministrationStage2Fields from "./AdministrationStage2Fields";

function AdministrationForm({
    initialAdministration,
    drugBatch,
    maxQtyAvailable,
    onSave,
    isStage2,
    onClose
}: Props) {
    const {
        administration,
        toggleAdministrationLocation,
        administeredOnLocation,
        updateAdministration
    } = useAdministrationForm(initialAdministration);
    return (
        <React.Fragment>
            <div className="modal-form">
                <FormRow rowName={"Drug Name"}>
                    <p className="mb-0">{drugBatch.name}</p>
                </FormRow>
                <FormRow rowName={"Batch Number"}>
                    <p className="mb-0">{drugBatch.batchNumber}</p>
                </FormRow>
                <FormRow rowName={"Date of Administration"} columnDetailClassName={"pl-0 pr-0"}>
                    <DatePicker
                        selected={moment.unix(administration.date).toDate()}
                        onChange={(date) => {
                            if (!date) return;
                            updateAdministration({
                                ...administration,
                                date: moment(date).unix()
                            });
                        }}
                        dateFormat="do MMMM yyyy"
                        customInput={<DatePickerInputButton />}
                    />
                </FormRow>
                <FormRow rowName={"Administered on Location?"}>
                    <Toggle
                        checked={administeredOnLocation}
                        onChange={() => {
                            toggleAdministrationLocation(administeredOnLocation);
                        }}
                        size={ToggleSize.Large}
                    />
                </FormRow>
                {administeredOnLocation ? (
                    <StaffWitnessWithLocationSection
                        request={administration}
                        updateRequest={(entity: Partial<DrugBatchAdministration>) => {
                            updateAdministration({
                                ...administration,
                                ...entity
                            });
                        }}
                        action={NominalActionType.Administration}
                    />
                ) : (
                    <StaffWitnessSection
                        request={administration}
                        updateRequest={(entity: Partial<DrugBatchAdministration>) => {
                            updateAdministration({
                                ...administration,
                                ...entity
                            });
                        }}
                        action={NominalActionType.Administration}
                    />
                )}
                <FormRow rowName={"CAD Number"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        value={administration.cadNumber}
                        onChange={(event) => {
                            const {value} = event.target;
                            updateAdministration({
                                ...administration,
                                cadNumber: value
                            });
                        }}
                        className="input-fields"
                        placeholder="Enter CAD number here..."
                    />
                </FormRow>
                {isStage2 && (
                    <AdministrationStage2Fields
                        administration={administration}
                        onChange={updateAdministration}
                    />
                )}
                <FormRow rowName={"Quantity administered"} columnDetailClassName={"pl-0 pr-0"}>
                    <div className="input-group suffix">
                        <input
                            value={administration.qty}
                            onChange={(event) => {
                                const value = isNaN(+event.target.value) ? 0 : +event.target.value;
                                //The max value that can be moved. We alter the max depending on the object.
                                const clampedValue = clamp(value, 0, maxQtyAvailable);
                                updateAdministration({
                                    ...administration,
                                    qty: clampedValue
                                });
                            }}
                            className="input-fields"
                            placeholder="Enter quantity administered...."
                            required={true}
                            type="tel"
                            min={0}
                            step={1}
                            max={drugBatch.qty}
                        />
                        <span className="input-group-addon ">
                            <b>Max: {maxQtyAvailable}</b>
                        </span>
                    </div>
                </FormRow>
                <FormRow rowName={"Comments?"} columnDetailClassName={"pl-0 pr-0"}>
                    <DebounceInput
                        debounceTimeout={300}
                        value={administration.comments}
                        onChange={(event) => {
                            const {value} = event.target;
                            updateAdministration({
                                ...administration,
                                comments: value
                            });
                        }}
                        element="textarea"
                        className="input-fields text-area-inputs"
                        placeholder="Enter comments here..."
                    />
                </FormRow>
                <FormActionContainer>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Administer"}
                        onClick={() => onSave(administration)}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={onClose}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </FormActionContainer>
            </div>
        </React.Fragment>
    );
}

export default AdministrationForm;

interface Props {
    initialAdministration: DrugBatchAdministration;
    drugBatch: DrugBatch;
    onSave: (entity: DrugBatchAdministration) => void;
    onClose: () => void;
    isStage2?: boolean;
    maxQtyAvailable: number;
}
