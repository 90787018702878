import {useEffect, useState} from "react";
import {BaseDropdownProps, DropdownOption} from "../Helpers/dropdownUtils";
import {useDispatch, useSelector} from "react-redux";
import store, {RootStore} from "../../../store/Store";
import {SingleValue} from "react-select";

export function useDropdown<T>({
    storeName,
    storeKeyValue,
    storeKeyLabel,
    onChange,
    id,
    reduxDispatchClear,
    reduxDispatchFetch
}: ServiceDropdownProps<T>) {
    const [options, setOptions] = useState<DropdownOption[]>([]);
    const [selectedOption, setSelectedOption] = useState<DropdownOption | null>();
    const reduxStore = useSelector((state: RootStore) => state[storeName]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (reduxDispatchFetch) {
            dispatch(reduxDispatchFetch());
        }
        return () => {
            if (reduxDispatchClear) {
                dispatch(reduxDispatchClear());
            }
        };
    }, []);

    useEffect(() => {
        if (!reduxStore) return;

        const {data} = reduxStore;
        if (!Array.isArray(data)) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        processDataIncoming(data);
    }, [reduxStore]);

    useEffect(() => {
        if (!id) return;
        if (options.length === 0) return;

        const option = options.find((item) => item.value == id);

        setSelectedOption(option);
    }, [id, options]);

    function processDataIncoming(data: T[]) {
        const generated = getDropdownOptionsFromReduxStore(data);
        setOptions(generated);
    }

    function getDropdownOptionsFromReduxStore(list: T[]): DropdownOption[] {
        return list.map((item) => {
            return {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                value: item[storeKeyValue],
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                label: item[storeKeyLabel]
            };
        });
    }

    function handleDropdownChange(newValue: SingleValue<DropdownOption>): void {
        setSelectedOption(newValue);
        const onChangeOption = getReduxStoreObjectFromDropdownOption(newValue);
        onChange(onChangeOption);
    }

    function getReduxStoreObjectFromDropdownOption(
        entity: SingleValue<DropdownOption>
    ): T | undefined | null {
        if (!entity) return;
        if (!reduxStore) return;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        return reduxStore.data.find((item: T) => item[storeKeyValue] == entity.value);
    }

    return {
        options,
        selectedOption,
        handleDropdownChange
    };
}

export interface ServiceDropdownProps<T> extends BaseDropdownProps<T> {
    storeName: StoreNames;
    storeKeyValue: string;
    storeKeyLabel: string;
    id?: string | number;
    reduxDispatchFetch?: () => void;
    reduxDispatchClear?: () => void;
}

const state = store.getState();
type StoreNames = keyof typeof state;
