import {Dispatch} from "redux";
import MedicineManagementApiModel from "../../apiModel/MedicineManagementApiModel";
import {DISPOSE_DRUGS_STORE, DisposeDrugsDispatchTypes} from "./DisposeDrugsActionTypes";
import {DisposeDrugsRequest} from "../../../api/mm";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

export const disposeDrugs = (request: DisposeDrugsRequest) => {
    return async (dispatch: Dispatch<DisposeDrugsDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                DISPOSE_DRUGS_STORE,
                dispatch,
                () => MedicineManagementApiModel.drugApi.disposeDrugs(request),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DISPOSE_DRUGS_STORE.ERROR,
                error: e,
                loading: false
            });
        }
    };
};
