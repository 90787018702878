import React from "react";
import {DrugBatchActionFormProps} from "../Helpers/drugActionFormHelpers";
import moment from "moment";
import FormRow from "../../Form/FormRow";
import DatePicker from "react-datepicker";
import DatePickerInputButton from "../../Button/DatePickerInputButton";
import Toggle, {ToggleSize} from "../../Toggle/Toggle";
import {DebounceInput} from "react-debounce-input";
import FormActionContainer from "../../Form/FormActionContainer";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import {useDisposeDrugBatchForm} from "./Hooks/useDisposeDrugBatchForm";
import StaffWitnessWithLocationSection, {
    NominalActionType
} from "../Shared/StaffWitnessWithLocationSection";
import {DrugBatchDisposal} from "../../../api/mm";
import StaffWitnessSection from "../Shared/StaffWitnessSection";
import {clamp} from "../../../utils/mathUtils";

const DisposeDrugBatchForm = (props: DrugBatchActionFormProps) => {
    const {disposal, updateDisposal, toggleDisposalLocation, disposedOnLocation, disposeDrug} =
        useDisposeDrugBatchForm(props);
    return (
        <React.Fragment>
            <FormRow rowName={"Drug Name"}>
                <p className="mb-0">{props.drugBatch.name}</p>
            </FormRow>
            <FormRow rowName={"Date of Disposal"} columnDetailClassName={"pl-0 pr-0"}>
                <DatePicker
                    selected={moment.unix(disposal.date).toDate()}
                    onChange={(date) => {
                        if (!date) return;
                        updateDisposal({
                            ...disposal,
                            date: moment(date).unix()
                        });
                    }}
                    dateFormat="do MMMM yyyy"
                    customInput={<DatePickerInputButton />}
                />
            </FormRow>
            <FormRow rowName={"Disposed on Location?"}>
                <Toggle
                    checked={disposedOnLocation}
                    onChange={() => {
                        toggleDisposalLocation(disposedOnLocation);
                    }}
                    size={ToggleSize.Large}
                />
            </FormRow>
            {disposedOnLocation ? (
                <StaffWitnessWithLocationSection
                    request={disposal}
                    updateRequest={(entity: Partial<DrugBatchDisposal>) => {
                        updateDisposal({
                            ...disposal,
                            ...entity
                        });
                    }}
                    action={NominalActionType.Disposal}
                />
            ) : (
                <StaffWitnessSection
                    request={disposal}
                    updateRequest={(entity: Partial<DrugBatchDisposal>) => {
                        updateDisposal({
                            ...disposal,
                            ...entity
                        });
                    }}
                    action={NominalActionType.Disposal}
                />
            )}
            <FormRow rowName={"Broken?"}>
                <Toggle
                    checked={disposal.broken}
                    onChange={() => {
                        let broken = disposal.broken;
                        updateDisposal({
                            ...disposal,
                            broken: (broken = !broken)
                        });
                    }}
                    size={ToggleSize.Large}
                />
            </FormRow>
            <FormRow rowName={"Denatured?"}>
                <Toggle
                    checked={disposal.denatured}
                    onChange={() => {
                        let denatured = disposal.denatured;
                        updateDisposal({
                            ...disposal,
                            denatured: (denatured = !denatured)
                        });
                    }}
                    size={ToggleSize.Large}
                />
            </FormRow>
            <FormRow rowName={"Quantity disposed?"} columnDetailClassName={"pl-0 pr-0"}>
                <div className="input-group suffix">
                    <input
                        value={disposal.qty}
                        onChange={(event) => {
                            const value = isNaN(+event.target.value) ? 0 : +event.target.value;
                            //The max value that can be moved. We alter the max depending on the object.
                            const clampedValue = clamp(value, 0, props.maxQtyAvailable);
                            updateDisposal({
                                ...disposal,
                                qty: clampedValue
                            });
                        }}
                        className="input-fields"
                        placeholder="Enter quantity disposed...."
                        required={true}
                        type="tel"
                        min={0}
                        step={1}
                        max={props.drugBatch.qty}
                    />
                    <span className="input-group-addon ">
                        <b>Max: {props.maxQtyAvailable}</b>
                    </span>
                </div>
            </FormRow>
            <FormRow rowName={"Comments"} columnDetailClassName={"pl-0 pr-0"}>
                <DebounceInput
                    debounceTimeout={300}
                    value={disposal.comments}
                    element="textarea"
                    onChange={(event) => {
                        const {value} = event.target;

                        updateDisposal({
                            ...disposal,
                            comments: value
                        });
                    }}
                    className="input-fields text-area-inputs"
                    placeholder="Enter comments here..."
                    required={true}
                />
            </FormRow>
            <FormActionContainer>
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Dispose"}
                    onClick={disposeDrug}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Cancel"}
                    onClick={props.onClose}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            </FormActionContainer>
        </React.Fragment>
    );
};

export default DisposeDrugBatchForm;
