import React from "react";
import {Switch, Redirect} from "react-router-dom";

/** Auth Pages */
import Login from "../../Pages/Auth/Login/Login";
import LoggedIn from "../../Pages/Auth/Loggedin/LoggedIn";
import NotFound from "../../Pages/Auth/NotFound/NotFound";
import NotAuthorised from "../../Pages/Auth/NotAuthorised/NotAuthorised";
import InActive from "../../Pages/Auth/InActive/InActive";
import Logout from "../../Pages/Auth/Logout/Logout";

import {routeNames} from "../routeNames";
import {GuardProvider} from "react-router-guards";
import {StaffAccessLevel} from "../../../api/staff";
import store from "../../../store/Store";
import {LoadingWheel, Error} from "react-state-helpers";

/** Route specific items */
import CustomGuardedRoute from "./CustomGuardedRoute";
import MedicareLayout from "../../Pages/Layouts/Layout/MedicareLayout";
import NoNavLayout from "../../Pages/Layouts/Layout/NoNavLayout";
import StockManagement from "../../Pages/StockManagementList/StockManagement";
import ActiveStock from "../../Pages/ActiveStock/ActiveStock";
import AddStockManagement from "../../Pages/StockManagement/AddStockManagement";
import EditStockManagement from "../../Pages/StockManagement/EditStockManagement";
import StockManagementAudit from "../../Pages/StockManagementAudit/StockManagementAudit";
import MedicineManagement from "../../Pages/MedicineManagement/MedicineManagement";
import AddDrugPackCategory from "../../Pages/DrugPackCategory/AddDrugPackCategory";
import EditDrugPackCategory from "../../Pages/DrugPackCategory/EditDrugPackCategory";
import AddDrugPackWithContents from "../../Pages/DrugPackWithContents/AddDrugPackWithContents";
import EditDrugPackWithContents from "../../Pages/DrugPackWithContents/EditDrugPackWithContents";
import AuditList from "../../Pages/AuditList/AuditList";
import LocationList from "../../Pages/LocationList/LocationList";
import EditLocation from "../../Pages/Location/EditLocation";
import AddLocation from "../../Pages/Location/AddLocation";
import HistoricAuditList from "../../Pages/HistoricAuditList/HistoricAuditList";
import NewAudit from "../../Pages/Audit/NewAudit";
import EditAudit from "../../Pages/Audit/EditAudit";
import ViewAudit from "../../Pages/Audit/ViewAudit";
import HistoricAudit from "../../Pages/Audit/HistoricAudit";
import GeneralReports from "../../Pages/Reports/GeneralReports";
import DisposalReport from "../../Pages/Reports/Report/Disposal/DisposalReport";
import ExpiryReport from "../../Pages/Reports/Report/Expiry/ExpiryReport";
import StatusReport from "../../Pages/Reports/Report/Status/StatusReport";
import AdministrationReport from "../../Pages/Reports/Report/Administration/AdministrationReport";
import DrugPackCategoryList from "../../Pages/DrugPackCategoryList/DrugPackCategoryList";
import {getConfig} from "../../../McConfigPlugin";
import {getUserAccessLevel} from "../../../utils/userDataUtils";

const requireLogin = async (to: any, from: any, next: any) => {
    const accessLevels = to.meta.auth?.accessLevels as Array<StaffAccessLevel>;
    //No auth required, carry on
    if (!accessLevels) {
        next();
        return;
    }

    const prevRoutesBlackList = [
        routeNames.login.path,
        routeNames.loggedin.path,
        routeNames.notFound.path,
        routeNames.notFound.path,
        routeNames.inActive.path,
        routeNames.logout.path
    ];

    const idx = prevRoutesBlackList.findIndex((route: string) => route === from.location.pathname);

    if (idx < 0) {
        localStorage.setItem("previous_route", from.location.pathname);
    }

    localStorage.setItem("current_route", to.location.pathname);

    const authenticated = store.getState().auth.authenticated;
    const user = store.getState().auth.data;

    if (!authenticated) {
        next.redirect(routeNames.login.path);
        return;
    }

    if (user) {
        const config = await getConfig();

        const userAccessLevel = getUserAccessLevel(user, config);
        const index = accessLevels.findIndex((x) => x === userAccessLevel);
        if (index < 0) {
            next.redirect(routeNames.notAuthorised.path);
            return;
        }
    }

    next();
};

const Routes = () => {
    return (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        <GuardProvider guards={[requireLogin]} loading={LoadingWheel} error={Error}>
            <Switch>
                <CustomGuardedRoute
                    exact
                    path={routeNames.login.path}
                    layout={NoNavLayout}
                    component={Login}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.loggedin.path}
                    layout={NoNavLayout}
                    component={LoggedIn}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.notAuthorised.path}
                    layout={MedicareLayout}
                    component={NotAuthorised}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.inActive.path}
                    layout={NoNavLayout}
                    component={InActive}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.logout.path}
                    layout={NoNavLayout}
                    component={Logout}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.drugPackCategoryList.path}
                    component={DrugPackCategoryList}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.drugPackCategoryAdd.path}
                    component={AddDrugPackCategory}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.drugPackCategoryEdit.path}/:id`}
                    component={EditDrugPackCategory}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.medicineManagement.path}
                    component={MedicineManagement}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.drugPackWithContentsAdd.path}
                    component={AddDrugPackWithContents}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.drugPackWithContentsEdit.path}/:id`}
                    component={EditDrugPackWithContents}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.stockManagement.path}
                    component={StockManagement}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.stockManagementAdd.path}
                    component={AddStockManagement}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.stockManagementEdit.path}/:id`}
                    component={EditStockManagement}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.stockManagementAudit.path}/:id`}
                    component={StockManagementAudit}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.viewActiveStock.path}
                    component={ActiveStock}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.generalReports.path}
                    component={GeneralReports}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.disposalReports.path}
                    component={DisposalReport}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.administrationReports.path}
                    component={AdministrationReport}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.expiryReports.path}
                    component={ExpiryReport}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.statusReports.path}
                    component={StatusReport}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.mmAuditsList.path}
                    component={AuditList}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.newAudit.path}
                    component={NewAudit}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.editAudit.path}/:auditId`}
                    component={EditAudit}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.viewAudit.path}/:auditId`}
                    component={ViewAudit}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [
                                StaffAccessLevel.SystemAdministrator,
                                StaffAccessLevel.DutyManager
                            ]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.historicAuditList.path}/:auditId`}
                    component={HistoricAuditList}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.historicAuditView.path}/:auditId/:historicId`}
                    component={HistoricAudit}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.auditLocationList.path}
                    component={LocationList}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.auditLocationAdd.path}
                    component={AddLocation}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={`${routeNames.auditLocationEdit.path}/:locationId`}
                    component={EditLocation}
                    layout={MedicareLayout}
                    meta={{
                        auth: {
                            accessLevels: [StaffAccessLevel.SystemAdministrator]
                        }
                    }}
                />
                <CustomGuardedRoute
                    exact
                    path={routeNames.notFound.path}
                    layout={MedicareLayout}
                    component={NotFound}
                />
                <CustomGuardedRoute
                    path="/"
                    render={(): JSX.Element => <Redirect to={routeNames.login.path} />}
                />

                <CustomGuardedRoute
                    render={(): JSX.Element => <Redirect to={routeNames.notFound.path} />}
                />
            </Switch>
        </GuardProvider>
    );
};

export default Routes;
