import {DrugPackWithContents} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DRUG_PACK_WITH_CONTENTS_STORE = createStoreState("drug_pack_with_contents");

interface DrugPackWithContentsLoading extends StoreServiceData<DrugPackWithContents> {
    type: typeof DRUG_PACK_WITH_CONTENTS_STORE.LOADING;
}
interface DrugPackWithContentsError extends StoreServiceData<DrugPackWithContents> {
    type: typeof DRUG_PACK_WITH_CONTENTS_STORE.ERROR;
}
interface DrugPackWithContentsSuccess extends StoreServiceData<DrugPackWithContents> {
    type: typeof DRUG_PACK_WITH_CONTENTS_STORE.SUCCESS;
}

export type DrugPackWithContentsDispatchTypes =
    | DrugPackWithContentsLoading
    | DrugPackWithContentsError
    | DrugPackWithContentsSuccess;
