import {DrugPackCategory} from "../../../api/mm";
import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DRUG_PACK_CATEGORY_LIST_STORE = createStoreState("drug_pack_category_list");

interface DrugPackCategoryListLoading extends StoreServiceData<DrugPackCategory[]> {
    type: typeof DRUG_PACK_CATEGORY_LIST_STORE.LOADING;
}
interface DrugPackCategoryListError extends StoreServiceData<DrugPackCategory[]> {
    type: typeof DRUG_PACK_CATEGORY_LIST_STORE.ERROR;
}
interface DrugPackCategoryListSuccess extends StoreServiceData<DrugPackCategory[]> {
    type: typeof DRUG_PACK_CATEGORY_LIST_STORE.SUCCESS;
}

export type DrugPackCategoryListDispatchTypes =
    | DrugPackCategoryListLoading
    | DrugPackCategoryListError
    | DrugPackCategoryListSuccess;
